import { AddPhotoAlternate, DateRange, Description, Help, MonetizationOn, Money, Person, QuestionMark } from '@mui/icons-material'
import { Box, Button, Stack, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import './createaidlounge.css'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const CreateAidLounge = ({uuid, onCancel}) => {
    const [isCreating, setIsCreating] = useState(false);
    const apiService = useApiService();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [aidId, setAidId] = useState();
    const [loungeData, setLoungeData] = useState({
        name: '',
        descr: '',
        end_date: null,
      });
    const [targetAmount, setTargetAmount] = useState('');
      const [communityType, setCommunityType] = useState('');
      

      const handleChange = (e) => {
        const { name, value } = e.target;
        // Check if the field is "target_amt" and ensure the value is numeric
        const numericValue = name === 'target_amt' ? parseFloat(value) || '' : value;
        setLoungeData((prevLoungeData) => ({
          ...prevLoungeData,
          [name]: numericValue,
        }));
      };

      const formatAmountWithCommas = (amount) => {
        // Add commas to the amount for display purposes
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

      const handleTargetAmount = (e) => {
        // Remove non-numeric characters from the input value
        const formattedValue = e.target.value.replace(/[^\d]/g, '');
        // Update the state with the formatted value
        setTargetAmount(formattedValue);
      }
      

      const handleTypeChange = (e) => {
        const selectedValue = e.target.value;
      
        // Use the callback function to ensure the state is updated before logging
        setCommunityType((prevCommunityType) => {
          // Check if the selected value is a valid option
          if (selectedValue === "COMMUNITY" || selectedValue === "INDIVIDUAL") {
            return selectedValue;
          } else {
            return prevCommunityType;
          }
        });
      };
         
      
    const getCurrentDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
      };

    const handleCreateAidLounge = async (e) => {
        e.preventDefault();
        try {
            setIsCreating(true);
            const requestBody = {
                community_id: uuid,
                name: loungeData.name,
                descr: loungeData.descr,
                target_amt: targetAmount,
                end_date: loungeData.end_date,
                aid_lounge_type: communityType
            }
            const response = await toast.promise(
                apiService.aidlounge.create(requestBody),
                {
                    loading: 'Creating Aid Lounge...',
                    success: 'Aid Lounge Created',
                    error: 'Error Creating Aid Lounge'
                }
            )
            if(response.status === 200 || response.status === 201) {
                const id = response.data.id;
                setAidId(id);
                setStep(step + 1);
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
            }
        } finally{
            setIsCreating(false);
        }
    }

    const handleImageSelect = (e) => {
        // Handle the image upload logic here
        // You can use the selected image file from e.target.files
        // Perform the upload and update the selectedImage
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setSelectedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
        setMainImage(file);
      };

      const handleBannerUpdate = async () => {
        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', mainImage);
            formData.append('aid_lounge_id', aidId);
            const response = await toast.promise(
                apiService.aidlounge.uploadBanner(formData),
                {
                    loading: 'Uploading Banner...',
                    success: 'Banner Photo Uploaded',
                    error: 'Error Uploading Banner'
                }
            )
            if(response.status === 200 || response.status === 201){
                history.push(`/aid-lounge/${aidId}`);
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
            } else{
              toast.error('Network Error');
            }
        } finally{
            setIsUploading(false);
        }
      }

      const skipUpload = () => {
        history.push(`/aid-lounge/${aidId}`);
      }
      
  return (
    <Box className='create-aid-container'>
        {step === 1 && (
            <>
                <form style={{width: '100%', marginTop: '15px'}} onSubmit={handleCreateAidLounge}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '0.5rem', maxHeight: '400px', overflowY: 'auto'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <MonetizationOn className='big-icon'/>
                        </Box>
                    <h2 className='h2-title'>Create Aid Lounge</h2>
                    <p>Aid Lounge empowers you to initiate and back meaningful campaigns that matters to you, giving you the opportunity to make a difference in causes you care about.</p>
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Person/>
                                <label htmlFor='lounge-name'>Aid Lounge Name:</label>
                            </Stack>
                            <input onChange={handleChange} value={loungeData.name} className='create-aid-input' type="text" name="name" id='lounge-name' required />
                        </Box>
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Description />
                                <label htmlFor='lounge-description'>Aid Lounge Description:</label>
                            </Stack>
                            <textarea
                                onChange={handleChange}
                                value={loungeData.descr}
                                className='create-aid-input'
                                type="text"
                                name="descr"
                                id='lounge-description'
                                required
                            />
                        </Box>

                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <MonetizationOn />
                                <label htmlFor='lounge-target-amount'>Target Amount:</label>
                            </Stack>
                            <input
                                onChange={handleTargetAmount}
                                value={formatAmountWithCommas(targetAmount)}
                                className='create-aid-input'
                                type="text"
                                name="target_amt"
                                id='lounge-target-amount'
                                required
                            />
                        </Box>

                        
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'} width={'100%'}>
                                <MonetizationOn />
                                <label htmlFor='lounge-end-date'>Aid Lounge Type:</label>
                                <Tooltip title="COMMUNITY: Community Aid Lounge is dependent on the Community in which it is created and simple majority voting is required to complete the withdrawal at the end of the aid.
                                 INDIVIDUAL: Individual Aid Lounge is independent of a Community and withdrawal can be made anytime by the creator.">
                                    <Help sx={{marginLeft: 'auto', cursor: 'pointer'}}/>
                                </Tooltip>
                            </Stack>
                            <select
                                onChange={handleTypeChange}
                                value={communityType}
                                required
                                className='create-aid-input'
                                >
                                <option value='' disabled>Select Aid Lounge Type</option>
                                <option value="COMMUNITY" disabled={!uuid}>Community</option>
                                <option value="INDIVIDUAL">Individual</option>
                                </select>
                        </Box>
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'} width={'100%'}>
                                <DateRange />
                                <label htmlFor='lounge-end-date'>End Date:</label>
                                <Tooltip title="If end date isn't specified, the campaign would end once withdrawal is initiated">
                                    <Help sx={{marginLeft: 'auto', cursor: 'pointer'}}/>
                                </Tooltip>
                            </Stack>
                            <input
                                onChange={handleChange}
                                value={loungeData.end_date}
                                className='create-aid-input'
                                type="date"
                                name="end_date"
                                id='lounge-end-date'
                                min={getCurrentDate()}
                            />
                        </Box>
                        <Box>
                            <p style={{fontSize: '12px', fontStyle: 'italic'}}>If end date isn't specified, the campaign would end once withdrawal is initiated</p>
                        </Box>
                    </Box>
                    <Box className='create-aid-buttons'>
                        <Button disabled={communityType === '' || loungeData.descr === '' || loungeData.target_amt === '' || isCreating} sx={{color: 'white'}} className='button create-aid-button' type='submit'>{isCreating ? 'Submitting...' : 'Submit'}</Button>
                        <Button sx={{color: 'white'}} className='button create-aid-button' onClick={onCancel}>Cancel</Button>
                    </Box>
                </form>
            </>
        )}
        {step === 2 && (
            <>
                <form style={{width: '100%', marginTop: '15px'}} onSubmit={handleBannerUpdate}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '0.5rem', maxHeight: '400px', overflowY: 'auto'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <AddPhotoAlternate className='big-icon'/>
                        </Box>
                    <h2 className='h2-title'>Upload Aid Lounge Banner</h2>
                    <p>Upload the banner associated with your aid campaign</p>
                    <Stack>
                        <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '200px', // Adjust the height as needed
                            overflow: 'hidden',
                            border: '1px solid lightgrey', // Border for the box
                            mt: '1rem'
                        }}
                        >
                        {selectedImage ? (
                            // Display the community banner if it exists
                            <>
                            <img
                            src={selectedImage}
                            alt="Community Banner"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            <div className='add-commbanner-icon'>
                            <AddPhotoAlternate
                                sx={{
                                    color: 'white'
                                }}
                                onClick={() => document.getElementById('uploadInput').click()}
                            />
                            </div>
                            <input
                                type="file"
                                id="uploadInput"
                                style={{ display: 'none' }}
                                onChange={handleImageSelect}
                                accept="image/*"
                            />
                            </>
                        ) : (
                            // If no banner exists, display an icon and allow upload
                            <>
                            <div className='add-commbanner-icon'>
                            <AddPhotoAlternate
                                sx={{
                                    color: 'white'
                                }}
                                onClick={() => document.getElementById('uploadInput').click()}
                            />
                            </div>
                            <input
                                type="file"
                                id="uploadInput"
                                style={{ display: 'none' }}
                                onChange={handleImageSelect}
                                accept="image/*"
                            />
                            </>
                        )}
                        </Box>
                    </Stack>
                    </Box>
                    <Box className='create-aid-buttons'>
                    <Button disabled={isUploading} onClick={handleBannerUpdate} sx={{color: 'white'}} className='button create-aid-button'>
                    {isUploading ? 'Updating...' : 'Update'}
                    </Button>
                    <Button sx={{color: 'white'}} className='button create-aid-button' onClick={skipUpload}>I'd Upload later</Button>
                    </Box>
                </form>
            </>
        )}
    </Box>
  )
}

export default CreateAidLounge