import React from "react";
import { Diversity1 } from "@mui/icons-material";

export const accordionData = [
    {
        menuName: 'My Community',
        subMenu: [ {'subMenuName': 'Mahogany, AB',
                    'subMenuUrl': '/community',
                   }, 
                   {'subMenuName': 'Hamilton, AB',
                    'subMenuUrl': '/community',
                   }, 
                ],
        icon: <Diversity1 className='icons'/>
    },
    
]