import React, { useEffect, useState } from 'react'
import './raiseMeAccount.css'
import { useApiService } from '../../services/api-service';
import MfaSetup from '../mfa/MfaSetup';
import { LinearProgress } from '@mui/material';
import AccountsDropdown from './AccountsDropdown';
import toast from 'react-hot-toast';

const RaiseMeAccount = ({onCancel, accountName,
    setAccountName,
    accountNumber,
    setAccountNumber,
    routingNumber,
    setRoutingNumber,
    bankName,
    setBankName,
    handleCreateAccount,
    bankBranch,
    setBankBranch,
    interacEmail,
    setInteracEmail,
    interacPhone,
    setInteracPhone,
    accountType,
    setAccountType,
    currency,
    setCurrency,
    bvn,
    setBvn,
    location,
    setLocation,
    raiseMeId,
    accountId,
    setAccountId,
    useExistingAccount,
    setUseExistingAccount,
    nextStep,
    isSubmitting,
    setAccountStatus,
    setVerifyUrl
}) => {

  const [existingAccounts, setExistingAccounts] = useState([]);
  const apiService = useApiService();
  const [accountLocation, setAccountLocation] = useState('')
  const [routingNumberError, setRoutingNumberError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [bvnError, setBvnError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isAccountNameValid, setIsAccountNameValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(true);
  const [isBvnValid, setIsBvnValid] = useState(true);
  const [isRoutingNumberValid, setIsRoutingNumberValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isFetchingAccount, setIsFetchingAccount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [finNumberError, setFinNumberError] = useState();
  const [isFinNumberValid, setIsFinNumberValid] = useState(false);

  const handleRoutingNumberChange = (e) => {
    const inputValue = e.target.value.trim();
    setRoutingNumber(inputValue);
    if(inputValue.length === 5 && !isNaN(inputValue)){
      setIsRoutingNumberValid(true)
      setRoutingNumberError('');
    } else {
      setRoutingNumberError(
        'Routing number should be 5 digits.'
      );
      setIsRoutingNumberValid(false)
    }
    validateForm();
  };

  const handleFinancialInstitutionNumberChange = (inputFinNum) => {
    const inputValue = inputFinNum;
    setBankBranch(inputValue);
    if(inputValue.length === 3 && !isNaN(inputValue)){
      setIsFinNumberValid(true);
      setFinNumberError('');
    } else{
      setIsFinNumberValid(false);
      setFinNumberError(
        'Financial Institution Number should be 3 digits.'
      );
    }
    validateForm();
  }

  const handleAccountNameChange = (inputAccountName) => {
    const accountName = inputAccountName;
    setAccountName(accountName);
  
    if (accountName.length >= 2 && /^[a-zA-Z -]+$/.test(accountName)) {
      setIsAccountNameValid(true);
      setAccountNameError('');
    } else {
      setAccountNameError('Account name must be at least 2 alphabetical characters');
      setIsAccountNameValid(false);
    }
  
    validateForm();
  };  
  

  
  const handleAccountNumberChange = (e) => {
    const inputValue = e.target.value.trim();
    setAccountNumber(inputValue);
    if (/^\d{7,12}$/.test(inputValue)) {
      
      setIsAccountNumberValid(true);
      setAccountNumberError('');
    } else {
      setAccountNumberError(
        'Account number should have a minimum of 8 and maximum of 12 digits.'
      );
      setIsAccountNumberValid(false);
    }
    validateForm();
  };

  const handleBvnChange = (e) => {
    const inputValue = e.target.value.trim();
    if (/^\d{0,11}$/.test(inputValue)) {
      setBvn(inputValue);
      setIsBvnValid(true);
      setBvnError('');
    } else {
      setBvnError(
        'BVN should have a maximum of 11 digits.'
      );
      setIsBvnValid(false);
    }
    validateForm();
  };

  const handleEmailChange = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setInteracEmail(inputEmail);
    if (isValid) {
      setIsEmailValid(true);
      setEmailErrorMessage('');
    } else {
      setIsEmailValid(false);
      setEmailErrorMessage('Please enter a valid email address');
    }
    validateForm();
  };

  const handlePhoneNumberChange = (inputPhoneNumber) => {
    // Remove non-digit characters from the input
    const digitsOnly = inputPhoneNumber.replace(/\D/g, '');
    // Check if the phone number has a length of 11 digits (including country code)
    if (digitsOnly.length === 11) {
      // Reformat the phone number to match the Canadian format: +12505550199
      // const formattedPhoneNumber = `+${digitsOnly.slice(0, 1)} (${digitsOnly.slice(1, 4)}) ${digitsOnly.slice(4, 7)}-${digitsOnly.slice(7, 11)}`;
      setInteracPhone(`+${digitsOnly}`);
      setIsPhoneValid(true)
      setPhoneErrorMessage('');
    } else {
      // If the input doesn't match the expected length, set an error message
      setInteracPhone(inputPhoneNumber)
      setPhoneErrorMessage('Please enter a phone number with a Canadian country code (e.g., +12505550199)');
      setIsPhoneValid(false)
    }
    validateForm();
  };

  const validateForm = () => {
    if(location === 'Canada'){
      if(isPhoneValid && isEmailValid && isAccountNameValid && accountType!=='' && currency!=='' && isAccountNumberValid && isRoutingNumberValid && isFinNumberValid){
        setIsButtonDisabled(false)
        setErrorMessage('')
      } else{
        setIsButtonDisabled(true);
        setErrorMessage('One or more fields are incorrectly filled')
      } 
    } else {
      if( routingNumber && accountNumber && isBvnValid && accountName.length >= 2 && accountType!=='' && currency!=='' && isAccountNumberValid && isRoutingNumberValid && isFinNumberValid){
        setIsButtonDisabled(false)
        setErrorMessage('')
      } else{
        setIsButtonDisabled(true);
        setErrorMessage('One or more fields are incorrectly filled')
      }
    }
    }

    useEffect(() => {
      validateForm();
    }, [accountName, accountNumber, accountType, routingNumber, interacEmail, interacPhone, currency])
  
  const handleUseExistingAccountChange = (e) => {
    setUseExistingAccount(e.target.value === 'yes');
    if(useExistingAccount === 'yes'){
      setIsCreatingAccount(true);
    }
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        setIsFetchingAccount(true);
        const response = await apiService.raiseme.getAccount();
        if (response.status === 200) {
          const responseData = response.data;
          setExistingAccounts(responseData); // Set the existing accounts data
        } else {
          console.error('Error fetching account data:', response.status, response.statusText);
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
        } else {
          toast.error('Network Error');
      }
      } finally{
        setIsFetchingAccount(false);
      }
    };
    fetchAccounts();
    // getRaiseMeInfo();
  }, []);

  useEffect(() => {
    if(!useExistingAccount){
          setAccountName('');
          setAccountNumber('');
          setAccountType('');
          setBvn(null);
          setCurrency('');
          setBankName('');
          setBankBranch('');
          setInteracEmail('');
          setInteracPhone('');
          setRoutingNumber('');
          setAccountId(null);
          setAccountStatus(null);
    }
  }, [useExistingAccount])

  
  
  
  const getRaiseMeInfo = async () => {
    try {
      const requestBody = {
        raiseme_id: raiseMeId
      }
      const response = await apiService.raiseme.getRaiseMeInfo(requestBody)
      const resLocation = response.data.location;
      if(resLocation){
      setLocation(resLocation);
      setAccountLocation(resLocation); 
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error('Network Error')
      }
    }
  }

  const handleSelectAccount = async (accountId) => {
      if (accountId) {
        if (accountId.location !== location) {
          toast.error("Account location doesn't match raiseMe location.");
        } else {
          // Set state values using the selected account's properties
          setAccountName(accountId.account_name);
          setAccountNumber(accountId.account_number);
          setAccountType(accountId.account_type);
          setBvn(accountId.bvn);
          setCurrency(accountId.currency);
          setBankName(accountId.financial_institution_name);
          setBankBranch(accountId.financial_institution_branch);
          setInteracEmail(accountId.interac_email);
          setInteracPhone(accountId.interac_phone_number);
          setLocation(accountId.location);
          setRoutingNumber(accountId.routing_transit_number);
          setAccountId(accountId.id);
          setAccountStatus(accountId.partner_status);
          setVerifyUrl(accountId.verify_url);
        }
    } else {
      setAccountId(null)
    }
  };
  
  const filteredAccounts = existingAccounts.filter(account => account.location === location);

    const renderFormFields = (filteredAccounts) => {
      if (useExistingAccount) {
        if (!existingAccounts.length) {
          return <p>No accounts found. Create one.</p>;
        } else if (!filteredAccounts.length) {
          return <p>No accounts found with the same location as raiseMe location.</p>;
        } else {
          return (
            <>
            <AccountsDropdown filteredAccounts={filteredAccounts} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} handleSelectAccount={handleSelectAccount}/>
              {/* <div className="form-group">
              <label>Select Existing Account:</label>
              <select onChange={(e) => handleSelectAccount(e.target.value)}>
                <option value={null}>Select an Account</option>
                {filteredAccounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.financial_institution_name} - {account.account_number}
                  </option>
                ))}
              </select>
            </div> */}
              </>
          );
        }
      } else {
        
        if (location === 'Canada') {
            return(
                <>         
          <div className="form-group">
            <label htmlFor="bank-name">Financial Institution Name:</label>
            <input type="text" id="bank-name" value={bankName} onChange={(e) => setBankName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="bank-name">Financial Institution Number:</label>
            <input type="text" id="bank-name" value={bankBranch} onChange={(e) => handleFinancialInstitutionNumberChange(e.target.value)} required />
            {finNumberError && (
            <p style={{ color: 'red', fontSize: 'small' }}>{finNumberError}</p>
          )}
          </div>
          <div className="form-group">
            <label htmlFor="account-number">Account Name:</label>
            <input type="text" id="account-number" value={accountName} onChange={(e) => handleAccountNameChange(e.target.value)} required />
            {accountNameError && (
            <p style={{ color: 'red', fontSize: 'small' }}>{accountNameError}</p>
          )}
          </div>
          <div className="form-group">
        <label htmlFor="routing-number">Routing/Transit Number:</label>
        <input
          type="text"
          id="routing-number"
          value={routingNumber}
          onChange={handleRoutingNumberChange}
          required
        />
        {routingNumberError && (
          <span className="error-text">{routingNumberError}</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="account-number">Account Number:</label>
        <input
          type="text"
          id="account-number"
          value={accountNumber}
          onChange={handleAccountNumberChange}
          required
        />
        {accountNumberError && (
          <span className="error-text">{accountNumberError}</span>
        )}
      </div>
          <div className="form-group">
            <label htmlFor="routing-number">Interac Email:</label>
            <input type="email" id="routing-number" value={interacEmail} onChange={(e) => handleEmailChange(e.target.value)} required />
            {emailErrorMessage && (
          <span className="error-text">{emailErrorMessage}</span>
        )}
          </div>
          <div className="form-group">
            <label htmlFor="routing-number">Interac Phone Number:</label>
            <input type="tel" id="routing-number" value={interacPhone} onChange={(e) => handlePhoneNumberChange(e.target.value)} required />
            {phoneErrorMessage && (
          <span className="error-text">{phoneErrorMessage}</span>
        )}
          </div>
          <div className="form-group">
            <label>Account Type</label>
            <select value={accountType} onChange={(e) => setAccountType(e.target.value)} name="" id="">
                <option value="">Select Account Type</option>
                <option value="Chequing">Chequing</option>
                <option value="Savings">Savings</option>
                <option value="Joint">Joint</option>
            </select>
          </div>
          <div className="form-group">
            <label>Currency</label>
            <select value={currency} onChange={(e) => setCurrency(e.target.value)} name="" id="">
                <option value="">Select Currency</option>
                <option value="CAD">CAD $</option>
                <option value="USD">USD $</option>
                <option value="GBP">GBP</option>
            </select>
            {errorMessage && (
          <span className="error-text">{errorMessage}</span>
        )}
          </div>
                </>
            );
        } else if(location === 'Nigeria'){
            return(
            <>
            <div className="form-group">
            <label htmlFor="bank-name">Financial Institution Name:</label>
            <input type="text" id="bank-name" value={bankName} onChange={(e) => setBankName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="bank-name">Financial Institution Branch:</label>
            <input type="text" id="bank-name" value={bankBranch} onChange={(e) => setBankBranch(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="account-number">Account Name:</label>
            <input type="text" id="account-number" value={accountName} onChange={(e) => handleAccountNameChange(e.target.value)} required />
            {accountNameError && (
            <p style={{ color: 'red', fontSize: 'small' }}>{accountNameError}</p>
          )}
          </div>
          <div className="form-group">
        <label htmlFor="account-number">Account Number:</label>
        <input
          type="text"
          id="account-number"
          value={accountNumber}
          onChange={handleAccountNumberChange}
          required
        />
        {accountNumberError && (
          <span className="error-text">{accountNumberError}</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="routing-number">Routing/Transit Number:</label>
        <input
          type="text"
          id="routing-number"
          value={routingNumber}
          onChange={handleRoutingNumberChange}
          required
        />
        {routingNumberError && (
          <span className="error-text">{routingNumberError}</span>
        )}
      </div>
          <div className="form-group">
            <label htmlFor="routing-number">BVN: </label>
            <input type="text" id="routing-number" value={bvn} onChange={handleBvnChange} required />
            {bvnError && (
          <span className="error-text">{bvnError}</span>
        )}
          </div>
          <div className="form-group">
            <label>Account Type</label>
            <select value={accountType} onChange={(e) => setAccountType(e.target.value)} name="" id="">
                <option value="">Select Account Type</option>
                <option value="Chequing">Current</option>
                <option value="Savings">Savings</option>
                <option value="Joint">Joint</option>
            </select>
          </div>
          <div className="form-group">
            <label>Currency</label>
            <select value={currency} onChange={(e) => setCurrency(e.target.value)} name="" id="">
                <option value="">Select Currency</option>
                <option value="NGN">NGN #</option>
                <option value="CAD">CAD $</option>
                <option value="USD">USD $</option>
                <option value="GBP">GBP</option>
            </select>
            {errorMessage && (
          <span className="error-text">{errorMessage}</span>
        )}
          </div>
            </>
            )
        }
    }
}

  return (
    <>
    {!isCreatingAccount ? (
      <>
      <h2 className="onboarding-text">Setup your account</h2>
      <p className='kyc-desc'>Setup your raiseMe account</p>
      <form style={{width: '100%'}} onSubmit={handleCreateAccount}>
        <div className="form-top">
          <div className="form-group">
            <label>Use an existing account for this RaiseMe?</label>
            <div className="form-radio">
              <label>
                <input
                  type="radio"
                  name="useExistingAccount"
                  value="yes"
                  checked={useExistingAccount}
                  onChange={handleUseExistingAccountChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="useExistingAccount"
                  value="no"
                  checked={!useExistingAccount}
                  onChange={handleUseExistingAccountChange}
                />
                No
              </label>
            </div>
          </div>
          {isFetchingAccount ? (
            <LinearProgress style={{ marginBottom: '1rem', backgroundColor: '#a06ccb' }} />
          ) : (
            <>
            {renderFormFields(filteredAccounts)}
            </>
          )}
        </div>
        <div className="form-bottom">
        
          <div className="form-actions">
            {useExistingAccount ? (
              <>
              {accountId ? (
                <>
            <button type='submit' className={`button ${useExistingAccount && !filteredAccounts.length ? 'disabled-button' : ''}`} disabled={useExistingAccount && !filteredAccounts.length}>
                Next
              </button>
              <button className="button" type="button" onClick={onCancel}>
              Cancel
            </button>
            </>
              ) : null}
              </>
            ):(
              <>
               
              <button onClick={handleCreateAccount} className={`button ${useExistingAccount && !filteredAccounts.length ? 'disabled-button' : ''}`} type="submit" disabled={isButtonDisabled}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
              <button className="button" type="button" onClick={onCancel}>
              Cancel
            </button>
            </>
              )}
            
          </div>
        </div>
        </form>
      </>
    ) : (
      <>
      <MfaSetup onCancel={onCancel}
      nextStep={nextStep}
      isCreatingAccount={isCreatingAccount}
      setIsCreatingAccount={setIsCreatingAccount}/>
      </>
    )}
    </>

  )
}

export default RaiseMeAccount