import React, { useState } from 'react';
import {
  SettingsOutlined,
  Chair,
  ExitToAppOutlined,
  Diversity1,
  HelpOutlineOutlined,
  MoneyOutlined,
  CurrencyExchangeOutlined,
  CreditCardOutlined,
  BusinessCenter,
  Support,
  EventNote,
  Group,
  SavingsOutlined,
  BusinessOutlined,
  MonetizationOn
} from '@mui/icons-material';

import './Accordions.css';

import AccordionDropDown from '../accordionDropDown/AccordionDropDown';
import { accordionData } from '../../Data/Accordion';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuthService } from '../../services/auth-service';
import VirtualCardPopup from '../VirtualCardPopup/VirtualCardPopup';
import { Backdrop, Fade, Modal } from '@mui/material';
import { removeCommunityInfo } from '../../utils/routes';



const Accordions = () => {
  const history = useHistory();
  const { clearToken } = useAuthService();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    clearToken();
    removeCommunityInfo();
    history.push('/login')
  };
  return (
    <div className="accordionWrapper">
      <Link className='my-link' to="/communities">
        <div className="accordion">
          <div className="menu-item">
            <Diversity1 className='icons' />
            <div className="title">
              My Communities
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/raiseme-contributions">
        <div className="accordion">
          <div className="menu-item">
            <SavingsOutlined className='icons' />
            <div className="title">
              My RaiseMe(s)
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/my-aid-lounges">
        <div className="accordion">
          <div className="menu-item">
            <MonetizationOn className='icons' />
            <div className="title">
              My Aid Lounge(s)
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/transactions">
        <div className="accordion">
          <div className="menu-item">
            <CurrencyExchangeOutlined className='icons' />
            <div className="title">
              Transaction Dashboard
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/my-pals">
        <div className="accordion">
          <div className="menu-item">
            <Group className='icons' />
            <div className="title">
              My Pal(s)
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/thrifters-corner">
        <div className="accordion">
          <div className="menu-item">
            <Support className='icons' />
            <div className="title">
              Thrifters Conner
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/my-business">
        <div className="accordion">
          <div className="menu-item">
            <BusinessOutlined className='icons' />
            <div className="title">
              My Business(es)
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/settings">
        <div className="accordion">
          <div className="menu-item">
            <SettingsOutlined className='icons' />
            <div className="title">
              Settings
            </div>
          </div>
        </div>
      </Link>

      <Link className='my-link' to="/need-help">
        <div className="accordion">
          <div className="menu-item">
            <HelpOutlineOutlined className='icons' />
            <div className="title">
              Need Help
            </div>
          </div>
        </div>
      </Link>
      <Link className='my-link' to="/virtual-card">
        <div className="accordion">
          <div className="menu-item">
            <CreditCardOutlined className='icons' />
            <div className="title">
              Virtual Card
            </div>
          </div>
        </div>
        </Link>

      <div className="accordion">
        <div className="menu-item" onClick={handleLogout}>
          <ExitToAppOutlined className='icons' />
          <div className="title">
            Logout
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordions;
