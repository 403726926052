import React from 'react'
import images from '../../Data/imageHelper'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ExpiredScreen = ({token}) => {
    const history = useHistory();

    const handleContinue = () => {
        if(token){
            history.push('/communities');
        } else {
            history.push('/login');
        }
    }
  return (
    <>
    <img className='error-animation' src={images.error} alt="" />
    <h2 className='title-md'>Invite Invalid</h2>
    <h2 style={{textAlign: 'center', padding: '0 15px'}} className='text-md'>This invite may be expired, or you might not have permission to join.</h2>
    <Button onClick={handleContinue} sx={{color: 'white', width: '100%'}} className='button'>Continue to MyAreaa</Button>
    </>
  )
}

export default ExpiredScreen