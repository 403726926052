import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React from 'react'
import { formatCurrency, formatDate, getTimeDifference } from '../../utils/routes';
import { Cancel, Download } from '@mui/icons-material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import images from '../../Data/imageHelper';

const ViewStatements = ({walletHistory, handleClose, community}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const columns = [
        { id: 'partner_id', label: 'Reference ID', minWidth: 10 },
        { id: 'first_name', label: 'First Name', minWidth: 80 },
        { id: 'last_name', label: 'Last Name', minWidth: 80 },
        { id: 'contrib_status', label: 'Status', minWidth: 80 },
        { id: 'amount', label: 'Amount', minWidth: 50 },
        { id: 'created_at', label: 'Created At', minWidth: 100 },
      ];

      const headers = ['Reference ID', 'First Name', 'Last Name', 'Status', 'Amount', 'Transaction Date'];

      const handleDownloadPDF = () => {
        const doc = new jsPDF({ orientation: 'landscape' });
        doc.setFont('Helvetica', 'Bold');

        const body = walletHistory.map(item => [
          item.partner_id.toString(),
          item.first_name || '',
          item.last_name || '',
          item.contrib_status || '',
          `$${item.amount.toString()}`,
          new Date(item.created_at).toISOString().split('T')[0]
        ]);

        doc.addImage(images.logoPNG, 'PNG', 10, 10)
        doc.text(`${community.commName} Community Wallet Statement`, 10, 45)

        autoTable(doc, {
          head: [headers],
          body: body,
          startY: 50, // Adjust the startY to position the table correctly
          theme: 'grid', // Optional: 'striped', 'grid', 'plain'
          headStyles: { fillColor: [160, 108, 203] }, // Optional: customize header styles
          styles: { fontSize: 10, cellPadding: 3, cellWidth: 'wrap' } // Optional: customize cell styles
        });
    
        doc.save('community-wallet-history.pdf');
      };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const renderTableCellContent = (column, value) => {
        switch (column.id) {
            case 'contrib_status':
                if (value === 'PENDING') {
                  return <span style={{ backgroundColor: '#fef5cc', color: 'rgb(170 141 4)', padding: '5px 10px', borderRadius: '50px', fontSize: '0.8rem' }}>Pending</span>;
                } else if (value === 'SETTLED' || value === 'OK' || value === 'APPROVED') {
                  return <span style={{ backgroundColor: '#d9f2dd', color: 'rgb(17 98 30)', padding: '5px 10px', borderRadius: '50px', fontSize: '0.8rem' }}>Settled</span>;
                } else {
                  return value;
                }
            case 'amount':
                return <span style={{fontWeight: 'bold'}}>{`${formatCurrency(value)}`}</span>;
            case 'created_at':
                return getTimeDifference(value)
          default:
            return value;
        }
      };

      let sortedWalletHistory;

      if(walletHistory){
        sortedWalletHistory = walletHistory.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
      
  return (
    <>
    <Box sx={{padding: '1rem'}}>
    <Box>
        <Cancel onClick={handleClose} sx={{cursor: 'pointer', float: 'right'}} />
        <h2 style={{color: 'black', textAlign: 'center'}}>View Wallet Statement History</h2>
    </Box>
    <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: 'white', marginBottom: '10px' }}>
      <TableContainer sx={{ height: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{backgroundColor: 'white', color: 'black'}}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWalletHistory
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <>
                        <TableCell key={column.id} align={'left'}>
                          {renderTableCellContent(column, value)}
                        </TableCell>
                        </>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={walletHistory.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
    <Button startIcon={<Download />} sx={{color: 'white',}} className='button' onClick={handleDownloadPDF}>Export</Button>
    </Box>
    </Box>
    </>
  )
}

export default ViewStatements