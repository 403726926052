import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiService } from './api-service';
import { useAuthService } from './auth-service';
import { LOGIN_ROUTE } from '../utils/routes';
import toast from "react-hot-toast";

const ProfileServiceContext = createContext({
  profile: null,
  profileLoaded: false,
  loadProfile: async () => {}
});

export function ProfileServiceProvider({ children }) {
  const history = useHistory();
  const { auth } = useApiService();
  const { token, clearToken } = useAuthService();
  const [profile, setProfile] = useState(null);
  const [profileLoaded, setProfileLoaded] = useState(false);

  const loadProfile = useCallback(async () => {
    if (token !== null) {
      try {
        const response = await auth.profile();
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            setProfile(data);
          } else {
            setProfile({});
          }
        } else {
          if(response.status === 401){
            // clearToken();
            // toast.error('Session expired! Login')
          }
        }
      } catch (e) {
        if(e.response){
          if(e.response.status === 401){
            // clearToken();
            // toast.error('Session expired! Login');
          }
          if (e.response.status === 404) {
            setProfile(null);
          }
          setProfile({});
        }
         
      }
    } else {
      setProfile(null);
    }
    setProfileLoaded(true);
  }, [auth, token, history]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]); // Empty dependency array to only run once when the component mounts

  return (
    <ProfileServiceContext.Provider
      value={{ profile, profileLoaded, loadProfile }}
    >
      {children}
    </ProfileServiceContext.Provider>
  );
}

export function useProfileService() {
  return useContext(ProfileServiceContext);
}
