import React, { useEffect, useRef, useState } from 'react'
import { Picker } from 'emoji-mart';
import { Backdrop, Badge, Box, Button, Fade, IconButton, Modal, TextField } from '@mui/material';
import { AttachFileRounded, CalendarMonth, EmojiEmotions, FileUploadRounded, Image, PlayCircle, Satellite } from '@mui/icons-material';
import EmojiPicker from 'emoji-picker-react';
import './createPost.css'
import UploadDocument from './UploadDocument';
import FileViewer from '@codesmith-99/react-file-preview'

const CreatePost = ({postText, handleTextChange, handleShareClick, isSharing, setPostText,
  previewUrl, selectedDocument, handleFileChange, handleButtonClick, fileInputRef, setSelectedDocument
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const containerRef = useRef(null);
  const textareaRef = useRef(null);
  const [showPreview, setShowPreview] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setShowPreview(false);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  
  


  const handlePostTextChange = (event) => {
    setPostText(event.target.value);
    autoResizeTextarea(event.target);
  };

  const handleEmojiSelect = (emojiObject) => {
    setPostText((prevPostText) => prevPostText + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) &&
        containerRef.current && !containerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPickerRef, containerRef]);

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = 'auto'; // Reset height to auto to calculate the new height correctly
    textarea.style.height = textarea.scrollHeight + 'px'; // Set height based on scroll height
  };

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea(textareaRef.current); // Initial resize on mount
    }
  }, []);

  return (
    <>
    <div ref={containerRef} className="create-post-container">
      <textarea
        ref={textareaRef}
        className="create-post-textarea"
        placeholder="What's on your mind?"
        rows={1}
        value={postText}
        onChange={handlePostTextChange}
      />
      {showPreview && selectedDocument && (
        <Box sx={{margin: '10px 0', maxHeight: '150px', overflowY: 'auto'}}>
        {showPreview && selectedDocument && selectedDocument.type === 'application/pdf' && (
                    <FileViewer
                    fileName={'pdf'}
                    src={previewUrl}/>
                  )}
                  {showPreview && selectedDocument &&
                  ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(selectedDocument.type) && (
                    <img
                      src={previewUrl}
                      title="Preview"
                      style={{
                        width: '200px',
                        height: '200px',
                        border: 'none',
                        background: 'white',
                        objectFit: 'contain',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                      sandbox="allow-same-origin"
                    ></img>
                  )}
                  {showPreview && selectedDocument && (selectedDocument.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    selectedDocument.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || selectedDocument.type === 'video/mp4' || selectedDocument.type === 'video/quicktime') && (
                      <p style={{textAlign: 'left', padding: '0 10px'}}>Selected Document: {selectedDocument.name}</p>
                  )}
        </Box>
      )}
      
      <div className="create-post-icons">
                <div className="option" onClick={handleOpen}>
                  <Badge badgeContent={selectedDocument && showPreview ? 1 : 0} color='secondary' max={100}>
                    <AttachFileRounded />
                    </Badge>
                </div>
                <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <Box className="comm-popup-modal">
                          <UploadDocument fileInputRef={fileInputRef}
                          handleButtonClick={handleButtonClick}
                          handleFileChange={handleFileChange}
                          selectedDocument={selectedDocument}
                          previewUrl={previewUrl}
                          handleClose={handleClose}
                          setShowPreview={setShowPreview}
                          setSelectedDocument={setSelectedDocument}
                          />
                        </Box>
                      </Fade>
                </Modal>
          <EmojiEmotions className='create-post-icons-item emoji-icon'  onClick={() => setShowEmojiPicker((prev) => !prev)}/>
          
          <Button
          sx={{color: 'white'}}
            className="button create-post-btn"
            onClick={() => handleShareClick(postText)}
            disabled={isSharing || postText === ''}
        >
            {isSharing ? 'Sharing...' : 'Share'}
          </Button>
      </div>
      
    </div>
    {showEmojiPicker && (
        // <Picker data={data} onEmojiSelect={console.log} />
        <div ref={emojiPickerRef}>
        <EmojiPicker
          open
          onEmojiClick={handleEmojiSelect}
          emojiStyle='apple'
          searchPlaceholder='Search for emoji...'
          suggestedEmojisMode='frequent'
          style={{ position: 'absolute', backgroundColor: 'white', top: '0', right: '10px', height: '350px' }}
        />
        </div>
      )}
      </>
  )
}

export default CreatePost