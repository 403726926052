import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './cardSkeleton.css'

const CardSkeleton = ({cards}) => {
  return (
    Array(cards).fill(0).map(item => 
    <div className='card-skeleton'>
            <div class="cover-skeleton">
                <div className='skeleton-cover-img'><Skeleton height={100}/></div>
              
            </div>
          
          <div class="skeleton-profile">
            <div className='skeleton-profile-img'><Skeleton height={64}/></div>
          </div>
          <div class="skeleton-info">
            <h2 class="skeleton-name"><Skeleton/></h2>
            <p class="skeleton-description"><Skeleton/></p>
            <div class="skeleton-stats">
            <Skeleton/>
            </div>
          </div>
          <div class="skeleton-cta">
          <button><Skeleton/></button>
          </div>
    </div>
    )
  )
}

export default CardSkeleton