import { Cancel, Done, Link } from '@mui/icons-material'
import { Box, Stack, Tooltip } from '@mui/material'
import React from 'react'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from 'react-share'

const SharePost = ({url, handleCopyLink, isCopied, onCancel}) => {
  return (
    <Box className="raiseme-position-modal">
      <Cancel onClick={onCancel} sx={{cursor: 'pointer', float: 'right'}} />
      <Stack spacing={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h2 className='blackText'>Share</h2>
        <Box sx={{display: 'flex', gap: '0.5rem', justifyContent: 'center', alignItems: 'center'}}>
                      <WhatsappShareButton url={url}>
                        <WhatsappIcon size={32} round={true} />
                      </WhatsappShareButton>
                      <FacebookShareButton url={url}>
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={url}>
                          <XIcon size={32} round={true} />
                        </TwitterShareButton>
                        <EmailShareButton>
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                      <Tooltip title='Copy Link'>
                        {isCopied ? <Done/> : <Link sx={{cursor: 'pointer'}} onClick={handleCopyLink}/>}
                        </Tooltip>
        </Box>
      </Stack>
    </Box>
  )
}

export default SharePost