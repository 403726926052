import { Block, Comment, Delete, Download, Favorite, FavoriteBorder, Flag, MoreVert, Share } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import './Post.css';
import { LinearLoad, NO_PROFILE, getFileType } from '../../utils/routes';
import ShareComment from '../ShareComment/ShareComment';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import { useCommunityPostsContext } from '../../contexts/CommunityPostsContext';
import { Avatar, Backdrop, Box, Divider, Fade, Menu, MenuItem, Modal } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ReportPost from './ReportPost';
import toast from 'react-hot-toast';
import ConfirmRequest from '../../utils/ConfirmRequest';
import FileViewer from '@codesmith-99/react-file-preview'

const Post = ({data, setCommentsMapping, comment, commentCount}) => {
  const [showComments, setShowComments] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [isSharing, setIsSharing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const apiService = useApiService();
  const { profile } = useProfileService();
  const [showOptionsMenu, setShowOptionsMenu] = useState(false); // Add state for options menu
  const { refreshCommunityPosts } = useCommunityPostsContext();
  const [isLiked, setIsLiked] = useState(data?.liked);
  const [likesCount, setLikesCount] = useState(data?.likes_count);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedData, setSelectedData] = useState();
  const [isBlocking, setIsBlocking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postComments, setPostComments] = useState([]);

  //open confirm request
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  //open lightbox
  //open confirm request
  const [openImage, setOpenImage] = React.useState(false);
  const handleOpenImage = () => setOpenImage(true);
  const handleCloseImage = () => setOpenImage(false);


    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

  const toggleComments = () => {
    setShowComments((prevShowComments) => !prevShowComments);
    loadComments(data.post_id);
  };

  const toggleOptionsMenu = () => {
    setShowOptionsMenu((prevShowOptionsMenu) => !prevShowOptionsMenu);
  };

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const loadComments = async (postId) => {
    try {
      console.log('Loading comments for post:', postId);
      setLoading(true);
  
      const response = await apiService.community.allComments({
        parent_id: postId,
      });
  
      if (response.status === 200 || response.status === 201) {
        const commPosts = response.data;
  
        // Use a set to store unique user IDs
        const userIdsSet = new Set();
  
        // Identify user IDs to fetch details for
        commPosts.forEach(post => userIdsSet.add(post.user_id));
  
        // Convert set to an array for easier manipulation
        const uniqueUserIds = Array.from(userIdsSet);
  
        // Throttle the number of concurrent requests to avoid overwhelming the server
        const concurrencyLimit = 5; // Adjust as needed
        const chunks = Array.from({ length: Math.ceil(uniqueUserIds.length / concurrencyLimit) }, (_, index) =>
          uniqueUserIds.slice(index * concurrencyLimit, (index + 1) * concurrencyLimit)
        );
  
        // Fetch user details in chunks
        const userDetailsChunks = await Promise.all(
          chunks.map(async (userIdsChunk) => {
            const userDetailsPromises = userIdsChunk.map(async (userId) => {
              try {
                const userResponse = await apiService.profile.profileDetails({ user_id: userId });
                return (userResponse.status === 200 || userResponse.status === 201) ? userResponse.data : null;
              } catch (error) {
                return null; // Handle errors if needed
              }
            });
            return Promise.all(userDetailsPromises);
          })
        );
  
        // Flatten the array of chunks
        const userDetails = userDetailsChunks.flat();
  
        // Update posts with fetched user details
        const postsWithUserDetails = await Promise.all(
          commPosts.map(async (post) => {
            // Find user details for the current post
            const userDetailsForPost = userDetails.find(userDetail => userDetail && userDetail.userId === post.user_id);
  
            let liked = false;
            let reactionId = null;
  
            try {
              // Check if the post has been liked
              const reactionStatusResponse = await apiService.community.reactionStatus({ post_id: post.post_id });
              liked = reactionStatusResponse.status === 200;
              reactionId = liked ? reactionStatusResponse.data.id : null;
            } catch (error) {
              // Handle 404 response (Post not liked)
              if (error.response && error.response.status === 404) {
                liked = false;
                reactionId = null;
              } else {
                throw error; // Rethrow other errors
              }
            }
  
            return { ...post, userDetails: userDetailsForPost, liked, reaction_id: reactionId };
          })
        );
  
        // Sort posts by createdAt in descending order
        postsWithUserDetails.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setPostComments(postsWithUserDetails);
        console.log('Posts sorted', postsWithUserDetails);
      } else {
        console.error('Failed to load comments, response status:', response.status);
      }
    } catch (e) {
      console.error('Error loading comments:', e);
      if (e.response) {
        if (e.response.status !== 404) {
          toast.error(e.response.data["code"]);
        }
      } else {
        toast.error("Network Error");
      }
    } finally {
      setLoading(false);
    }
  };

  // const loadComments = async () => {
  //   try {
  //     const requestBody = {
  //       parent_id: data.post_id,
  //     };
  //     const response = await apiService.community.allComments(requestBody);
  //     if (response.status === 200 || response.status === 201) {
  //       const commPosts = response.data;
  
  //       // Filter posts that are comments (have a parent_id)
  //       const commentPosts = commPosts.filter((post) => post.parent_id);
  
  //       const postsWithUserDetails = await Promise.all(
  //         commentPosts.map(async (post) => {
  //           const userResponse = await apiService.profile.profileDetails({
  //             user_id: post.user_id,
  //           });
  
  //           if (userResponse.status === 200 || userResponse.status === 201) {
  //             const userDetails = userResponse.data;
  //             return { ...post, userDetails };
  //           } else {
  //             // Handle error fetching user details
  //             return post;
  //           }
  //         })
  //       );
  
  //       // Sort comment posts by createdAt in descending order
  //       postsWithUserDetails.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  
  //       // Create a mapping between parent post IDs and their comments
  //       const commentsMapping = {};
  
  //       postsWithUserDetails.forEach((comment) => {
  //         const parentId = comment.parent_id;
  
  //         if (!commentsMapping[parentId]) {
  //           commentsMapping[parentId] = [];
  //         }
  
  //         commentsMapping[parentId].push(comment);
  //       });

  //       setCommentsMapping(commentsMapping);
  //       // Now you have a mapping between parent post IDs and their comments
  //       console.log('commentsMapping: ', commentsMapping);
  //       console.log('commentsMapping: ', postsWithUserDetails);
  //     }
  //   } catch (e) {
  //     // Handle the error
  //   }
  // };

  // useEffect(() => {
  //   loadComments();
  // }, [])

  const handleCommentSubmit = async () => {
    // Implement the logic to submit the comment
    try {
      setIsSharing(true)
        const requestBody = {
            community_id: postComments.community_id,
            message: commentText,
            user_id: profile.userId,
            parent_id: postComments.post_id
        }
        const response = await toast.promise(
          apiService.community.createPost(requestBody),
          {
            loading: 'Posting comment...',
            success: 'Comment posted successfully',
            error: 'Error posting comment'
          }
        )
        if(response.status === 200 || response.status === 201){
          refreshCommunityPosts();
          // Add the new comment to the local state without refreshing from the server
        const newComment = {
          ...requestBody, // Assuming your API response contains necessary data for the new comment
          userDetails: profile, // Assuming profile contains necessary user details
        };
        postComments.push(newComment);
          setCommentText('');
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    } finally{
      setIsSharing(false)
    }
  };

  const handleDeletePosts = async () => {
    try {
      setIsDeleting(true)
      const response = await toast.promise(
        apiService.community.deletePosts(data.post_id),
        {
          loading: 'Deleting post...',
          success: 'Post deleted successfully',
          error: 'Error deleting post'
        }
      )
      if(response.status === 200 || response.status === 201){
        refreshCommunityPosts();
        setShowOptionsMenu(false)
    }
    } catch (e) {
      
    } finally{
      setIsDeleting(false)
    }
  }

  const handleBlockUser = async (data) => {
    try {
      setIsBlocking(true)
      await toast.promise(
        apiService.user.blockUser({
          blocker_id: profile.userId,
          blocked_id: data.user_id
        }),
        {
          loading: 'Blocking user...',
          success: 'User blocked successfully',
          error: 'Error blocking user'
        }
      )
      refreshCommunityPosts();
      handleMenuClose();
    } catch (e) {
      
    } finally{
      setIsBlocking(false)
    }
  }

  const likePost = async () => {
    try {
      setIsLiked(true)
      setLikesCount((prevLikesCount) => prevLikesCount + 1);
      const requestBody = {
        post_id: data.post_id,
        rxn_type: 'like'
      }
      const response = await apiService.community.createReaction(requestBody);
      if(response.status === 200 || response.status === 201){
        refreshCommunityPosts();
    }
    } catch (e) {
      
    }
  }

  const unLikePost = async () => {
    try {
      setIsLiked(false)
      setLikesCount((prevLikesCount) => prevLikesCount - 1);
      const response = await apiService.community.deleteReaction(data.post_id);
      if(response.status === 200 || response.status === 201){
        refreshCommunityPosts();
    }
    } catch (e) {
      
    }
  }

  const getTimeDifference = (createdAt) => {
    const currentDate = new Date();
    const pastDate = new Date(createdAt);

    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (daysDifference > 0) {
      return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} min${minutesDifference > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  };

  const handleMenuItemClick = async (action, data) => {
    // Implement actions based on the selected menu item (e.g., delete or make admin)
    setSelectedData(data)
    if (action === 'delete') {
      await handleDeletePosts();
    }
    if(action === 'report'){
      handleMenuClose();
      handleOpen();
    }
    if(action === 'block'){
      handleMenuClose();
      handleOpenConfirm();
    }
  };
  
  return (
    <>
    {data.userDetails && (
      <>
      <div className="post">
        <div className="postHeader">
        <div className="postHeader-left">
        <Avatar className='post-img' alt={`${data.userDetails.firstName} ${data.userDetails.lastName}`} src={data.userDetails.photoUrl} />
            {/* <img src={data.userDetails.photoUrl || NO_PROFILE} alt={data.userDetails.photoUrl} /> */}
            <div className='postHeader-userInfo'>
            <Link to={`/profile/${data.userDetails.userId}`}>
              <p className='userInfo-name'>{data.userDetails.firstName} {data.userDetails.lastName}</p>
              </Link>
              <p className='userInfo-location'>{data.userDetails.city}, {data.userDetails.country}</p>
            </div>
            </div>
            <div className="postHeader-right">
            <div className="postime">
            {getTimeDifference(data.created_at)}
            
            <span onClick={(e) => handleMenuOpen(e)}><MoreVert /></span>
            <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {data.user_id === profile.userId && (
                      <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('delete')}>
                        <Delete />
                        Delete
                        </MenuItem>
                        )}
                        {data.user_id !== profile.userId && (
                          <>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('report', data)}>
                        <Flag />
                        Report
                        </MenuItem>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('block')}>
                        <Block />
                        Block
                        </MenuItem>
                        </>
                        )}
                    </Menu>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <Box className="comm-popup-modal">
                          <ReportPost refreshPosts={refreshCommunityPosts} handleClose={handleClose} selectedData={selectedData}/>
                        </Box>
                      </Fade>
                    </Modal>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openConfirm}
                      onClose={handleCloseConfirm}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <ConfirmRequest
                        sendingAction={isBlocking}
                        title={'Block User?'}
                        text={`Are you sure you want to block ${data.userDetails.firstName} ${data.userDetails.lastName}?`}
                        onAction={() => handleBlockUser(data)} // Pass a function reference
                        onClose={handleCloseConfirm}
                        actionButton={'Block'}
                        sendingActionButton={'Blocking...'}/>
                      </Fade>
                    </Modal>
            </div>
            </div>
        </div>
        <Link to={`/community/post/${data.community_id}/${data.post_id}`} key={data.post_id}>
        <div className='detail'>
            <span>{data.message}</span>
        </div>
        </Link>
        {data.media_url && (
          <>
          
            {['jpg', 'jpeg', 'png', 'gif'].includes(getFileType(data.media_url)) && (
              <>
              <div className='media-attached-image'>
              <img style={{cursor: 'pointer'}} className='media-img-attached' src={data.media_url} onClick={handleOpenImage}/>
              <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openImage}
                      onClose={handleCloseImage}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                          className: 'custom-backdrop'
                        },
                    }}>
                      <Fade>
                      <Box className="image-popup-modal">
                        <img className='media-img-attached-lightbox' src={data.media_url} />
                        </Box>
                      </Fade>
                    </Modal>
                    </div>
              </>
            )} 
            
            
            {['pdf'].includes(getFileType(data.media_url)) && (
             <>
             <div className='media-attached'>
             <FileViewer
                src={data.media_url}
                fileName='pdf'
              />
              </div>
             </>
            )}
            {['doc', 'docx', 'xlsx', 'mp4', 'mov'].includes(getFileType(data.media_url)) && (
              <div className='media-attached'>
              <FileViewer
              src={data.media_url}
              fileName={getFileType(data.media_url)}
            />
            </div>
            )}
            
            </>
        )}
        
        {/* <img src={data.img} alt='' className='postImg' /> */}
        <div className="postReaction">
          <div className="postReaction-likes">
          {isLiked? 
                <Favorite style={{color: 'red'}} onClick={unLikePost}/> :
                <FavoriteBorder onClick={likePost}/>
            }
           <p>{likesCount <= 1 ? `${likesCount} Like` : `${likesCount} Likes`}</p>
          </div>
          <div className="postReaction-comments" onClick={toggleComments}>
          <Comment className='comments-icon'  />
          <p>{data?.comments_count <= 1 ? `${data?.comments_count} Comment` : `${data?.comments_count} Comments`}</p>
          </div> 
          {data.media_url && (
            <a href={data.media_url} target='_blank'>
            <div className='postReaction-comments'>
              <Download />
              Download attachment
            </div>
            </a>
          )}
        </div>
        {/* <span>{data.likes} likes</span> */}
        {showComments && (
          <>
          {loading ? (
            <>
              {LinearLoad()}
            </>
          ) : (
            <>
            <ShareComment
          handleCommentSubmit={handleCommentSubmit}
          data={data}
          setPostComments={setPostComments}
          postComments={postComments}
          isSharing={isSharing}
          comment={comment}
          profile={profile}
          setIsSharing={setIsSharing}
          />
            </>
          )}
          </>
    )}
    </div>
      </>
    )}
    </>
  )
}

export default Post;