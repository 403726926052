import React, { useState } from 'react'
import Header from '../../components/header/Header'
import './needhelp.css'
import UserProfile from '../../components/userProfile/UserProfile'
import { Button, FormControl, InputLabel, MenuItem, Stack, TextField } from '@mui/material'
import { SendOutlined } from '@mui/icons-material'
import Select, { SelectChangeEvent } from '@mui/material/Select';

const NeedHelp = () => {
  const [department, setDepartment] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setDepartment(event.target.value);
  };
  return (
    <>
        <Header/>
        <div className="needHelpWrapper">
        <div className="needHelp-sidebar">
            <UserProfile/>
        </div>
        <div className="needHelpContainer">
        <h2>Request Help</h2>
        <p>We are dedicated to assist you however we can 24/7. Fill up this form</p>
        <Stack className='needhelp-stack' spacing={2} mt={2} sx={{maxWidth: {
          xs: 1000,
          sm: 1000,
          lg: 500,
          xl: 500,
        }}}>
        <TextField color='secondary' type='text' id="outlined-basic" label="Enter Name" variant="outlined" />
        <TextField color='secondary' type='tel' id="outlined-basic" label="Enter Phone Number" variant="outlined" />
        <TextField color='secondary' multiline id="outlined-basic" label="Enter Help Request Details" variant="outlined" />
        <FormControl sx={{ m: 1 }}>
                <InputLabel color='secondary' id="demo-simple-select-autowidth-label">Department</InputLabel>
                <Select
                color='secondary'
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={department}
                onChange={handleChange}
                autoWidth
                label="Select Period"
                >
                <MenuItem value={10}>Billing</MenuItem>
                <MenuItem value={21}>Technical Support</MenuItem>
                <MenuItem value={22}>Sales</MenuItem>
                <MenuItem value={22}>Others</MenuItem>
                </Select>
            </FormControl>
        <Button sx={{maxWidth: {
          xs: 1000,
          sm: 1000,
          lg: 500,
          xl: 500
        }, borderColor: '#a06ccb', color: '#a06ccb'}} variant='outlined' startIcon={<SendOutlined/>}>
                        Send Message
        </Button>
        </Stack>
        </div>
        </div>
    </>
  )
}

export default NeedHelp