import { Avatar, Box, Chip, CircularProgress, Divider } from '@mui/material';
import React, { useEffect } from 'react'
import { useApiService } from '../../services/api-service';
import { Person, Place, Replay } from '@mui/icons-material';
import { NO_PROFILE } from '../../utils/routes';
import { useProfileService } from '../../services/profile-service';
import toast from 'react-hot-toast';

const LoungeMembers = ({uuid, loungeId}) => {
    const [loading, setLoading] = React.useState(false);
    const [members, setMembers] = React.useState([]);
    const profile = useProfileService();
    const apiService = useApiService();

    const fetchData = async () => {
        try {
          setLoading(true);
          const membersResponse = await apiService.lounge.getMembers({ lounge_uuid: loungeId });
          // Handle community members response
          if (membersResponse.status === 200 || membersResponse.status === 201) {
            const data = membersResponse.data;
            const userIds = Object.values(data).map((member) => member.user_id);
            const memberDetailsPromises = userIds.map((userId) => apiService.profile.profileDetails({ user_id: userId }));
            const memberDetailsResponses = await Promise.all(memberDetailsPromises);
            const memberDetails = memberDetailsResponses.map((response, index) => {
              const userDetails = response.data;
              const memberInfo = data[index]; // Get member info corresponding to the response index
              return { ...userDetails, role: memberInfo.role }; // Include role in the member details
            });
            setMembers(memberDetails);
          } else {
            console.error('Error fetching community members:', membersResponse.statusText);
          }
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }else{
            toast.error("Network Error")
          }
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchData();
      }, [uuid]);
  return (
    <>
    {loading ? (
        <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress style={{ color: '#a06ccb'}}/>
        <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
        </Box>
    ) : (
        <>
        <div className='comm-members-h2'>
        <h2 style={{color: 'black', textAlign: 'center'}}>Lounge Members</h2>
        </div>
        <div className="comm-members-container">
          {members ? (
            <>
            {members
            .sort((a, b) => (a.userId === profile.profile.userId ? -1 : b.userId === profile.profile.userId ? 1 : 0))
            .map((member) => (
              <>
              {member ? (
              <>
              <div className="community-info-items" key={member.userId}>
                <Avatar alt={`${member.firstName} ${member.lastName}`} src={member.photoUrl} />
                <div className="member-info">
                  <div>
                  <p>{`${member.firstName} ${member.lastName}`}</p>
                  <span className='comm-member-location'><Place sx={{color: 'light.main'}}/>{member.city}, {member.country}</span>
                  </div>
                  <div>
                  {member.userId === profile.profile.userId && (<Chip sx={{backgroundColor: "light.main"
                    }} icon={<Person/>} label='You' size='small'/> )}
                  </div>
                </div>
              </div>
              <Divider/>
              </>
              ) : (
                <p style={{textAlign: 'left'}}>Error fetching member details</p>
              )}
              </>
            ))}
            </>
          ) : (
            <>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '10px'}}>
            <p>Error loading members</p>
            <Replay onClick={fetchData} sx={{cursor: 'pointer'}} />
            </Box>
            </>
          )}
        </div>
        </>
    )}
    </>
  )
}

export default LoungeMembers