import React, { useState } from 'react'
import CreateAidLounge from '../CreateAidLounge/CreateAidLounge'
import { Avatar, Box, Divider, Stack, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { GroupAdd } from '@mui/icons-material'
import CreateChitFunds from './CreateChitFunds'

const ChitFundsList = ({uuid, chitFunds}) => {
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = () => {
      setOpenModal(true)
    }

    const handleCloseModal = () => {
      setOpenModal(false)
    }
  return (
    <>
    {/*this is for list of raiseme*/}
    <div className='lounge-list'>
      <div className="lounge-list-container">
      {chitFunds.length === 0 ? (
        <Stack direction={'column'}  justifyContent={'center'} alignItems={'center'} gap={0.5}>
          <p>No records of Chit Funds</p>
          <button onClick={handleOpenModal} className='button create-lounge-cta'>Create Chit Funds</button>
          {/* <Button style={{width: 'fit-content', color: '#a06ccb', backgroundColor: '#ede7f6'}}>Create RaiseMe</Button> */}
        </Stack>
        ) : (
          <>
          <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <h2 style={{color: 'black'}}>Chit Funds</h2>
          <Tooltip title='Create Chit Fund'>
          <button onClick={handleOpenModal} className="button" style={{padding: '7px'}}><GroupAdd/></button>
          </Tooltip>
          </Stack>
          <Divider />
          <Box className='lounge-list-box'>
            {chitFunds.map((chit) => (
              <React.Fragment key={chit.id}>
                <div key={chit.id} className="lounge-list-item">
                  <Avatar alt={`${chit.name}`} src={chit.photoUrl} />
                  {/* <img className='profile-img' src={NO_PROFILE} alt={lounge.name} /> */}
                  <div className='lounge-list-info'>
                    <h3>{chit.name}</h3>
                    <p>{chit.description}</p>
                  </div>
                  <Link to={`/community/chit/${chit.community_id ?? ''}/${chit.id}`}>
                    <button className='button view-lounge-cta'>View Chit</button>
                  </Link>
                  {/* Add any other lounge details you want to display */}
                </div>
                <Divider key={`divider-${chit.id}`} />
            </React.Fragment>
          ))}
          </Box>
          </Box>
        </>
        )}
      </div>
    </div>
    {openModal && (
      <div className='modal-overlay'>
        <Box className="comm-popup-modal">
        <CreateChitFunds uuid={uuid} onCancel={handleCloseModal} />
        </Box>
      </div>
    )}
    </>
  )
}

export default ChitFundsList