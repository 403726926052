import { Box, Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useApiService } from '../../services/api-service';
import { AddPhotoAlternate } from '@mui/icons-material';
import toast from "react-hot-toast";

const EditAidLoungeBanner = ({loungeData, handleClose, getLoungeInfo}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const apiService = useApiService();

    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setSelectedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
        setMainImage(file);
      };
    
      const handleBannerUpdate = async () => {
        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', mainImage);
            formData.append('aid_lounge_id', loungeData.id);
            const response = await apiService.aidlounge.uploadBanner(formData);
            if(response.status === 200 || response.status === 201){
                getLoungeInfo();
                toast.success('Banner Photo Uploaded');
                handleClose();
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
            } else{
              toast.error('Network Error');
            }
        } finally{
            setIsUploading(false);
        }
      }
  return (
    <Box className="raiseme-position-modal">
        <div className="card-details-modal-top">
          <h2 style={{color: 'black'}}>Edit Aid Lounge Banner</h2>
        </div>
        <div className="card-details-modal-middle">
        <Box>
      <Stack>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '200px', // Adjust the height as needed
            overflow: 'hidden',
            border: '1px solid lightgrey', // Border for the box
            mt: '1rem'
          }}
        >
          {selectedImage || loungeData.banner ? (
            // Display the community banner if it exists
            <>
            <img
              src={selectedImage || loungeData.banner}
              alt="Aid Lounge Banner"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className='add-commbanner-icon'>
            <AddPhotoAlternate
                sx={{
                    color: 'white'
                }}
                onClick={() => document.getElementById('uploadInput').click()}
              />
              </div>
              <input
                type="file"
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleImageSelect}
                accept="image/*"
              />
              </>
          ) : (
            // If no banner exists, display an icon and allow upload
            <>
              <div className='add-commbanner-icon'>
            <AddPhotoAlternate
                sx={{
                    color: 'white'
                }}
                onClick={() => document.getElementById('uploadInput').click()}
              />
              </div>
              <input
                type="file"
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleImageSelect}
                accept="image/*"
              />
            </>
          )}
        </Box>
      </Stack>
    </Box>
        </div>
        <div className="card-details-modal-bottom">
          <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} mt={3}>
            <Button onClick={handleClose} style={{ color: '#a06ccb', fontSize: '14px' }}>
              Cancel
            </Button>
            <Button disabled={isUploading} onClick={handleBannerUpdate} style={{ backgroundColor: '#a06ccb', color: 'white', fontSize: '14px' }}>
            {isUploading ? 'Updating...' : 'Update'}
            </Button>
          </Stack>
        </div>
    </Box>
  )
}

export default EditAidLoungeBanner