import React, { useEffect, useState } from 'react'
import CreateLounge from '../CreateLounge/CreateLounge'
import { Avatar, Box, CircularProgress, Divider, Stack, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { NO_PROFILE } from '../../utils/routes'
import { GroupAdd } from '@mui/icons-material'
import toast from "react-hot-toast";
import { useApiService } from '../../services/api-service'

const CommunityLounges = ({uuid}) => {
  const apiService = useApiService();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [communityLounges, setCommunityLounges] = useState([]);

    const getLounge = async () => {
      try {
        setLoading(true);
        const loungeResponse = await apiService.lounge.getLounges({ comm_uuid: uuid });
        // Handle aid lounges response
        if (loungeResponse.status === 200 || loungeResponse.status === 201) {
          setCommunityLounges(loungeResponse.data);
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data[" code"]);
        }else{
          toast.error("Network Error")
        }
      } finally{
        setLoading(false);
      }
    }

    useEffect(() => {
      getLounge();
    }, [openModal]);

    const handleOpenModal = () => {
      setOpenModal(true)
    }

    const handleCloseModal = () => {
      setOpenModal(false)
    }
  return (
    <>
    {/*this is for list of raiseme*/}
    {loading ? (
      <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <CircularProgress style={{ color: '#a06ccb'}}/>
      <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
      </Box>
    ) : (
      <>
      <div className='lounge-list'>
      <div className="lounge-list-container">
      {communityLounges.length === 0 ? (
        <Stack direction={'column'}  justifyContent={'center'} alignItems={'center'} gap={0.5}>
          <p>No records of Lounges</p>
          <button onClick={handleOpenModal} className='button create-lounge-cta'>Create Lounge</button>
          {/* <Button style={{width: 'fit-content', color: '#a06ccb', backgroundColor: '#ede7f6'}}>Create RaiseMe</Button> */}
        </Stack>
        ) : (
          <>
          <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <h2 style={{color: 'black'}}>Lounges</h2>
          <Tooltip title='Create a Social Lounge'>
          <button onClick={handleOpenModal} className="button" style={{padding: '7px'}}><GroupAdd/></button>
          </Tooltip>
          </Stack>
          <Divider />
          <Box className='lounge-list-box'>
            {communityLounges.map((lounge) => (
              <React.Fragment key={lounge.id}>
                <div key={lounge.id} className="lounge-list-item">
                  <Avatar alt={`${lounge.lounge_name}`} src={lounge.photoUrl} />
                  {/* <img className='profile-img' src={NO_PROFILE} alt={lounge.name} /> */}
                  <div className='lounge-list-info'>
                    <h3>{lounge.lounge_name}</h3>
                    <p>{lounge.lounge_descr}</p>
                  </div>
                  <Link to={`/lounge/${lounge.comm_uuid ?? ''}/${lounge.lounge_uuid}`}>
                    <button className='button view-lounge-cta'>View Lounge</button>
                  </Link>
                  {/* Add any other lounge details you want to display */}
                </div>
                <Divider key={`divider-${lounge.id}`} />
            </React.Fragment>
          ))}
          </Box>
          </Box>
        </>
        )}
      </div>
    </div>
      </>
    )}
    
    {openModal && (
      <div className='modal-overlay'>
        <CreateLounge onCancel={handleCloseModal} />
      </div>
    )}
    </>
  )
}

export default CommunityLounges