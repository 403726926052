import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useApiService } from '../../services/api-service'
import QRCode from 'react-qr-code';
import { QrCodeScanner } from '@mui/icons-material';
import './kycverification.css'

const KYCVerification = ({customerNumber, firstName, lastName, nextStep}) => {
  const apiService = useApiService();
  const [qrLink, setQrLink] = useState(''); 
  const [QRstep, setQRStep] = useState();

  const verifyKYC = async (e) => {
    try {
        const requestBody = {
            customer_number: customerNumber,
            first_name: firstName,
            last_name: lastName
        }
        const response = await apiService.raiseme.verifyKYC(requestBody);
        const qrUrl = response.data.url;
        setQrLink(qrUrl);
        if(response.status === 200 || response.status === 201){
            setQRStep(2)
        }
    } catch (error) {
        
    }
}
    
  useEffect(() => {
    verifyKYC();
  }, []);
  return (
    <>
        {QRstep === 1 && (
            <>
                <Box display={'flex'} justifyContent={'center'}>
                <CircularProgress style={{color: '#a06ccb'}}/>
                </Box>
            </>
        )}
        {QRstep === 2 &&(
            <>
                <Box gap={1.5} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                    <form style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}} onSubmit={nextStep}>
                        {/* <QrCodeScanner className='big-icon'/> */}
                    <h2 style={{color: 'black', textAlign: 'center', margin: '0'}}>Scan QR Code for Personal Identity Verification</h2>
                    <p>Upon scanning the QR Code you will be required to do the following:</p>
                    <ul className='kyc-ul'>
                        <li>Submit a photo of your government issued ID Card</li>
                        <li>Take a picture to undergo facial recognition</li>
                    </ul>
                    <QRCode
                    size={256}
                    style={{ padding: '10px 0'}}
                    value={qrLink}
                    />
                    <button style={{height: '2.5rem', width: '50%'}} type='submit' className='button'>Next Step</button>
                    </form>
                </Box>
            </>
        )}
    </>
  )
}

export default KYCVerification