import React from 'react';
import './communityMembers.css';
import { NO_PROFILE } from '../../utils/routes';
import { LocationCityRounded, Person, Place } from '@mui/icons-material';
import { Avatar, Chip, Divider } from '@mui/material';
import { useProfileService } from '../../services/profile-service';

const CommunityMembers = ({ members }) => {
  const profile = useProfileService();
  return (
    <>
    <div className='comm-members-h2'>
    <h2 style={{color: 'black'}}>Community Members</h2>
    </div>
    <div className="comm-members-container">
      {members
        .sort((a, b) => (a.userId === profile.profile.userId ? -1 : b.userId === profile.profile.userId ? 1 : 0))
        .map((member) => (
          <>
          <div className="community-info-items" key={member.userId}>
            <Avatar alt={`${member.firstName} ${member.lastName}`} src={member.photoUrl || NO_PROFILE} />
            <div className="member-info">
              <div>
              <p>{`${member.firstName} ${member.lastName}`}</p>
              <span className='comm-member-location'><Place sx={{color: 'light.main'}}/>{member.city}, {member.country}</span>
              </div>
              <div>
              {member.userId === profile.profile.userId && (<Chip sx={{backgroundColor: "light.main"
                }} icon={<Person/>} label='You' size='small'/> )}
              </div>
            </div>
          </div>
          <Divider/>
          </>
        ))}
      </div>
      </>
  );
}

export default CommunityMembers;
