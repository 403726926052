import { Box, Button, Divider, Stack, Switch } from '@mui/material'
import '../SubmitRaiseMePosition/submitRaiseMePosition.css'
import './editcommunity.css'
import React, { useContext, useEffect, useState } from 'react'
import { AddPhotoAlternate } from '@mui/icons-material';
import { useApiService } from '../../services/api-service';
import { CommunityContext } from '../../contexts/CommunityContext';
import MigratingAdminComm from './MigratingAdminComm';
import { LoadingPopup, MAX_IMG_SIZE } from '../../utils/routes';
import toast from 'react-hot-toast';
import EditCommDetails from './EditCommDetails';

const EditCommunity = ({handleClose, community, uuid,
  fetchData, isUserAdmin, isAdminComm, walletStatus, isDemoComm, profile}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const apiService = useApiService();
    const [checked, setChecked] = React.useState(community.walletStatus);
    const [balanceVisibility, setBalanceVisibility] = React.useState(community.commBalanceVisibility);
    const [loading, setLoading] = useState(false);
    const [votes, setVotes] = useState();
    const [senderDetails, setSenderDetails] = useState();
    const [communityName, setCommunityName] = useState(community.commName);
    const [isUpdated, setIsUpdated] = useState(false);
    const [visibilityUpdated, setVisibilityUpdated] = useState(false);
    const [walletCheckedUpdate, setWalletCheckedUpdate] = useState(false);

    const handleCommunityNameChange = (e) => {
        setCommunityName(e.target.value);
        setIsUpdated(true);
    };

    const activateWallet = async (e) => {
      try {
          const requestBody = {
            comm_uuid: community.commUuid,
            walletStatus: true
          }
          const response = await apiService.community.updateWalletStatus(requestBody);
          if(response.status === 200 || response.status === 201){
              setChecked(true);
              toast.success("Wallet status updated")
          }
      } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }
      }
    }

    const showBalanceVisibility = async (e) => {
      try {
          const requestBody = {
            comm_uuid: community.commUuid,
            comm_balance_visibility: true
          }
          const response = await apiService.community.updateBalanceStatus(requestBody);
          if(response.status === 200 || response.status === 201){
              setBalanceVisibility(true);
              toast.success("Balance visibility updated")
          }
      } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }
      }
    }

    const hideBalanceVisibility = async (e) => {
      try {
        const requestBody = {
          comm_uuid: community.commUuid,
          comm_balance_visibility: false
        }
        const response = await apiService.community.updateBalanceStatus(requestBody);
        if(response.status === 200 || response.status === 201){
            setBalanceVisibility(false);
            toast.success("Balance visibility updated")
        }
    } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
        }
    }
    }

    const handleChange = (event) => {
      setChecked(event.target.checked);
      setWalletCheckedUpdate(true);
    };
    const handleVisibilityChange = (event) => {
      setBalanceVisibility(event.target.checked);
      setVisibilityUpdated(true)
    };

    

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setMainImage(file);
  };

  const handleFileValidation = (e) => {
    const file = e.target.files[0];
    const validTypes = [
      'image/jpeg', 'image/png'
    ];

    if (!validTypes.includes(file.type)) {
      toast.error('Invalid file type. Only jpg and png');
      e.target.value = ''; // Reset the file input
      return;
    }

    if (file.size > MAX_IMG_SIZE) {
      const maxSizeInMB = MAX_IMG_SIZE / (1024 * 1024); // Convert MAX_IMG_SIZE to MB
      toast.error(`File size exceeds ${maxSizeInMB}MB limit.`);
      e.target.value = ''; // Reset the file input
      return;
    }

    handleImageSelect(e);
  };

  const handleBannerUpdate = async () => {
    try {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', mainImage);
        formData.append('comm_uuid', uuid);
        await toast.promise(
          apiService.community.uploadBanner(formData),{
            loading: 'Uploading Banner Photo...',
            success: 'Banner Photo Uploaded',
            error: 'Error Uploading Banner Photo'
          }
        )
    } catch (e) {
        if(e.response){
            toast.error(e.response.data["code "]);
        } else{
          toast.error('Network Error');
        }
    } finally{
        setIsUploading(false);
    }
  }

  const handleCommunityNameUpdate = async () => {
    try {
      await toast.promise(
        apiService.community.updateCommunityName({
          comm_uuid: community.commUuid,
          comm_name: communityName
        }),{
          loading: 'Updating Community Name...',
          success: 'Community Name Updated',
          error: 'Error Updating Community Name'
        }
      )
      handleClose();
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
    } else{
      toast.error('Network Error');
    }
    }
  }

  const onSubmit = async () => {
    try {
      setIsUploading(true);

      if(checked && !walletStatus){
        await activateWallet();
      }

      if(selectedImage){
        await handleBannerUpdate();
      }
      if(visibilityUpdated){
        if(walletStatus && isAdminComm && isUserAdmin){
          if(balanceVisibility){
            await showBalanceVisibility();
          } else {
            await hideBalanceVisibility();
          }
        }
      }

      if(isUpdated){
        await handleCommunityNameUpdate();
      }
      setIsUpdated(false);
      setVisibilityUpdated(false);
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data["code "]);
      } else {
        toast.error('Network Error');
      }
    } finally{
      setIsUploading(false);
    }
  }

  const fetchVotes = async () => {
    try {
      setLoading(true);
      const response = await apiService.community.getMigrateVotes({
        comm_uuid: community.commUuid
      })
      setVotes(response.data);
      const senderResponses = await apiService.profile.profileDetails({
        user_id: response.data.senderId
      })
      setSenderDetails(senderResponses.data);
    } catch (e) {
      if(e.response){
        if(e.response.status !== 404){
          toast.error(e.response.data["code "]);
        }
      }
    } finally{
      setLoading(false);
    }
  }

  useEffect(() => {
    if(isDemoComm)fetchVotes();
  }, [])

  const isButtonDisabled = isUploading || 
  (!selectedImage && !isUpdated && !visibilityUpdated && (!(checked && !walletStatus)));
  console.log('active', isButtonDisabled)
  return (
    <>
    <Box className="raiseme-position-modal">
    {loading ? (
      <>
      {LoadingPopup()}
      </>
    ) : (
      <>
      
    <div className="card-details-modal-top">
          <h2 style={{color: 'black', textAlign: 'center'}}>Community Settings</h2>
        </div>
        <div className="edit-comm-details-modal-middle">
        <Box>
      <Stack>
      <p className='alignLeft' style={{textAlign: 'left', fontSize: '1rem', marginTop: '1rem', marginBottom: '0.5rem', fontWeight: 'bold'}}>Change Community Banner</p>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '200px', // Adjust the height as needed
            overflow: 'hidden',
            border: '1px solid lightgrey', // Border for the box
          }}
        >
          {selectedImage || community.banner ? (
            // Display the community banner if it exists
            <>
            <img
              src={selectedImage || community.banner}
              alt="Community Banner"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className='add-commbanner-icon'>
            <AddPhotoAlternate
                sx={{
                    color: 'white'
                }}
                onClick={() => document.getElementById('uploadInput').click()}
              />
              </div>
              <input
                type="file"
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleFileValidation}
                accept="image/*"
              />
              </>
          ) : (
            // If no banner exists, display an icon and allow upload
            <>
              <div className='add-commbanner-icon'>
            <AddPhotoAlternate
                sx={{
                    color: 'white'
                }}
                onClick={() => document.getElementById('uploadInput').click()}
              />
              </div>
              <input
                type="file"
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleImageSelect}
                accept="image/*"
              />
            </>
          )}
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '10px'}}>
          {isUserAdmin && isAdminComm && (
            <Stack direction={'row'} justifyContent={'space-between'}>
            <p className='alignLeft' style={{textAlign: 'left', fontSize: '1rem', fontWeight: 'bold'}}>Wallet Activation</p>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              color='secondary'
              disabled={checked}
            />
            </Stack>
          )}
          {walletStatus && isAdminComm && isUserAdmin && (
            <Stack direction={'row'} justifyContent={'space-between'}>
            <p className='alignLeft' style={{textAlign: 'left', fontSize: '1rem', fontWeight: 'bold'}}>Wallet Balance Visibility</p>
            <Switch
              checked={balanceVisibility}
              onChange={handleVisibilityChange}
              inputProps={{ 'aria-label': 'controlled' }}
              color='secondary'
            />
          </Stack>
          )}
          <EditCommDetails isAdminComm={isAdminComm} community={community}
          profile={profile} communityName={communityName} handleCommunityNameChange={handleCommunityNameChange}
          />
          {isDemoComm && (
            <MigratingAdminComm senderDetails={senderDetails}
            community={community} votes={votes} profile={profile}
            fetchVotes={fetchVotes}
            />
          )}
        </Box>
      </Stack>
    </Box>
        </div>
        <div className="card-details-modal-bottom">
          <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} mt={3}>
            <Button onClick={handleClose} style={{ color: '#a06ccb', fontSize: '14px' }}>
              Cancel
            </Button>
            <Button disabled={isButtonDisabled} onClick={onSubmit} style={{ backgroundColor: '#a06ccb', color: 'white', fontSize: '14px' }}>
            {isUploading ? 'Updating...' : 'Update'}
            </Button>
          </Stack>
        </div>
      </>
    )}
    </Box>
    </>
  )
}

export default EditCommunity