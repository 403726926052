import React, { useEffect, useState } from 'react'
import { useApiService } from '../../services/api-service';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';

const EmailVerificationScreen = ({email, handleLoginClick}) => {
    const [resendBtn, setResendBtn] = useState(true);
    const [countdown, setCountdown] = useState(60);
    const [isResending, setIsResending] = useState(false);
    const apiService = useApiService()
    

    const handleResendEmail = async () => {
        try {
          setIsResending(true);
          const response = await apiService.auth.resendEmail({email: email})
          if(response.status === 200 || response.status === 201){
            toast.success('Email sent successfully');
            setCountdown(60); // Reset countdown to 60 seconds
            setResendBtn(true); // Enable the button
          }
        } catch (e) {
          toast.error(e.response.data["code "]);
        } finally{
          setIsResending(false);
        }
      }

    useEffect(() => {
        if (resendBtn) {
          const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
          }, 1000);
      
          return () => clearInterval(timer);
        }
      }, [resendBtn]);
  
      useEffect(() => {
      if (countdown === 0) {
        setResendBtn(false);
      }
  }, [countdown]);
  return (
    <>
        <h2 className='sign-in-header'>Confirmation Email Sent</h2>
        <p className='sign-in-text'>
                    A confirmation email has been sent to your email address.
                    Please click on the link in the email to confirm your
                    account.
        </p>
        <span style={{color: '#a06ccb', textDecoration: 'underline', cursor: 'pointer'}} onClick={handleLoginClick}>Login</span>
                  
        <Button
            disabled={resendBtn || isResending}
            className='sign-in-btn'
            onClick={handleResendEmail}
            style={{color: 'white', backgroundColor: '#a06ccb'}}
            >
            {isResending ? 'Resending...' : `${countdown === 0 ? '': countdown} Resend Email Confirmation`}
        </Button>
    </>
  )
}

export default EmailVerificationScreen