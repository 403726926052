import React, { useEffect, useState } from 'react';
import './raiseMeAdditionalService.css';
import { Divider, LinearProgress } from '@mui/material';
import { useApiService } from '../../services/api-service';
import toast from 'react-hot-toast';

const RaiseMeAdditionalService = ({
  tiers,
  setTiers,
  selectedTier,
  raiseMePrice,
  onTierChange,
  createRaiseMeSubscription,
  currency
}) => {
  const [loading, setLoading] = useState();
  const apiService = useApiService();

  useEffect(() => {
    const getTiers = async (e) => {
      try {
        setLoading(true)
       const response = await apiService.raiseme.getTiers();
      const tierData = response.data;
      setTiers(tierData);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data["code "]);
        } 
      } finally{
        setLoading(false)
      }
    }

    getTiers();
  },[])
  return (
    <>
      {loading ? (
        <LinearProgress style={{ marginBottom: '1rem', backgroundColor: '#a06ccb' }} />
      ) : (
        <>
      <p>6/6</p>
      <h2 className='onboarding-text' style={{margin: 0}}>Setup Additional Services</h2>
      <p className='kyc-desc'>Select your additional service tier</p>
      <div className="form-top">
        <div className="tier-selection">
          {tiers.map((tier) => (
            <>
            <label key={tier.id} className={`additional-tier-radio-container ${tier.status === false ? 'disabled' : ''}`}>
              <input
                type="radio"
                className='tier-radio-input'
                id={tier.id}
                value={tier.id}
                checked={selectedTier === tier.id}
                onChange={() => onTierChange(tier.id)}
                disabled={tier.status === false}
              />
              <div className="tier-details">
                <div className="tier-group">
                <p className="tier-name">{tier.name}</p>
                <p className="tier-description">{tier.descr}</p>
                </div>
                {tier.status === false ? (
                  <div className="tier-price">COMING SOON</div>
                ) : (
                  <div className="tier-price">${tier.amount}</div>
                )}
                
              </div>
            </label>
            <Divider/>
            </>
          ))}
        </div>
      </div>
      <div className="form-bottom">
        <div className="form-actions">
          <button className="button" type="submit" onClick={createRaiseMeSubscription}>
            Submit
          </button>
          <button className="button" type="button">
            Cancel
          </button>
        </div>
      </div>
      </>
      )}
    </>
  );
};

export default RaiseMeAdditionalService;
