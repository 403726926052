import { Box, Button, LinearProgress, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useApiService } from '../../services/api-service';
import AccountsDropdown from '../RaiseMeAccount/AccountsDropdown';
import { useProfileService } from '../../services/profile-service';
import toast from 'react-hot-toast';

const RetryPayment = ({data, selectedLounge, handleClose, loadTransactions}) => {
    const [selectedAccount, setSelectedAccount] = useState("");
    const [existingAccounts, setExistingAccounts] = useState([]);
    const [location, setLocation] = useState();
    const [isFetchingAccount, setIsFetchingAccount] = useState(false);
    const [accountName, setAccountName] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [routingNumber, setRoutingNumber] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [bankBranch, setBankBranch] = useState(null);
    const [interacEmail, setInteracEmail] = useState(null);
    const [interacPhone, setInteracPhone] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [accountType, setAccountType] = useState('');
    const [verifyUrl, setVerifyUrl] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);
    const [currency, setCurrency] = useState('');
    const [bvn, setBvn] = useState(null);
    const [kycData, setKycData] = useState();
    const [debitInfo, setDebitInfo] = useState('');
    const [loadingDebitInfo, setLoadingDebitInfo] = useState(false);
    const [paymentLoadings, setPaymentLoadings] = useState(false);
    const apiService = useApiService();
    const profile = useProfileService();

    const fetchAccounts = async () => {
        try {
          setIsFetchingAccount(true);
          const response = await apiService.raiseme.getAccount();
          if (response.status === 200) {
            const responseData = response.data;
            setExistingAccounts(responseData); // Set the existing accounts data
          } else {
            console.error('Error fetching account data:', response.status, response.statusText);
          }
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          } else {
            toast.error('Network Error');
        }
        } finally{
          setIsFetchingAccount(false);
        }
      };
    
    const getKycData = async () => {
        try {
            const response = await apiService.raiseme.getKYCByLocation(selectedLounge.location);
            if(response.status === 200 || response.status === 201){
                setKycData(response.data);
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
            }
        }
    }

    const getCreditCharges = async () => {
      try {
        setLoadingDebitInfo(true);
        const response = await apiService.raiseme.debitInfo({amount: data.transaction_amount})
        setDebitInfo(response.data);
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
        }
      } finally{
        setLoadingDebitInfo(false);
      }
    }

    useEffect(() => {
        fetchAccounts();
        getKycData();
        getCreditCharges();
      }, []);

    const filteredAccounts = existingAccounts.filter(account => account.location === selectedLounge.location && account.partner_status === 'ACCOUNT_VERIFICATION_DONE');


    const handleSelectAccount = async (accountId) => {
          if (accountId) {
            if (accountId.location !== selectedLounge.location) {
              toast.error("Account location doesn't match raiseMe location.");
            } else {
              // Set state values using the selected account's properties
              setAccountName(accountId.account_name);
              setAccountNumber(accountId.account_number);
              setAccountType(accountId.account_type);
              setBvn(accountId.bvn);
              setCurrency(accountId.currency);
              setBankName(accountId.financial_institution_name);
              setBankBranch(accountId.financial_institution_branch);
              setInteracEmail(accountId.interac_email);
              setInteracPhone(accountId.interac_phone_number);
              setLocation(accountId.location);
              setRoutingNumber(accountId.routing_transit_number);
              setAccountId(accountId.id);
              setAccountStatus(accountId.partner_status);
              setVerifyUrl(accountId.verify_url);
            }
        } else {
          setAccountId(null)
        }
      };
    
    const makePaymentRequest = async () => {
        try {
          setPaymentLoadings(true);
            const requestBody = {
                customer_number: kycData.customerNumber,
                amount: data.transaction_amount,
                raiseme_id: selectedLounge.id,
                user_id: profile.profile.userId,
                account_id: data.account_id,
                partner_id: data.partner_id
            }
            const response = await apiService.raiseme.requestToPay(requestBody);
            if(response.status === 200 || response.status === 201){
              toast.success('Payment Request Sent Successfully');
              handleClose();
              loadTransactions();
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
            }
        } finally{
          setPaymentLoadings(false);
        }
    }
  return (
    <>
    {loadingDebitInfo ? (
      <>
        <LinearProgress style={{ marginBottom: '1rem', backgroundColor: 'light.main' }} />
      </>
    ) : (
      <>
    <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
        <h2 style={{color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0'}}>Make Instant Contribution</h2>
        <p style={{fontSize: '45px', fontWeight: 'bold'}}>${data.transaction_amount}</p>
        <p>Charges: ${debitInfo.charges}</p>
        <p>Total Amount: ${debitInfo.total_amount}</p>
        {/* <Box>
        <AccountsDropdown filteredAccounts={filteredAccounts} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} handleSelectAccount={handleSelectAccount}/>
        </Box> */}
        <Stack direction={'row'} width={'100%'} spacing={1}>
                        <Button style={{width: '50%', backgroundColor: '#a06ccb', color: 'white'}}
                        variant='contained' onClick={makePaymentRequest}>{paymentLoadings ? 'Making Payment...' : 'Make Payment'}</Button>
                        <Button style={{width: '50%', color: '#a06ccb', borderColor: '#a06ccb'}} variant='outlined' onClick={handleClose}>Cancel</Button>
        </Stack>
    </Box>
    </>
    )}
    </>
  )
}

export default RetryPayment