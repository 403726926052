import { AccountBalance, CheckCircle } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const AccountVerification = ({ verifyUrl, nextStep }) => {
  const [verificationClicked, setVerificationClicked] = useState(false);
  const location = useLocation();

  const isSettings = location.pathname.startsWith('/settings');
  const handleVerificationClick = () => {
    setVerificationClicked(true);
  };

  return (
    <Box gap={1.5} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
      <form style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }} onSubmit={nextStep}>
      <AccountBalance className='big-icon'/>
      <h2 style={{color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0'}}>Bank Account Verification</h2>
        <p>The button below redirects you to our 3rd party account verification partners. Click the link below to verify your account</p>
        <Button variant='contained' target='_blank' href={verifyUrl} style={{ height: '2.5rem', color: '#fff', backgroundColor: '#a06ccb' }} startIcon={<CheckCircle />} onClick={handleVerificationClick}>
          Verify Account Created
        </Button>
        {verificationClicked && !isSettings && (
          <button style={{ height: '2.5rem', width: '50%' }} type='submit' className='button'>
            Next Step
          </button>
        )}
      </form>
    </Box>
  );
};

export default AccountVerification;