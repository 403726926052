import { Avatar, Box, Button, Stack } from '@mui/material'
import React from 'react'
import { capitalizeFirstLetter } from '../../utils/routes'

const LoggedUserScreen = ({handleDecline, handleJoinCommunity, user, isJoiningCommunity, community}) => {
  return (
    <>
                <Avatar src={user.photoUrl} sx={{width: '75px', height: '75px'}}/>
              <h2 className='text-md'>{user.firstName} {user.lastName} has invited you to join</h2>
              <Stack direction={'row'} spacing={0.5}>
                <Avatar sx={{width: '30px', height: '30px'}} variant='rounded'>
                  {capitalizeFirstLetter(community.commName)}
                </Avatar>
                <h2 className='title-md'>{community.commName} Community</h2>
              </Stack>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%'}}>
                <Stack direction={'row'} width={'100%'} spacing={1}>
                  <Button disabled={isJoiningCommunity} onClick={handleJoinCommunity} sx={{color: 'white', width: '50%'}} className='button'>
                    {isJoiningCommunity ? 'Accepting...' : 'Accept'}
                  </Button>
                  <Button onClick={handleDecline} sx={{color: 'white', width: '50%'}} className='button'>
                    DECLINE
                  </Button>
                </Stack>
              </Box>
                </>
  )
}

export default LoggedUserScreen