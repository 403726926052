import React from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useProfileService } from '../../services/profile-service'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";
import './createlounge.css'
import '../../pages/OnBoarding/onboarding.css'
import { useEffect } from 'react'
import { Button } from '@mui/material'

const CreateLounge = ({onCancel}) => {
    const modalRef = useRef(null)
    const {uuid} = useParams();
    const [loungeName, setLoungeName] = useState(null);
    const [loungeDesc, setLoungeDesc] = useState(null);
    const [loungeId, setLoungeId] = useState(null);
    const profile = useProfileService();
    const apiService = useApiService();
    const history = useHistory();
    const [isCreating, setIsCreating] = useState(false);


    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
          onCancel();
        }
      };

      useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsCreating(true);
            const requestBody = {
                comm_uuid: uuid,
                lounge_name: loungeName,
                lounge_descr: loungeDesc,
                city: profile.profile.city,
                country: profile.profile.country
            }
            const response = await apiService.lounge.createLounge(requestBody);
            const loungeID = response.data.id;
            setLoungeId(loungeID)
            if(response.status === 200 || response.status === 201){
                toast.success('Lounge created successfully')
                const loungeRequestBody = {
                    lounge_uuid: response.data.id,
                    user_id: profile.profile.userId
                }
                await toast.promise(
                  apiService.lounge.createMember(loungeRequestBody),
                  {
                      loading: 'Adding User to Lounge...',
                      success: 'User Added Successfully',
                      error: 'Error adding user to lounge created'
                  }
              );
              history.push(`/lounge/${uuid}/${response.data.id}`);
            }
            
        } catch(e){
            if(e.response){
              toast.error(e.response.data["code "]);
              toast.error(e.response.data["errors"]);
            } else{
              toast("Network Error", {
                type: "error",
                data: {
                  msg: "Network error, please check your internet connection.",
                  type: "error",
                }
              });
            }
          } finally{
            setIsCreating(false);
          }
    }
  return (
    <div className="create-community">
      <div className="create-lounge-modal" ref={modalRef}>
      <h2 className="onboarding-text" style={{marginBottom: '10px'}}>Create Social Lounge</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-top" style={{display: 'flex', flexDirection: 'column', gap: "1rem"}}>
            <div className="kyc-form-group" style={{width: '100%'}}>
                <label htmlFor='lounge-name'>Lounge Name:</label>
                <input type="text" id="lounge-name" value={loungeName} onChange={(e) => setLoungeName(e.target.value)} required />
            </div>
            <div className="kyc-form-group" style={{width: '100%'}}>
                <label htmlFor='lounge-name'>Lounge Description:</label>
                <textarea id="lounge-name" value={loungeDesc} onChange={(e) => setLoungeDesc(e.target.value)} required />
            </div>
        </div>
        <div className="form-bottom">
          <div className="form-actions-kyc">
          <Button sx={{color: 'white'}} disabled={isCreating} className="button" type="submit">
              {isCreating ? 'Creating' : 'Create'}
            </Button>
            <Button sx={{color: 'white'}} className="button" type="button" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
       </div>
    </div>
  )
}

export default CreateLounge