// LOungePostsContext.js
import { createContext, useContext, useState } from 'react';

const LoungePostsContext = createContext();

export const useLoungePostsContext = () => {
  return useContext(LoungePostsContext);
};

export const LoungePostsProvider = ({ children }) => {
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshLoungePosts = () => {
    setRefreshCount((prevCount) => prevCount + 1);
  };

  const contextValue = {
    refreshCount,
    refreshLoungePosts,
  };

  return (
    <LoungePostsContext.Provider value={contextValue}>
      {children}
    </LoungePostsContext.Provider>
  );
};
