import toast from "react-hot-toast";
import React, { useEffect, useRef, useState } from 'react';
import './raiseme.css';
import '../pages/OnBoarding/onboarding.css';
import { useApiService } from '../services/api-service';
import { useProfileService } from '../services/profile-service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useEmailService from '../services/email-service';
import RaiseMeKYC from '../components/raiseMeKYC/RaiseMeKYC';
import RaiseMeAccount from '../components/RaiseMeAccount/RaiseMeAccount';
import RaiseMeAdditionalService from '../components/RaiseMeAdditionalService/RaiseMeAdditionalService';
import { CurrencyExchangeRounded, MonetizationOn } from '@mui/icons-material';
import KYCVerification from '../components/KYCVerification/KYCVerification';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Chip, LinearProgress } from '@mui/material';
import AccountVerification from '../components/AccountVerification/AccountVerification';
import PendingVerification from '../components/PendingVerification/PendingVerification';

const RaiseMe = ({ onCancel }) => {
  const modalRef = useRef(null);
  const [loungeType, setLoungeType] = useState('raiseme');
  // const [isAddMembersChecked, setIsAddMembersChecked] = useState(false);
  const [period, setPeriod] = useState('');
  const apiService = useApiService();
  const profile = useProfileService();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [contributionAmount, setContributionAmount] = useState('');
  const [groupSize, setGroupSize] = useState(null);
  const [startDate, setStartDate] = useState('');
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [additionalService, setAdditionalService] = useState('1');
  const [bankName, setBankName] = useState(null);
  const [bankBranch, setBankBranch] = useState(null);
  const [interacEmail, setInteracEmail] = useState(null);
  const [interacPhone, setInteracPhone] = useState(null);
  const [accountType, setAccountType] = useState('');
  const [currency, setCurrency] = useState('');
  const [bvn, setBvn] = useState(null);
  const { getUserEmail } = useEmailService();
  const [raiseMeId, setRaiseMeId] = useState(null);
  const [serviceLocation, setServiceLocation] = useState('Canada');
  const [raiseMePrice, setRaiseMePrice] = useState(0);
  const [selectedTier, setSelectedTier] = useState('1');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [email, setEmail] = useState('');
  const [Date, setDate] = useState('');
  const [location, setLocation] = useState(null);
  const [gender, setGender] = useState('');
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [customerNumber, setCustomerNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [province, setProvince] = useState(null);
  const [city, setCity] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLGA, setSelectedLGA] = useState(null);
  const [useExistingAccount, setUseExistingAccount] = useState('yes');
  const [kycMessage, setKycMessage] = useState('');
  const [nextButtonStatus, setNextButtonStatus] = useState(false);
  const [kycStatus, setKycStatus]  = useState(false);
  const [loading, setLoading] = useState(false);
  // const [isLounge, setIsLounge] = useState(false);
  // const {uuid} = useParams();
  const [tiers, setTiers] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [verifyUrl, setVerifyUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [accountStatus, setAccountStatus] = useState(null);
  const [partnerStatus, setPartnerStatus] = useState(null);
  const url = window.location.href;
  const communityId = url.substring(url.lastIndexOf("/") + 1);

  

  const handleTierChange = (tierId) => {
    setSelectedTier(tierId);
    setRaiseMePrice(tiers.find((tier) => tier.id === tierId).amount);
  };  

  const handleContributionAmountChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9]*$/; // Regular expression to allow only numeric characters
  
    if (regex.test(input)) {
      // If the input matches the numeric pattern, update the state
      setContributionAmount(input);
    }
  };

  

  const handleGroupSizeChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9]*$/; // Regular expression to allow only numeric characters
  
    if (regex.test(input)) {
      // If the input matches the numeric pattern, update the state
      setGroupSize(input);
    }
  };
  

  // const handleAddMembersChange = (e) => {
  //   setIsAddMembersChecked(e.target.checked);
  // };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };
  
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      
    }
  };

  const handleKYCLocationChange = async (event) => {
    let selectedLocation = '';
    try {
      setLoading(true);
      selectedLocation = event.target.value;
      setLocation(selectedLocation);
  
      if (selectedLocation !== '') {
        setNextButtonStatus(true);
  
        const response = await apiService.raiseme.getKYCByLocation(selectedLocation);
        if (response.status === 200 || response.status === 201) {
          setKycStatus(true);
          setKycMessage(`You have a KYC in ${selectedLocation}`);
        } else {
          // Handle specifically when the response status isn't 200 or 201
          if (response.status === 404) {
            setKycStatus(false);
            setKycMessage(`You do not have a KYC in ${selectedLocation}`);
          } else {
            // Handle other non-success statuses if needed
            // For instance, you can add more specific error messages here
          }
        }
      } else {
        setNextButtonStatus(false);
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data["code "], {
          type: 'error',
          data: {
            msg: error.response.data["code "],
            type: 'error',
          },
        });
        if(error.response.status === 404){
          setKycStatus(false);
          setLocation(selectedLocation);
          setKycMessage(`You do not have a KYC in ${selectedLocation}. Create one`);
        }
      } 
    }finally {
      setLoading(false); // Set loading state to false when request completes
    }
  };

  const checkVerification = async () => {
    try {
      setIsVerifying(true);
      const kycdata = await apiService.raiseme.getKYCByLocation(location);
      const partnerStatus = kycdata.data.partnerStatus;
      setPartnerStatus(partnerStatus);
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else{
        toast.error('Network Error!')
      }
    } finally {
      setIsVerifying(false);
    }
  }

  // const handleLocationChange = (e) => {
  //   setLocation(e.target.value);
  // }

  const handleNext = () => {
    if(kycStatus){
      setStep(4)
    }else{
      setStep(step+1)
    }
  }
  

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if(step === 6)checkVerification();
  }, [step]);
  

  const nextStep = () => {
    setStep(step+1)
}

const nextStepMFA = () => {
  setStep(step+2)
}


  // const handleLoungeTypeChange = (e) => {
  //   const selectedLoungeType = e.target.value;
  //   setLoungeType(selectedLoungeType)
  //   if(selectedLoungeType === 'social'){
  //     setIsLounge(true)
  //     console.log('social')
  //   }
  //   setIsAddMembersChecked(false); // Reset the checkbox state
  // };

  const handleCreateKYC = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const requestBody = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        location: location,
        phone: phoneNumber,
        postal_code: postalCode,
        address_street: address,
        address_city: profile.profile.city,
        user_id: profile.profile.userId,
        country: location === 'Canada' ? 'CA' : location === 'Nigeria' ? 'NG' : ''
      }
      const response = await toast.promise(
        apiService.raiseme.createKYC(requestBody),
        {
          loading: 'Creating KYC...',
          success: 'KYC created successfully',
          error: 'Error creating KYC'
        }
      )
      const cusNumber = response.data.results.customer_number;
      const partnerStatus = response.data.results.partner_status;
      setCustomerNumber(cusNumber);
      setPartnerStatus(partnerStatus);
      if(response.status === 200 || response.status === 201){
        setStep(step+1)
      }
    } catch(e){
      if(e.response){
        toast.error(e.response.data["code "]);
      }else{
        toast.error("Network Error");
      }
    } finally{
      setIsSubmitting(false);
    }
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if(useExistingAccount){
        setStep(step+2);
      } else {
      const requestBody = {
        financial_institution_name: bankName,
        financial_institution_branch: bankBranch,
        account_number: accountNumber,
        account_type: accountType,
        bvn: bvn,
        currency: currency,
        interac_email: interacEmail,
        interac_phone_number: interacPhone,
        location: location,
        routing_transit_number: routingNumber,
        account_name: accountName
      }
      const response = await toast.promise(
        apiService.raiseme.createAccount(requestBody),
        {
            loading: 'Creating Account...',
            success: 'Account Created Successfully',
            error: 'Error Creating Account'
        }
      )
    if (response.status === 200 || response.status === 201) {
      const getAccountID = response.data.id;
      const verificationURL = response.data.verifyUrl;
      const getStatus = response.data.partnerStatus;
      setAccountId(getAccountID)
      setVerifyUrl(verificationURL);
      setAccountStatus(getStatus);
      setStep(step+1)
    }
  }
  } catch(e){
    if(e.response){
      toast.error(e.response.data["code "]);
    } else{
      toast.error("Network Error");
    }
  } finally{
    setIsSubmitting(false);
  }
  }

  const addMemberToRaiseMe = async (raisemeid) => {
    const userEmail = await getUserEmail();
    try {
      const requestBody = {
        additional_service: "additionalService",
        email: userEmail,
        raiseme_id: raisemeid,
        user_id: profile.profile.userId,
        user_type: profile.profile.userType,
        account_id: accountId
      }
      const response = await toast.promise(
        apiService.raiseme.addMember(requestBody),
        {
          loading: 'Adding User to Collective Cash...',
          success: 'User Added Successfully',
          error: 'Error adding user'
        }
      )
      
      if (response.status === 200 || response.status === 201) {
        history.push(`/community/${communityId ?? ''}/${raisemeid}`)
      }
    } catch (e) {
      if (e.response) {
        toast(e.response.data["code "], {
          type: "error",
          data: {
            msg: e.response.data["code "],
            type: "error",
          }
        });
      } else {
        toast.error('Network Error');
      }
    }
  }

  const createRaiseMe = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const url = window.location.href;
    const communityId = url.substring(url.lastIndexOf("/") + 1);
    const requestBody = {
      communityId: communityId,
      userId: profile.profile.userId,
      name: name,
      description: description,
      contributionAmount: contributionAmount,
      groupSize: parseInt(groupSize),
      period: period,
      startDate: startDate,
      user_type: profile.profile.userType,
      location: location,
      raiseme_type: "RAISEME_CONTRIBUTION"
    };

    const response = await toast.promise(
    apiService.raiseme.addRaise(requestBody),
    {
      loading: 'Creating Collective Cash',
      success: 'Created Collective Cash successfully',
      error: 'Error creating Collective Cash'
    }
  )
      const raiseMeId = response.data.id;
      setRaiseMeId(raiseMeId);
      await addMemberToRaiseMe(raiseMeId);
    } catch(e){
      if(e.response){
        toast.error(e.response.data["code "]);
      }else{
        toast.error("Network Error");
      }
    } finally{
      setIsSubmitting(false)
    }
  }

  const renderLoungeFields = () => {
    switch (loungeType) {
      case 'raiseme':
        return (
          <>
            <div className='form-group' style={{gap: 0}}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <MonetizationOn className='big-icon'/>
              </Box>
              <h2 className='h2-title'>Create Collective Cash</h2>
              <p style={{textAlign: 'center'}}>This Collective Cash involves a group of users taking turns to contribute or receive benefits. In this cyclical arrangement, each member contributes to one person at a time, ensuring that everyone receives their fair share over time.</p>
            </div>
            <div className="form-group">
            <label htmlFor="raiseme-name">Collective Cash Name:</label>
            <input type="text" id="raiseme-name" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="raiseme-description">Collective Cash Description:</label>
            <textarea id="raiseme-description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
          </div>
            {/* <div className="form-group">
              <label htmlFor="raiseme-banner">RaiseMe Banner:</label>
              <input type="file" id="raiseme-banner" accept="image/*" required />
            </div> */}
            <div className="form-group">
            <label htmlFor="contribution-amount">Contribution Amount:</label>
            <input
              type="text"
              id="contribution-amount"
              value={contributionAmount}
              onChange={handleContributionAmountChange}
              required
            />
          </div>
            <div className="form-group">
            <label htmlFor="group-size">Number of Participants:</label>
            <input type="text" id="group-size" value={groupSize} onChange={handleGroupSizeChange} required />
          </div>
            <div className="form-group">
            <label htmlFor="period">Period:</label>
            <select id="period" value={period} onChange={handlePeriodChange} required>
              <option value="">Select Period</option>
              <option value="7">Weekly</option>
              <option value="14">Bi-Weekly</option>
              <option value="30">Monthly</option>
            </select>
          </div>
          
          <div className="form-group">
            <label htmlFor="start-date">Start Date:</label>
            <input type="date" id="start-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
          </div>
          </>
        );
      case 'aid':
        return (
          <>
          <div className="form-group">
            <div className='contribution-raiseme'>
                <CurrencyExchangeRounded/>
                <h2>Aid RaiseMe</h2>
              </div>
              <p>This RaiseMe involves a group of users taking turns to contribute or receive benefits. In this cyclical arrangement, each member contributes to one person at a time, ensuring that everyone receives their fair share over time.</p>
              </div>
            <div className="form-group">
              <label htmlFor="raiseme-name">Aid Name:</label>
              <input type="text" id="aid-lounge-name" required />
            </div>
            <div className="form-group">
              <label htmlFor="aid-lounge-description">Aid Lounge Description:</label>
              <textarea id="aid-lounge-description" required></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="aid-lounge-banner">Aid Lounge Banner:</label>
              <input type="file" id="aid-lounge-banner" accept="image/*" required />
            </div>
            <div className="form-group">
              <label htmlFor="goal-amount">Goal Amount:</label>
              <input type="number" id="goal-amount" required />
            </div>
            <div className="form-group">
              <label htmlFor="start-date">Start Date:</label>
              <input type="date" id="start-date" required />
            </div>
            <div className="form-group">
              <label htmlFor="end-date">End Date:</label>
              <input type="date" id="end-date" required />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="create-community">
      <div className="create-community-modal" ref={modalRef}>
      {step === 1 && (
        <>
        <Chip label='1/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
        <h2 className="onboarding-text" style={{margin: 0}}>Select Location</h2>
        <p className='kyc-desc'>Select a location</p>
        <form onSubmit={handleNext}>
        <div className='form-top'>
        <div className="form-group">
      <select id="lounge-type" value={location} onChange={handleKYCLocationChange} required>
        <option value='' title="Select Location" className="select-tooltip">Select Location</option>
        <option value="Canada" title="Canada" className="select-tooltip">Canada</option>
        <option value="Nigeria" title="Nigeria" className="select-tooltip">Nigeria</option>
      </select>
      </div>
      </div>
      {loading ? (
    <LinearProgress style={{ marginBottom: '1rem', backgroundColor: '#a06ccb' }} />
  ) : (
    <>
    {kycMessage && <p style={{ marginBottom: '1rem' }}>{kycMessage}</p>}
    {nextButtonStatus && (
      <div className='form-bottom'>
        <div className="form-actions">
          <button className={`button full-width-btn`} type='submit'>
            Next
          </button>
          <button className={`button full-width-btn`} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    )}
  </>
)}

          </form>
        </>
      )}
      {step === 2 && (
        <>
        <Chip label='2/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
        <RaiseMeKYC onCancel={onCancel} handleCreateKYC={handleCreateKYC} step={step} setServiceLocation={setServiceLocation}
         firstName={firstName} setFirstName={setFirstName}
         lastName={lastName} setLastName={setLastName}
         middleName={middleName} setMiddleName={setMiddleName} email={email} setEmail={setEmail}
         Date={Date} setDate={setDate} location={location} setLocation={setLocation}
         gender={gender} setGender={setGender} countryCode={countryCode} setCountryCode={setCountryCode}
         phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} address={address} setAddress={setAddress}
         address2={address2} setAddress2={setAddress2} province={province} setProvince={setProvince}
         city={city} setCity={setCity} postalCode={postalCode} setPostalCode={setPostalCode}
         selectedCity={selectedCity} setSelectedCity={setSelectedCity} setSelectedLGA={setSelectedLGA} selectedLGA={selectedLGA}
         isSubmitting={isSubmitting}
         />
        </>
      )}
      {step === 3 && (
        <>
        <Chip label='3/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
        <KYCVerification customerNumber={customerNumber} firstName={firstName}
                          lastName={lastName} step={step} setStep={setStep} nextStep={nextStep}
                          />
        </>
      )}
      {step === 4 && (
        <>
        <Chip label='4/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
        <RaiseMeAccount onCancel={onCancel} setServiceLocation={setServiceLocation} serviceLocation={serviceLocation}
        accountName={accountName}
        setAccountName={setAccountName}
        accountNumber={accountNumber}
        setAccountNumber={setAccountNumber}
        routingNumber={routingNumber}
        setRoutingNumber={setRoutingNumber}
        bankName={bankName}
        setBankName={setBankName}
        handleCreateAccount={handleCreateAccount}
        bankBranch={bankBranch}
        setBankBranch={setBankBranch}
        interacEmail={interacEmail}
        setInteracEmail={setInteracEmail}
        accountType={accountType}
        setAccountType={setAccountType}
        currency={currency}
        setCurrency={setCurrency}
        bvn={bvn}
        setBvn={setBvn}
        location={location}
        setLocation={setLocation}
        setInteracPhone={setInteracPhone}
        interacPhone={interacPhone}
        raiseMeId={raiseMeId}
        setRaiseMeId={setRaiseMeId}
        setAccountId={setAccountId}
        accountId={accountId}
        nextStep={nextStepMFA}
        useExistingAccount={useExistingAccount}
        setUseExistingAccount={setUseExistingAccount}
        isSubmitting={isSubmitting}
        setAccountStatus={setAccountStatus} setVerifyUrl={setVerifyUrl}
        />
        </>
      )}
      {step === 5 && (
        <>
        <Chip label='5/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
          <AccountVerification verifyUrl={verifyUrl} nextStep={nextStep}/>
        </>
      )}
      {step === 6 && (
        <>
        {isVerifying ? (
  <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
    ) : (
        <>
        {partnerStatus === 'AML check in progress' || accountStatus === null || partnerStatus === 'error' ? (
          <PendingVerification partnerStatus={partnerStatus} accountStatus={accountStatus} verifyUrl={verifyUrl} onCancel={onCancel}/>
        ): (
        <>
        <Chip label='6/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
       <form onSubmit={createRaiseMe}>
         <div className='form-top'>
   {renderLoungeFields()}
         </div>
         <div className='form-bottom'>
         <div className="form-actions">
           <button className="button" type="submit">
             {isSubmitting ? 'Creating...' : 'Create'}
           </button>
           <button className="button" type="button" onClick={onCancel}>
             Cancel
           </button>
         </div>
         </div>
       </form>
       </>
       )}
       </>
       )}
       </>
      )}
      </div>
    </div>
  );
};

export default RaiseMe;
