import React from 'react'

const InputFieldError = ({ errorText, ...props }) => {
  return (
    <div
      style={{
        textAlign: 'start',
        fontSize: '12px',
        color: 'red',
        ...props
      }}
    >
      {errorText}
    </div>
  )
}

export default InputFieldError
