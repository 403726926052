import { CalendarMonth, PlayCircle, Satellite } from '@mui/icons-material';
import React, { useState } from 'react'
import { useProfileService } from '../../services/profile-service';
import { NO_PROFILE } from '../../utils/routes';
import { Avatar, TextField } from '@mui/material';
import { useApiService } from '../../services/api-service';
import toast from "react-hot-toast";
import { useCommunityPostsContext } from '../../contexts/CommunityPostsContext';
import { useLoungePostsContext } from '../../contexts/LoungePostsContext';
import CreatePost from '../postShare/CreatePost';

const LoungePostShare = ({loungeId, uuid}) => {
    const { profile } = useProfileService();
    const [postText, setPostText] = useState('');
    const [isSharing, setIsSharing] = useState(false);
    const apiService = useApiService();
    const { refreshLoungePosts } = useLoungePostsContext();

    const handleTextChange = (event) => {
        setPostText(event.target.value);
    };

    const handleShareClick = async () => {
        try {
            setIsSharing(true)
            const requestBody = {
                community_id: uuid,
                lounge_id: loungeId,
                message: postText,
                user_id: profile.userId
            }
            const response = await apiService.lounge.createPost(requestBody);
            if(response.status === 200 || response.status === 201){
                refreshLoungePosts();
                toast.success('Post Created Successfully');
                setPostText('');
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
                toast.error(e.response.data["msg"]);
                toast.error(e.response.data["error"]);
            } else {
                toast.error('Network Error');
            }
        } finally{
            setIsSharing(false)
        }
    };

  return (
    <div className="postShare">
        <Avatar className='profile-img' src={profile?.photoUrl} alt={`${profile.firstName} ${profile.lastName}`}/>
        <div style={{width: '100%'}}>
            {/* <input type="text" placeholder='What is happening in your community?' id="postInput" /> */}
            <CreatePost postText={postText} handleTextChange={handleTextChange}
            handleShareClick={handleShareClick}
            isSharing={isSharing}
            setPostText={setPostText}
            />
        </div>
    </div>
  )
}

export default LoungePostShare