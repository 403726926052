import React, { useEffect, useState } from 'react';
import { countryList } from '../../Data/CountryList';
import './renderCountryList.css'

const RenderCountryList = ({ formData, setFormData }) => {
  const [selectedCountry, setSelectedCountry] = useState(formData.country || '');
  const [selectedCountryStates, setSelectedCountryStates] = useState([]);
  const [customState, setCustomState] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountryObj = countryList.find(country => country.country === selectedCountryName);
    setSelectedCountry(selectedCountryName);
    setSelectedCountryStates(selectedCountryObj ? selectedCountryObj.states : []);
    setFormData({ ...formData, country: selectedCountryName, state: '', customState: '' });
  };

  const handleCustomStateChange = (e) => {
    const newCustomState = e.target.value;
    setCustomState(newCustomState);
    setFormData({ ...formData, customState: newCustomState });
  };
  

  return (
    <>
      <select
        required
        className='create-aid-input'
        name="country"
        value={selectedCountry}
        onChange={handleCountryChange}
      >
        <option value="">Select a country</option>
        {countryList.map(country => (
          <option key={country.country} value={country.country}>
            {country.country}
          </option>
        ))}
      </select>

      {selectedCountryStates.length > 0 && (
        <div className='selected-states'>
          <select
            required
            className='create-aid-input'
            name="state"
            value={formData.state}
            onChange={handleChange}
          >
            <option value="">Select a {selectedCountry === 'Canada' ? 'province' : 'state'}</option>
            <option value="other">Other</option>
            {selectedCountryStates.map(state => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>

          {formData.state === 'other' && (
            <input
              required
              className='create-aid-input'
              type="text"
              name="otherState"
              placeholder={`Enter your ${selectedCountry === 'Canada' ? 'province' : 'state'}`}
              value={customState}
              onChange={handleCustomStateChange}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RenderCountryList;
