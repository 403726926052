import React from 'react'
import { Add, AddCardOutlined, ArrowDownward, ArrowUpward, AttachMoney, CurrencyExchangeOutlined, SavingsOutlined } from '@mui/icons-material';
import './raisemewalletoverview.css'
import { Button } from '@mui/material';
import { formatCurrency } from '../../utils/routes';
const RaiseMeWalletOverview = ({totalCreditedAmount, totalDebitedAmount}) => {
  return (
    <div className="raisemewallet-overview">
                    {/* <div className="raisemewallet-container">
                  <div className="raisemewallet-info">
                    <div className="raisemewallet-overview-top">
                    <div className="raisemewallet-overview-title">
                    <CurrencyExchangeOutlined/>
                    <h2>Balance</h2>
                    </div>
                    </div>
                    <div className="raisemewallet-overview-bottom">
                    <p><span className='arrow-span'>$2,000.50</span> </p>
                    <div className="raisemewallet-addfunds">
                    <Button variant='outlined' startIcon={<Add/>}>
                        Add Funds
                    </Button>
                    </div>
                    </div>
                  </div>

                </div> */}
                <div className="raisemewallet-container">
                  <div className="raisemewallet-info">
                    <div className="raisemewallet-overview-top">
                    {/* <div className="raisemewallet-overview-icon green">
                    <AttachMoney/>
                    </div> */}
                    <div className="raisemewallet-overview-title">
                    <SavingsOutlined/>
                    <h2>Total Received</h2>
                    </div>
                    </div>
                    {/* <div className="myProgress">
                    <div className="myBar"></div>
                    </div> */}
                    <div className="raisemewallet-overview-bottom">
                    <p><span className='arrow-span'>{formatCurrency(totalDebitedAmount)}</span> </p>
                    {/* <div className="raisemewallet-growth">
                    <ArrowUpward/>
                    <p>+4.5% from last week</p>
                    </div> */}
                    </div>
                  </div>

                </div>
                <div className="raisemewallet-container">
                  <div className="raisemewallet-info">
                    <div className="raisemewallet-overview-top">
                    {/* <div className="raisemewallet-overview-icon">
                    <AttachMoney/>
                    </div> */}
                    <div className="raisemewallet-overview-title">
                    <AddCardOutlined/>
                    <h2>Total Contribution</h2>
                    </div>
                    </div>
                    {/* <div className="myProgress">
                    <div className="myBar"></div>
                    </div> */}
                    <div className="raisemewallet-overview-bottom">
                    <p><span className='arrow-span green'>{formatCurrency(totalCreditedAmount)}</span> </p>
                    {/* <div className="raisemewallet-growth orange">
                    <ArrowDownward/>
                    <p>-1.5% from last week</p>
                    </div> */}
                    </div>
                  </div>

                </div>
                {/* <div className="raisemewallet-container">
                  <div className="raisemewallet-info">
                    <div className="raisemewallet-overview-top">
                    <div className="raisemewallet-overview-icon green">
                    <AttachMoney/>
                    </div>
                    <div className="raisemewallet-overview-title">
                    <h2>% Goal Acheived</h2>
                  <span>20%</span>
                    </div>
                    </div>
                    <div className="myProgress green">
                    <div className="myBar green"></div>
                    </div>
                    <div className="raisemewallet-overview-bottom">
                    <p><span className='arrow-span green'>Monthly Contribution - </span> <strong>$50,000</strong> </p>
                    </div>
                  </div>

                </div>

                <div className="raisemewallet-container">
                  <div className="raisemewallet-info">
                    <div className="overview-top">
                   
                        <img src='img/img2.png' alt='Brad' style={{width:'70px', height:'70px', marign:0}} />
                    
                    <div className="raisemewallet-overview-title">
                    <h2>Next Cycle Receiver</h2>
                     <span>John Doe - $50,000 </span>
                    </div>
                    </div>
                    <div className="myProgress blue">
                    <div className="myBar blue"></div>
                    </div>
                    <div className="raisemewallet-overview-bottom">
                    <p><span className='arrow-span green'>View Participants</span> </p>
                    </div>
                  </div>

                </div> */}
                </div>
  )
}

export default RaiseMeWalletOverview