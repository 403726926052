import React, { useContext, useEffect, useState } from 'react'
import './switchCommunityList.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { LinearLoad, capitalizeFirstLetter, mapCommTypeToText } from '../../utils/routes'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";
import { Avatar, Box, Divider } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import CreateCommunity from '../createCommunity/CreateCommunity'
import JoinCommunity from '../joinCommunity/JoinCommunity'
import { CommunityContext } from '../../contexts/CommunityContext'

const SwitchCommunityList = ({toggleDrawer}) => {
  const { communityInfo, setCommunityInfo, loading, loadCommunity } = useContext(CommunityContext);
  const apiService = useApiService();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openJoinModal, setOpenJoinModal] = useState(false);

  const handleOpenModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseModal = () => {
    setOpenCreateModal(false);
  };

  const handleOpenJoinModal = () => {
    setOpenJoinModal(true);
  };

  const handleCloseJoinModal = () => {
    setOpenJoinModal(false);
  };

  // const fetchData = async () => {
  //   const MAX_RETRIES = 5;
  //   const RETRY_DELAY = 1000; // 1 second
  //   try {
  //     setLoading(true);
  //     const response = await apiService.community.membership();

  //     if (response.status === 200 || response.status === 201) {
  //       const data = Object.values(response.data || {});

  //       if (Object.keys(data).length === 0) {
  //         console.log('data is an empty object');
  //       } else {
  //         const fetchWithRetry = async (requestFn, maxRetries) => {
  //           for (let attempt = 0; attempt < maxRetries; attempt++) {
  //             try {
  //               const response = await requestFn();
  //               if (response.status === 200 || response.status === 201) {
  //                 const data = response.data;
  //                 if (Object.keys(data).length > 0) {
  //                   return data;
  //                 }
  //               }
  //             } catch (error) {
  //               console.error(`Attempt ${attempt + 1} failed:`, error);
  //               return null; // Exit the loop on error
  //             }
  //             await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
  //           }
  //           throw new Error('Max retries reached or error encountered');
  //         };
  //         const handleRequest = async (community, requestFn, key) => {
  //           try {
  //             const data = await fetchWithRetry(requestFn, MAX_RETRIES);
  //             if (data) {
  //               community[key] = data;
  //             } else {
  //               community.failed = true;
  //             }
  //           } catch (error) {
  //             console.error(`Error fetching ${key} for community ID ${community.id}`, error);
  //             community.failed = true;
  //           }
  //         };

  //         const createRequests = (communities, requestFn, key) =>
  //           communities.map(async (community) =>
  //             handleRequest(community, () => requestFn(community), key)
  //           );

  //         const commDetailsRequests = createRequests(
  //           data,
  //           (n) => apiService.community.info({ comm_uuid: n.comm_uuid }),
  //           'commDetails'
  //         );

  //         await Promise.allSettled([
  //           ...commDetailsRequests
  //         ]);

  //         const successfulRequests = data.filter((community) => !community.failed);
  //         localStorage.setItem('communityInfo', JSON.stringify(successfulRequests));
  //         setCommunityInfo([...successfulRequests]);
  //       }
  //     } else {
  //       console.error('Error fetching community:', response.statusText);
  //     }
  //   } catch (e) {
  //     if (e.response) {
  //       toast.error(e.response.data["code "]);
  //     } else {
  //       toast.error("Network Error");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const storedCommunityInfo = JSON.parse(localStorage.getItem('communityInfo'));
    if (storedCommunityInfo) {
      setCommunityInfo(storedCommunityInfo);
    } else {
      loadCommunity();
    }
  }, []);

  useEffect(() => {
    const checkForUpdates = async () => {
      const response = await apiService.community.membership();
      if (response.status === 200 || response.status === 201) {
        const data = Object.values(response.data || {});
        const storedCommunityInfo = JSON.parse(localStorage.getItem('communityInfo'));
        if (storedCommunityInfo && data.length !== storedCommunityInfo.length) {
          loadCommunity();
        }
      }
    };

    checkForUpdates();
  }, []);
  
  return (
    <>
    <Box sx={{ width: 350, backgroundColor: 'white'}} role="presentation">
    <Box className='notifications-list-top'>
            <h2>My Communities</h2>
            <Cancel sx={{cursor: 'pointer'}} onClick={toggleDrawer(false)}/>
      </Box>
      <Box className='notifications-list-body'>
      <div className='switchCommunityWrapper'>
        <div className='switch-buttons-container'>
            <button className='button create-community-btn' onClick={handleOpenModal}>Create</button>
            <button className='button create-community-btn' onClick={handleOpenJoinModal}>Join</button>
        </div>
        {loading ? (
          <>
          {LinearLoad()}
          </>
        ): (
          <div className='menu-list'>
          {communityInfo.map((community, index) => (
            <>
            {community && community.commDetails && community.commDetails.commName && (
              <>
              <Link className='switch-comm-list' to={`/community/${community.commDetails.commUuid}`} key={index}>
                <div className='community-list-item' key={index}>
                  {/* Use onClick to trigger the full page reload */}
                    <Avatar sx={{backgroundColor: '#e2cef1'}} alt={community.commDetails.commName}>
                      {capitalizeFirstLetter(community.commDetails.commName)}
                    </Avatar>
                    <div className='community-list-details'>
                      <h3 className='community-list-title'>
                        {community.commDetails.commName}
                      </h3>
                      <p className='community-list-desc'>
                        {community.commDetails.commDescr}
                      </p>
                      <p className='community-list-desc'>
                        {mapCommTypeToText(community.commDetails.communityType)} COMMUNITY
                      </p>
                    </div>
                </div>
                </Link>
              </>
            )}
          </>
        ))}
          </div>
        )}
      </div>
      </Box>
      </Box>
      {openCreateModal &&
        <div className="modal-overlay">
          <CreateCommunity openModal={handleOpenModal} onCancel={handleCloseModal} />
        </div>
      }
      {openJoinModal &&
        <div className="modal-overlay">
          <JoinCommunity onCancel={handleCloseJoinModal} />
        </div>
      }
    </>
  )
}

export default SwitchCommunityList
