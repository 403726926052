import React, { useEffect, useRef, useState } from 'react'
import './PostShare.css';
import { useProfileService } from '../../services/profile-service';
import { containsBadWords, handleError } from '../../utils/routes';
import { Avatar, Backdrop, Fade, Modal } from '@mui/material';
import { useApiService } from '../../services/api-service';
import { useCommunityPostsContext } from '../../contexts/CommunityPostsContext';
import CreatePost from './CreatePost';
import toast from 'react-hot-toast';
import ConfirmRequest from '../../utils/ConfirmRequest';
import imageCompression from 'browser-image-compression';

const PostShare = ({uuid}) => {
    const profile = useProfileService();
    const [postText, setPostText] = useState('');
    const [isSharing, setIsSharing] = useState(false);
    const apiService = useApiService();
    const { refreshCommunityPosts } = useCommunityPostsContext();
    //open confirm request
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

  const handleTextChange = (event) => {
    setPostText(event.target.value);
    
  };

  const fileInputRef = useRef(null);
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [previewUrl, setPreviewUrl] = useState('');
  const [postID, setPostID] = useState();
  const uploadStatusRef = useRef(null);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log('file', file);
  //   setSelectedDocument(file);

  //   if (file) {
  //     const url = URL.createObjectURL(file);
  //     console.log('url', url);
  //     setPreviewUrl(url);
  //   }
  // };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log('file', file);

    if (file && file.type.startsWith('image/')) {
        try {
            const options = {
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                fileType: 'image/jpeg'
            };
            const compressedBlob = await imageCompression(file, options);
            console.log('compressedBlob', compressedBlob);

            // Convert the compressed blob back to a file
            const compressedFile = new File(
                [compressedBlob],
                file.name,
                { type: file.type, lastModified: file.lastModified }
            );
            console.log('compressedFile', compressedFile);

            setSelectedDocument(compressedFile);

            const url = URL.createObjectURL(compressedFile);
            console.log('url', url);
            setPreviewUrl(url);
        } catch (error) {
            console.error('Error compressing the image', error);
        }
    } else {
        setSelectedDocument(file);
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
    }
};

   // Clean up the preview URL when the component is unmounted or the selected file changes
   useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const uploadDocument = async (id) => {
    try {
        const formData = new FormData();
        formData.append('post_id', id);
        formData.append('media_url', selectedDocument);

        const isVideo = selectedDocument.type.startsWith('video/');
        const endpoint = isVideo ? apiService.community.uploadVideo : apiService.community.uploadDocument;

        await toast.promise(
            endpoint(formData),
            {
                loading: 'Uploading Media...',
                success: 'Media Uploaded',
                error: 'Error Uploading Media. Try again',
            }
        );
        uploadStatusRef.current = 'success';
    } catch (e) {
        handleError(e);
        uploadStatusRef.current = 'failed';
    }
};

const handleShareClick = async () => {
    try {
        setIsSharing(true);
        if (containsBadWords(postText)) {
            return handleOpenConfirm();
        }
        if (uploadStatusRef.current === 'failed') {
            return await retryUpload();
        }
        const requestBody = {
            community_id: uuid,
            message: postText,
            user_id: profile.userId,
        };
        const response = await toast.promise(
            apiService.community.createPost(requestBody),
            {
                loading: 'Creating Post...',
                success: 'Post Created Successfully',
                error: 'Failed to Create Post',
            }
        );
        setPostID(response.data.id);
        if (response.status === 200 || response.status === 201) {
            if (selectedDocument) {
                await uploadDocument(response.data.id);
            }
            if (uploadStatusRef.current === 'success' || !selectedDocument) {
                refreshCommunityPosts();
                resetForm();
            }
        }
    } catch (e) {
        handleError(e);
    } finally {
        setIsSharing(false);
    }
};

const handleForceShareClick = async () => {
    try {
        setIsSharing(true);
        if (uploadStatusRef.current === 'failed') {
            return await retryUpload();
        }
        const requestBody = {
            community_id: uuid,
            message: postText,
            user_id: profile.userId,
        };
        const response = await toast.promise(
            apiService.community.createPost(requestBody),
            {
                loading: 'Creating Post...',
                success: 'Post Created Successfully',
                error: 'Failed to Create Post',
            }
        );
        setPostID(response.data.id);
        if (response.status === 200 || response.status === 201) {
            if (selectedDocument) {
                await uploadDocument(response.data.id);
            }
            if (uploadStatusRef.current === 'success' || !selectedDocument) {
                refreshCommunityPosts();
                resetForm();
                handleCloseConfirm();
            }
        }
    } catch (e) {
        handleError(e);
    } finally {
        setIsSharing(false);
    }
};

const retryUpload = async () => {
    try {
        await uploadDocument(postID);
        console.log('retrying upload', uploadStatusRef.current)
        if (uploadStatusRef.current === 'success') {
            refreshCommunityPosts();
            resetForm();
        }
    } catch (e) {
        handleError(e);
    }
};

const resetForm = () => {
    setPostText('');
    setSelectedDocument(null);
    setPreviewUrl('');
};
 
  return (
    <div className="postShare">
        {/* <img className='profile-img' src={profile?.photoUrl || NO_PROFILE} alt=''/> */}
        <Avatar src={profile.profile.photoUrl} />
        <div className='postShare-container'>
            <CreatePost postText={postText} handleTextChange={handleTextChange}
            handleShareClick={handleShareClick}
            isSharing={isSharing}
            setPostText={setPostText}
            previewUrl={previewUrl}
            selectedDocument={selectedDocument}
            handleFileChange={handleFileChange}
            handleButtonClick={handleButtonClick}
            fileInputRef={fileInputRef}
            setSelectedDocument={setSelectedDocument}
            />
            <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openConfirm}
                      onClose={handleCloseConfirm}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <ConfirmRequest
                        sendingAction={isSharing}
                        title={'Share Post?'}
                        text={`Your post contains offensive language. Please confirm if you still wish to share it.`}
                        onAction={() => handleForceShareClick()} // Pass a function reference
                        onClose={handleCloseConfirm}
                        actionButton={'Share Post'}
                        sendingActionButton={'Sharing...'}/>
                      </Fade>
                    </Modal>
        </div>
        
    </div>
  )
}

export default PostShare;