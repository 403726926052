import React, { useState, useEffect, useRef } from 'react';
import './createCommunity.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import toast from "react-hot-toast";
import { useContext } from 'react';
import { CommunityContext } from '../../contexts/CommunityContext';
import '../renderCountryList/renderCountryList.css'
import { Backdrop, Checkbox, Fade, FormControlLabel, Modal, Stack, Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertMessage from '../../utils/AlertMessage';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CreateCommunity = ({ openModal, onCancel }) => {
  const [communityName, setCommunityName] = useState('');
  const [communityDescription, setCommunityDescription] = useState('');
  const [communityDefault, setCommunityDefault] = useState();
  const modalRef = useRef(null);
  const history = useHistory();
  const apiService = useApiService();
  const profile = useProfileService();
  const { loadProfile } = useProfileService(); // Access the loadProfile function from the profile service
  const { loadCommunity } = useContext(CommunityContext);
  const [level, setLevel] = useState(1)
  const [bannerFile, setBannerFile] = useState(null);
  const [communityId, setCommunityId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [communityType, setCommunityType] = useState('');
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpen = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const handleCommunityNameChange = (e) => {
    setCommunityName(e.target.value);
  };

  const handleBannerFileChange = (e) => {
    const file = e.target.files[0];
    setBannerFile(file);
  };

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    const checkCommunityDefault = async () => {
      try {
        const membersResponse = await apiService.community.membership();
        if (Object.keys(membersResponse.data).length > 0) {
          setCommunityDefault(false);
        } else
        setCommunityDefault(true);
      } catch (e) {
        if(e.response){
          if(e.response.status !== 404){
            toast.error(e.response.data["code "])
          }
        } else {
          toast.error('Network Error');
        }
      }
    };

    checkCommunityDefault();
  }, [apiService.community]);

  const CreateMemberForOwner = async (createdCommunityId) => {
    try {
      const requestBody = {
        comm_default: true,
        comm_uuid: createdCommunityId,
        user_id: profile.profile.userId
      };
      await toast.promise(
        apiService.community.createMember(requestBody),
        {
          loading: 'Adding User to Community...',
          success: 'User Added Successfully',
          error: 'Error adding you to the community created'
        }
      )
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    }
  };

  const assignAdminRole = async (createdCommunityId) => {
    try {
      const requestBody = {
        comm_uuid: createdCommunityId,
        user_id: profile.profile.userId,
        role_name: "admin"
      };
      if(communityType === 'DEMOCRATIZED_COMM'){
        await apiService.community.assignRole(requestBody);
      } else {
        await toast.promise(
          apiService.community.assignRole(requestBody),
          {
            loading: 'Assigning Admin Role...',
            success: 'Admin Role Assigned Successfully',
            error: 'Error Assigning Admin Role'
          }
        )
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    }
  }

  const handleCommunityTypeChange = (event) => {
    const selectedCommunityType = event.target.value;
    // You can set the selected community type in the state if needed
    setCommunityType(selectedCommunityType);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    await loadProfile();
    try {
      let requestBody;
      if (communityType === 'ADMIN_MANAGED_COMM') {
        // Modify the requestBody if checked is true
        requestBody = {
          comm_name: communityName,
          comm_descr: communityDescription,
          city: profile.profile.city,
          country: profile.profile.country,
          community_type: "ADMIN_MANAGED_COMM",
          creator_id: profile.profile.userId
        };
    } else {
      requestBody = {
        comm_name: communityName,
        comm_descr: communityDescription,
        city: profile.profile.city,
        country: profile.profile.country,
        community_type: "DEMOCRATIZED_COMM",
        creator_id: profile.profile.userId,
        wallet_status: true
      };

    }
      
      const response = await toast.promise(
        apiService.community.createCommunity(requestBody),
        {
          loading: 'Creating Community...',
          success: 'Community Created Successfully',
          error: 'Error Creating Community'
        }
      )
      if (response.status === 201) {
        const createdCommunityId = response.data.id;
        setCommunityId(createdCommunityId);
        // Close the modal
        loadProfile();
        await CreateMemberForOwner(createdCommunityId);
        if(communityType === 'ADMIN_MANAGED_COMM'){
          await assignAdminRole(createdCommunityId);
        }
        loadCommunity();
        setLevel(2)
      }
    } catch (e) {
      if(e.response){
      toast.error(e.response.data["code "]);
      } else{
        toast.error("Network Error");
      }
    } finally{
      setIsCreating(false);
    }
  };

  const handleSkip = (e) => {
        history.push('/communities');
        onCancel();
  }
  
  const handleUploadBanner = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', bannerFile);
      // Append community ID to the form data
      formData.append('comm_uuid', communityId);
      const response = await toast.promise(
        apiService.community.uploadBanner(formData),{
          loading: 'Uploading Banner Photo...',
          success: 'Banner Photo Uploaded',
          error: 'Error Uploading Banner Photo'
        }
      )
      if (response.status === 200 || response.status === 201) {
        history.push(`/community/${communityId}`);
        onCancel();
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
        toast.error(e.response.data["error"]);
      } else{
        toast.error("Network Error");
      }
    } finally{
      setIsUploading(false);
    }
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      // onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  let message = (
    <div>
      <p><b>DEMOCRATIZED COMMUNITY:</b>The Democratized Community operates on democratic principles where decision-making authority is shared among its members, typically involving voting.</p>
      <p><b>ADMIN-MANAGED COMMUNITY:</b>The Admin managed Community is overseen and controlled exclusively by the admin. who holds all authority.</p>
    </div>
  );
  let title = 'COMMUNITY TYPES';

  return (
    <>
    <div className='create-community'>
      <div className="create-community-modal" ref={modalRef}>
      {level === 1 && (
        <>
        <h2 className='onboarding-text'>Create Community</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="community-name">Community Name:</label>
            <input
              type="text"
              id="community-name"
              value={communityName}
              onChange={handleCommunityNameChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="community-description">Community Description:</label>
            <textarea
              id="community-description"
              value={communityDescription}
              onChange={(e) => setCommunityDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <div className='form-group'>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <label htmlFor=''>Community Type</label>
              <Help sx={{cursor: 'pointer', color: 'black'}} onClick={handleOpen}/>
            </Stack>
            <select onChange={handleCommunityTypeChange}>
              <option value={''}>Select Community Type</option>
              <option value="DEMOCRATIZED_COMM">Democratized Community</option>
              <option value="ADMIN_MANAGED_COMM">Admin Managed Community</option>
            </select>
          </div>
          <div className="form-actions">
            <Button disabled={isCreating} className='button' type="submit">{isCreating ? 'Creating...' : 'Create'}</Button>
            <Button className='outline-button' type="button" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </form>
        </>
        )}
        {level === 2 && (
        <>
        <h2 className='onboarding-text'>Upload your community banner</h2>
        <div className="form-group">
                  <label htmlFor="banner">Community Banner:</label>
                  <input
                    type="file"
                    id="banner"
                    accept="image/*"
                    onChange={handleBannerFileChange}
                    required
                  />
                </div>
            <div className="form-actions">
            <Button className='button full-width-btn' type="submit" onClick={handleUploadBanner}>{isUploading ? 'Uploading...' : 'Upload Banner'}</Button>
            <Button className='outline-button full-width-btn' onClick={handleSkip}>I'd Upload Later</Button>
          </div>
        </>
        )}
      </div>
    </div>
    {openAlert &&
      <div className="alert-modal-overlay">
        <AlertMessage message={message} title={title} handleCloseAlert={handleCloseAlert} />
      </div>
    }
    </>
  );
};

export default CreateCommunity;
