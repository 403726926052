import { Cancel } from '@mui/icons-material'
import { Avatar, Box, CircularProgress, Divider, LinearProgress, Stack } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './notificationsList.css'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";
import { NO_PROFILE, getTimeDifference } from '../../utils/routes'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useEmailService from '../../services/email-service'
import AccountVerification from '../AccountVerification/AccountVerification'
import PendingVerification from '../PendingVerification/PendingVerification'
import RaiseMeAccount from '../RaiseMeAccount/RaiseMeAccount'
import { NotificationContext } from '../../contexts/NotificationContext'

const NotificationList = ({setNotificationCount, loadCommunity,
    partnerStatus,
    toggleDrawer, profile}) => {
  const { getUserEmail } = useEmailService();
  const [isOpen, setIsOpen] = useState(false);
  const apiService = useApiService();
  const [acceptingNotification, setAcceptingNotification] = useState(null);
  const [decliningNotification, setDecliningNotification] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);
  const [notifsDetail, setNotifsDetail] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [useExistingAccount, setUseExistingAccount] = useState(false);
  const [location, setLocation] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankBranch, setBankBranch] = useState(null);
  const [interacEmail, setInteracEmail] = useState(null);
  const [interacPhone, setInteracPhone] = useState(null);
  const [accountType, setAccountType] = useState('');
  const [currency, setCurrency] = useState('');
  const [bvn, setBvn] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [raiseMeId, setRaiseMeId] = useState(null);
  const [verifyUrl, setVerifyUrl] = useState(null);
  const [step, setStep] = useState(1);
  const [isVerifying, setIsVerifying] = useState(false);
  const history = useHistory();
  const modalRef = useRef(null);
  const { notificationDetails, setNotificationDetails, loading, loadNotification } = useContext(NotificationContext);
  

  const correctDateFormat = (datestring) => {
    const formattedDate = new Date(datestring).toISOString().split('T')[0];
    return formattedDate
  }

  const handleAcceptCommunity = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        comm_default: false,
        comm_uuid: notificationDetail.comm_uuid,
        user_id: profile.profile.userId
        // Add other necessary data for the request
      };
  
      // Make the API call to join the community
      const response = await apiService.community.createMember(requestBody);
      
      if(response.status === 200 || response.status === 201){
        loadCommunity();
        const requestBody = {
          id: notificationDetail.id
        }
       await apiService.notification.updateStatus(requestBody)
        toast.success('Community joined');
      }
      
      // After successful join, update the UI or perform additional actions
      
      // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      history.push(`/community/${notificationDetail.comm_uuid}`)
    } catch (e) {
      if(e.response){
      toast(e.response.data["code "], {
        type: "error",
        data: {
          msg: e.response.data["code "],
          type: "error",
        }
      });
      if(e.response.status === 409){
        const requestBody = {
          id: notificationDetail.id
        }
       await apiService.notification.updateStatus(requestBody)
      }
      } else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
      }
    } finally {
      setAcceptingNotification(null);
    }
  };
  
  const handleDeclineCommunity = async (notificationDetail) => {
    try {
      setDecliningNotification(notificationDetail.id);
      const requestBody = {
        id: notificationDetail.id
      }
      const response = await apiService.notification.updateStatus(requestBody);
      if(response.status === 200 || response.status === 201){
        
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      toast.success("Notification Declined")
      }
    } catch (e) {
      if(e.response){
      toast(e.response.data["code "], {
        type: "error",
        data: {
          msg: e.response.data["code "],
          type: "error",
        }
      });
      } else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
      }
    } finally {
      setDecliningNotification(null);
    }
  };

  const handleAcceptLounge = async (notificationDetail) => {
    let communityConflict = false;
    try {
      setAcceptingNotification(notificationDetail.id);
      const commRequestBody = {
        comm_default: false,
        comm_uuid: notificationDetail.loungeInfo.commUuid,
        user_id: profile.profile.userId
        // Add other necessary data for the request
      };
      // Make the API call to join the community
      const commResponse = await apiService.community.createMember(commRequestBody);
      if(commResponse.status === 200 || commResponse.status === 201){
        const requestBody = {
          lounge_uuid: notificationDetail.lounge_id,
          user_id: profile.profile.userId
          // Add other necessary data for the request
        };
    
        // Make the API call to join the community
        const response = await apiService.lounge.createMember(requestBody);
        if(response.status === 200 || response.status === 201){
          const requestBody = {
            id: notificationDetail.id
          }
         await apiService.notification.updateStatus(requestBody)
          toast.success('Lounge joined');
          history.push(`/lounge/${notificationDetail.loungeInfo.commUuid}/${notificationDetail.lounge_id}`);
        }
      }
      
      // After successful join, update the UI or perform additional actions
      
      // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
    } catch (e) {
      if(e.response){
      toast.error(e.response.data["code "]);
      if(e.response.status === 409){
        communityConflict = true;
      }
      } else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
      }
    } finally {
      setAcceptingNotification(null);
    }
    // If there was a conflict while adding to the community, proceed to add to Raiseme
    if (communityConflict) {
      const createLoungeBody = {
        lounge_uuid: notificationDetail.lounge_id,
        user_id: profile.profile.userId
      };
      
      try {
        const res = await apiService.lounge.createMember(createLoungeBody);
        if (res.status === 200 || res.status === 201) {
          const requestBody = {
            id: notificationDetail.id
          };
          await apiService.notification.updateStatus(requestBody);
          history.push(`/lounge/${notificationDetail.loungeInfo.commUuid}/${notificationDetail.lounge_id}`);
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
        } else{
          toast.error('Network Error')
        }
      } finally{
        setIsJoining(false);
      }
    }
  
    const updatedNotifications = notificationDetails.filter(
      (notification) => notification.id !== notificationDetail.id
    );
    setNotificationDetails(updatedNotifications);
  
    // Update notification count
    setNotificationCount(updatedNotifications.length);
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const handleOpenModal = () => {
    setOpenModal(true)
    if(isOpen){
      setIsOpen(false)
    }
  }

  const nextStep = async (e) => {
    if(!useExistingAccount) e.preventDefault();
    setStep(3)
    if(partnerStatus === 'AML check in progress' || accountStatus === null || partnerStatus === 'error'){
      setStep(3)
    } else {
      await joinRaiseMe(accountId);
      handleCloseModal();
    }
}

  const handleCloseModal = () => {
    setOpenModal(false)
    setUseExistingAccount(false);
    setStep(1);
  }

  const handleAcceptRaiseMe =  async (notificationDetail) => {
    const RaiseMeLocation = notificationDetail.raisemeInfo.location;
    const rID = notificationDetail.raiseme_id;
    setRaiseMeId(rID);
    setLocation(RaiseMeLocation)
    const notifsData = notificationDetail;
    setNotifsDetail(notifsData);
    handleOpenModal();
  }

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    try {
      if(useExistingAccount){
        // await joinRaiseMe();
        setStep(step + 2)
        nextStep();
      } else {
      const requestBody = {
        financial_institution_name: bankName,
        financial_institution_branch: bankBranch,
        account_number: accountNumber,
        account_type: accountType,
        bvn: bvn,
        currency: currency,
        interac_email: interacEmail,
        interac_phone_number: interacPhone,
        location: location,
        routing_transit_number: routingNumber,
        account_name: accountName
      }
      const response = await apiService.raiseme.createAccount(requestBody);
    if (response.status === 200 || response.status === 201) {
      toast.success("Account Created Successfully");
      const getAccountID = response.data.id;
      const verificationURL = response.data.verifyUrl;
      const getStatus = response.data.partnerStatus;
      setAccountId(getAccountID)
      setVerifyUrl(verificationURL);
      setAccountStatus(getStatus);
      setStep(step+1)
      // await joinRaiseMe(getAccountID);
    }
  }
  } catch(e){
    if(useExistingAccount){
      toast.error('Error while joining Raiseme');
    }
    else {
      if(e.response){
        toast(e.response.data["code "], {
          type: "error",
          data: {
            msg: e.response.data["code "],
            type: "error",
          }
        });
      } else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
    }
    }
  }
  }
  const joinRaiseMe = async (getAccountID) => {
    let communityConflict = false;
    const userEmail = await getUserEmail();
    try {
      setIsJoining(true);
      // const isChitFund = notifsDetail.raisemeInfo.raisemeType === "CHIT_FUND";
      const requestBody = {
        comm_default: false,
        comm_uuid: notifsDetail.comm_uuid,
        user_id: profile.profile.userId
        // Add other necessary data for the request
      };
      // Make the API call to join the community
      const response = await apiService.community.createMember(requestBody);
      
      if(response.status === 200 || response.status === 201){
        const createRaiseMeBody = {
        additional_service: "additionalService",
        email: userEmail,
        raiseme_id: notifsDetail.raisemeInfo.id,
        user_id: profile.profile.userId,
        user_type: profile.profile.userType,
        account_id: useExistingAccount ? accountId : getAccountID
        }
        const res = await apiService.raiseme.addMember(createRaiseMeBody);
        if(res.status === 200 || res.status === 201){
          const requestBody = {
            id: notifsDetail.id
          }
          const updateResponse = await apiService.notification.updateStatus(requestBody)
          if(updateResponse.status === 200 || updateResponse.status === 201){
            toast.success('Collective Cash joined');
            history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
            // if(isChitFund){
            //   toast.success('Chit Fund joined');
            //   history.push(`/community/chit/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
            // } else {
            //   toast.success('Contribution joined');
            // history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
            // }
          }
        }
      }
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notifsDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        communityConflict = true;
      } else {
        if (e.response) {
          toast(e.response.data["code "], {
            type: "error",
            data: {
              msg: e.response.data["code "],
              type: "error",
            }
          });
        } else {
          toast("Network Error", {
            type: "error",
            data: {
              msg: "Network error, please check your internet connection.",
              type: "error",
            }
          });
          console.error("Error while joining community:", e);
        }
      }
    } finally{
      if(!communityConflict){
        setIsJoining(false);
      }
    }
  
    // If there was a conflict while adding to the community, proceed to add to Raiseme
    if (communityConflict) {
      // const isChitFund = notifsDetail.raisemeInfo.raisemeType === "CHIT_FUND";
      const createRaiseMeBody = {
        additional_service: "additionalService",
        email: userEmail,
        raiseme_id: notifsDetail.raisemeInfo.id,
        user_id: profile.profile.userId,
        user_type: profile.profile.userType,
        account_id: useExistingAccount ? accountId : getAccountID
      };
      
      try {
        const res = await apiService.raiseme.addMember(createRaiseMeBody);
        if (res.status === 200 || res.status === 201) {
          const requestBody = {
            id: notifsDetail.id
          };
          await apiService.notification.updateStatus(requestBody);
          toast.success('Collective Cash joined');
          history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
          // if(isChitFund){
          //   toast.success('Chit Fund joined');
          //   history.push(`/community/chit/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
          // } else {
          //   toast.success('Contribution joined');
          // history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
          // }
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
        } else{
          toast.error('Network Error')
        }
      } finally{
        setIsJoining(false);
      }
    }
  
    const updatedNotifications = notificationDetails.filter(
      (notification) => notification.id !== notifsDetail.id
    );
    setNotificationDetails(updatedNotifications);
  
    // Update notification count
    setNotificationCount(updatedNotifications.length);
  };
  const handleDeclineRaiseMe = async (notificationDetail) => {
    try {
      setDecliningNotification(notificationDetail.id);
      const requestBody = {
        id: notificationDetail.id
      }
      const response = await apiService.notification.updateStatus(requestBody);
      if(response.status === 200 || response.status === 201){
        
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      toast.success("Notification Declined")
      }
    } catch (e) {
      if(e.response){
      toast(e.response.data["code "], {
        type: "error",
        data: {
          msg: e.response.data["code "],
          type: "error",
        }
      });
      } else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
      }
    } finally {
      setDecliningNotification(null);
    }
  };

  const handleAcceptSwapRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const res = await apiService.raiseme.getRaiseMeInfo({raiseme_id: notificationDetail.raiseme_id});
      const raisemeInfo = res.data;
      let isStarted;
      let endpoint;
      const startDate = new Date(raisemeInfo.startDate);

      // Get the current date
      const currentDate = new Date();
      isStarted = currentDate >= startDate;
      if (isStarted) {
          endpoint = "submitSwapKickOff"; // Endpoint for after start
      } else {
          endpoint = "submitSwap"; // Endpoint for before start
      }
      const requestBody = {
        sender_id: notificationDetail.sender_id,
        receiver_id: notificationDetail.reciever_id,
        raiseme_id: notificationDetail.raiseme_id,
        sender_date: correctDateFormat(notificationDetail.sender_anniversary_date),
        receiver_date: correctDateFormat(notificationDetail.reciever_anniversary_date)
      }
      const response = await apiService.raiseme[endpoint](requestBody);
      if(response.status === 200 || response.status === 201){
        await apiService.notification.updateStatus({id: notificationDetail.id});
        toast.success('Swap Request Accepted');
        // After successful join, update the UI or perform additional actions
      
        // For example, remove the accepted notification from the list
        const updatedNotifications = notificationDetails.filter(
          (notification) => notification.id !== notificationDetail.id
        );
        setNotificationDetails(updatedNotifications);
        
        // Update notification count
        setNotificationCount(updatedNotifications.length);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error")
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  const handleAcceptBroadcastRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        comm_uuid: notificationDetail.comm_uuid,
        user_id: notificationDetail.senderDetails.userId,
      }
      const response = await apiService.notification.addEndorsement(requestBody);
      if(response.status === 200 || response.status === 201){
        const requestBody = {
          id: notificationDetail.id
        }
        await apiService.notification.updateStatus(requestBody)
        toast.success('User Endorsed');
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  const handleAcceptAdminCommRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        comm_uuid: notificationDetail.comm_uuid,
        user_id: notificationDetail.senderDetails.userId,
      }
      const response = await apiService.notification.addEndorsement(requestBody);
      if(response.status === 200 || response.status === 201){
        const requestBody = {
          id: notificationDetail.id
        }
        await apiService.notification.updateStatus(requestBody)
        toast.success('User Added to Community');
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  const handleDeclineBroadCast = async (notificationDetail) => {
    try {
      setDecliningNotification(notificationDetail.id);
      const requestBody = {
        id: notificationDetail.id
      }
      const response = await apiService.notification.updateStatus(requestBody);
      if(response.status === 200 || response.status === 201){
        
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      toast.success("Notification Declined")
      }
    } catch (e) {
      if(e.response){
      toast(e.response.data["code "], {
        type: "error",
        data: {
          msg: e.response.data["code "],
          type: "error",
        }
      });
      } else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
      }
    } finally {
      setDecliningNotification(null);
    }
  }

  const handleAcceptWithdrawalRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        user_id: notificationDetail.senderDetails.userId,
        aidlounge_id: notificationDetail.aidlounge_id
      }
      const response = await apiService.aidlounge.vote(requestBody);
      if(response.status === 200 || response.status === 201){
        const requestBody = {
          id: notificationDetail.id
        }
        await apiService.notification.updateStatus(requestBody)
        toast.success('Withdrawal Endorsed');
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error")
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  const handleAcceptAdminCommWithdrawRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        user_id: notificationDetail.senderDetails.userId,
        comm_uuid: notificationDetail.comm_uuid
      }
      const response = await apiService.community.voteWithdrawal(requestBody);
      if(response.status === 200 || response.status === 201){
        const requestBody = {
          id: notificationDetail.id
        }
        await apiService.notification.updateStatus(requestBody)
        toast.success('Withdrawal Endorsed');
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error")
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  const handleAcceptDemocratizedCommWithdrawRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        user_id: notificationDetail.senderDetails.userId,
        comm_uuid: notificationDetail.comm_uuid
      }
      const response = await apiService.community.democratizedVoteWithdrawal(requestBody);
      if(response.status === 200 || response.status === 201){
        const requestBody = {
          id: notificationDetail.id
        }
        await apiService.notification.updateStatus(requestBody)
        toast.success('Withdrawal Endorsed');
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error")
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  const handleAcceptUpgradeCommRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        user_id: notificationDetail.sender_id,
        comm_uuid: notificationDetail.comm_uuid
      }
      const response = await apiService.notification.upgradeCommVote(requestBody);
      if(response.status === 200 || response.status === 201){
        const requestBody = {
          id: notificationDetail.id
        }
        await apiService.notification.updateStatus(requestBody)
        toast.success('Community Upgrade Endorsed');
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
        toast.error(e.response.data["error"]);
      } else {
        toast.error("Network Error")
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  const handleAcceptCommUpdateRequest = async (notificationDetail) => {
    try {
      setAcceptingNotification(notificationDetail.id);
      const requestBody = {
        user_id: notificationDetail.senderDetails.userId,
        comm_uuid: notificationDetail.comm_uuid
      }
      if(notificationDetail.notification_type === 'adminManageCommNameUpdate'){
        await apiService.community.voteCommNameAdmin(requestBody);
      } else {
        await apiService.community.voteCommNameDemocratized(requestBody);
      }
        await apiService.notification.updateStatus({
          id: notificationDetail.id
        })
        toast.success('Update Request Endorsed');
        // For example, remove the accepted notification from the list
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notificationDetail.id
      );
      setNotificationDetails(updatedNotifications);
      // Update notification count
      setNotificationCount(updatedNotifications.length);
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error")
      }
    } finally {
      setAcceptingNotification(null);
    }
  }

  useEffect(() => {
    const storedNotificationInfo = JSON.parse(localStorage.getItem('notificationDetails'));
    if (storedNotificationInfo) {
      setNotificationDetails(storedNotificationInfo);
    } else {
      loadNotification();
    }
  }, []);

  useEffect(() => {
    const checkForUpdates = async () => {
      const requestBody = {
        userId: profile.profile.userId,
      };
      const response = await apiService.notification.received(requestBody);
      if (response.status === 200 || response.status === 201) {
        const data = Object.values(response.data || {});
        const storedNotificationInfo = JSON.parse(localStorage.getItem('notificationDetails'));
        if (storedNotificationInfo && data.length !== storedNotificationInfo.length) {
          loadNotification();
        }
      }
    };

    checkForUpdates();
  }, []);
  return (
    <>
    <Box sx={{ width: 350, backgroundColor: 'white'}} role="presentation">
        <Box className='notifications-list-top'>
            <h2>Notifications</h2>
            <Cancel sx={{cursor: 'pointer'}} onClick={toggleDrawer(false)}/>
        </Box>
        <Box className='notifications-list-body'>
            {loading ? (
                <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <CircularProgress style={{ color: '#a06ccb'}}/>
                <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
                </Box>
            ) : (
                <>
    {notificationDetails ? (
      <Stack className="notification-dropdown-list">
        {Array.isArray(notificationDetails) && notificationDetails.length > 0 ? (
          notificationDetails.map((notificationDetail) => (
            <React.Fragment key={notificationDetail.id}>
              <Stack className="accordionsMenu">
                <div className='notification-item'>
                  {(() => {
                    switch (notificationDetail.notification_type) {
                      case 'communityInvite':
                        return  notificationDetail.commName && notificationDetail.commName.commName ? (
                          <>
                            <div className='community-notification-category'><p>Community Invite</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar src={NO_PROFILE} alt='Community Banner' />
                                <p className='notification-text'>You have been invited to {notificationDetail.commName.commName} community</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptCommunity(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Accepting...' : 'Accept'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineCommunity(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Community Invite</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineCommunity(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                      case 'raiseMeInvite':
                        return notificationDetail.raisemeInfo && notificationDetail.raisemeInfo.name ? (
                          <>
                            <div className='raiseme-notification-category'><p>RaiseMe Invite</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar src={NO_PROFILE} alt='RaiseMe Banner' />
                                <p className='notification-text'>You have been invited to {notificationDetail.raisemeInfo.name} RaiseMe</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptRaiseMe(notificationDetail)}>Join</button>
                              <button className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>RaiseMe Invite</p></div>
                              <div className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                        case 'loungeInvite':
                        return notificationDetail.loungeInfo && notificationDetail.loungeInfo.loungeName ? (
                          <>
                            <div className='raiseme-notification-category'><p>Lounge Invite</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar src={NO_PROFILE} alt='RaiseMe Banner' />
                                <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} invited you to join {notificationDetail.loungeInfo.loungeName} Lounge</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptLounge(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Accepting...' : 'Accept'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Lounge Invite</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                        case 'chitfundInvite':
                            return notificationDetail.raisemeInfo && notificationDetail.raisemeInfo.name ? (
                              <>
                                <div className='raiseme-notification-category'><p>Chit Funds Invite</p></div>
                                <div className="notification-list">
                                  <div>
                                    <Avatar src={NO_PROFILE} alt='Chit Fund Banner' />
                                    <p className='notification-text'>You have been invited to {notificationDetail.raisemeInfo.name} RaiseMe</p>
                                  </div>
                                  <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                                </div>
                                <Stack direction={'row'} spacing={1}>
                                  <button className='button notification-btn' onClick={() => handleAcceptRaiseMe(notificationDetail)}>Join</button>
                                  <button className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                                </Stack>
                              </>
                            ) : (
                                <>
                                <div className='broadcast-notification-category'><p>Chit Funds Invite</p></div>
                                <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                                </div>
                                </>
                            );
                      case 'swapRequest':
                        return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName ? (
                          <>
                            <div className='swap-notification-category'><p>Swap Request</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar src={notificationDetail.senderDetails.photoUrl} alt='Swap Request' />
                                <p className='notification-text'>
                                  You received a request to swap your raise me date from {formatDate(notificationDetail.reciever_anniversary_date)} to {formatDate(notificationDetail.sender_anniversary_date)} from {notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName}.
                                </p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptSwapRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Accepting...' : 'Accept'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                          <>
                                <div className='swap-notification-category'><p>Swap Request</p></div>
                                <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                                </div>
                           </>
                        );
                        case 'communityBroadcast':
                            return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName && notificationDetail.commName && notificationDetail.commName.commName ? (
                              <>
                                <div className='broadcast-notification-category'><p>Community Broadcast</p></div>
                                <div className="notification-list">
                                  <div>
                                    <Avatar src={notificationDetail.senderDetails.photoUrl} alt='Community Broadcast' />
                                    <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to join {notificationDetail.commName.commName} Community.</p>
                                  </div>
                                  <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                                </div>
                                <Stack direction={'row'} spacing={1}>
                                  <button className='button notification-btn' onClick={() => handleAcceptBroadcastRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Endorsing...' : 'Endorse'}</button>
                                  <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                                </Stack>
                              </>
                            ) : (
                                <>
                              <div className='broadcast-notification-category'><p>Community Broadcast</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                            );
                      case 'adminManageCommInvite':
                        return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName && notificationDetail.commName && notificationDetail.commName.commName ? (
                          <>
                            <div className='broadcast-notification-category'><p>Admin Managed Community Invite</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar src={notificationDetail.senderDetails.photoUrl} alt={notificationDetail.senderDetails.firstName} />
                                <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to join {notificationDetail.commName.commName} Community.</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptAdminCommRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Accepting...' : 'Accept'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Admin Managed Community Invite</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                      case 'adminManageCommWithdrawal':
                        return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName && notificationDetail.commName && notificationDetail.commName.commName ? (
                          <>
                            <div className='broadcast-notification-category'><p>Admin Managed Comm Withdrawal</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar src={notificationDetail.senderDetails.photoUrl} alt={notificationDetail.senderDetails.firstName} />
                                <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to withdraw from {notificationDetail.commName.commName} Community.</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptAdminCommWithdrawRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Accepting...' : 'Accept'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Admin Managed Comm Withdrawal</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                        case 'democratizedCommWithdrawal':
                          return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName && notificationDetail.commName && notificationDetail.commName.commName ? (
                            <>
                              <div className='broadcast-notification-category'><p>Democratized Comm Withdrawal</p></div>
                              <div className="notification-list">
                                <div>
                                  <Avatar src={notificationDetail.senderDetails.photoUrl} alt={notificationDetail.senderDetails.firstName} />
                                  <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to withdraw from {notificationDetail.commName.commName} Community.</p>
                                </div>
                                <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                              </div>
                              <Stack direction={'row'} spacing={1}>
                                <button className='button notification-btn' onClick={() => handleAcceptDemocratizedCommWithdrawRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Accepting...' : 'Accept'}</button>
                                <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </Stack>
                            </>
                          ) : (
                              <>
                                <div className='broadcast-notification-category'><p>Democratized Comm Withdrawal</p></div>
                                <div style={{alignItems: 'center'}} className="notification-list">
                                  <p>Error loading notification</p>
                                  <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                                </div>
                                </>
                          );
                      case 'aidloungeWithdrawal':
                        return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName && notificationDetail.aidLoungeInfoResponse && notificationDetail.aidLoungeInfoResponse.name ? (
                          <>
                            <div className='broadcast-notification-category'><p>Withdrawal Request</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar alt="Withdrawal Request" src={notificationDetail.senderDetails.photoUrl} />
                                <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to withdraw from {notificationDetail.aidLoungeInfoResponse.name} Aid Lounge.</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptWithdrawalRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Endorsing...' : 'Endorse'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Withdrawal Request</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                        case 'upgradeToAdminManagedCommInvite':
                        return notificationDetail.commName && notificationDetail.commName.commName && notificationDetail.senderDetails && notificationDetail.senderDetails.firstName ? (
                          <>
                            <div className='broadcast-notification-category'><p>Upgrade To Admin-Managed Community Invite</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar alt="Withdrawal Request" src={notificationDetail.commName.banner} />
                                <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to upgrade <b>{notificationDetail.commName.commName}</b> Community to an Admin-Managed Community Type </p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptUpgradeCommRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Endorsing...' : 'Endorse'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Upgrade To Admin-Managed Community Invite</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                        case 'adminManageCommNameUpdate':
                        return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName && notificationDetail.commName && notificationDetail.commName.commName ? (
                          <>
                            <div className='broadcast-notification-category'><p>Community Details Update Request</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar alt="Withdrawal Request" src={notificationDetail.senderDetails.photoUrl} />
                                <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to edit details from {notificationDetail.commName.commName} Community.</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptCommUpdateRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Endorsing...' : 'Endorse'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Community Details Update Request</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                        case 'democratizedCommNameUpdate':
                        return notificationDetail.senderDetails && notificationDetail.senderDetails.firstName && notificationDetail.commName && notificationDetail.commName.commName ? (
                          <>
                            <div className='broadcast-notification-category'><p>Community Details Update Request</p></div>
                            <div className="notification-list">
                              <div>
                                <Avatar alt="Withdrawal Request" src={notificationDetail.senderDetails.photoUrl} />
                                <p className='notification-text'>{notificationDetail.senderDetails.firstName} {notificationDetail.senderDetails.lastName} wants to edit details from {notificationDetail.commName.commName} Community.</p>
                              </div>
                              <p className='notification-timestamp'>{getTimeDifference(notificationDetail.created_at)}</p>
                            </div>
                            <Stack direction={'row'} spacing={1}>
                              <button className='button notification-btn' onClick={() => handleAcceptCommUpdateRequest(notificationDetail)}>{acceptingNotification === notificationDetail.id ? 'Endorsing...' : 'Endorse'}</button>
                              <button className='button notification-btn' onClick={() => handleDeclineBroadCast(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                            </Stack>
                          </>
                        ) : (
                            <>
                              <div className='broadcast-notification-category'><p>Community Details Update Request</p></div>
                              <div style={{alignItems: 'center'}} className="notification-list">
                                <p>Error loading notification</p>
                                <button style={{width: 'fit-content'}} className='button notification-btn' onClick={() => handleDeclineRaiseMe(notificationDetail)}>{decliningNotification === notificationDetail.id ? 'Declining...' : 'Decline'}</button>
                              </div>
                              </>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              </Stack>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <li className="accordionsMenu">
            <p>No notifications</p>
          </li>
        )}
      </Stack>
    ) : (
      <p>No notifications</p>
    )}
  </>
            )}
        </Box>
    </Box>
    {openModal && (
        <div className="modal-overlay">
        <div className="create-community">
          <div className='create-community-modal' ref={modalRef}>
            {step === 1 && (
              <RaiseMeAccount onCancel={handleCloseModal}
              accountName={accountName}
              setAccountName={setAccountName}
              accountNumber={accountNumber}
              setAccountNumber={setAccountNumber}
              routingNumber={routingNumber}
              setRoutingNumber={setRoutingNumber}
              bankName={bankName}
              setBankName={setBankName}
              handleCreateAccount={handleCreateAccount}
              bankBranch={bankBranch}
              setBankBranch={setBankBranch}
              interacEmail={interacEmail}
              setInteracEmail={setInteracEmail}
              accountType={accountType}
              setAccountType={setAccountType}
              currency={currency}
              setCurrency={setCurrency}
              bvn={bvn}
              setBvn={setBvn}
              location={location}
              setLocation={setLocation}
              setInteracPhone={setInteracPhone}
              interacPhone={interacPhone}
              raiseMeId={raiseMeId}
              setRaiseMeId={setRaiseMeId}
              setAccountId={setAccountId}
              accountId={accountId}
              useExistingAccount={useExistingAccount}
              setUseExistingAccount={setUseExistingAccount}
              setAccountStatus={setAccountStatus}
              setVerifyUrl={setVerifyUrl}
        />
            )}
            {step === 2 && (
        <>
        <AccountVerification verifyUrl={verifyUrl} nextStep={nextStep}/>
        </>
      )}
      {step === 3 && (
        <>
        {isVerifying ? (
          <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
        ) : (
          <>
          {isJoining ? (
            <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
          ) : (
            <>
            <PendingVerification partnerStatus={partnerStatus} accountStatus={accountStatus} verifyUrl={verifyUrl} onCancel={handleCloseModal}/>
            </>
          )}
          </>
        )}
        </>
        )}
          </div>
          </div>
          </div>
      )}
      </>
  )
}

export default NotificationList