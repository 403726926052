import { Box, Button } from '@mui/material'
import React from 'react'

const ConfirmRequest = ({actionButton,
    text, title, sendingAction, sendingActionButton,
     onClose, onAction}) => {
  return (
    <Box className="comm-popup-modal">
        <Box sx={{padding: '0.5rem 1rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <Box>
            <h2 style={{textAlign: 'left', color: 'black'}}>{title}</h2>
            <p style={{textAlign: 'left'}}>{text}</p>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'end'}}>
            <Button onClick={onClose} sx={{color: 'light.main'}}>
                Cancel
            </Button>
            <Button disabled={sendingAction} onClick={onAction} sx={{color: 'white'}} className='button'>
                {sendingAction ? sendingActionButton : actionButton}
            </Button>
        </Box>
        </Box>
    </Box>
  )
}

export default ConfirmRequest