import React, { useEffect, useState } from 'react'
import CreateAidLounge from '../CreateAidLounge/CreateAidLounge'
import { Avatar, Box, CircularProgress, Divider, Stack, Tooltip } from '@mui/material'
import { GroupAdd } from '@mui/icons-material'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import toast from "react-hot-toast";
import { useApiService } from '../../services/api-service'

const AidLoungesList = ({uuid}) => {
    const apiService = useApiService();
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [aidLounges, setAidLounges] = useState([]);

    const getAidLounge = async () => {
      try {
        setLoading(true);
        const aidLoungesResponse = await apiService.aidlounge.allLoungesByCommunity({ comm_uuid: uuid })
        // Handle aid lounges response
        if (aidLoungesResponse.status === 200 || aidLoungesResponse.status === 201) {
          setAidLounges(aidLoungesResponse.data);
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data[" code"]);
        }else{
          toast.error("Network Error")
        }
      } finally{
        setLoading(false);
      }
    }

    useEffect(() => {
      getAidLounge();
    }, [openModal]);

    const handleOpenModal = () => {
      setOpenModal(true)
    }

    const handleCloseModal = () => {
      setOpenModal(false)
    }
  return (
    <>
    {/*this is for list of raiseme*/}
    {loading ? (
      <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <CircularProgress style={{ color: '#a06ccb'}}/>
      <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
      </Box>
    ) : (
      <>
      <div className='lounge-list'>
      <div className="lounge-list-container">
      {aidLounges.length === 0 ? (
        <Stack direction={'column'}  justifyContent={'center'} alignItems={'center'} gap={0.5}>
            <h2 style={{color: 'black'}}>Aid Lounges</h2>
          <p>No records of Aid Lounges</p>
          <button onClick={handleOpenModal} className='button create-lounge-cta'>Create Aid Lounge</button>
          {/* <Button style={{width: 'fit-content', color: '#a06ccb', backgroundColor: '#ede7f6'}}>Create RaiseMe</Button> */}
        </Stack>
        ) : (
          <>
          <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <h2 style={{color: 'black'}}>Aid Lounges</h2>
          <Tooltip title='Create Aid Lounge'>
          <button onClick={handleOpenModal} className="button" style={{padding: '7px'}}><GroupAdd/></button>
          </Tooltip>
          </Stack>
          <Divider />
          <Box className='lounge-list-box'>
            {aidLounges.map((lounge) => (
              <React.Fragment key={lounge.id}>
                <div key={lounge.id} className="lounge-list-item">
                  <Avatar alt={`${lounge.name}`} src={lounge.photoUrl} />
                  <div className='lounge-list-info'>
                    <h3>{lounge.name}</h3>
                    <p>{lounge.descr}</p>
                    <progress style={{marginTop: 'auto'}} className='aid-lounge-progress' value={lounge.contr_amt} max={lounge.target_amt}/>
                    <p style={{display: 'inline'}}><span style={{color: '#a06ccb', fontWeight: 'bold'}}>${lounge.contr_amt}</span> CAD raised of ${lounge.target_amt} goal</p>
                  </div>
                  <Link to={`/aid-lounge/${lounge.id}`}>
                    <button className='button view-lounge-cta'>View Lounge</button>
                  </Link>
                  {/* Add any other lounge details you want to display */}
                </div>
                <Divider />
            </React.Fragment>
          ))}
          </Box>
          </Box>
        </>
        )}
      </div>
    </div>
      </>
    )}
    {openModal && (
      <div className='modal-overlay'>
        <Box className="comm-popup-modal">
        <CreateAidLounge onCancel={handleCloseModal} uuid={uuid} />
        </Box>
      </div>
    )}
    </>
  )
}

export default AidLoungesList