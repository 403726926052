import { ArrowBack, CreditCardOutlined, Email, MonetizationOn } from '@mui/icons-material'
import { Box, Button, Checkbox, Chip, Divider, FormControlLabel, Radio, Stack, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { formatAmountWithCommas } from '../../utils/routes'
import { useApiService } from '../../services/api-service'
import Lottie from 'lottie-react'
import Successful from "../../lottiefiles/Successful.json"
import toast from "react-hot-toast";

const TopUpWallet = ({handleEmail, firstName, lastName, handleFirstName, handleLastName, handleCheckboxChange, isContributing, handleChange, contributionAmount, handleContributionAmount, paymentMethod,
    handleBackButtonClick, checked, email, community, setIsContributing
}) => {
    const [step, setStep] = useState(1);
    const apiService = useApiService();
    const makeContribution = async (e) => {
        e.preventDefault(); // Corrected to e.preventDefault();
        try {
            setIsContributing(true);
            const requestBody = {
                amount: contributionAmount,
                email: email,
                first_name: firstName,
                last_name: lastName,
                comm_uuid: community.commUuid,
                comm_name: community.commName
            }
            const response = await apiService.community.walletContribution(requestBody);
            if(response.status === 200 || response.status === 201){
                setStep(step + 1);
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
            }
        } finally{
            setIsContributing(false);
        }
    }
  return (
    <Box sx={{width: '100%'}}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                            <Chip sx={{backgroundColor: 'light.main',
                                        "&:hover": {
                                        backgroundColor: "light.pending"
                                    },
                            }} icon={<ArrowBack />} label="Back" onClick={handleBackButtonClick}/>
                        </Box>
                        {step === 1 && (
                            <>
                            <form onSubmit={makeContribution}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '1rem'}}>
                        <p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.1rem'}}>Top Up Community Wallet</p>
                        <Box sx={{marginBottom: '10px'}} className='donate-input-container'>
                            <Stack sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <MonetizationOn/>
                                <h3>CAD</h3>
                            </Stack>
                            <Stack direction={'row'} style={{width: '100%'}}>
                                <input style={{width: '100%'}} className='donate-input'
                                type="text" inputMode='numeric' autoComplete='off'
                                maxLength={6} autoFocus onChange={handleContributionAmount}
                                value={formatAmountWithCommas(contributionAmount)}
                                required
                                />
                                <h3 className='donate-h3'>.00</h3>
                            </Stack>
                        </Box>
                        <p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '.9rem'}}>Payment Method</p>
                        <Box sx={{marginBottom: '10px'}} className='donate-input-container'>
                            <Stack
                            className='donate-payment-method'
                            direction={'column'}
                            >
                                <Tooltip title='Coming Soon' placement='top'>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                    <Radio
                                        value="card"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                        disabled
                                        />
                                    <CreditCardOutlined/>
                                    <h4>Credit or Debit</h4>
                                </Box>
                                </Tooltip>
                                <Divider />
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                    <Radio
                                        value="email"
                                        checked={paymentMethod === 'email'}
                                        onChange={handleChange}
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                        />
                                    <Email/>
                                    <h4>Interac Email</h4>
                                </Box>
                                {paymentMethod === 'email' && (
                                    <>
                                    <Stack direction={'row'} spacing={1} marginBottom={2} width={'100%'}>
                                        <input className='donate-user'
                                        type='text' placeholder='First Name'
                                        onChange={handleFirstName}
                                        value={firstName}
                                        required
                                        />
                                        <input className='donate-user'
                                        type='text' placeholder='Last Name'
                                        onChange={handleLastName}
                                        value={lastName}
                                        required
                                        />
                                    </Stack>
                                    <input className='donate-email'
                                    type='email' placeholder='Interac Email'
                                    onChange={handleEmail}
                                    value={email}
                                    required
                                    />
                                    </>
                                )}
                            </Stack>
                        </Box>

                    </Box>
                    <Button disabled={!paymentMethod || isContributing} className='button'
                    sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}
                    type='submit'
                    >{isContributing ? 'Contributing' : 'Contribute'}</Button>
                        </form>
                            </>
                        )}
                        {step === 2 && (
                <>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '10px'}}>
                        <h2 style={{textAlign: 'center', display: 'inline'}} className='blackText'>You have sucessfully requested to make contribution to <b>{community.commName}</b> community</h2>
                        <p>Check your email to process your request</p>
                        <Lottie animationData={Successful}/>
                    </Box>
                </>
            )}
                        
                    {/* Add top up screen content here */}
                </Box>
  )
}

export default TopUpWallet