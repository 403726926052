import { Box } from '@mui/material'
import React, { useState } from 'react'
import './inviteUser.css'
import { Cancel, Email, Link } from '@mui/icons-material'
import AddMemberToCommunity from '../AddMemberToCommunity/AddMemberToCommunity'
import GenerateLinkInvite from './GenerateLinkInvite'

const InviteUser = ({uuid, onCancel, community}) => {
    const [showMainScreen, setShowMainScreen] = useState(true);
    const [showEmailScreen, setShowEmailScreen] = useState(false);
    const [showLinkScreen, setShowLinkScreen] = useState(false);

    const handleEmailClick = () => {
        setShowMainScreen(false);
        setShowEmailScreen(true);
    }

    const handleLinkClick = () => {
        setShowMainScreen(false);
        setShowLinkScreen(true);
    }

    const handleBackClick = () => {
        setShowMainScreen(true);
        setShowEmailScreen(false);
        setShowLinkScreen(false);
    }

  return (
    <>
    
        {showMainScreen && (
            <>
            <Box className="raiseme-position-modal">
            <Cancel onClick={onCancel} sx={{cursor: 'pointer', float: 'right'}} />
            <h2 style={{color: 'black', textAlign: 'center'}}>Invite to Community</h2>
            <p>You can invite users by specifying the username/email address or by generating a shareable link.</p>
            <Box sx={{display: 'flex', gap: '1rem', marginTop: '10px', justifyContent: 'center'}}>
                <Box className='qr-code-option-item' onClick={handleEmailClick}>
                    <Email className='big-icon' />
                    <p>Invite via email/name</p>
                </Box>
                <Box className='qr-code-option-item' onClick={handleLinkClick}>
                    <Link className='big-icon' />
                    <p>Generate invite link</p>
                </Box>
            </Box>
            </Box>
            </>
        )}
        {showEmailScreen && (
            <>
            <AddMemberToCommunity commUuid={uuid} onCancel={handleBackClick} />
            </>
        )}
        {showLinkScreen && (
            <>
            <GenerateLinkInvite uuid={uuid} community={community} onCancel={handleBackClick}/>
            </>
        )}
    </>
  )
}

export default InviteUser