import React, { useEffect, useRef, useState } from 'react';
import './joinCommunity.css';
import { Close, Search } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useProfileService } from '../../services/profile-service';
import { LoadingPopup, NO_PROFILE } from '../../utils/routes';
import { useApiService } from '../../services/api-service';
import toast from "react-hot-toast";
import { Avatar, Button, InputAdornment, TextField } from '@mui/material';

const JoinCommunity = ({ onCancel }) => {
  const history = useHistory();
  const modalRef = useRef(null);
  const { profile, loadProfile } = useProfileService();
  const apiService = useApiService();
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [joinedCommunity, setJoinedCommunity] = useState(false);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [userid, setUserid] = useState('');
  const [isLoaded, setIsLoaded] = useState(false)
  const [searchInput, setSearchInput] = useState('');
  const [filteredCommunities, setFilteredCommunities] = useState(communities);
  const [pendingCommunities, setPendingCommunities] = useState([]);
  const [isJoining, setIsJoining] = useState({});

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };
  
  useEffect(() => {
    const filtered = communities.filter((community) =>
      community.comm_name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredCommunities(filtered);
  }, [searchInput, communities]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCancel]);

  const fetchCommunitiesByLocation = async () => {
    try {
      setLoading(true);
      const requestBody = {
        city: city,
        country: country
      };

      const response = await apiService.community.retrieveLocation(requestBody);

      if (response.status === 200) {
        const data = await response.data;
        const communitiesArray = Object.values(data);
        setCommunities(communitiesArray);
      }
    } catch (error) {
      console.error('Error fetching communities by location:', error);
      
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await apiService.auth.profile();
        setCity(response.data.city);
        setCountry(response.data.country);
        setUserid(response.data.userId)
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching communities by location:', error);
      }
    }
  
    if (isLoaded) {
      fetchCommunitiesByLocation();
    }
  
    fetchProfile();
  }, [apiService.community.retrieveLocation, profile, isLoaded]);

  const fetchPendingCommunities = async () => {
    try {
      const response = await apiService.community.getPendingEndorsements();
      if (response.status === 200) {
        const data = await response.data;
        const pendingCommunitiesArray = data.map((item) => item.comm_uuid);
        setPendingCommunities(pendingCommunitiesArray);
      }
    } catch (error) {
      console.error('Error fetching pending communities:', error);
    }
  };

  useEffect(() => {
    fetchPendingCommunities();
  }, []);

  const handleJoinCommunity = async (commUuid) => {
    try {
  
      // Loop through communityMembers and invite each user with ranking 1 to the community
          let joined = false;
          setIsJoining((prevLoadingStates) => ({
            ...prevLoadingStates,
            [commUuid]: true,
          }));
          const requestBody = {
            comm_uuid: commUuid,
            sender_id: userid,
            notification_type: 'communityBroadcast',
            delivery_type: 2
          };
  
            // Log the requestBody to check if it contains the necessary data
  
            const response = await toast.promise(
              apiService.notification.push(requestBody),
              {
                loading: 'Requesting to join Community...',
                success: 'Request to join Community Successfully',
                error: 'Error requesting to joining community',
              }
            )
            if (response.status === 201 || response.status === 200) {
              fetchPendingCommunities();
              joined = true;
            }
            setJoinedCommunity(joined);
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    } finally{
      setIsJoining((prevLoadingStates) => ({
        ...prevLoadingStates,
        [commUuid]: false,
      }));
    }
  };
  

  const handleContinue = () => {
    loadProfile();
    history.push('/communities');
  };

  return (
    <div className="join-community">
      <div className="join-community-modal" ref={modalRef}>
        <div className="close-button" onClick={onCancel}>
          <Close />
        </div>
        <h2 className="onboarding-text">Join Communities</h2>
        <p className="join-community-desc">
          Select a few to get started. You can update them later
        </p>
        {loading ? (
          <>
          {LoadingPopup()}
          </>
        ) : (
          <>
          <TextField fullWidth
                className='join-community-search'
                value={searchInput}
                onChange={handleSearch}
                label="Search communities"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                id="fullWidth" color='secondary' />
            {filteredCommunities.length > 0 ? (
              <div className="community-list-container">
                <div className="community-list">
                {filteredCommunities.map((community) => (
                    <div key={community.comm_uuid} className="community-item">
                      <div className="community-item-left">
                      <Avatar alt={`${community.comm_name}`} src={community.banner || NO_PROFILE} />
                      {/* <img src={community.banner ?? NO_PROFILE} alt={community.comm_name} /> */}
                      <div className="community-details">
                        <h3 className="communitylist-name">{community.comm_name}</h3>
                        <p className="communitylist-desc">{community.comm_descr}</p>
                      </div>
                      </div>
                      <div className="community-item-right">
                      <Button
                          sx={{color: 'white', width: '100px'}}
                          className="button join-community-btn"
                          onClick={() => handleJoinCommunity(community.comm_uuid)}
                          disabled={pendingCommunities.includes(community.comm_uuid) || isJoining[community.comm_uuid]}
                        >
                          {isJoining[community.comm_uuid] ? 'Joining...' : pendingCommunities.includes(community.comm_uuid) ? 'Pending' : 'Join'}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p>No communities found in your location.</p>
            )}
          </>
        )}
        <div className="button-container">
          <button
            className={`full-width-btn continue-community-btn ${!joinedCommunity ? "disabled" : "button"}`}
            onClick={handleContinue}
            disabled={!joinedCommunity}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
