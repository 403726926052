import '../RaiseMe/raiseMe.css'
import '../OnBoarding/onboarding.css';
import '../../components/createCommunity/createCommunity.css';
import './loungeDetails.css'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useApiService } from '../../services/api-service';
import { Group, Info, InfoRounded, MoreHoriz, PersonAddAlt, Weekend } from '@mui/icons-material';
import { LoadingFullScreen, NO_PROFILE } from '../../utils/routes';
import { Avatar, Backdrop, Box, CircularProgress, Fade, Modal, Skeleton, Tooltip } from '@mui/material';
import UserProfile from '../../components/userProfile/UserProfile';
import Header from '../../components/header/Header';
import PostShare from '../../components/postShare/PostShare';
import LoungePostShare from '../../components/LoungePostShare/LoungePostShare';
import LoungePosts from '../../components/posts/LoungePosts';
import AddMemberToLounge from '../../components/AddMemberToCommunity/AddMemberToLounge';
import LoungeMembers from '../../components/CommunityMembers/LoungeMembers';

const LoungeDetails = () => {
    const { uuid, loungeId } = useParams();
    const apiService = useApiService();
    const [lounge, setLounge] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showInviteUser, setShowInviteUser] = useState(false)
    const [showMembers, setShowMembers] = useState(false)

    const handleInviteUser = () => {
      setShowInviteUser(true) // Show the CreateCommunity component
    }
  
    const handleCancelInviteUser = () => {
      setShowInviteUser(false) // Hide the CreateCommunity component
    }

    const handleShowMembers = () => {
      setShowMembers(true);
    };
  
    const handleCloseMembers = () => {
      setShowMembers(false);
    };

    useEffect(() => {
        const getLoungeInfo = async () => {
            try {
                const requestBody = {
                    lounge_uuid: loungeId
                }
                const response = await apiService.lounge.getInfo(requestBody);
                const {data} = response
                setLounge(data)
            } catch (e) {
                
            } finally{
                setLoading(false)
            }
        }

        getLoungeInfo();
    }, [loungeId])

  return (
    <>
    {loading ? (
        <>
        {LoadingFullScreen()}
        </>
    ) : (
        <>
    <Header uuid={uuid} />
    <div className="communityWrapper">
        <div className='community-sidebar'>
          <UserProfile />
        </div>
        <div className="lounge-page-container">
          {/* Profile header section */}
          <div className="profile-header-cover">
                    <div className='bannerContanier'><img src='https://res.cloudinary.com/shopsfit/image/upload/v1688810856/MyAreaa/mahogany_jajib7.jpg' alt='' /></div>
                  <div className="profile-header-info">
                    <div className="user-short-description">
                      {/* <img className='profile-img' src="img/calgary.png" alt="" /> */}
                      <Avatar className='profile-img'/>
                      <div className="username">
                        <h2>{lounge?.loungeName ?? ''}</h2>
                        <p>{lounge?.loungeDescr ?? ''}</p>
                        <p>{lounge?.city ?? ''}, {lounge?.country ?? ''}</p>
                      </div>
                    </div>
                    <div className='user-stats'>
                  </div>
                    <div className="user-actions">
                      <Tooltip title='Invite user to lounge'>
                      <button className="button" onClick={handleInviteUser}><PersonAddAlt/></button>
                      </Tooltip>
                      <Tooltip title='View Members'>
                      <button className="button" onClick={handleShowMembers}><Group/></button>
                      </Tooltip>
                      <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={showMembers}
                      onClose={handleCloseMembers}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}>
                      <Fade>
                        <Box className="comm-popup-modal">
                        <LoungeMembers uuid={uuid} loungeId={loungeId}/>
                        </Box>
                              </Fade>
                  </Modal>
                    </div>

                  </div>
                  </div>
                  {/* <div className='section-navigation'>
              <div className='nav-item'>
                <Info />
                Info
              </div>
              <div className='nav-item'>
                  <Group />
                  Members
                </div>
              </div> */}
              {showInviteUser && (
                <div className='modal-overlay'>
                  <AddMemberToLounge commUuid={uuid} loungeId={loungeId} onCancel={handleCancelInviteUser} />
                </div>
              )}
              <div className='lounge-post-section'>
              <div className='right-lounge-section-post'>
              <LoungePostShare loungeId={loungeId} uuid={uuid}/>
              <LoungePosts loungeId={loungeId}/>
                </div>
              </div>
        </div>
      </div>
      </>
      )}
    </>
  )
}

export default LoungeDetails