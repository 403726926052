import React, { useEffect, useRef, useState } from 'react';
import './shareComment.css';
import { Avatar, Button, Divider, TextField } from '@mui/material';
import { Comment, EmojiEmotions, Favorite, FavoriteBorder, MoreHoriz, Share } from '@mui/icons-material';
import { useApiService } from '../../services/api-service';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useLoungePostsContext } from '../../contexts/LoungePostsContext';
import EmojiPicker from 'emoji-picker-react';
import toast from 'react-hot-toast';

const ShareLoungeComment = ({ 
   data, isSharing, comment, profile, loadComments, setIsSharing, loungeId }) => {
  const [showReply, setShowReply] = useState(Array(comment?.length).fill(false));
  const [replyText, setReplyText] = useState(Array(comment?.length).fill(''));
  const apiService = useApiService();
  const { refreshLoungePosts } = useLoungePostsContext();
  const [isLiked, setIsLiked] = useState({});
  const [likesCount, setLikesCount] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const containerRef = useRef(null);
  const [commentText, setCommentText] = useState('');
  const textareaRef = useRef(null);

  const fetchLikes = () => {
    if (data.comments && data.comments.length > 0) {
      console.log('data', data)
      const initialLikesStatus = {};
      const initialLikesCount = {};
      data.comments.forEach(comment => {
        initialLikesStatus[comment.post_id] = comment.liked;
        initialLikesCount[comment.post_id] = comment.likes_count;
      });
      setIsLiked(initialLikesStatus);
      setLikesCount(initialLikesCount);
    }
  }

  useEffect(() => {
    fetchLikes();
  }, [data.comments]);

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
    autoResizeTextarea(event.target);
  };

  const toggleReply = (index) => {
    setShowReply((prevShowReply) => {
      const updatedShowReply = [...prevShowReply];
      updatedShowReply.forEach((value, i) => {
        if (i !== index) {
          updatedShowReply[i] = false; // Close other replies
        }
      });
      updatedShowReply[index] = !updatedShowReply[index];
      return updatedShowReply;
    });
  };

  const handleReplyChange = (event, index) => {
    setReplyText((prevReplyText) => {
      const updatedReplyText = [...prevReplyText];
      updatedReplyText[index] = event.target.value;
      return updatedReplyText;
    });
  };

  const handleEmojiSelect = (emojiObject) => {
    setCommentText((prevCommentText) => prevCommentText + emojiObject.emoji);
    setShowEmojiPicker(false);
  };


  const handleReplySubmit = async (index) => {
    try {
      setIsSharing(true)
        const requestBody = {
            lounge_id: loungeId,
            community_id: data.community_id,
            message: replyText[index],
            user_id: profile.userId,
            parent_id: data.post_id
        }
        const response = await apiService.community.createPost(requestBody);
        if(response.status === 200 || response.status === 201){
          loadComments();
          toast.success('Comment Created Successfully');
          setReplyText((prevReplyText) => {
            const updatedReplyText = [...prevReplyText];
            updatedReplyText[index] = '';
            return updatedReplyText;
          });
      }
    } catch (error) {
      
    } finally{
      setIsSharing(false)
    }
  };

  const likePost = async (postId) => {
    try {
      setIsLiked(prevIsLiked => ({
        ...prevIsLiked,
        [postId]: true
      }));
      setLikesCount(prevLikesCount => ({
        ...prevLikesCount,
        [postId]: prevLikesCount[postId] + 1
      }));

      const requestBody = {
        post_id: postId,
        rxn_type: 'like'
      };

      const response = await apiService.lounge.createReaction(requestBody);

      if (response.status === 200 || response.status === 201) {
        
      }
    } catch (e) {
      console.error('Error liking post:', e);
    }
  };

  const unLikePost = async (postId) => {
    try {
      setIsLiked(prevIsLiked => ({
        ...prevIsLiked,
        [postId]: false
      }));
      setLikesCount(prevLikesCount => ({
        ...prevLikesCount,
        [postId]: prevLikesCount[postId] - 1
      }));

      const response = await apiService.lounge.deleteReaction(postId);

      if (response.status === 200 || response.status === 201) {
        
      }
    } catch (e) {
      console.error('Error unliking post:', e);
    }
  };

  const handleCommentSubmit = async () => {
    // Implement the logic to submit the comment
    try {
      setIsSharing(true)
        const requestBody = {
            community_id: data.community_id,
            lounge_id: loungeId,
            message: commentText,
            user_id: profile.userId,
            parent_id: data.post_id
        }
        const response = await toast.promise(
          apiService.lounge.createPost(requestBody),
          {
            loading: 'Creating comment...',
            success: 'Comment created successfully',
            error: 'Error creating comment',
          }
        )
        if(response.status === 200 || response.status === 201){
          const newComment = {
            ...requestBody, // Assuming your API response contains necessary data for the new comment
            userDetails: profile, // Assuming profile contains necessary user details
            post_id: data.post_id, // Assuming the API returns the post ID of the new comment
            likes_count: 0,
            comments_count: 0,
          };
            data.comments.push(newComment);
            fetchLikes();
          setCommentText('');
      }
    } catch (error) {
      
    } finally{
      setIsSharing(false)
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) &&
        containerRef.current && !containerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPickerRef, containerRef]);

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = 'auto'; // Reset height to auto to calculate the new height correctly
    textarea.style.height = textarea.scrollHeight + 'px'; // Set height based on scroll height
  };

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea(textareaRef.current); // Initial resize on mount
    }
  }, []);
  

  return (
    <>
      <div className="commentField">
        {/* <img className="comments-profile-img" src={profile.photoUrl || NO_PROFILE} alt={profile.firstName} /> */}
        <Avatar alt={`${profile.firstName} ${profile.lastName}`} src={profile.photoUrl} />
        <div ref={containerRef} className="create-comment-container">
        <textarea
          ref={textareaRef}
          className="comments-textarea"
          placeholder="Write a comment..."
          value={commentText}
          onChange={handleCommentChange}
        />
        <div className="create-comment-icons">
              <EmojiEmotions className='create-comment-icons-item'  onClick={() => setShowEmojiPicker((prev) => !prev)}/>
              <Button
              sx={{color: 'white'}}
                className="button create-post-btn"
                onClick={() => handleCommentSubmit(commentText)}
                disabled={isSharing || commentText === ''}
            >
                {isSharing ? 'Sharing...' : 'Share'}
              </Button>
          </div>
        </div>
      </div>
      {data.comments && data.comments?.length > 0 && data.comments.map((comments, index) => (
        <div className="comments-list" key={index}>
          {/* <img className="comments-list-img" src={comments.userDetails?.photoUrl || NO_PROFILE} alt={comments.userDetails?.photoUrl} /> */}
          <Avatar alt={`${comments.userDetails.firstName} ${comments.userDetails.lastName}`} src={comments.userDetails.photoUrl} />
          
          <div className="comment-container">
            <div className="comment">
            <Link to={`/profile/${comments.userDetails?.userId}`}>
              <p style={{fontWeight: 'bold'}}>
                {comments.userDetails?.firstName} {comments.userDetails?.lastName}
              </p>
              </Link>
              {/* <MoreHoriz /> */}
            </div>
            <div className="comment-post">
            <Link to={`/lounge/post/${comments.community_id}/${comments.post_id}`} key={index}>
              <p className='alignLeft'>{comments.message}</p>
              </Link>
              {/* <div className="comments-reactions">
                <Favorite />
                <Comment onClick={() => toggleReply(index)} />
              </div> */}
              <div className="postReaction">
            <div className="postReaction-likes">
            {isLiked[comments.post_id] ? 
                    <Favorite style={{ color: 'red' }} onClick={() => unLikePost(comments.post_id)} /> :
                    <FavoriteBorder onClick={() => likePost(comments.post_id)} />
                  }
              <p>{likesCount[comments.post_id] <= 1 ? `${likesCount[comments.post_id]} Like` : `${likesCount[comments.post_id]} Likes`}</p>
            </div>
            {/* <div className="postReaction-comments" onClick={() => toggleReply(index)}>
            <Comment className='comments-icon'  />
            <p>{comments?.comments_count <= 1 ? `${comments?.comments_count} Comment` : `${comments?.comments_count} Comments`}</p>
            </div>  */}
            <Link to={`/lounge/post/${comments.community_id}/${comments.post_id}`} key={index}>
            <div className="postReaction-comments">
            <Comment className='comments-icon'  />
            <p>{comments?.comments_count <= 1 ? `${comments?.comments_count} Comment` : `${comments?.comments_count} Comments`}</p>
            </div> 
            </Link>
              {/* <Share/> */}
          </div>
              <Divider />
              {showReply[index] && (
                <div>
                  <TextField
                    className="comments-textarea"
                    label="Write a reply..."
                    value={replyText[index]}
                    onChange={(event) => handleReplyChange(event, index)}
                    multiline
                  />
                  <button className="button comments-btn" onClick={() => handleReplySubmit(index)} disabled={!replyText[index]?.trim()}>
                    {isSharing ? 'Sharing...' : 'Reply'}
                  </button>
                </div>
              )}
              {/* Nested Replies */}
              {comments?.comments && (
                <ShareLoungeComment
                  commentText={replyText[index]}
                  handleCommentChange={(event) => handleReplyChange(event, index)}
                  handleCommentSubmit={() => handleReplySubmit(index)}
                  data={data}
                  isSharing={isSharing}
                  comment={comments.comments}
                />
              )}
            </div>
          </div>
        </div>
      ))}
      {showEmojiPicker && (
        // <Picker data={data} onEmojiSelect={console.log} />
        <div ref={emojiPickerRef}>
        <EmojiPicker
          open
          onEmojiClick={handleEmojiSelect}
          emojiStyle='apple'
          searchPlaceholder='Search for emoji...'
          suggestedEmojisMode='frequent'
          style={{ position: 'absolute', backgroundColor: 'white', top: '-25px', right: '10px' }}
        />
        </div>
      )}
    </>
  );
};

export default ShareLoungeComment;
