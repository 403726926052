// CommunityPostsContext.js
import { createContext, useContext, useState } from 'react';

const CommunityPostsContext = createContext();

export const useCommunityPostsContext = () => {
  return useContext(CommunityPostsContext);
};

export const CommunityPostsProvider = ({ children }) => {
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshCommunityPosts = () => {
    setRefreshCount((prevCount) => prevCount + 1);
  };

  const contextValue = {
    refreshCount,
    refreshCommunityPosts,
  };

  return (
    <CommunityPostsContext.Provider value={contextValue}>
      {children}
    </CommunityPostsContext.Provider>
  );
};
