import React from 'react'
import "./raiseMeOverview.css"
import "../../pages/Community/community.css";
import "../loungeOverview/loungeOverview.css";
import "../../components/header/header.css";
import { AttachMoney } from '@mui/icons-material';
import { Avatar, Box, LinearProgress, Stack } from '@mui/material';
import { LinearLoad } from '../../utils/routes';

const RaiseMeOverview = ({selectedLounge, period, loadMembers, members, cycleCalculation}) => {
  const mapPeriodToText = (period) => {
    switch (period) {
      case 30:
        return 'Monthly';
      case 14:
        return 'Bi-weekly';
      case 7:
        return 'Weekly';
      case 1:
          return 'Daily';
      default:
        return '';
    }
  };
  return (
    <div className="raiseMe-overview">
                    <div className="lounge-container">
                  <div className="lounge-info">
                    <div className="overview-top">
                    <div className="overview-icon">
                    <AttachMoney/>
                    </div>
                    <div className="overview-title">
                    <p className='profile-name'>Contribution Amount</p>
                  <p>${selectedLounge?.contributionAmount ?? ''} / {mapPeriodToText(period)}</p>
                    </div>
                    </div>
                    {/* <div className="myProgress">
                    <div className="myBar"></div>
                    </div> */}
                    {/* <div className="overview-bottom">
                    <p><span>View Contribution Breakdown</span> </p>
                    </div> */}
                  </div>

                </div>
                <>
                {loadMembers ? (
                  <>
                  {LinearLoad()}
                  </>
                ): (
                  <>
                    {members.length > 0 && (
                  <>
                <div className="raiseme-overview-container">
                  <Box display={'flex'} gap={'1rem'}>
                  <Avatar sx={{width: 60, height: 60}} alt={`${members[0].userDetails.firstName} ${members[0].userDetails.lastName}`} src={members[0].userDetails.photoUrl} />
                  <Stack display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <p className='profile-name' style={{textAlign: 'left'}}>{`${members[0].userDetails.firstName} ${members[0].userDetails.lastName}`}</p>
                    <p className='alignLeft'>Cycle Receiver (${cycleCalculation}.0)</p>
                  </Stack>
                  </Box>
                </div>
                </>
                )}
                  </>
                )}
                </>
                </div>
  )
}

export default RaiseMeOverview;