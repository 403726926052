import { Cancel, HourglassEmpty, NewReleases, PendingActions, Verified } from '@mui/icons-material';
import { Box, Chip, Link, Stack, Tooltip } from '@mui/material';
import React from 'react';

const PendingVerification = ({ partnerStatus, accountStatus, verifyUrl, onCancel }) => {

  return (
    <Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Chip label='6/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
            <Cancel sx={{cursor: 'pointer'}} onClick={onCancel}/>
        </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <PendingActions style={{ textAlign: 'center' }} className='big-icon' />
      </Box>
      <Box>
        {partnerStatus === 'OK' && accountStatus === null && (
          <>
            <h2 style={{ color: 'black', textAlign: 'center', fontSize: '1.3rem' }}>Account Verification in Process</h2>
            <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Personal Identity Verification</p>
                <Tooltip title='Personal Identity Verification is verified.'>
                <Chip icon={<Verified/>} 
                label='Verified' size='small'
                sx={{backgroundColor: "light.success",  width: '30%'
                }}/>
                </Tooltip>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Account Verification Status</p>
                <Tooltip title='Account verification is pending. It takes 2 - 3 hours to process verification.'>
                <Chip icon={<HourglassEmpty/>} label='Pending' size='small' sx={{backgroundColor: "light.pending", width: '30%'}}/>
                </Tooltip>
            </Stack>
            </Stack>
          </>
        )}
        {partnerStatus !== 'OK' && accountStatus === 'ACCOUNT_VERIFICATION_DONE' && (
          <>
            <h2 style={{ color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0' }}>Personal Verification in Process</h2>
            <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Personal Identity Verification</p>
                <Tooltip title='Personal identity verification is pending. It takes 2 - 3 hours to process verification'>
                <Chip icon={<HourglassEmpty/>} label='Pending' size='small' sx={{backgroundColor: "light.pending", width: '30%'}}/>
                </Tooltip>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Account Verification Status</p>
                <Tooltip title='Account verification is verified.'>
                <Chip icon={<Verified/>} 
                label='Verified' size='small'
                sx={{backgroundColor: "light.success",  width: '30%'
                }}/>
                </Tooltip>
            </Stack>
            </Stack>
          </>
        )}
        {partnerStatus !== 'OK' && partnerStatus !=='error' && accountStatus === null &&  (
          <>
            <h2 style={{ color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0' }}>Personal Verification & Account Status in Process</h2>
            <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Personal Identity Verification</p>
                <Tooltip title='Personal identity verification is pending. It takes 2 - 3 hours to process verification'>
                <Chip icon={<HourglassEmpty/>} label='Pending' size='small' sx={{backgroundColor: "light.pending", width: '30%'}}/>
                </Tooltip>
            </Stack>
            {/* <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Account Verification Status</p>
                <Link href={verifyUrl} target='_blank' sx={{ width: '30%'}}>
                <Chip icon={<NewReleases/>} 
                label='Verify Account' size='small'
                clickable
                href={verifyUrl}
                sx={{backgroundColor: "light.failed",
                "&:hover": {
                    backgroundColor: "light.main"
                },
                }}/>
                 </Link>
            </Stack> */}
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Account Verification Status</p>
                <Tooltip title='Account verification is pending. It takes 2 - 3 hours to process verification.'>
                <Chip icon={<HourglassEmpty/>} label='Pending' size='small' sx={{backgroundColor: "light.pending", width: '30%'}}/>
                </Tooltip>
            </Stack>
            </Stack>
          </>
        )}
        {partnerStatus === 'error' && accountStatus !== 'ACCOUNT_VERIFICATION_DONE' && (
          <>
            <h2 style={{ color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0' }}>Personal Verification & Account Status in Process</h2>
            <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Personal Identity Verification</p>
                <Link href={'/settings'} target='_blank' sx={{ width: 'auto'}}>
                <Tooltip title='Personal identity verification failed. Go to settings to restart process'>
                <Chip icon={<NewReleases/>} 
                label='Verify Identity in Settings' size='small'
                clickable
                sx={{backgroundColor: "light.failed",
                "&:hover": {
                    backgroundColor: "light.main"
                },
                }}/>
                </Tooltip>
                 </Link>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Account Verification Status</p>
                <Tooltip title='Account verification is pending. It takes 2 - 3 hours to process verification.'>
                <Chip icon={<HourglassEmpty/>} label='Pending' size='small' sx={{backgroundColor: "light.pending", width: '30%'}}/>
                </Tooltip>
            </Stack>
            </Stack>
          </>
        )}
        {/* {partnerStatus === 'AML check in progress' && accountStatus === null && (
          <>
            <h2 style={{ color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0' }}>Personal Verification & Account Status in Process</h2>
            <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Personal Identity Verification</p>
                <Tooltip title='Personal identity verification is pending. It takes 2 - 3 hours to process verification'>
                <Chip icon={<HourglassEmpty/>} label='Pending' size='small' sx={{backgroundColor: "light.pending", width: '30%'}}/>
                </Tooltip>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p>Account Verification Status</p>
                <Tooltip title='Account verification is pending. It takes 2 - 3 hours to process verification.'>
                <Chip icon={<HourglassEmpty/>} label='Pending' size='small' sx={{backgroundColor: "light.pending", width: '30%'}}/>
                </Tooltip>
            </Stack>
            </Stack>
          </>
        )} */}
      </Box>
    </Box>
  );
};

export default PendingVerification;
