import { Button } from '@mui/material'
import React from 'react'
import images from '../../Data/imageHelper'

const ErrorScreen = ({fetchLinkInfo}) => {
  return (
    <>
    <img className='error-animation' src={images.error} alt="" />
    <h2 className='text-md'>Error loading invitation. Try again</h2>
    <Button onClick={fetchLinkInfo} sx={{color: 'white'}} className='button'>Retry</Button>
    </>
  )
}

export default ErrorScreen