import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Posts.css';
import './communityPosts.css'
import Post from '../post/Post';
import useEmailService from '../../services/email-service';
import { useApiService } from '../../services/api-service';
import { useCommunityPostsContext } from '../../contexts/CommunityPostsContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { LinearLoad } from '../../utils/routes';
import toast from 'react-hot-toast';

const CommunityPosts = ({ uuid }) => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getUserEmail } = useEmailService();
  const apiService = useApiService();
  const [communityPosts, setCommunityPosts] = useState([]);
  const { refreshCount } = useCommunityPostsContext();
  const [commentsMapping, setCommentsMapping] = useState({});
  const [page, setPage] = useState(1);
  const observerRef = useRef();
  const loadMoreTriggerRef = useRef();
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const fetchPosts = useCallback(async (pageToFetch = 1) => {
    try {
      setLoading(true);
      const requestBody = { comm_uuid: uuid };
      const response = await apiService.community.allPosts(requestBody, pageToFetch, 5);

      if (response.status === 200 || response.status === 201) {
        const commPosts = response.data.filter(post => !post.parent_id);
        if (commPosts.length === 0) {
          setHasMorePosts(false);
          return;
        }
        const userIdsSet = new Set(commPosts.map(post => post.user_id));
        const uniqueUserIds = Array.from(userIdsSet);
        const concurrencyLimit = 5;

        const chunks = Array.from({ length: Math.ceil(uniqueUserIds.length / concurrencyLimit) }, (_, index) =>
          uniqueUserIds.slice(index * concurrencyLimit, (index + 1) * concurrencyLimit)
        );

        const userDetailsChunks = await Promise.all(
          chunks.map(async (userIdsChunk) => {
            const userDetailsPromises = userIdsChunk.map(async (userId) => {
              try {
                const userResponse = await apiService.profile.profileDetail({ user_id: userId });
                return (userResponse.status === 200 || userResponse.status === 201) ? userResponse.data : null;
              } catch (error) {
                return null;
              }
            });
            return Promise.all(userDetailsPromises);
          })
        );

        const userDetails = userDetailsChunks.flat();

        const postsWithUserDetails = await Promise.all(
          commPosts.map(async (post) => {
            const userDetailsForPost = userDetails.find(userDetail => userDetail && userDetail.userId === post.user_id);

            let liked = false;
            let reactionId = null;

            try {
              const reactionStatusResponse = await apiService.community.reactionStatus({ post_id: post.post_id });
              liked = reactionStatusResponse.status === 200;
              reactionId = liked ? reactionStatusResponse.data.id : null;
            } catch (error) {
              if (error.response && error.response.status === 404) {
                liked = false;
                reactionId = null;
              } else {
                throw error;
              }
            }

            return { ...post, userDetails: userDetailsForPost, liked, reaction_id: reactionId };
          })
        );

        postsWithUserDetails.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setCommunityPosts(prev => (pageToFetch === 1 ? postsWithUserDetails : [...prev, ...postsWithUserDetails]));
      }
    } catch (e) {
      if (e.response) {
        if(e.response.status !== 404){
          toast.error(e.response.data["code"]);
        }
        setHasMorePosts(false);
      } else {
        toast.error("Network Error");
      }
    } finally {
      setLoading(false);
    }
  }, [apiService, uuid]);

  useEffect(() => {
    fetchPosts(page);
  }, [uuid, page, fetchPosts]);

  useEffect(() => {
    if (refreshCount > 0) {
      handlePostCreated();
      console.log('handlePostCreated', hasMorePosts);
    }
  }, [refreshCount]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };

    const handleObserver = (entries) => {
      const target = entries[0];
      console.log('loading status ', loading);
      if (target.isIntersecting && !loading && hasMorePosts) {
        setPage(prevPage => prevPage + 1);
      }
    };

    observerRef.current = new IntersectionObserver(handleObserver, options);
    if (loadMoreTriggerRef.current) {
      observerRef.current.observe(loadMoreTriggerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [loading, hasMorePosts]);

  const handlePostCreated = () => {
    setPage(1);
    setHasMorePosts(true);
    setCommunityPosts([]);
    fetchPosts(1); 
  };

  


  if (loading && page === 1) {
    return LinearLoad();
  }

  return (
    <div className="community-posts">
  {communityPosts.length === 0 ? (
    <div className='post'>No records of posts found in the community.</div>
  ) : (
    communityPosts.map((post, id) => {
      const commentCount = commentsMapping[post.post_id]?.length || 0;
      const comment = commentsMapping[post.post_id];
      const postUrl = `/community/post/${uuid}/${post.post_id}`;
      return (
          <Post key={post.post_id} data={post} id={id} commentCount={commentCount} comment={comment} setCommentsMapping={setCommentsMapping} />
      );
    })
    
  )}
  {!hasMorePosts && communityPosts.length > 0 && <p>End of posts in community</p>}
  <div ref={loadMoreTriggerRef} id="load-more-trigger" className="load-more-trigger"></div>
  <div className='loading-trigger'>
    {loading && LinearLoad()}
  </div>
  
</div>

  );
};

export default CommunityPosts;
