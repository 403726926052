import { Block, Comment, Delete, Favorite, FavoriteBorder, Flag, MoreVert, Share } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import './Post.css';
import { NO_PROFILE } from '../../utils/routes';
import ShareComment from '../ShareComment/ShareComment';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import { useCommunityPostsContext } from '../../contexts/CommunityPostsContext';
import { Avatar, Backdrop, Box, Divider, Fade, Menu, MenuItem, Modal } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useLoungePostsContext } from '../../contexts/LoungePostsContext';
import ShareLoungeComment from '../ShareComment/ShareLoungeComment';
import ReportPost from './ReportPost';
import toast from 'react-hot-toast';
import ConfirmRequest from '../../utils/ConfirmRequest';

const LoungePost = ({loungeId, data, setCommentsMapping, comment, commentCount}) => {
  const [showComments, setShowComments] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [isSharing, setIsSharing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const apiService = useApiService();
  const { profile } = useProfileService();
  const [showOptionsMenu, setShowOptionsMenu] = useState(false); // Add state for options menu
  const { refreshLoungePosts } = useLoungePostsContext();
  const [isLiked, setIsLiked] = useState(data?.liked);
  const [likesCount, setLikesCount] = useState(data?.likes_count);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedData, setSelectedData] = useState();
  const [isBlocking, setIsBlocking] = useState(false);

  //open confirm request
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);


    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  

  const toggleComments = () => {
    setShowComments((prevShowComments) => !prevShowComments);
  };

  const toggleOptionsMenu = () => {
    setShowOptionsMenu((prevShowOptionsMenu) => !prevShowOptionsMenu);
  };

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  // const loadComments = async () => {
  //   try {
  //     const requestBody = {
  //       parent_id: data.post_id,
  //     };
  //     const response = await apiService.community.allComments(requestBody);
  //     if (response.status === 200 || response.status === 201) {
  //       const commPosts = response.data;
  
  //       // Filter posts that are comments (have a parent_id)
  //       const commentPosts = commPosts.filter((post) => post.parent_id);
  
  //       const postsWithUserDetails = await Promise.all(
  //         commentPosts.map(async (post) => {
  //           const userResponse = await apiService.profile.profileDetails({
  //             user_id: post.user_id,
  //           });
  
  //           if (userResponse.status === 200 || userResponse.status === 201) {
  //             const userDetails = userResponse.data;
  //             return { ...post, userDetails };
  //           } else {
  //             // Handle error fetching user details
  //             return post;
  //           }
  //         })
  //       );
  
  //       // Sort comment posts by createdAt in descending order
  //       postsWithUserDetails.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  
  //       // Create a mapping between parent post IDs and their comments
  //       const commentsMapping = {};
  
  //       postsWithUserDetails.forEach((comment) => {
  //         const parentId = comment.parent_id;
  
  //         if (!commentsMapping[parentId]) {
  //           commentsMapping[parentId] = [];
  //         }
  
  //         commentsMapping[parentId].push(comment);
  //       });

  //       setCommentsMapping(commentsMapping);
  //       // Now you have a mapping between parent post IDs and their comments
  //       console.log('commentsMapping: ', commentsMapping);
  //       console.log('commentsMapping: ', postsWithUserDetails);
  //     }
  //   } catch (e) {
  //     // Handle the error
  //   }
  // };

  // useEffect(() => {
  //   loadComments();
  // }, [])

  const handleCommentSubmit = async () => {
    // Implement the logic to submit the comment
    try {
      setIsSharing(true)
        const requestBody = {
            community_id: data.community_id,
            lounge_id: loungeId,
            message: commentText,
            user_id: profile.userId,
            parent_id: data.post_id
        }
        const response = await apiService.lounge.createPost(requestBody);
        if(response.status === 200 || response.status === 201){
            refreshLoungePosts();
          toast.success('Comment Created Successfully');
          setCommentText('');
      }
    } catch (error) {
      
    } finally{
      setIsSharing(false)
    }
  };

  const handleDeletePosts = async () => {
    try {
      setIsDeleting(true)
      const response = await toast.promise(
        apiService.lounge.deletePosts(data.post_id),
        {
          loading: 'Deleting Post...',
          success: 'Post Deleted Successfully',
          error: 'Error Deleting Post',
        }
      )
      if(response.status === 200 || response.status === 201){
        refreshLoungePosts();
        setShowOptionsMenu(false)
    }
    } catch (e) {
      
    } finally{
      setIsDeleting(false)
    }
  }

  const likePost = async () => {
    try {
      setIsLiked(true)
      setLikesCount((prevLikesCount) => prevLikesCount + 1);
      const requestBody = {
        post_id: data.post_id,
        rxn_type: 'like'
      }
      const response = await apiService.lounge.createReaction(requestBody);
      if(response.status === 200 || response.status === 201){
        refreshLoungePosts();
    }
    } catch (e) {
      
    }
  }

  const unLikePost = async () => {
    try {
        setIsLiked(false)
        setLikesCount((prevLikesCount) => prevLikesCount - 1);
      const response = await apiService.lounge.deleteReaction(data.post_id);
      if(response.status === 200 || response.status === 201){
        refreshLoungePosts();
    }
    } catch (e) {
      
    }
  }

  const getTimeDifference = (createdAt) => {
    const currentDate = new Date();
    const pastDate = new Date(createdAt);

    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (daysDifference > 0) {
      return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} min${minutesDifference > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  };

  const handleMenuItemClick = async (action, data) => {
    // Implement actions based on the selected menu item (e.g., delete or make admin)
    setSelectedData(data)
    if (action === 'delete') {
      await handleDeletePosts();
    }
    if(action === 'block'){
      handleMenuClose();
      handleOpenConfirm();
    }
    if(action === 'report'){
      handleMenuClose();
      handleOpen();
    }
  };

  const handleBlockUser = async (data) => {
    try {
      setIsBlocking(true)
      await toast.promise(
        apiService.user.blockUser({
          blocker_id: profile.userId,
          blocked_id: data.user_id
        }),
        {
          loading: 'Blocking user...',
          success: 'User blocked successfully',
          error: 'Error blocking user'
        }
      )
      refreshLoungePosts();
      handleMenuClose();
    } catch (e) {
      
    } finally{
      setIsBlocking(false)
    }
  }
  
  return (
    <>
    {data.userDetails && (
      <>
      <div className="post">
        <div className="postHeader">
            {/* <img src={data.userDetails.photoUrl || NO_PROFILE} alt={data.userDetails.photoUrl} /> */}
            <div className="postHeader-left">
            <Avatar alt={`${data.userDetails.firstName} ${data.userDetails.lastName}`} src={data.userDetails.photoUrl} />
            <div className='postHeader-userInfo'>
            <Link to={`/profile/${data.userDetails.userId}`}>
              <p className='userInfo-name'>{data.userDetails.firstName} {data.userDetails.lastName}</p>
              </Link>
              <p className='userInfo-location'>{data.userDetails.city}, {data.userDetails.country}</p>
            </div>
            </div>
            <div className="postHeader-right">
            <div className="postime">
            {getTimeDifference(data.created_at)}
            <span onClick={(e) => handleMenuOpen(e)}><MoreVert /></span>
            <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {data.user_id === profile.userId && (
                      <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('delete')}>
                        <Delete />
                        Delete
                        </MenuItem>
                        )}
                        {data.user_id !== profile.userId && (
                          <>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('report', data)}>
                        <Flag />
                        Report
                        </MenuItem>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('block', data)}>
                        <Block />
                        {isBlocking ? 'Blocking...' : 'Block'}
                        </MenuItem>
                        </>
                        )}
                    </Menu>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <Box className="comm-popup-modal">
                          <ReportPost refreshPosts={refreshLoungePosts} handleClose={handleClose} selectedData={selectedData}/>
                        </Box>
                      </Fade>
                    </Modal>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openConfirm}
                      onClose={handleCloseConfirm}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <ConfirmRequest
                        sendingAction={isBlocking}
                        title={'Block User?'}
                        text={`Are you sure you want to block ${data.userDetails.firstName} ${data.userDetails.lastName}?`}
                        onAction={() => handleBlockUser(data)} // Pass a function reference
                        onClose={handleCloseConfirm}
                        actionButton={'Block'}
                        sendingActionButton={'Blocking...'}/>
                      </Fade>
                    </Modal>
            </div>
            </div>
        </div>
        <Link to={`/lounge/post/${data.community_id}/${data.post_id}`} key={data.post_id}>
        <div className='detail'>
            <span>{data.message}</span>
        </div>
        </Link>
        {/* <img src={data.img} alt='' className='postImg' /> */}
        <div className="postReaction">
          <div className="postReaction-likes">
          {isLiked? 
                <Favorite style={{color: 'red'}} onClick={unLikePost}/> :
                <FavoriteBorder onClick={likePost}/>
            }
            <p>{likesCount} Likes</p>
          </div>
          <div className="postReaction-comments" onClick={toggleComments}>
          <Comment className='comments-icon'  />
          <p>{data?.comments_count <= 1 ? `${data?.comments_count} Comment` : `${data?.comments_count} Comments`}</p>
          </div> 
            {/* <Share/> */}
        </div>
        {/* <span>{data.likes} likes</span> */}
        {showComments && (
              <ShareLoungeComment commentText={commentText}
              handleCommentChange={handleCommentChange}
              handleCommentSubmit={handleCommentSubmit}
              data={data}
              isSharing={isSharing}
              comment={comment}
              profile={profile}
              setIsSharing={setIsSharing}
              loungeId={loungeId}
              />
            )}
    </div>
      </>
    )}
    </>
  )
}

export default LoungePost;