import { Backup, Cancel } from '@mui/icons-material'
import { Box, Button, Divider } from '@mui/material'
import React from 'react'
import './uploadDocument.css'
import toast from 'react-hot-toast'
import FileViewer from '@codesmith-99/react-file-preview'
import { MAX_IMG_SIZE, MAX_VIDEO_SIZE } from '../../utils/routes'

const UploadDocument = ({handleButtonClick, fileInputRef,
    selectedDocument, previewUrl, handleClose,
    handleFileChange, setShowPreview, setSelectedDocument}) => {
      const validTypes = [
        'image/jpeg', 'image/png', 'image/gif',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'video/mp4', 'video/quicktime'
      ];
      const handleFileValidation = (event) => {
        const file = event.target.files[0];
        
        const maxSize = file.type.startsWith('video/') ? MAX_VIDEO_SIZE : MAX_IMG_SIZE; // 15MB for videos, 2MB for others
    
        if (!validTypes.includes(file.type)) {
          toast.error('Invalid file type. Only jpg, png, xlsx, docx, doc, pdf, and mp4 files are allowed.');
          event.target.value = ''; // Reset the file input
          return;
        }
    
        if (file.size > maxSize) {
          const maxSizeInMB = maxSize / (1024 * 1024); // Convert MAX_IMG_SIZE to MB
          toast.error(`File size exceeds ${maxSizeInMB}MB limit.`);
          event.target.value = ''; // Reset the file input
          return;
        }
    
        handleFileChange(event);
      };

      const doneClicked = () => {
        setShowPreview(true);
        handleClose();
      }
  return (
    <Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '0 1rem 1rem 1rem'}}>
            <h2 style={{color: 'black'}}>Share media</h2>
            <Cancel sx={{cursor: 'pointer'}} onClick={handleClose}/>
        </Box>
        <Divider />
        <Box sx={{display: 'flex', flexDirection: 'column', padding: '1rem'}}>
        <input
            type="file"
            id="myFileInput"
            ref={fileInputRef}
            style={{ display: 'none' }} // Hide the actual file input
            onChange={handleFileValidation}
            accept={validTypes}
        />
        {/* <Button 
        startIcon={<AttachFileRounded />}
        sx={{color: 'white'}} className='button' onClick={handleButtonClick}>
            Select a File
        </Button> */}
        <Box className='select-media-box' onClick={handleButtonClick}>
          <Box sx={{ display: 'flex', flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center' }}>
            <Backup className='big-icon'/>
            <p style={{color: 'light.main'}}>Select media</p>
            <p style={{fontStyle: 'italic'}}>Only jpg, png, xlsx, docx, doc, pdf, and mp4 files are allowed.<br/>Max size is 5MB or 15MB for videos.</p>
          </Box>
        </Box>
        {selectedDocument && (
          <Box sx={{ marginTop: '1rem' }}>
            <p>Selected Document: {selectedDocument.name}</p>
            {previewUrl && (
              <Box sx={{ marginTop: '1rem', maxHeight: '300px', overflowY: 'auto' }}>
                {selectedDocument.type.startsWith('image/') && (
                  <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                )}
                {selectedDocument.type === 'application/pdf' && (
                  <FileViewer
                  src={previewUrl}
                  fileName='pdf'/>
                )}
                {selectedDocument.type.startsWith('text/') && (
                  <iframe
                    src={previewUrl}
                    title="Preview"
                    style={{ width: '100%', height: '200px', border: 'none', background: 'white' }}
                  ></iframe>
                )}
                {/* {(selectedDocument.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (
                    <FileViewer
                    src={previewUrl}
                    fileName='xlsx'
                    />
                )}
                {selectedDocument.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && (
                  <FileViewer
                  src={previewUrl}
                  fileName='docx'
                  />
                )} */}
              </Box>
            )}
          </Box>
        )}
        </Box>
        <Divider />
        <Box sx={{display: 'flex', justifyContent: 'flex-end', padding: '1rem 1rem 0 1rem', gap: '1rem'}}>
            {selectedDocument && (
              <Button onClick={()=> {setSelectedDocument(null)}} className='outline-button'>
                Clear
            </Button>)}
            <Button onClick={handleClose} className='outline-button'>
                Cancel
            </Button>
            <Button disabled={!selectedDocument} sx={{color: 'white'}} onClick={doneClicked} className='button'>
                Attach
            </Button>
        </Box>
    </Box>
  )
}

export default UploadDocument