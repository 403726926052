import React, { useState, useEffect } from 'react';
import './Posts.css';
import './communityPosts.css'
import Post from '../post/Post';
import useEmailService from '../../services/email-service';
import { useApiService } from '../../services/api-service';
import LoungePost from '../post/LoungePost';
import { useLoungePostsContext } from '../../contexts/LoungePostsContext';
import { LinearLoad } from '../../utils/routes';

const LoungePosts = ({loungeId}) => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getUserEmail } = useEmailService();
  const apiService = useApiService();
  const [loungePosts, setLoungePosts] = useState([]);
  const { refreshCount } = useLoungePostsContext();
  const [commentsMapping, setCommentsMapping] = useState({});
  const [communityComments, setCommunityComments] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const requestBody = {
          lounge_uuid: loungeId,
        };
        const response = await apiService.lounge.allPosts(requestBody);
        if (response.status === 200 || response.status === 201) {
          const commPosts = response.data.filter(post => !post.parent_id);
          // Use a set to store unique user IDs
          const userIdsSet = new Set();
    
          // Identify user IDs to fetch details for
          commPosts.forEach(post => userIdsSet.add(post.user_id));
    
          // Convert set to an array for easier manipulation
          const uniqueUserIds = Array.from(userIdsSet);
    
          // Throttle the number of concurrent requests to avoid overwhelming the server
          const concurrencyLimit = 5; // Adjust as needed
          const chunks = Array.from({ length: Math.ceil(uniqueUserIds.length / concurrencyLimit) }, (_, index) =>
            uniqueUserIds.slice(index * concurrencyLimit, (index + 1) * concurrencyLimit)
          );

          // Fetch user details in chunks
          const userDetailsChunks = await Promise.all(
            chunks.map(async (userIdsChunk) => {
              const userDetailsPromises = userIdsChunk.map(async (userId) => {
                try {
                  const userResponse = await apiService.profile.profileDetails({ user_id: userId });
                  return (userResponse.status === 200 || userResponse.status === 201) ? userResponse.data : null;
                } catch (error) {
                  return null; // Handle errors if needed
                }
              });
              return Promise.all(userDetailsPromises);
            })
          );
    
          // Flatten the array of chunks
          const userDetails = userDetailsChunks.flat();
          const postsWithComments = await Promise.all(
            commPosts.map(async (post) => {
              // Find user details for the current post
              const userDetailsForPost = userDetails.find(userDetail => userDetail && userDetail.userId === post.user_id);
    
              // Fetch comments for the post
              const commentsResponse = await apiService.lounge.allComments({ parent_id: post.post_id });
              const comments = (commentsResponse.status === 200 || commentsResponse.status === 201) ? commentsResponse.data : [];
    
              // Update comments with fetched user details and reaction status
              const commentsWithUserDetails = await Promise.all(
                comments.map(async (comment) => {
                  const commentUserDetails = userDetails.find(userDetail => userDetail && userDetail.userId === comment.user_id);
    
                  let commentLiked = false;
                  let commentReactionId = null;
    
                  try {
                    // Check if the comment has been liked
                    const commentReactionStatusResponse = await apiService.lounge.reactionStatus({ post_id: comment.post_id });
                    commentLiked = commentReactionStatusResponse.status === 200;
                    commentReactionId = commentLiked ? commentReactionStatusResponse.data.id : null;
                  } catch (error) {
                    // Handle 404 response (Comment not liked)
                    if (error.response && error.response.status === 404) {
                      commentLiked = false;
                      commentReactionId = null;
                    } else {
                      throw error; // Rethrow other errors
                    }
                  }
    
                  return { ...comment, userDetails: commentUserDetails, liked: commentLiked, reaction_id: commentReactionId };
                })
              );
    
              let liked = false;
              let reactionId = null;
    
              try {
                // Check if the post has been liked
                const reactionStatusResponse = await apiService.lounge.reactionStatus({ post_id: post.post_id });
                liked = reactionStatusResponse.status === 200;
                reactionId = liked ? reactionStatusResponse.data.id : null;
              } catch (error) {
                // Handle 404 response (Post not liked)
                if (error.response && error.response.status === 404) {
                  liked = false;
                  reactionId = null;
                } else {
                  throw error; // Rethrow other errors
                }
              }
    
              return { ...post, userDetails: userDetailsForPost, comments: commentsWithUserDetails, liked, reaction_id: reactionId };
            })
          );
          // Sort posts by createdAt in descending order
          postsWithComments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          console.log('lounge w posts', postsWithComments)
          setLoungePosts(postsWithComments);
        }
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [loungeId, refreshCount]);

  if (loading) {
    return LinearLoad();
  }

  return (
    <div className="community-posts">
  {loungePosts.length === 0 ? (
    <div className='post'>No records of posts found in the lounge.</div>
  ) : (
    loungePosts.map((post, id) => {
      const commentCount = commentsMapping[post.post_id]?.length || 0;
      const comment = commentsMapping[post.post_id];
      return (
          <LoungePost loungeId={loungeId} data={post} id={id} commentCount={commentCount} comment={comment} setCommentsMapping={setCommentsMapping} />
        
      );
    })
  )}
</div>
  )
}

export default LoungePosts;