import React, { useEffect, useState } from 'react'
import './chitfunds.css'
import { useApiService } from '../../services/api-service';
import { Box, Button, Chip, Stack, Tooltip } from '@mui/material';
import { DateRange, Description, Help, MonetizationOn, Person } from '@mui/icons-material';
import { LinearLoad, formatAmountWithCommas, getCurrentDate } from '../../utils/routes';
import toast from "react-hot-toast";
import RaiseMeKYC from '../raiseMeKYC/RaiseMeKYC';
import { useProfileService } from '../../services/profile-service';
import KYCVerification from '../KYCVerification/KYCVerification';
import RaiseMeAccount from '../RaiseMeAccount/RaiseMeAccount';
import AccountVerification from '../AccountVerification/AccountVerification';
import PendingVerification from '../PendingVerification/PendingVerification';
import useEmailService from '../../services/email-service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CreateChitFunds = ({uuid, onCancel}) => {
  const [isCreating, setIsCreating] = useState(false);
  const apiService = useApiService();
  const [step, setStep] = useState(1);
  const [chitData, setChitData] = useState({
    name: '',
    descr: '',
    start_date: null,
    group_size: null,
    period: null,
  });
  const { getUserEmail } = useEmailService();
  const [goalAmount, setGoalAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [email, setEmail] = useState('');
  const [Date, setDate] = useState('');
  const [gender, setGender] = useState('');
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [customerNumber, setCustomerNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [province, setProvince] = useState(null);
  const [city, setCity] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLGA, setSelectedLGA] = useState(null);
  const [location, setLocation] = useState(null);
  const [nextButtonStatus, setNextButtonStatus] = useState(false);
  const [kycStatus, setKycStatus]  = useState(false);
  const [kycMessage, setKycMessage] = useState('');
  const [serviceLocation, setServiceLocation] = useState('Canada');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [verifyUrl, setVerifyUrl] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [partnerStatus, setPartnerStatus] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankBranch, setBankBranch] = useState(null);
  const [interacEmail, setInteracEmail] = useState(null);
  const [interacPhone, setInteracPhone] = useState(null);
  const [accountType, setAccountType] = useState('');
  const [currency, setCurrency] = useState('');
  const [bvn, setBvn] = useState(null);
  const [useExistingAccount, setUseExistingAccount] = useState('yes');
  const [accountStatus, setAccountStatus] = useState(null);
  const profile = useProfileService();
  const [raiseMeId, setRaiseMeId] = useState(null);
  const [groupSize, setGroupSize] = useState(null);
  const history = useHistory();

  const handleGoalAmount = (e) => {
    // Remove non-numeric characters from the input value
    const formattedValue = e.target.value.replace(/[^\d]/g, '');
    // Update the state with the formatted value
    setGoalAmount(formattedValue);
  }

  const handleGroupSizeChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9]*$/; // Regular expression to allow only numeric characters
  
    if (regex.test(input)) {
      // If the input matches the numeric pattern, update the state
      setGroupSize(input);
    }
  };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the field is "group_size" and "period"
    const numericValue = (name === 'group_size' || name === 'period') ? parseInt(value) || '' : value;
    setChitData((prevChitData) => ({
        ...prevChitData,
        [name]: numericValue,
    }));
};

  const nextStepMFA = () => {
    setStep(step+2)
  }

  const handleKYCLocationChange = async (event) => {
    let selectedLocation = '';
    try {
      setLoading(true);
      selectedLocation = event.target.value;
      setLocation(selectedLocation);
  
      if (selectedLocation !== '') {
        setNextButtonStatus(true);
        const response = await apiService.raiseme.getKYCByLocation(selectedLocation);
        if (response.status === 200 || response.status === 201) {
          setKycStatus(true);
          setKycMessage(`You have a KYC in ${selectedLocation}`);
        } else {
          // Handle specifically when the response status isn't 200 or 201
          if (response.status === 404) {
            setKycStatus(false);
            setKycMessage(`You do not have a KYC in ${selectedLocation}`);
          } else {
            // Handle other non-success statuses if needed
            // For instance, you can add more specific error messages here
          }
        }
      } else {
        setNextButtonStatus(false);
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data["code "], {
          type: 'error',
          data: {
            msg: error.response.data["code "],
            type: 'error',
          },
        });
        if(error.response.status === 404){
          setKycStatus(false);
          setLocation(selectedLocation);
          setKycMessage(`You do not have a KYC in ${selectedLocation}. Create one`);
        }
      } 
    }finally {
      setLoading(false); // Set loading state to false when request completes
    }
  };

  const handleCreateKYC = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const requestBody = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        location: location,
        phone: phoneNumber,
        postal_code: postalCode,
        address_street: address,
        address_city: profile.profile.city,
        user_id: profile.profile.userId,
        country: location === 'Canada' ? 'CA' : location === 'Nigeria' ? 'NG' : ''
      }
      const response = await apiService.raiseme.createKYC(requestBody);
      const cusNumber = response.data.results.customer_number;
      const partnerStatus = response.data.results.partner_status;
      setCustomerNumber(cusNumber);
      setPartnerStatus(partnerStatus);
      if(response.status === 200 || response.status === 201){
        toast.success("KYC Created Successfully");
        setStep(step+1)
      }
    } catch(e){
      if(e.response){
        toast(e.response.data["code "], {
          type: "error",
          data: {
            msg: e.response.data["code "],
            type: "error",
          }
        });
      }else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
      }
    } finally{
      setIsSubmitting(false);
    }
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if(useExistingAccount){
        setStep(step+2);
      } else {
      const requestBody = {
        financial_institution_name: bankName,
        financial_institution_branch: bankBranch,
        account_number: accountNumber,
        account_type: accountType,
        bvn: bvn,
        currency: currency,
        interac_email: interacEmail,
        interac_phone_number: interacPhone,
        location: location,
        routing_transit_number: routingNumber,
        account_name: accountName
      }
      const response = await apiService.raiseme.createAccount(requestBody);
    if (response.status === 200 || response.status === 201) {
      toast.success("Account Created Successfully");
      const getAccountID = response.data.id;
      const verificationURL = response.data.verifyUrl;
      const getStatus = response.data.partnerStatus;
      setAccountId(getAccountID)
      setVerifyUrl(verificationURL);
      setAccountStatus(getStatus);
      setStep(step+1)
    }
  }
  } catch(e){
    if(e.response){
      toast.error(e.response.data["code "]);
    } else{
      toast("Network Error", {
        type: "error",
        data: {
          msg: "Network error, please check your internet connection.",
          type: "error",
        }
      });
    }
  } finally{
    setIsSubmitting(false);
  }
  }

  const checkVerification = async () => {
    try {
      setIsVerifying(true);
      const kycdata = await apiService.raiseme.getKYCByLocation(location);
      const partnerStatus = kycdata.data.partnerStatus;
      setPartnerStatus(partnerStatus);
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else{
        toast.error('Network Error!')
      }
    } finally {
      setIsVerifying(false);
    }
  }

  const addMemberToRaiseMe = async (raisemeid) => {
    const userEmail = await getUserEmail();
    try {
      const requestBody = {
        additional_service: "additionalService",
        email: userEmail,
        raiseme_id: raisemeid,
        user_id: profile.profile.userId,
        user_type: profile.profile.userType,
        account_id: accountId
      }
      const response = await apiService.raiseme.addMember(requestBody);
      if (response.status === 200 || response.status === 201) {
        history.push(`/community/chit/${uuid ?? ''}/${raisemeid}`)
      }
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data["code "]);
      } else {
        toast.error('Network Error');
      }
    }
  }

  const handleCreateChit = async (e) => {
    e.preventDefault();
    try {
      setIsCreating(true);
      const requestBody = {
        community_id: uuid,
        user_type: profile.profile.userType,
        raiseme_type: 'CHIT_FUND',
        name: chitData.name,
        description: chitData.descr,
        contribution_amount: goalAmount,
        period: "30",
        location: location,
        group_size: groupSize,
        start_date: chitData.start_date
      }
      const response = await apiService.raiseme.addRaise(requestBody);
      if(response.status === 201){
      const raiseMeId = response.data.id;
      setRaiseMeId(raiseMeId);
      await addMemberToRaiseMe(raiseMeId);
    toast.success("Chit Fund Created Successfully");
  }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      }else{
        toast.error('Network Error');
      }
    } finally{
      setIsCreating(false);
    }
  }

  useEffect(() => {
    if(step === 6)checkVerification();
  }, [step]);

  const nextStep = () => {
    setStep(step+1)
}

  const handleNext = () => {
    if(kycStatus){
      setStep(4)
    }else{
      setStep(step+1)
    }
  }
  return (
    <>
        <Box className='create-chit-container'>
        {step === 1 && (
            <>
            <Box sx={{display: 'flex', alignSelf: 'start'}}>
            <Chip label='1/6' size='small'
            sx={{backgroundColor: 'light.pending'}}
            />
            </Box>
            <form style={{width: '100%'}} onSubmit={handleNext}>
            <Stack direction={'column'} spacing={1} sx={{width: '100%'}}>
                <h2 className='h2-title'>Select Location</h2>
                <p>Select location you want chit to be in</p>
                <select value={location} onChange={handleKYCLocationChange}>
                    <option>Select Location</option>
                    <option value={'Canada'}>Canada</option>
                    <option value={'Nigeria'}>Nigeria</option>
                </select>
            </Stack>
            {loading ? (
                <>
                {LinearLoad()}
                </>
            ) : (
                <>
                {kycMessage && <p style={{ marginBottom: '1rem' }}>{kycMessage}</p>}
                {nextButtonStatus && (
                <Box className='create-aid-buttons'>
                        <Button sx={{color: 'white'}} className='button create-aid-button' type='submit'>{'Next'}</Button>
                        <Button sx={{color: 'white'}} className='button create-aid-button' onClick={onCancel}>Cancel</Button>
                </Box>
                )}
                </>
            )}
            </form>
            </>
        )}
        {step === 2 && (
        <>
        <Chip label='2/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
        <RaiseMeKYC onCancel={onCancel} handleCreateKYC={handleCreateKYC} step={step} setServiceLocation={setServiceLocation}
         firstName={firstName} setFirstName={setFirstName}
         lastName={lastName} setLastName={setLastName}
         middleName={middleName} setMiddleName={setMiddleName} email={email} setEmail={setEmail}
         Date={Date} setDate={setDate} location={location} setLocation={setLocation}
         gender={gender} setGender={setGender} countryCode={countryCode} setCountryCode={setCountryCode}
         phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} address={address} setAddress={setAddress}
         address2={address2} setAddress2={setAddress2} province={province} setProvince={setProvince}
         city={city} setCity={setCity} postalCode={postalCode} setPostalCode={setPostalCode}
         selectedCity={selectedCity} setSelectedCity={setSelectedCity} setSelectedLGA={setSelectedLGA} selectedLGA={selectedLGA}
         isSubmitting={isSubmitting}
         />
        </>
      )}
      {step === 3 && (
        <>
        <Chip label='3/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
        <KYCVerification customerNumber={customerNumber} firstName={firstName}
                          lastName={lastName} step={step} setStep={setStep} nextStep={nextStep}
                          />
        </>
      )}
      {step === 4 && (
        <>
        <Chip label='4/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
        <RaiseMeAccount onCancel={onCancel} setServiceLocation={setServiceLocation} serviceLocation={serviceLocation}
        accountName={accountName}
        setAccountName={setAccountName}
        accountNumber={accountNumber}
        setAccountNumber={setAccountNumber}
        routingNumber={routingNumber}
        setRoutingNumber={setRoutingNumber}
        bankName={bankName}
        setBankName={setBankName}
        handleCreateAccount={handleCreateAccount}
        bankBranch={bankBranch}
        setBankBranch={setBankBranch}
        interacEmail={interacEmail}
        setInteracEmail={setInteracEmail}
        accountType={accountType}
        setAccountType={setAccountType}
        currency={currency}
        setCurrency={setCurrency}
        bvn={bvn}
        setBvn={setBvn}
        location={location}
        setLocation={setLocation}
        setInteracPhone={setInteracPhone}
        interacPhone={interacPhone}
        raiseMeId={raiseMeId}
        setRaiseMeId={setRaiseMeId}
        setAccountId={setAccountId}
        accountId={accountId}
        nextStep={nextStepMFA}
        useExistingAccount={useExistingAccount}
        setUseExistingAccount={setUseExistingAccount}
        isSubmitting={isSubmitting}
        setAccountStatus={setAccountStatus} setVerifyUrl={setVerifyUrl}
        />
        </>
      )}
      {step === 5 && (
        <>
        <Chip label='5/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
          <AccountVerification verifyUrl={verifyUrl} nextStep={nextStep}/>
        </>
      )}
      {step === 6 && (
        <>
        {isVerifying ? (
            <>
            {LinearLoad()}
            </>
    ) : (
        <>
        {partnerStatus === 'AML check in progress' || accountStatus === null || partnerStatus === 'error' ? (
          <PendingVerification partnerStatus={partnerStatus} accountStatus={accountStatus} verifyUrl={verifyUrl} onCancel={onCancel}/>
        ): (
        <>
        <Chip label='6/6' size='small'
        sx={{backgroundColor: 'light.pending'}}
          />
       <form onSubmit={handleCreateChit}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '0.5rem', maxHeight: '400px', overflowY: 'auto'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <MonetizationOn className='big-icon'/>
                        </Box>
                    <h2 className='h2-title'>Create Chit Funds</h2>
                    <p>Chit funds offer a collective saving and borrowing platform where members pool their resources to achieve financial goals. It's a simple yet effective way to access funds for various needs, fostering community-driven financial empowerment and support.</p>
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Person/>
                                <label htmlFor='lounge-name'>Name:</label>
                            </Stack>
                            <input onChange={handleChange} value={chitData.name} className='create-aid-input' type="text" name="name" id='lounge-name' required />
                        </Box>
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Description />
                                <label htmlFor='lounge-description'>Description:</label>
                            </Stack>
                            <textarea
                                onChange={handleChange}
                                value={chitData.descr}
                                className='create-aid-input'
                                type="text"
                                name="descr"
                                id='lounge-description'
                                required
                            />
                        </Box>

                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <MonetizationOn />
                                <label htmlFor='lounge-target-amount'>Contribution Amount:</label>
                            </Stack>
                            <input
                                onChange={handleGoalAmount}
                                value={formatAmountWithCommas(goalAmount)}
                                className='create-aid-input'
                                type="text"
                                name="target_amt"
                                id='lounge-target-amount'
                                required
                            />
                        </Box>
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'} width={'100%'}>
                                <DateRange />
                                <label htmlFor="period">Group Size:</label>
                            </Stack>
                            <input className='create-aid-input' type="text" id="group-size" value={groupSize} onChange={handleGroupSizeChange} required />
                        </Box>
                        <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'} width={'100%'}>
                                <DateRange />
                                <label htmlFor='lounge-end-date'>Start Date:</label>
                                <Tooltip title="If end date isn't specified, the campaign would end once withdrawal is initiated">
                                    <Help sx={{marginLeft: 'auto', cursor: 'pointer'}}/>
                                </Tooltip>
                            </Stack>
                            <input
                                onChange={handleChange}
                                value={chitData.start_date}
                                className='create-aid-input'
                                type="date"
                                name="start_date"
                                id='lounge-end-date'
                                min={getCurrentDate()}
                            />
                        </Box>
                        {/* <Box>
                            <p style={{fontSize: '12px', fontStyle: 'italic'}}>If end date isn't specified, the campaign would end once withdrawal is initiated</p>
                        </Box> */}
                    </Box>
                    <Box className='create-aid-buttons'>
                        <Button disabled={isCreating} sx={{color: 'white'}} className='button create-aid-button' type='submit'>{isCreating ? 'Submitting...' : 'Submit'}</Button>
                        <Button sx={{color: 'white'}} className='button create-aid-button' onClick={onCancel}>Cancel</Button>
                    </Box>
            </form>
       </>
       )}
       </>
       )}
       </>
      )}
        </Box>
    </>
  )
}

export default CreateChitFunds