import { Box, Button, Container, Stack } from '@mui/material'
import React from 'react'
import './error404.css'

const Error404 = () => {
  return (
    <>
      <Container style={{height: '98vh'}}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
        <Stack alignItems={'center'} spacing={2}>
          <div className="oops-header">
            <h1>Oops!</h1>
            <p>404 - Page Not Found</p>
          </div>
          <div className="oops-description">
            <p>The page you are looking for might have been removed, had its url changed or is temporary unavailable.</p>
          </div>
          <Button href='/communities' style={{backgroundColor: '#a06ccb', color: 'white'}} variant='contained'>GO TO COMMUNITIES PAGE</Button>
        </Stack>
        </Box>
      </Container>
    </>
  )
}

export default Error404