import React, { useEffect, useRef } from 'react';

import './raiseMeInviteModal.css';
import '../../pages/OnBoarding/onboarding.css';
import { useProfileService } from '../../services/profile-service';
import { useState } from 'react';
import { useAuthService } from '../../services/auth-service';
import { useApiService } from '../../services/api-service';
import Skeleton from 'react-loading-skeleton';
import { NO_PROFILE } from '../../utils/routes';
import { Avatar, Button, Divider, LinearProgress, Stack } from '@mui/material';
import { Place, Send } from '@mui/icons-material';
import toast from 'react-hot-toast';

const RaiseMeInviteModal = ({ onCancel, selectedLounge, uuid }) => {
  const [invitationStatusMap, setInvitationStatusMap] = useState({});
  const modalRef = useRef(null);
  const [name, setName] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const apiService = useApiService();
  const { token } = useAuthService()
  const [loading, setLoading] = useState(false);
  const profile = useProfileService()
  const [isEmailFormat, setIsEmailFormat] = useState(false);
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const emailPattern = /^.{2,}@/;
    setIsEmailFormat(emailPattern.test(name));
  }, [name]);  

  const handleNameChange = (e) => {
    const inputName = e.target.value;
    setName(inputName);

    // Only trigger search if the input contains at least 3 characters
    if (inputName.length >= 3) {
      setLoading(true);
      searchProfiles(inputName);
    } else {
      setSearchResults([]);
    }
  };

  const searchProfiles = async (searchTerm) => {
    try {
      if (searchTerm.length < 3) {
        setSearchResults([]);
        return;
      }

      const response = await apiService.profile.search(searchTerm);
  
      if (response.status === 200 || response.status === 201) {
        const data = await response.data;
        setSearchResults(data);
      } else {
        // Handle non-200 status codes
        toast.error('Error searching for profiles');
      }
    } catch (error) {
      // Handle fetch or other errors
      toast('Error searching for profiles', {
        type: 'error',
        data: {
          msg: 'Error searching for profiles',
          type: 'error',
        },
      });
    }finally {
      setLoading(false); // Set loading to false when the request is completed (whether successful or not)
    }
  };  

  const getMemberEmail = async (userId) => {
    try {
      const requestBody = {
        user_id: userId
      };
      const response = await apiService.auth.getUserInfo(requestBody);
      if (response.status === 200) {
        return response.data.email; // Return the user's email from the response
      } else {
        return null; // Return null if the email retrieval fails
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    }
  };

  const handleEmailInvite = async(e) => {
    e.preventDefault();
    setIsSendingInvite(true);
    try {
      const request = {
        email: name
      }

      const responseEmail = await apiService.auth.verifyEmail(request);
      const receiverID = responseEmail.data.id;
      if(receiverID){
        // const requestBody = {
        //   comm_uuid: uuid,
        //   descr: 'ADD USER TO RAISEME',
        //   email: name,
        //   sender_id: profile.profile.userId,
        //   reciever_id: receiverID,
        //   notification_type: selectedLounge.raisemeType === 'CHIT_FUND' ? 'chitfundInvite' : 'raiseMeInvite',
        //   raisemeId: selectedLounge.id
        // };
        const requestBody = {
          comm_uuid: uuid,
          descr: 'ADD USER TO RAISEME',
          email: name,
          sender_id: profile.profile.userId,
          reciever_id: receiverID,
          notification_type: 'raiseMeInvite',
          raisemeId: selectedLounge.id,
          delivery_type: 2
        };
    
        const response = await toast.promise(
          apiService.notification.push(requestBody),
          {
            loading: 'Sending Invite...',
            success: 'User Invited Successfully',
            error: 'Error Inviting User',
          }
        )
        if (response.status === 201 || response.status === 200) {
          setName('');
        }
      } else {
        const requestBody = {
          raisemeId: selectedLounge.id,
          comm_uuid: uuid,
          descr: 'ADD USER TO RAISEME',
          email: name,
          sender_id: profile.profile.userId,
          reciever_id: 0,
          notification_type: 'raiseMeInvite',
          delivery_type: 2
        };
    
        const response = await toast.promise(
          apiService.notification.push(requestBody),
          {
            loading: 'Sending Invite...',
            success: 'User Invited Successfully',
            error: 'Error Inviting User',
          }
        )
        if (response.status === 201 || response.status === 200) {
          setName('');
        }
      }
    } catch (error) {
      
    } finally {
      setIsSendingInvite(false); // Reset loading state after request completion
    }
  }
  
  const handleInviteUser = async (e, user) => {
    e.preventDefault();

    setInvitationStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [user.user_id]: 'Inviting',
    }));

    try {
      // Get the user's email using the user_id
      const userEmail = await getMemberEmail(user.user_id);
      if (!userEmail) {
        // Handle the case where user email retrieval failed
        toast('Error getting user email', {
          type: 'error',
          data: {
            msg: 'Error getting user email',
            type: 'error',
          },
        });
        setInvitationStatusMap((prevStatusMap) => ({
          ...prevStatusMap,
          [user.user_id]: 'Invite',
        }));
        return;
      }
  
      // Use the retrieved userEmail in the requestBody
      const requestBody = {
        raisemeId: selectedLounge.id,
        comm_uuid: uuid,
        descr: 'ADD USER TO RAISEME',
        email: userEmail,
        sender_id: profile.profile.userId,
        reciever_id: user.user_id,
        notification_type: 'raiseMeInvite',
        delivery_type: 2
      };
  
      const response = await toast.promise(
        apiService.notification.push(requestBody),
        {
          loading: 'Sending Invite...',
          success: 'User Invited Successfully',
          error: 'Error Inviting User',
        }
      )
      if (response.status === 201 || response.status === 200) {
        setInvitationStatusMap((prevStatusMap) => ({
          ...prevStatusMap,
          [user.user_id]: 'Invited',
        }));
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
      setInvitationStatusMap((prevStatusMap) => ({
        ...prevStatusMap,
        [user.user_id]: 'Invite',
      }));
    }
  }; 

  return (
    <div className='create-community'>
      <div className='create-community-modal' ref={modalRef}>
        <h2 className='onboarding-text'>Invite User to My Collective Cash</h2>
        <form>
          <div className='form-group'>
            <input
              type='text'
              id='raiseme-email'
              placeholder='Enter Name/Email'
              value={name}
              onChange={handleNameChange}
              required
            />
          </div>
          {loading && (
            <>
            <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
            </>
          // <div className='search-skeleton'>
          //   <Skeleton animation='wave' height={50} /> {/* Wave animation for the img */}
          //   <Skeleton animation='wave' height={20} width='50%' style={{ marginBottom: 12 }} /> 
          // </div>
        )}
        {/* {!loading && searchResults.length === 0 && (
            <p>No records found</p>
          )} */}
          {!loading && searchResults.length > 0 && (
          <div className='search-results-container'>
            <h3>Search Results:</h3>
            <div className='search-results-scrollable'>
              <Stack direction={'column'} spacing={1}>
                {searchResults.map((user) => (
                  <>
                  <li className='search-list-item' key={user.id}>
                    <div className='search-user-details'>
                    <Avatar src={user.photo_url} alt={user.first_name} />
                    <div className='search-user-info'>
                      <span>
                        {user.first_name} {user.last_name}
                      </span>
                      <span className='search-location'>
                        <Place/>{user.city} {user.country}
                      </span>
                      </div>
                    </div>
                    <Button
                    endIcon={<Send />}
                    sx={{textTransform: 'capitalize', color: 'white'}}
                      className='button'
                      onClick={(e) => handleInviteUser(e, user)}
                      disabled={invitationStatusMap[user.user_id] === 'Invited' || invitationStatusMap[user.user_id] === 'Inviting'}
                    >
                      {invitationStatusMap[user.user_id] === 'Inviting'
                        ? 'Inviting...'
                        : invitationStatusMap[user.user_id] === 'Invited'
                        ? 'Invited'
                        : 'Invite'}
                    </Button>
                  </li>
                  <Divider/>
                  </>
                ))}
              </Stack>
            </div>
          </div>
        )}
          {isEmailFormat ? (
            <button
            className='button full-width-btn email-invite-btn'
            onClick={(e) => handleEmailInvite(e)}
            disabled={isSendingInvite} // Disable while sending invite
          >
            {isSendingInvite ? 'Inviting User...' : 'Invite E-mail'}
          </button>
          ) : (
          <div className='form-actions'>
          {Object.values(invitationStatusMap).includes('Invited') ? (
            <button className='button full-width-btn' onClick={onCancel}>
              Done
            </button>
          ) : (
            <button className='button full-width-btn' onClick={onCancel}>
              Cancel
            </button>
          )}
          </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default RaiseMeInviteModal;
