import React, { useEffect, useRef, useState } from 'react';
import { NO_PROFILE, containsBadWords, handleError } from '../../utils/routes';
import './shareComment.css';
import { Avatar, Backdrop, Badge, Box, Button, Divider, Fade, Modal } from '@mui/material';
import { AttachFileRounded, Comment, Download, EmojiEmotions, Favorite, FavoriteBorder } from '@mui/icons-material';
import { useApiService } from '../../services/api-service';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import EmojiPicker from 'emoji-picker-react';
import toast from 'react-hot-toast';
import UploadDocument from '../postShare/UploadDocument';
import ConfirmRequest from '../../utils/ConfirmRequest';
import FileViewer from '@codesmith-99/react-file-preview'

const ShareComment = ({ data, isSharing, comment, setCommunityPosts,
  profile, loadComments, setIsSharing, postComments, postId }) => {
  const [replyText, setReplyText] = useState(Array(comment?.length).fill(''));
  const apiService = useApiService();
  const [isLiked, setIsLiked] = useState({});
  const [likesCount, setLikesCount] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const containerRef = useRef(null);
  const [commentText, setCommentText] = useState('');
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [previewUrl, setPreviewUrl] = useState('');
  //open confirm request
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const uploadStatusRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('file', file);
    setSelectedDocument(file);

    if (file) {
      const url = URL.createObjectURL(file);
      console.log('url', url);
      setPreviewUrl(url);
    }
  };

   // Clean up the preview URL when the component is unmounted or the selected file changes
   useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [showPreview, setShowPreview] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [postID, setPostID] = useState();
  const [RB, setRB] = useState({});
  const handleOpen = () => {
    setShowPreview(false);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const fetchLikes = () => {
    if (postComments && postComments.length > 0) {
      const initialLikesStatus = {};
      const initialLikesCount = {};
      postComments.forEach(comment => {
        initialLikesStatus[comment.post_id] = comment.liked;
        initialLikesCount[comment.post_id] = comment.likes_count;
      });
      setIsLiked(initialLikesStatus);
      setLikesCount(initialLikesCount);
    }
  }

  useEffect(() => {
    fetchLikes();
  }, [postComments]);

  const uploadDocument = async (id) => {
    try {
      const formData = new FormData();
      formData.append('post_id', id);
      formData.append('media_url', selectedDocument);
      const response = await toast.promise(
        apiService.community.uploadDocument(formData),
        {
          loading: 'Uploading Media...',
          success: 'Media Uploaded',
          error: 'Error Uploading Media',
        }
      );
      uploadStatusRef.current = 'success';
      setSelectedDocument(null);
      return response;  // Return the response from the API
    } catch (e) {
      handleError(e);
      uploadStatusRef.current = 'failed';
    }
  };

  const handleCommentSubmit = async () => {
    // Implement the logic to submit the comment
    try {
      setIsSharing(true)
      if(containsBadWords(commentText)){
        return handleOpenConfirm();
      }

      if (uploadStatusRef.current === 'failed') {
        return await retryUpload();
      }
        const requestBody = {
            community_id: data.community_id,
            message: commentText,
            user_id: profile.userId,
            parent_id: data.post_id
        }
        setRB(requestBody)
        const response = await toast.promise(
          apiService.community.createPost(requestBody),
          {
            loading: 'Creating Comment...',
            success: 'Comment Created Successfully',
            error: 'Failed to Create Comment',
          }
        )
        if(response.status === 200 || response.status === 201){
          let uploadResponse = null;
          setPostID(response.data.id);
          if(selectedDocument){
            uploadResponse = await uploadDocument(response.data.id);
          }
          if (uploadStatusRef.current === 'success' || uploadStatusRef.current === null) {
            const newComment = {
              ...requestBody, // Assuming your API response contains necessary data for the new comment
              userDetails: profile, // Assuming profile contains necessary user details
              post_id: data.post_id, // Assuming the API returns the post ID of the new comment
              likes_count: 0,
              comments_count: 0,
              media_url: uploadResponse ? uploadResponse.data.mediaUrl : null,
              created_at: uploadResponse ? uploadResponse.data.createdAt : null
            };
              console.log('new comment', newComment)
              postComments.unshift(newComment);
              
              data.comments_count += 1;
              fetchLikes();
              setCommentText('');
          }
      }
    } catch (e) {
      handleError(e);
    } finally{
      setIsSharing(false)
    }
  };

  const handleForceCommentSubmit = async () => {
    // Implement the logic to submit the comment
    try {
      setIsSharing(true)
      console.log('hi')
      console.log(postComments);
      console.log(data);
        const requestBody = {
            community_id: data.community_id,
            message: commentText,
            user_id: profile.userId,
            parent_id: data.post_id
        }
        const response = await toast.promise(
          apiService.community.createPost(requestBody),
          {
            loading: 'Creating Comment...',
            success: 'Comment Created Successfully',
            error: 'Failed to Create Comment',
          }
        )
        if(response.status === 200 || response.status === 201){
          let uploadResponse = null;
          if(selectedDocument){
            uploadResponse = await uploadDocument(response.data.id);
          }
          const newComment = {
            ...requestBody, // Assuming your API response contains necessary data for the new comment
            userDetails: profile, // Assuming profile contains necessary user details
            post_id: data.post_id, // Assuming the API returns the post ID of the new comment
            likes_count: 0,
            comments_count: 0,
            media_url: uploadResponse ? uploadResponse.data.mediaUrl : null,
            created_at: uploadResponse ? uploadResponse.data.createdAt : null
          };
          postComments.unshift(newComment);
          
          data.comments_count += 1;
          fetchLikes();
          setCommentText('');
          handleCloseConfirm();
          setSelectedDocument(null);
          setPreviewUrl('');
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    } finally{
      setIsSharing(false)
    }
  };

  const retryUpload = async () => {
    try {
        let uploadResponse = null;
        uploadResponse = await uploadDocument(postID);
        console.log('retrying upload', uploadStatusRef.current)
        if (uploadStatusRef.current === 'success') {
          const newComment = {
            ...RB, // Assuming your API response contains necessary data for the new comment
            userDetails: profile, // Assuming profile contains necessary user details
            post_id: data.post_id, // Assuming the API returns the post ID of the new comment
            likes_count: 0,
            comments_count: 0,
            media_url: uploadResponse ? uploadResponse.data.mediaUrl : null,
            created_at: uploadResponse ? uploadResponse.data.createdAt : null
          };
            console.log('new comment', newComment)
            postComments.unshift(newComment);
            
            data.comments_count += 1;
            fetchLikes();
            setCommentText('');
        }
    } catch (e) {
        handleError(e);
    }
};

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
    autoResizeTextarea(event.target);
  };

  const handleReplyChange = (event, index) => {
    setReplyText((prevReplyText) => {
      const updatedReplyText = [...prevReplyText];
      updatedReplyText[index] = event.target.value;
      return updatedReplyText;
    });
  };

  const handleEmojiSelect = (emojiObject) => {
    setCommentText((prevCommentText) => prevCommentText + emojiObject.emoji);
    setShowEmojiPicker(false);
  };
  
  const likePost = async (postId) => {
    try {
      setIsLiked(prevIsLiked => ({
        ...prevIsLiked,
        [postId]: true
      }));
      setLikesCount(prevLikesCount => ({
        ...prevLikesCount,
        [postId]: prevLikesCount[postId] + 1
      }));

      const requestBody = {
        post_id: postId,
        rxn_type: 'like'
      };

      const response = await apiService.community.createReaction(requestBody);

      if (response.status === 200 || response.status === 201) {
        
      }
    } catch (e) {
      console.error('Error liking post:', e);
    }
  };

  const unLikePost = async (postId) => {
    try {
      setIsLiked(prevIsLiked => ({
        ...prevIsLiked,
        [postId]: false
      }));
      setLikesCount(prevLikesCount => ({
        ...prevLikesCount,
        [postId]: prevLikesCount[postId] - 1
      }));

      const response = await apiService.community.deleteReaction(postId);

      if (response.status === 200 || response.status === 201) {
        
      }
    } catch (e) {
      console.error('Error unliking post:', e);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) &&
        containerRef.current && !containerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPickerRef, containerRef]);

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = 'auto'; // Reset height to auto to calculate the new height correctly
    textarea.style.height = textarea.scrollHeight + 'px'; // Set height based on scroll height
  };

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea(textareaRef.current); // Initial resize on mount
    }
  }, []);

  return (
    <>
      <div className="commentField">
        <Avatar sx={{alignSelf:'flex-start'}} src={profile.photoUrl || NO_PROFILE} alt={data.userDetails?.photoUrl} />
        <div ref={containerRef} className="create-comment-container">
          <textarea
            ref={textareaRef}
            className="comments-textarea"
            placeholder="Write a comment..."
            value={commentText}
            onChange={handleCommentChange}
            rows={1}
          />
          {previewUrl && (
          <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
              {showPreview && selectedDocument && selectedDocument.type === 'application/pdf' && (
                  <FileViewer
                  fileName='pdf'
                  src={previewUrl}
                  />
                )}
                {showPreview && selectedDocument &&
                ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(selectedDocument.type) && (
                  <img
                    src={previewUrl}
                    title="Preview"
                    style={{
                      width: '200px',
                      height: '200px',
                      border: 'none',
                      background: 'white',
                      objectFit: 'contain',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                    sandbox="allow-same-origin"
                  ></img>
                )}
                {showPreview && selectedDocument && (selectedDocument.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  selectedDocument.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (
                    <p style={{textAlign: 'left', padding: '0 10px'}}>Selected Document: {selectedDocument.name}</p>
                )}
                </Box>
                )}
          <div className="create-comment-icons">
                <div className="option" onClick={handleOpen}>
                <Badge badgeContent={selectedDocument && showPreview ? 1 : 0} color='secondary' max={100}>
                    <AttachFileRounded />
                    </Badge>
                </div>
                <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <Box className="comm-popup-modal">
                          <UploadDocument fileInputRef={fileInputRef}
                          handleButtonClick={handleButtonClick}
                          handleFileChange={handleFileChange}
                          selectedDocument={selectedDocument}
                          previewUrl={previewUrl}
                          handleClose={handleClose}
                          setShowPreview={setShowPreview}
                          setSelectedDocument={setSelectedDocument}
                          />
                        </Box>
                      </Fade>
                </Modal>
                <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openConfirm}
                      onClose={handleCloseConfirm}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <ConfirmRequest
                        sendingAction={isSharing}
                        title={'Share Comment?'}
                        text={`Your post contains offensive language. Please confirm if you still wish to share it.`}
                        onAction={() => handleForceCommentSubmit()} // Pass a function reference
                        onClose={handleCloseConfirm}
                        actionButton={'Share Comment'}
                        sendingActionButton={'Sharing...'}/>
                      </Fade>
                    </Modal>
              <EmojiEmotions className='create-comment-icons-item emoji-icon'  onClick={() => setShowEmojiPicker((prev) => !prev)}/>
              <Button
              sx={{color: 'white'}}
                className="button create-post-btn"
                onClick={handleCommentSubmit}
                disabled={isSharing || commentText === ''}
            >
                {isSharing ? 'Sharing...' : 'Share'}
              </Button>
          </div>
          
        </div>
      </div>
      {postComments && postComments.length > 0 && postComments.map((comment, index) => (
        <>
        <div className="comments-list" key={index}>
          <Avatar src={comment.userDetails?.photoUrl || NO_PROFILE} alt={comment.userDetails?.photoUrl} />
          <div className="comment-container">
            <div className="comment">
              <Link to={`/profile/${comment.userDetails?.userId}`}>
                <p style={{ fontWeight: 'bold' }}>
                  {comment.userDetails?.firstName} {comment.userDetails?.lastName}
                </p>
              </Link>
            </div>
            <div className="comment-post">
              <Link to={`/community/post/${comment.community_id}/${comment.post_id}`} key={index}>
                <p className='alignLeft'>{comment.message}</p>
              </Link>
              <div className="postReaction">
                <div className="postReaction-likes">
                  {isLiked[comment.post_id] ? 
                    <Favorite style={{ color: 'red' }} onClick={() => unLikePost(comment.post_id)} /> :
                    <FavoriteBorder onClick={() => likePost(comment.post_id)} />
                  }
                  <p>
                    {likesCount[comment.post_id] <= 1 ? `${likesCount[comment.post_id]} Like` : `${likesCount[comment.post_id]} Likes`}
                    </p>
                </div>
                {/* <div className="postReaction-comments" onClick={() => toggleReply(comment.post_id)}>
                  <Comment className='comments-icon' />
                  <p>{comment.comments_count <= 1 ? `${comment.comments_count} Comment` : `${comment.comments_count} Comments`}</p>
                </div> */}
                <Link to={`/community/post/${comment.community_id}/${comment.post_id}`} key={index}>
                <div className="postReaction-comments">
                  <Comment className='comments-icon' />
                  <p>{comment.comments_count <= 1 ? `${comment.comments_count} Comment` : `${comment.comments_count} Comments`}</p>
                </div>
                </Link>
                {comment.media_url && (
                  <a href={comment.media_url} target='_blank'>
                  <div className='postReaction-comments'>
                    <Download />
                    Download attachment
                  </div>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <Divider sx={{margin: '7px 0'}}/>
        </>
      ))}
      {showEmojiPicker && (
        // <Picker data={data} onEmojiSelect={console.log} />
        <div ref={emojiPickerRef}>
        <EmojiPicker
          open
          onEmojiClick={handleEmojiSelect}
          emojiStyle='apple'
          searchPlaceholder='Search for emoji...'
          suggestedEmojisMode='frequent'
          style={{ position: 'absolute', backgroundColor: 'white', top: '-25px', right: '10px' }}
        />
        </div>
      )}
    </>
  );
};

export default ShareComment;
