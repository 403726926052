import { Group, Help } from '@mui/icons-material'
import { Avatar, Box, Button, Divider, Stack } from '@mui/material'
import React, { useState } from 'react'
import { capitalizeFirstLetter } from '../../utils/routes'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";

const MigratingAdminComm = ({senderDetails, community, votes,
    profile, fetchVotes}) => {
    const apiService = useApiService();
    const [isUpgradingCommType, setIsUpgradingCommType] = useState(false);

    const requestToUpgradeCommType = async () => {
        try {
          setIsUpgradingCommType(true);
          const requestBody = {
            sender_id: profile.profile.userId,
            notification_type: 'upgradeToAdminManagedCommInvite',
            comm_uuid: community.commUuid,
            delivery_type: 2
          }
          const response = await apiService.notification.push(requestBody);
          if (response.status === 201 || response.status === 200) {
            toast.success('Upgrade Request Sent Successfully');
            fetchVotes();
          }
        } catch (e) {
          toast.error(e.response.data["code "]);
          toast.error(e.response.data["error"]);
        } finally{
          setIsUpgradingCommType(false);
        }
      }
  return (
    <>
    {votes ? (
        <>
        {senderDetails && (
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
            <Stack direction={'row'} spacing={1}>
            <p className='alignLeft' style={{textAlign: 'left', fontSize: '1rem', fontWeight: 'bold'}}>Migrating to Admin-Managed</p>
            </Stack>
            <p style={{textAlign: 'left'}}>{senderDetails.firstName} {senderDetails.lastName} has made proposal to migrate {community.commName} from Democratized to Admin-Managed Community. This community will be migrated when the required community member votes is achieved.</p>
            <Box sx={{display: 'flex', gap: '10px'}}>
                <Avatar src={senderDetails.photoUrl} alt={senderDetails.firstName}>
                    {capitalizeFirstLetter(senderDetails.firstName)}
                </Avatar>
                <Stack direction={'column'}>
                    <p style={{textAlign: 'left'}}>Proposed By</p>
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>{senderDetails.firstName} {senderDetails.lastName}</p>
                </Stack>
            </Box>
            {votes && (
                <Box>
                <Divider />
                <Box sx={{display: 'flex', width: '100%', gap: '10px', marginTop: '10px'}}>
                    <Group />
                    <Stack direction={'column'} width={'100%'} spacing={0.5}>
                    <p style={{textAlign: 'left'}}>{votes.requiredEndorsement - votes.endorsementCount} vote(s) left</p>
                    <progress className='aid-lounge-progress' value={votes.endorsementCount} max={votes.requiredEndorsement} />
                    </Stack>
                </Box>
            </Box>
            )}
        </Box>
        )}
        </>
    ) : (
        <>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
        <p className='alignLeft' style={{textAlign: 'left', fontSize: '1rem', fontWeight: 'bold'}}>Migrate to Admin-Managed</p>
        <p style={{textAlign: 'left'}}>The Admin-Managed Community is overseen and controlled exclusively by the admin, who holds all authority. Migration is irreversible and requires voting process by the community members for approval.</p>
        <Button onClick={requestToUpgradeCommType} sx={{color: 'white', width: 'fit-content'}} className='button' disabled={isUpgradingCommType}>{isUpgradingCommType ? 'Requesting...' : 'Migrate to Admin-Managed Community'}</Button>
        </Box>
        </>
    )}
    </>
  )
}

export default MigratingAdminComm