import { ArrowBack, CreditCardOutlined, Download, Email, MonetizationOn, Settings, TrendingFlat, Upload } from '@mui/icons-material'
import { Backdrop, Box, Button, Checkbox, Chip, CircularProgress, Divider, Fade, FormControlLabel, Modal, Radio, Stack, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";
import AccountsDropdown from '../RaiseMeAccount/AccountsDropdown'
import { formatAmountWithCommas } from '../../utils/routes'
import WithdrawWallet from './WithdrawWallet'
import TopUpWallet from './TopUpWallet'
import WalletHistory from './WalletHistory'
import ViewStatements from './ViewStatements';
import './communityWallet.css'

const CommunityWallet = ( {community, isUserAdmin, isAdminComm, profile} ) => {
    const apiService = useApiService();
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState();
    const [showMainScreen, setShowMainScreen] = useState(true);
    const [showTopUpScreen, setShowTopUpScreen] = useState(false);
    const [showWithdrawScreen, setShowWithdrawScreen] = useState(false);
    const [isFetchingAccount, setIsFetchingAccount] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState("");
    const [location, setLocation] = useState('Canada');
    const [accountName, setAccountName] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [routingNumber, setRoutingNumber] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [bankBranch, setBankBranch] = useState(null);
    const [interacEmail, setInteracEmail] = useState(null);
    const [interacPhone, setInteracPhone] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [accountType, setAccountType] = useState('');
    const [verifyUrl, setVerifyUrl] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);
    const [currency, setCurrency] = useState('');
    const [bvn, setBvn] = useState(null);
    const [existingAccounts, setExistingAccounts] = useState([]);
    const [amount, setAmount] = useState('');
    const [contributionAmount, setContrinutionAmount] = useState('');
    const [intAmount, setIntAmount] = useState();
    const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [checked, setChecked] = React.useState(false);
    const [isContributing, setIsContributing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState();
    const [walletHistory, setWalletHistory] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleTopUpClick = () => {
        setShowMainScreen(false);
        setShowTopUpScreen(true);
    };

    const handleWithdrawClick = () => {
        setShowMainScreen(false);
        setShowWithdrawScreen(true);
    };

    const handleBackButtonClick = () => {
        setShowMainScreen(true);
        setShowTopUpScreen(false);
        setShowWithdrawScreen(false);
        fetchData();
    };

    const handleAmount = (e) => {
        // Remove non-numeric characters from the input value
        const formattedValue = e.target.value.replace(/[^\d]/g, '');
        // Update the state with the formatted value
        setAmount(formattedValue);
        setIntAmount(formattedValue);
        console.log(amount, intAmount)
    }

    const handleContributionAmount = (e) => {
        // Remove non-numeric characters from the input value
        const formattedValue = e.target.value.replace(/[^\d]/g, '');
        // Update the state with the formatted value
        setContrinutionAmount(formattedValue);
    }

    const handleChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleFirstName = (e) => {
        const name = e.target.value;
            setFirstName(name);
    }

    const handleLastName = (e) => {
        const name = e.target.value;
        setLastName(name);
    }

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
      };

    const fetchData = async () => {
        try {
            setLoading(true);
            const [accountsResponse, walletHistoryResponse] = await Promise.all([
                apiService.raiseme.getAccount(),
                apiService.community.getWalletHistory({comm_uuid: community.commUuid})
            ]);

            if(accountsResponse.status === 200 || accountsResponse.status === 201){
                setExistingAccounts(accountsResponse.data);
            }

            if(walletHistoryResponse.status === 200 || walletHistoryResponse.status === 201){
                setWalletHistory(walletHistoryResponse.data);
            }

            const balanceResponse = await apiService.community.getBalance({comm_uuid: community.commUuid});

            //Handle balance
            if(balanceResponse.status === 200 || balanceResponse.status === 201){
                setBalance(balanceResponse.data);
            }

            
        } catch (e) {
            if(e.response){
                if(e.response.status === 404){
                    fetchAccounts();
                }else {
                    toast.error(e.response.data["code "]);
                }
              }else{
                toast.error("Network Error")
              }
        } finally{
            setLoading(false);
        }
    }

    const handleSelectAccount = async (accountId) => {
        if (accountId) {
          if (accountId.location !== location) {
            toast.error("Account location doesn't match raiseMe location.");
          } else {
            // Set state values using the selected account's properties
            setAccountName(accountId.account_name);
            setAccountNumber(accountId.account_number);
            setAccountType(accountId.account_type);
            setBvn(accountId.bvn);
            setCurrency(accountId.currency);
            setBankName(accountId.financial_institution_name);
            setBankBranch(accountId.financial_institution_branch);
            setInteracEmail(accountId.interac_email);
            setInteracPhone(accountId.interac_phone_number);
            setLocation(accountId.location);
            setRoutingNumber(accountId.routing_transit_number);
            setAccountId(accountId.id);
            setAccountStatus(accountId.partner_status);
            setVerifyUrl(accountId.verify_url);
          }
      } else {
        setAccountId(null)
      }
      };

    const fetchAccounts = async () => {
        try {
          setLoading(true);
          const response = await apiService.raiseme.getAccount();
          const responseData = response.data;
            setExistingAccounts(responseData);
        } catch (e) {
          if(e.response){
            if(e.response.status !== 404){
              toast.error(e.response.data["code "]);
            }
          } else {
            toast.error('Network Error');
        }
        } finally{
          setLoading(false);
        }
      };

    useEffect(() => {
        fetchData();
      }, [community.commUuid]);

      let filteredAccounts = [];
      let availableBalance = balance ? balance.availableBalance : 0;

    if(existingAccounts){
    filteredAccounts = existingAccounts.filter(account => account.location === 'Canada');
    }
  return (
    <>
    {loading ? (
        <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress style={{ color: '#a06ccb'}}/>
        <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
        </Box>
    ) : (
        <>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 1rem', gap: '1rem' }}>
            {showMainScreen && (
                <>
                <Box>
                    <p>Main Balance</p>
                    <h2 className='purple-text withdrawal-balance'>
                    {community.commBalanceVisibility || isUserAdmin ? `$${availableBalance}` : '****'}
                    </h2>
                </Box>
                <Stack direction={'row'} display={'flex'} divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                    <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', cursor: 'pointer' }} onClick={handleTopUpClick}>
                        <Upload />
                        <p>Top Up</p>
                    </Stack>
                    {isAdminComm ? (
                        <>
                        {isUserAdmin && (
                            <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', cursor: 'pointer' }} onClick={handleWithdrawClick}>
                            <Download />
                            <p>Withdraw</p>
                            </Stack>
                        )}
                        </>
                    ) : (
                        <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', cursor: 'pointer' }} onClick={handleWithdrawClick}>
                            <Download />
                            <p>Withdraw</p>
                        </Stack>
                    )}
                </Stack>
                <Box sx={{ width: '100%', backgroundColor: '#f4edfa', borderRadius: '5px', padding: '5px 7px', marginTop: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3 style={{ fontSize: '15px' }}>Latest Transactions</h3>
                            {walletHistory && walletHistory.length > 0 && (
                                <p onClick={handleOpen} className='purpleHover' style={{ fontSize: '.775rem', cursor: 'pointer', fontWeight: 'bold' }}>View all</p>
                            )}
                        </Box>
                        
                        {walletHistory && (
                            <>
                            {walletHistory.length === 0 ? (
                                <Box sx={{ minHeight: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                                <p>No Transactions Recorded</p>
                                </Box>
                                </Box>
                            ) : (
                                <WalletHistory walletHistory={walletHistory}/>
                            )}
                            </>
                        )}
                </Box>
                </>
            )}

            {showTopUpScreen && (
                <TopUpWallet
                handleEmail={handleEmail}
                email={email}
                firstName={firstName}
                lastName={lastName}
                handleFirstName={handleFirstName}
                handleLastName={handleLastName}
                handleCheckboxChange={handleCheckboxChange}
                isContributing={isContributing}
                handleChange={handleChange}
                contributionAmount={contributionAmount}
                handleContributionAmount={handleContributionAmount}
                paymentMethod={paymentMethod}
                handleBackButtonClick={handleBackButtonClick}
                checked={checked}
                community={community}
                setIsContributing={setIsContributing}
                />
            )}

            {showWithdrawScreen && (
                <>
                    <WithdrawWallet availableBalance={availableBalance}
                    filteredAccounts={filteredAccounts}
                    selectedAccount={selectedAccount}
                    setIsWithdrawing={setIsWithdrawing}
                    community={community}
                    profile={profile}
                    isAdminComm={isAdminComm}
                    setSelectedAccount={setSelectedAccount} handleSelectAccount={handleSelectAccount} isWithdrawing={isWithdrawing} amount={amount} intAmount={intAmount} handleAmount={handleAmount} handleBackButtonClick={handleBackButtonClick}/>
                </>
            )}
        </Box>
        </>
    )}
                            <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                timeout: 500,
                                },
                            }}>
                            <Fade>
                                <Box className="statement-popup-modal">
                                <ViewStatements walletHistory={walletHistory} community={community} handleClose={handleClose}/>
                                </Box>
                            </Fade>
                            </Modal>
    </>
    
  )
}

export default CommunityWallet