import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useApiService } from '../../services/api-service';
import { Avatar, Backdrop, Box, Button, CircularProgress, Fade, Modal, Stack, Tooltip } from '@mui/material';
import Header from '../../components/header/Header';
import UserProfile from '../../components/userProfile/UserProfile';
import './profile.css'
import { CalendarToday, Edit, Error, Person, Place, Share } from '@mui/icons-material';
import SharePost from '../../components/Share/SharePost';
import { NO_COVER_IMG } from '../../utils/routes';
import EditProfile from '../../components/Profile/EditProfile';
import { useProfileService } from '../../services/profile-service';
import ErrorComponent from '../../components/errors/ErrorComponent';
import toast from 'react-hot-toast';

const Profile = () => {
    const {id} = useParams(); //Access the ID paramater
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const profile = useProfileService();
    const apiService = useApiService();
    const [openShare, setOpenShare] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const url = window.location.href;
    const message = 'Error Loading Profile';
    let isProfile;
    let intID;
    const [isCopied, setIsCopied] = useState(false);
    const handleOpenShare = () => setOpenShare(true);
    const handleCloseShare = () => setOpenShare(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleCopyLink = () => {
        navigator.clipboard.writeText(url)
        setIsCopied(true)
        toast.success("Link Copied to Clipboard")
      }
    const fetchProfile = async () => {
        try {
            setLoading(true);
            const response = await apiService.profile.profileDetails({user_id : id});
            setUserInfo(response.data);
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
                if(e.response.status === 404){
                  setUserInfo(null);
                }
              }else{
                toast.error("Network Error")
              }
        } finally{
            setLoading(false);
        }
    }

    let formattedDate;

    if(userInfo){
    formattedDate = new Date(userInfo.createdAt).toLocaleDateString('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
      });
    }

    useEffect(() => {
        fetchProfile();
    }, []);
    if(profile){
      isProfile = parseInt(id) === profile.profile.userId;
    }
  return (
    <>
    {loading ? (
        <Box gap={'0.5rem'} height={'100vh'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress style={{ color: '#a06ccb'}}/>
        <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
      </Box>
    ) : (
        <>
        <Header />
        <>
        <div className='communityWrapper'>
            <div className='community-sidebar'>
              <UserProfile />
            </div>
            {userInfo && userInfo.firstName && userInfo.lastName &&
            userInfo.city && userInfo.country && formattedDate
             ? (
            <Box className='profile-wrapper'>
                <Box className='bio-cover-wrapper'>
                  <img className='bio-cover-img' src={NO_COVER_IMG} />
                  <Box className='profile-wrapper-left'>
                    <Avatar className='bio-img' src={userInfo.photoUrl} />
                  </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box sx={{padding: '3rem 1rem 1rem 1rem', display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    <h2 className='blackText'>{userInfo.firstName} {userInfo.lastName}</h2>
                    <p className='alignLeft bio-text'>{userInfo.bio || 'No bio'}</p>
                    <Stack spacing={1} direction={'row'} display={'flex'} alignItems={'center'}>
                      <Place/> 
                      <p style={{color: 'rgb(0 0 0/.6)'}} className='alignLeft'>{userInfo.city}, {userInfo.country}</p>
                    </Stack>
                    <Stack spacing={1} direction={'row'} display={'flex'} alignItems={'center'}>
                      <Person/>
                      <p style={{color: 'rgb(0 0 0/.6)', textTransform: 'capitalize'}} className='alignLeft'>{userInfo.gender}</p>
                    </Stack>
                    {/* <Stack spacing={1} direction={'row'} display={'flex'} alignItems={'center'}>
                      <CalendarToday />
                      <p style={{color: 'rgb(0 0 0/.6)'}} className='alignLeft'>Birthday {formattedBOD}</p>
                    </Stack> */}
                    <Stack spacing={1} direction={'row'} display={'flex'} alignItems={'center'}>
                      <CalendarToday />
                      <p style={{color: 'rgb(0 0 0/.6)'}} className='alignLeft'>Created {formattedDate}</p>
                    </Stack>
                    </Box>
                    <Box sx={{paddingRight: '10px', display: 'flex', gap: '20px'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center'}}>
                      <Tooltip title='Share Link'>
                        <div className='add-circle-icon'>
                      <Share sx={{cursor: 'pointer', color: 'light.main'}} onClick={handleOpenShare}/>
                      </div>
                      </Tooltip>
                      <p className='purple-text'>Share</p>
                      <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openShare}
                    onClose={handleCloseShare}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                    <SharePost onCancel={handleCloseShare} url={url} handleCopyLink={handleCopyLink} isCopied={isCopied}/>
                            </Fade>
                        </Modal>
                      </Box>
                      {isProfile && (
                      <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center'}}>
                      <Tooltip title='Edit Profile'>
                        <div className='add-circle-icon'>
                      <Edit sx={{cursor: 'pointer', color: 'light.main'}} onClick={handleOpenEdit}/>
                      </div>
                      </Tooltip>
                      <p className='purple-text'>Edit</p>
                      <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openEdit}
                    onClose={handleCloseEdit}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                    <EditProfile userInfo={userInfo} handleCloseEdit={handleCloseEdit} fetchProfile={fetchProfile}/>
                            </Fade>
                        </Modal>
                      </Box>
                      )}
                      </Box>
                </Box>
            </Box>
             ) : (
              <>
              <ErrorComponent message={message}/>
              </>
             )}
          </div>
        </>
        </>
    )}
    </>
  )
}

export default Profile