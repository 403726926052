import React from 'react'
import Header from '../../components/header/Header'
import RaiseMeCard from '../../components/RaiseMeCard/RaiseMeCard';
import { useState } from 'react';
import UserProfile from '../../components/userProfile/UserProfile';
import { Chair } from '@mui/icons-material';
import ChitFundCard from '../../components/ChitFunds/ChitFundCard';

const RaiseMeContributions = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showMyRaisemes, setShowMyRaisemes] = useState(true);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // const handleTabChange = () => {
  //   setShowMyRaisemes(!showMyRaisemes);
  // };
  return (
    <>
    <Header />
    <div className="community-details-wrapper">
        <div className='community-details-sidebar'>
          <UserProfile />
        </div>
        <div className='community-details-container'>
          <div className="section-banner">
            <Chair />
            <div>
              <h2>My RaiseMes</h2>
              <p style={{textAlign: 'left'}}>All the raiseme you belong!</p>
            </div>

          </div>

          <div className="lounge-navigation">
            <div className="section-navigation-left">
              <input
                className='search-box'
                type="text"
                placeholder='Search RaiseMe'
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div className="section-navigation-right">
              <div
              className={`section-navigation-tab ${showMyRaisemes ? 'active' : ''}`}
            >
              <p>My Collective Cash</p>
            </div>
            {/* <div
              className={`section-navigation-tab ${!showMyRaisemes ? 'active' : ''}`}
              onClick={handleTabChange}
            >
              <p>My Chit Funds</p>
            </div> */}
            </div>
          </div>


          {/* <div className="grid">
          {showMyRaisemes ? <RaiseMeCard searchQuery={searchQuery} /> : <ChitFundCard searchQuery={searchQuery} />}
        </div> */}
        <div className="grid">
          <RaiseMeCard searchQuery={searchQuery} />
      </div>
        </div>
      </div>
    </>
    
    
  )
}

export default RaiseMeContributions