import { Avatar, Box, Button, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import InputFieldError from '../../components/forms/inputFieldError/input-field-error';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import * as Yup from 'yup';
import { capitalizeFirstLetter } from '../../utils/routes';

const RegisterScreen = (
    {
        handleRegister, initialValues, handleLoginClick,
        showConfirmPassword, setShowConfirmPassword, PASSWORD,
        EMAIL, showPassword, setShowPassword, user, community
    }
) => {
  return (
    <>
     {user && user.firstName ? (
        <>
        <Avatar src={user.photoUrl} sx={{width: '75px', height: '75px'}}/>
                    <h2 className='text-md'>{user.firstName} {user.lastName} has invited you to join</h2>
                    <Stack direction={'row'} spacing={0.5}>
                      <Avatar sx={{width: '30px', height: '30px'}} variant='rounded'>
                        {capitalizeFirstLetter(community.commName)}
                      </Avatar>
                      <h2 className='title-md'>{community.commName} Community</h2>
                      <p></p>
                    </Stack>
        <Box sx={{padding: '15px', display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%'}}>
                      <Formik
                                onSubmit={handleRegister}
                                initialValues={initialValues}
                                validationSchema={Yup.object().shape({
                                  [EMAIL]: Yup.string().email('Invalid email').required('Required'),
                                  [PASSWORD]: Yup.string()
                                  .min(11, 'Password is too short')
                                  .matches(
                                    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                                    'Password must have at least one uppercase letter, one number, and one special character'
                                  )
                                  .required('Required'),
                                  confirmPassword: Yup.string()
                                    .oneOf([Yup.ref(PASSWORD), null], 'Passwords must match')
                                    .required('Required'),
                                })}
                              >
                                {({ handleChange, handleSubmit, values, errors, touched, isSubmitting, handleBlur, isValid }) => (
                                <Form style={{display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%'}}>
                                  <Stack spacing={1}>
                                    <h2 className='sub-header-popup'>E-mail</h2>
                                    <input
                                    type='email'
                                    placeholder='Email'
                                    name={EMAIL}
                                    value={values[EMAIL]}
                                    className='create-aid-input'
                                    onChange={handleChange}
                                  />
                                  {errors[EMAIL] && (
                                    <InputFieldError errorText={errors[EMAIL]} />
                                  )}
                                  </Stack>
                                  <Stack className='login-password-container' spacing={1}>
                                    <h2 className='sub-header-popup'>Password</h2>
                                    <input
                                        className='create-aid-input'
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Password'
                                        name={PASSWORD}
                                        value={values[PASSWORD]}
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                      />
                                      {values[PASSWORD] && (
                                        <button
                                          type="button"
                                          className="password-toggle-button"
                                          onClick={() => setShowPassword(!showPassword)}
                                        >
                                          {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                        </button>
                                      )}
                                      {errors[PASSWORD] && (
                                        <InputFieldError errorText={errors[PASSWORD]} />
                                      )}
                                </Stack>
                                <Stack className='login-password-container' spacing={1}>
                                  <h2 className='sub-header-popup'>Confirm Password</h2>
                                  <input
                                    className='create-aid-input'
                                    type={showConfirmPassword ? 'text' : 'password'}
                                      placeholder='Confirm Password'
                                      name='confirmPassword'
                                      value={values.confirmPassword}
                                      onChange={handleChange}
                                    />
                                    {values['confirmPassword'] && (
                                      <button
                                        type="button"
                                        className="password-toggle-button"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                      >
                                        {showConfirmPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                      </button>
                                    )}
                                    {errors.confirmPassword && (
                                  <InputFieldError errorText={errors.confirmPassword} />
                                    )}
                                </Stack>
                                <Stack direction={'row'} spacing={1} alignItems={'flex-start'}>
                                  <input type="checkbox" name="" id="" required/>
                                    <p style={{display: 'inline'}} className='privacy-policy-text'>By creating an account, you agree to our 
                                     <a style={{textDecoration: 'underline', color: '#a06ccb'}} target='_blank' rel='noreferrer' href='https://myareaa.com/terms.html'> terms and conditions.</a>
                                    </p>
                                </Stack>
                                <Button type='submit' className='sign-in-btn'
                                disabled={isSubmitting || !isValid} style={{color: 'white', backgroundColor: '#a06ccb'}}
                                >
                                  {isSubmitting ? 'Registering...' : 'Register'}
                                </Button>
                              </Form>
                            )}
                          </Formik>
                          <p>
                          Already have an account?
                            <span style={{color: '#a06ccb', textDecoration: 'underline', cursor: 'pointer'}} onClick={handleLoginClick}>Sign In</span>
                        </p>
                      </Box>
        </>
     ) : (
        <>
        <Button className='button' sx={{color: 'white'}}>Retry</Button>
        </>
     )}
    </>
  )
}

export default RegisterScreen