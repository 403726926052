import { Box, Divider, Stack } from '@mui/material'
import React from 'react'
import { formatCurrency, getTimeDifference } from '../../utils/routes'

const WalletHistory = ({walletHistory}) => {
    console.log(walletHistory)
    const sortedWalletHistory = walletHistory.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  return (
    <Box sx={{width: '100%', maxHeight: '150px', overflowY: 'scroll'}}>
        {sortedWalletHistory.map((data, index) => (
            <>
                {data.first_name && data.last_name && data.created_at && data.contrib_status && data.amount ? (
                    <>
                    <Box key={index} sx={{width: '100%', margin: '5px 0'}}>
                    <Box sx={{display: 'flex', alignItems: 'flex-start', width: '100%', justifyContent: 'space-between'}}>
                        <Stack direction={'column'} alignItems={'flex-start'}>
                            <p style={{ fontSize: '.875rem' }} className="wallet-history-name">{data.first_name} {data.last_name}</p>
                            <p style={{ fontSize: '.775rem' }}>{getTimeDifference(data.created_at)}</p>
                        </Stack>
                        <Stack direction={'column'} alignItems={'flex-end'}>
                            <p style={{fontSize: '1.2rem', fontWeight: 'bold'}}>{formatCurrency(data.amount)}</p>
                            <p style={{ fontSize: '.775rem' }}>{data.contrib_status}</p>
                        </Stack>
                    </Box>
                    </Box>
                    <Divider />
                    </>
                ) : (
                    <p>Error loading transaction</p>
                )}
                </>
            ))}
    </Box>
  )
}

export default WalletHistory