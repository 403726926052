import React from 'react'
import { useApiService } from '../../services/api-service'
import { useState } from 'react';
import CardSkeleton from '../CardSkeleton/CardSkeleton';
import { useEffect } from 'react';
import { useProfileService } from '../../services/profile-service';
import Skeleton from 'react-loading-skeleton';
import { NO_PROFILE } from '../../utils/routes';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import toast from 'react-hot-toast';

const RaiseMeCard = ({ searchQuery }) => {
    const apiService = useApiService();
    const [loading, setLoading] = useState(false)
    const profile = useProfileService();
    const [raisemes, setRaisemes] = useState([]);

    useEffect(() => {
        const loadRaiseMe = async (e) => {
          const MAX_RETRIES = 5;
          const RETRY_DELAY = 1000; // 1 second
            try {
              setLoading(true);
                const res = await apiService.raiseme.getAllRaiseMe();
                if(res.status === 200 || res.status === 201){
                    const data =  Object.values(res.data || {});
                    if (Object.keys(data).length === 0) {
                      console.log('data is an empty object');
                    } else {
                      const fetchWithRetry = async (requestFn, maxRetries) => {
                        for (let attempt = 0; attempt < maxRetries; attempt++) {
                          try {
                            const response = await requestFn();
                            if (response.status === 200 || response.status === 201) {
                              const data = response.data;
                              if (Object.keys(data).length > 0) {
                                return data;
                              }
                            }
                          } catch (error) {
                            console.error(`Attempt ${attempt + 1} failed:`, error);
                            return null; // Exit the loop on error
                          }
                          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
                        }
                        throw new Error('Max retries reached or error encountered');
                      };
                      const handleRequest = async (raiseme, requestFn, key) => {
                        try {
                          const data = await fetchWithRetry(requestFn, MAX_RETRIES);
                          if (data) {
                            raiseme[key] = data;
                          } else {
                            raiseme.failed = true;
                          }
                        } catch (error) {
                          console.error(`Error fetching ${key} for raiseme ID ${raiseme.id}`, error);
                          raiseme.failed = true;
                        }
                      };

                      const createRequests = (raisemes, requestFn, key) =>
                        raisemes.map(async (raiseme) =>
                          handleRequest(raiseme, () => requestFn(raiseme), key)
                        );

                        const raisemeInfoRequests = createRequests(
                          data,
                          (n) => apiService.raiseme.getRaiseMeInfo({ raiseme_id: n.raiseme_id }),
                          'raisemeInfo'
                        );

                        await Promise.allSettled([
                          ...raisemeInfoRequests
                        ]);

                        const successfulRequests = data.filter((raiseme) => !raiseme.failed);
                        setRaisemes([...successfulRequests])
                    }
                    
                }
            } catch (e) {
                if(e.response){
                  if(e.response.status !== 404){
                    toast.error(e.response.data["code "]);
                  }
                } else{
                  toast.error("Network Error");
                }
              } finally{
                setLoading(false)
              }
        }

        loadRaiseMe();
    }, [])

    // const filteredRaiseMeData = raisemes.filter((raiseme) => {
    //   const lowerCaseSearchQuery = searchQuery.toLowerCase();
    //   const lowerCaseName = (raiseme.raisemeInfo && raiseme.raisemeInfo.name) ? raiseme.raisemeInfo.name.toLowerCase() : '';
    //   const matchName = lowerCaseName.includes(lowerCaseSearchQuery);
    //   return raiseme.raisemeInfo && raiseme.raisemeInfo.raisemeType === "RAISEME_CONTRIBUTION" && matchName;
    // });    
    const filteredRaiseMeData = raisemes.filter((raiseme) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const lowerCaseName = raiseme.raisemeInfo.name.toLowerCase();
      const matchName = lowerCaseName.includes(lowerCaseSearchQuery);

      return matchName;
    });
    

    const mapPeriodToText = (period) => {
      switch (period) {
        case 30:
          return 'Monthly';
        case 14:
          return 'Bi-weekly';
        case 7:
          return 'Weekly';
        default:
          return ''; // You can handle other cases as needed
      }
    };

    if(loading){
        return <CardSkeleton cards={8}/>
    }
  return (
    <>
    {filteredRaiseMeData.length === 0 ?(
      <p>No records of raiseMe</p>
    ) : (
    filteredRaiseMeData.map((raiseme, index) => (
        <div className="lounge-card" key={index}>
          <div className="lounge-cover">
          <img
          src={
            raiseme.banner
              ? `${raiseme.raisemeInfo.banner}`
              : 'https://res.cloudinary.com/shopsfit/image/upload/v1688810856/MyAreaa/mahogany_jajib7.jpg'
          }
          alt="Lounge Cover"
        />

          </div>
          <div className="lounge-profile">
            <img src={raiseme.raisemeInfo.photoUrl || NO_PROFILE} alt="Lounge Profile" />
          </div>
          <div className="lounge-info">
            <h2 className="lounge-name">{raiseme.raisemeInfo.name || <Skeleton />}</h2>
            <p className='lounge-desc'>{raiseme.raisemeInfo.description}</p>
            <div className="lounge-stats">
            <span className="lounge-members">{mapPeriodToText(raiseme.raisemeInfo.period)} Contribution</span>
              <span className="lounge-posts">{raiseme.raisemeInfo.location}</span>
              <span className="lounge-posts">${raiseme.raisemeInfo.contributionAmount}</span>
            </div>
          </div>
          <div className="lounge-cta">
            <Link to={`/community/${raiseme.raisemeInfo.communityId?? ''}/${raiseme.raisemeInfo.id}`}>
              <button className="join-button button">Visit Collective Cash</button>
              </Link>
          </div>
        </div>
    ))
    )}
    </>
  );
};

export default RaiseMeCard