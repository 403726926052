import { Box, Button, Drawer, Stack } from '@mui/material'
import React from 'react'
import images from '../../Data/imageHelper'
import './mobileVersionDrawer.css'
import { AndroidLink, IOSLink } from '../../utils/routes'
import { Cancel, Web } from '@mui/icons-material'

const MobileVersionDrawer = ({ isOpen, onClose, deviceType }) => {
  return (
    <>
    <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
      <div style={{ padding: '20px', textAlign: 'center' }}>
      <Cancel onClick={onClose} sx={{cursor: 'pointer', float: 'right', color: 'black'}} />
        <h2 className='title-sm' style={{fontSize: '1.2rem'}}>See MyAreaa in...</h2>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '15px'}}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                    <img className='app-icons' src={images.favicon} />
                    <h2 style={{fontSize: '1rem'}} className='title-sm'>MyAreaa App</h2>
                </Box>
                <a target='_blank' href={deviceType === 'iOS' ? IOSLink : AndroidLink}>
                <button onClick={onClose} className='app-button'>
                    Open
                </button>
                </a>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                    <Web className='app-icons'/>
                    <h2 style={{fontSize: '1rem'}} className='title-sm'>Browser</h2>
                </Box>
                <button onClick={onClose} style={{backgroundColor: '#333'}} className='app-button'>
                    Continue
                </button>
            </Stack>
        </Box>
      </div>
    </Drawer>
    </>
  )
}

export default MobileVersionDrawer