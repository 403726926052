import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './submitRaiseMePosition.css';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import { useAuthService } from '../../services/auth-service';
import { CalendarMonth, Done, HourglassEmpty, HourglassEmptyRounded, SwapHoriz } from '@mui/icons-material';
import toast from 'react-hot-toast';

const SubmitRaiseMePosition = ({ isStarted, handleClose, RaisemePositions,
  isLoading, canViewPosition,
   loungeId, fetchPositions, selectedLounge }) => {
  const apiService = useApiService();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const {profile} = useProfileService();
  const {clearToken} = useAuthService();
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  // let isChitFunds;
  // isChitFunds = selectedLounge.raisemeType === "CHIT_FUND";

  const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const correctDateFormat = (datestring) => {
    const formattedDate = new Date(datestring).toISOString().split('T')[0];
    return formattedDate
  }

  let endpoint;
  if (isStarted) {
      endpoint = "submitSwapKickOff"; // Endpoint for after start
  } else {
      endpoint = "submitSwap"; // Endpoint for before start
  }

  useEffect(() => {
    // Fetch user details for each position with user_id
    const fetchUserDetails = async (user_id) => {
      try {
        setLoading(true);
        const userDetailsResponse = await apiService.profile.profileDetails({
          user_id: user_id,
        });

        if (userDetailsResponse.status === 200 || userDetailsResponse.status === 201) {
          setUserDetails((prevDetails) => ({
            ...prevDetails,
            [user_id]: userDetailsResponse.data,
          }));
        } else {
          console.error('Error fetching user details:', userDetailsResponse);
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
          if(e.response.status === 401){
            // clearToken();
            // toast.error('Session Expired! Login')
          }
        }
      } finally{
        setLoading(false)
      }
    };

    RaisemePositions.forEach((position) => {
      if (position.user_id !== null && !userDetails[position.user_id]) {
        fetchUserDetails(position.user_id);
      }
    });
  }, [RaisemePositions, userDetails]);

  useEffect(() => {
    fetchPositions();
  },[])

  // Render a position with a "Select" button if user_id is null
  const renderOpenPosition = (position) => {
    const isProfileUserInPositions = RaisemePositions.some(pos => pos.user_id === profile.userId);
    return (
    <div key={position.position}>
        <Stack 
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      >
        <div style={{display: 'flex', width: '60%', alignItems: 'center', gap: '1rem'}}>
        <div className='position-calendar'>
            <CalendarMonth/>
        </div>
     <div className='raiseme-position'>
      <p>Open Position:</p>
      <p>{formatDate(position.position)}</p>
      </div>
      </div>
      <Button onClick={() => handleSelectPosition(position)}
      style={{ backgroundColor: '#a06ccb', color: 'white', fontSize: '14px', width: '100px'}}
      disabled={isProfileUserInPositions}
      startIcon={<Done/>}
      >
        Select
      </Button>
      {/* {isChitFunds ? (
        <Button onClick={handleClickOpen}
        style={{ backgroundColor: '#a06ccb', color: 'white', fontSize: '14px', width: '100px'}}
        disabled={isProfileUserInPositions}
        startIcon={<Done/>}
        >
          Select
        </Button>
      ) : (
        <Button onClick={() => handleSelectPosition(position)}
      style={{ backgroundColor: '#a06ccb', color: 'white', fontSize: '14px', width: '100px'}}
      disabled={isProfileUserInPositions}
      startIcon={<Done/>}
      >
        Select
      </Button>
      )} */}
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Accept 5% forfeiture?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{textAlign: 'left'}} id="alert-dialog-description">
            By Clicking Agree, you accept that when position is selected you forfeit 5% of the total amount
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose}>Disagree</Button>
          <Button onClick={() => handleSelectPosition(position)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      </Stack>
    </div>
    );
    };

  // Render a position with user details and a "Request Swap" button if user_id is not null
  const renderOccupiedPosition = (position, user) => {
    const isProfileUser = position.user_id === profile.userId;
    const isProfileUserInPositions = RaisemePositions.some(pos => pos.user_id === profile.userId);
  
    return (
      <div key={position.position}>
        <Stack 
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <div style={{ display: 'flex', width: '60%', alignItems: 'center', gap: '1rem' }}>
            <img className='position-profile-img' src={user.photoUrl} alt={user.photoUrl} />
            <div>
              <p style={{textAlign: 'left'}}>{user.firstName}'s Position:</p>
              <p>{formatDate(position.position)}</p>
            </div>
          </div>  
          <Button
            onClick={() => handleRequestSwap(position, user)}
            style={{
              backgroundColor: isProfileUser ? '#dddddd' : isProfileUserInPositions ? '#a06ccb' : '#dddddd',
              color: isProfileUser ? '#aaaaaa' : isProfileUserInPositions ? 'white' : '#aaaaaa',
              fontSize: '14px',
              width: '100px'
            }}
            disabled={!isProfileUserInPositions || isProfileUser}
            startIcon={<SwapHoriz/>}
          >
            Swap
          </Button>
          {/* {!isChitFunds && (
            <Button
            onClick={() => handleRequestSwap(position, user)}
            style={{
              backgroundColor: isProfileUser ? '#dddddd' : isProfileUserInPositions ? '#a06ccb' : '#dddddd',
              color: isProfileUser ? '#aaaaaa' : isProfileUserInPositions ? 'white' : '#aaaaaa',
              fontSize: '14px',
              width: '100px'
            }}
            disabled={!isProfileUserInPositions || isProfileUser}
            startIcon={<SwapHoriz/>}
          >
            Swap
          </Button>
          )} */}
        </Stack>
      </div>
    );
  };
  

  const handleSelectPosition = async (position) => {
    try {
        const originalDate = new Date(position.position);
    
        // Format the date in the desired format (YYYY-MM-DD)
        const formattedDate = originalDate.toISOString().split('T')[0];
        const requestBody = {
            user_id: profile.userId,
            raiseme_id: loungeId,
            position: formattedDate
        }
        const response = await apiService.raiseme.submitPosition(requestBody);
        if(response.status === 200 || response.status === 201){
            toast.success('Position Selected');
            fetchPositions();
            handleClose();
        }
    } catch (e) {
        if(e.response){
            toast.error(e.response.data["code "]);
          } else{
            toast.error("Network Error");
        }
    }
  };

  const handleRequestSwap = async (position, user) => {
    try {
        const originalDate = new Date(position.position);
    
        // Format the date in the desired format (YYYY-MM-DD)
        const formattedDate = originalDate.toISOString().split('T')[0];
        const senderPosition = RaisemePositions.find(pos => pos.user_id === profile.userId);
        const requestBody = {
            sender_id: profile.userId,
            reciever_id: position.user_id,
            notification_type: "swapRequest",
            raiseme_id: loungeId,
            sender_anniversary_date: correctDateFormat(senderPosition.position),
            reciever_anniversary_date: formattedDate,
            delivery_type: 2
        }
        const response = await apiService.notification.push(requestBody);
        if(response.status === 200 || response.status === 201){
            toast.success('Swap Requested');
            handleClose();
        }
    } catch (e) {
        if(e.response){
            toast.error(e.response.data["code "]);
          } else{
            toast.error("Network Error");
        }
    }
    // Add logic for handling the swap request
  };

  return (
    <>
      <Box className="raiseme-position-modal">
        <>
        <div className="card-details-modal-top">
          {/* <h2 className='blackText'>Select {isChitFunds ? 'Chit Funds Position' : 'Ajo Position'}</h2> */}
          <h2 className='blackText'>Select My Collective Cash Position</h2>
          <p>Open Positions are unclaimed anniversary dates.
          You can instantly secure these dates. To obtain a
          date already chosen by someone else, request a
          swap. If the member agrees, the position is yours</p>
          <Divider style={{ marginTop: '10px' }} variant="middle" />
        </div>
        <div className="card-details-modal-middle">
          {canViewPosition && (
          <h3>Details</h3>
          )}
          <Box display={'flex'} flexDirection={'column'} gap={'10px'} alignSelf={'center'}>
          {loading || isLoading ? (
              <LinearProgress style={{ marginBottom: '1rem', backgroundColor: '#a06ccb' }} />
            ) : (
              <>
              {canViewPosition ? (
                <>
                <Stack maxHeight={'300px'} style={{overflowY: 'auto'}} direction={'column'} justifyContent={'space-between'} gap={2}>
                {RaisemePositions.map((position) =>
                  position.user_id === null ? renderOpenPosition(position) : userDetails[position.user_id]
                    ? renderOccupiedPosition(position, userDetails[position.user_id])
                    : null
                )}
              </Stack>
                </>
              ) : (
                <Box sx={{padding: '1rem', display: 'flex', flexDirection: 'column' , justifyContent: 'center', alignItems: 'center'}}>
                  <HourglassEmptyRounded className='big-icon'/>
                  <p>Positions not yet active for this Collective Cash</p>
                </Box>
              )}
              
              </>
            )}
          </Box>
        </div>
        <div className="card-details-modal-bottom">
          <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} mt={3}>
            <Button onClick={handleClose} style={{ color: '#a06ccb', fontSize: '14px' }}>
              Cancel
            </Button>
            <Button onClick={handleClose} style={{ backgroundColor: '#a06ccb', color: 'white', fontSize: '14px' }}>
              Done
            </Button>
          </Stack>
        </div>
        </>
      </Box>
    </>
  );
};

export default SubmitRaiseMePosition;
