import Cookies from 'js-cookie'
import React, { createContext, useContext, useEffect, useState } from 'react'
import toast from "react-hot-toast";
import { BASE_URL } from '../utils/routes'

/**
 * The cookie key where auth tokens are stored.
 */
const COOKIE_KEY = 'myarea_auth_token'

/**
 * Auth Service React Context.
 */
export const AuthServiceContext = createContext({
  /**
   * Auth Token.
   *
   * @type {String|Null}
   */
  token: null,
  refreshToken: null,

  /**
   * Set Auth Token.
   *
   * @param {{ token: string, expires: number, refreshToken: string}} tokenData
   */
  setToken: ({ token, expires, refreshToken }) => {},

  /**
   * Clear Auth Token.
   */
  clearToken: () => {},
  refreshAccessToken: () => {},
})

/**
 * Auth Service React Context Provider.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function AuthServiceProvider ({ children }) {
  const [token, updateToken] = useState(() => {
    const v = document.cookie.match('(^|;) ?' + COOKIE_KEY + '=([^;]*)(;|$)')

    return v ? v[2] : null
  })

  const [isFailed, setIsFailed] = useState(false);
  const setToken = ({ token, expires }) => {
    Cookies.set(COOKIE_KEY, token, { expires: expires / (60 * 60 * 24) }); // Convert seconds to days for js-cookie
    updateToken(token);
  };

  // const setToken = ({ token, expires, refreshToken }) => {
  //   const d = new Date()

  //   d.setTime(d.getTime() + 1000 * expires)

  //   document.cookie = `${COOKIE_KEY}=${token};path=/;expires=${d.toUTCString()}`
  //   updateToken(token)
  //   setRefreshToken(refreshToken)
  // }

  const clearToken = () => {
    Cookies.remove(COOKIE_KEY);
    updateToken(null);
    Cookies.remove('refresh_myarea_auth_token')
    Cookies.remove('remember')
  };
  
  const refreshAccessToken = async () => {
    const rToken = Cookies.get('refresh_myarea_auth_token');
    try {
      // Add your logic to fetch and set the new access token using the refresh token
      const response = await fetch(
        `${BASE_URL}/auth/refresh-token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${rToken}`,
          },
        }
      )
      if (response.status === 200 || response.status === 201) {
        const responseData = await response.json()
        const newAccessToken = responseData.access_token
        const expirationTimeInSeconds = 15 * 60 * 1000 // Set the new expiration time
        setToken({ token: newAccessToken, expires: expirationTimeInSeconds })
      } else {
        // Handle error response from refresh token request
      }
    } catch (e) {
      if(e.response){
        setIsFailed(true);
        if(e.response.status === 401){
          clearToken();
          toast.error('Session Expired! Login')
      }
      } else {
        toast.error('Network Error');
      }
    }
  }

  useEffect(() => {
    const tokenExpirationTime = 13 * 60 * 1000; // 14 minutes in milliseconds
  
    const tokenExpirationTimer = setTimeout(() => {
      refreshAccessToken(); // Trigger token refresh instead of clearing token
    }, tokenExpirationTime);
  
    return () => {
      clearTimeout(tokenExpirationTimer);
    };
  }, [token, refreshAccessToken]);

  useEffect(() => {
    const tokes = Cookies.get(COOKIE_KEY)
    if(tokes){
      updateToken(tokes)
    }
    if(isFailed){
      refreshAccessToken();
    }
  }, [isFailed])
  

  return (
    <AuthServiceContext.Provider
      value={{
        token,
        setToken,
        clearToken,
        refreshAccessToken,
      }}
    >
      {children}
    </AuthServiceContext.Provider>
  )
}


/**
 * Use Auth Service React Context.
 */
export function useAuthService () {
  return useContext(AuthServiceContext)
}
