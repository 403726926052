import React from 'react'
import Header from '../../components/header/Header'
import './virtualcard.css'
import { styled } from '@mui/material/styles';
import UserProfile from '../../components/userProfile/UserProfile'
import { Backdrop, Box, Button, Card, Divider, Fade, FormControlLabel, Modal, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { AccountCircleOutlined, AddCardOutlined, CardGiftcard, CopyAllOutlined, CurrencyExchangeOutlined, MoreHoriz } from '@mui/icons-material'
import { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const VirtualCard = () => {
      // const [tableData, setTableData] = useState([
      //   {
      //     date: '23-02-2023',
      //     amount: '$250.00',
      //     transactionType: 'RaiseMe Subscription',
      //   },
      //   {
      //     date: '12-03-2023',
      //     amount: '$150.00',
      //     transactionType: 'RaiseMe Contribution',
      //   },
      //   {
      //     date: '05-04-2023',
      //     amount: '$500.00',
      //     transactionType: 'RaiseMe Anniversary',
      //   },
      //   {
      //       date: '23-02-2023',
      //       amount: '$250.00',
      //       transactionType: 'RaiseMe Subscription',
      //     },
      //     {
      //       date: '12-03-2023',
      //       amount: '$150.00',
      //       transactionType: 'RaiseMe Contribution',
      //     },
      //     {
      //       date: '05-04-2023',
      //       amount: '$500.00',
      //       transactionType: 'RaiseMe Anniversary',
      //     },
      //     {
      //       date: '23-02-2023',
      //       amount: '$250.00',
      //       transactionType: 'RaiseMe Subscription',
      //     },
      //     {
      //       date: '12-03-2023',
      //       amount: '$150.00',
      //       transactionType: 'RaiseMe Contribution',
      //     },
      //     {
      //       date: '05-04-2023',
      //       amount: '$500.00',
      //       transactionType: 'RaiseMe Anniversary',
      //     },
      //     {
      //       date: '05-04-2023',
      //       amount: '$500.00',
      //       transactionType: 'RaiseMe Anniversary',
      //     },
      //     {
      //       date: '23-02-2023',
      //       amount: '$250.00',
      //       transactionType: 'RaiseMe Subscription',
      //     },
      //     {
      //       date: '12-03-2023',
      //       amount: '$150.00',
      //       transactionType: 'RaiseMe Contribution',
      //     },
      //     {
      //       date: '05-04-2023',
      //       amount: '$500.00',
      //       transactionType: 'RaiseMe Anniversary',
      //     },
      //   // Add more objects as needed
      // ]);
      // const [page, setPage] = React.useState(0);
      // const [rowsPerPage, setRowsPerPage] = React.useState(5);
      // const handleChangePage = (event, newPage) => {
      //   setPage(newPage);
      // };
    
      // const handleChangeRowsPerPage = event => {
      //   setRowsPerPage(parseInt(event.target.value, 10));
      //   setPage(0);
      // };
      // const emptyRows =
      //   rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
        
      //   const [open, setOpen] = React.useState(false);
      //   const handleOpen = () => setOpen(true);
      //   const handleClose = () => setOpen(false);
  return (
    <>
        <Header/>
        <div className="virtualCardWrapper">
        <div className="virtualCard-sidebar">
            <UserProfile/>
        </div>
        {/* <div className="virtualCardContainer">
            <div className="card-balance-container">
            <div className="card-balance-container-left">
                <img src='https://res.cloudinary.com/shopsfit/image/upload/v1700225041/my_areaa_virtual_card_b2p3ng.webp' alt='virtual card' />
            </div>
            <div className="card-balance-container-right">
                <h3>Card Balance</h3>
                <h2>$20.00</h2>
                <p>You can transfer money from your vault into your card to withdraw at ATMs or spend on web and POS transactions.</p>
                <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                    <Button onClick={handleOpen} style={{color: "#fff", backgroundColor: "#a06ccb"}} variant='filled' startIcon={<CopyAllOutlined/>}>
                    Get Card Details
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade in={open}>
                            <Box className='card-details-modal'>
                                <div className="card-details-modal-top">
                                    <h2>Card Details</h2>
                                    <p>The information below are your card details and should be kept secure</p>
                                    <Divider style={{marginTop: '10px'}} variant='middle' />
                                </div>
                                <div className="card-details-modal-middle">
                                    <h3>Details</h3>
                                    <Box display={'flex'} flexDirection={'column'} gap={'10px'} alignSelf={'center'}>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <p>Card Number</p>
                                        <span>0000 0000 0000 0000</span>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <p>Expiry Date</p>
                                        <span>12/26</span>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <p>CVV</p>
                                        <span>126</span>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <p>Billing Address</p>
                                        <span>KOFO KASUMU AVENUE , AMUWO ODOFIN</span>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <p>Zip Code</p>
                                        <span>100001</span>
                                    </Stack>
                                    </Box>
                                </div>
                                <div className="card-details-modal-bottom">
                                    <Stack direction={'row'} spacing={3} justifyContent={'flex-end'} mt={3}>
                                        <Button onClick={handleClose} style={{color: '#a06ccb', fontSize: '14px'}}>
                                            Cancel
                                        </Button>
                                        <Button onClick={handleClose} style={{backgroundColor: '#a06ccb', color: 'white', fontSize: '14px'}}>
                                            Done
                                        </Button>
                                    </Stack>
                                </div>
                            </Box>
                            </Fade>
                        </Modal>
                <Button style={{ color: "#a06ccb", borderColor: "#a06ccb"}} variant='outlined' startIcon={<CurrencyExchangeOutlined/>}>
                    Withdraw
                </Button>
                <Button style={{ color: "#fff", backgroundColor: "#a06ccb"}} variant='filled' startIcon={<AddCardOutlined/>}>
                    Deposit
                </Button>
                </Stack>
            </div>
            </div>
            <div className="virtual-card-management">
                <h2>Card Management</h2>
                <p>Manage your Virtual card to your preferences</p>
                <div className="virtual-card-management-item">
                    <div className="virtual-card-management-item-left">
                        <h3>Freeze Card</h3>
                        <p>Freeze your card</p>
                    </div>
                    <div className="virtual-card-management-item-right">
                    <Switch defaultChecked color="secondary" />
                    </div>
                </div>
                <div className="virtual-card-management-item">
                    <div className="virtual-card-management-item-left">
                        <h3>Report Problem</h3>
                        <p>Contact support to report any issue with your debit card.</p>
                    </div>
                    <div className="virtual-card-management-item-right">
                    <Link to='/need-help'>
                        <Button variant='filled' startIcon={<AccountCircleOutlined/>} style={{backgroundColor: '#a06ccb', color: 'white'}}>
                        Contact
                        </Button>
                    </Link>
                    </div>
                </div>
            </div>
            <div className="virtual-card-history">
                <h2>Card History</h2>
                <div className="transaction-bottom">
                <TableContainer sx={{maxHeight: '450px', boxShadow: 'none'}}>
                    <Table aria-label='transaction-history' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black'}}>Date</TableCell>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black'}}>Amount</TableCell>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black'}}>Type</TableCell>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black'}} align='right'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{color: 'black'}}>
                        {tableData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((data, index) => (
                                <TableRow key={index}>
                                <TableCell>{data.date}</TableCell>
                                <TableCell className='transaction-amount'>{data.amount}</TableCell>
                                <TableCell>{data.transactionType}</TableCell>
                                <TableCell align='right'><MoreHoriz/></TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            on
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </TableContainer>
            </div>
            </div>
        </div> */}
        <div className="virtualCardContainer">
          <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} height={'100%'}>
                <CardGiftcard style={{fontSize: '5rem', color: '#a06ccbc6'}}/>
                <p className='pals-list'>COMING SOON</p>
            </Stack>
        </div>
        </div>
    </>
  )
}

export default VirtualCard