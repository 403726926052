import axios from 'axios'
import Cookies from 'js-cookie'
import { useContext, useMemo, useState } from 'react'
import { BASE_URL, logOut } from '../utils/routes'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AuthServiceContext } from '../services/auth-service'

/**
 * Axios Instance Hook.
 *
 * @param {String} token
 * @param {String} baseURL
 * @returns {import('axios').AxiosInstance}
 */

export default function useAxios (token, baseURL = BASE_URL) {

  const history = useHistory();
  const location = useLocation();
  const isLogin = location.pathname.startsWith('/login');
  const isInvite = location.pathname.startsWith('/invite');
  const { refreshAccessToken } = useContext(AuthServiceContext);
  const client = useMemo(() => {
    const instance = axios.create({ baseURL })

    if (token) {
      instance.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      delete instance.defaults.headers.common.Authorization
    }

    instance.interceptors.response.use(
      response => response, // If the response is successful, just return it
      async error => {
        if ((error.response && error.response.status === 401 || error.response.status === 422)) {
          const rememberMe = Cookies.get('remember');
          if(!isLogin && !isInvite){
            if(rememberMe === 'true'){
              try {
                await refreshAccessToken();
                return axios.request(error.config); // Utilize axios.request for flexibility
              } catch (refreshError) {
                logOut(history); // Handle refresh failure
                toast.error('Unauthorized access. Please log in to continue..');
              }
            } else {
              logOut();  // Call logOut with history for redirection
            history.push('/login')
            toast.error('Unauthorized access. Please log in to continue.', {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            }
          }
        }
        return Promise.reject(error); // Reject the promise to propagate the error
      }
    );

    return instance
  }, [token])
  
  return client
}