import { Box, CircularProgress, LinearProgress } from "@mui/material"
import Cookies from 'js-cookie'
import { badWords } from "../Data/badWords"
import toast from "react-hot-toast"

export const BASE_URL = 'https://myareaa-prod.eastus.cloudapp.azure.com'
export const LOGIN_ROUTE = '/login'
export const ONBOARDING_ROUTE = '/onboarding'
export const COMMUNITY_ROUTE = '/community'
export const NO_PROFILE = 'https://res.cloudinary.com/shopsfit/image/upload/v1689850325/user_ddvjro.png'
export const NO_COVER_IMG = 'https://res.cloudinary.com/shopsfit/image/upload/v1705670247/9289114_gtloqu.webp'
export const MAX_IMG_SIZE = 5 * 1024 * 1024;
export const MAX_VIDEO_SIZE = 15 * 1024 * 1024;
export const COOKIE_KEY = 'myarea_auth_token';
export const REFRESH_COOKIE_KEY = 'refresh_myarea_auth_token';
export const IOSLink = 'https://apps.apple.com/ca/app/myareaa/id6470510888'
export const AndroidLink = 'https://play.google.com/store/apps/details?id=ca.jl13concepts.myareaa'
export const LinearLoad = () => {
    return (
        <LinearProgress style={{ marginBottom: '1rem', backgroundColor: '#a06ccb' }} />
    )
}

export const LoadingFullScreen = () => {
  return (
    <Box gap={'0.5rem'} height={'100vh'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
    <CircularProgress style={{ color: '#a06ccb'}}/>
    <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
    </Box>
  )
}

export const LoadingPopup = () => {
  return (
    <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
    <CircularProgress style={{ color: '#a06ccb'}}/>
    <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
    </Box>
  )
}
export const formatAmountWithCommas = (amount) => {
  if (amount === undefined || amount === null) {
    return '0';
  }
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const getTimeDifference = (createdAt) => {
    const currentDate = new Date();
    const pastDate = new Date(createdAt);

    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (daysDifference > 0) {
      return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} min${minutesDifference > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  };

export const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  export const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase();
  };

  export const mapCommTypeToText = (type) => {
    switch (type) {
      case 'DEMOCRATIZED_COMM':
        return 'DEMOCRATIZED';
      case 'ADMIN_MANAGED_COMM':
        return 'ADMIN MANAGED';
      default:
        return '';
    }
  };

  export const removeCommunityInfo = () => {
    localStorage.removeItem('communityInfo');
    localStorage.removeItem('notificationDetails');
  };

  export const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  export const logOut = () => {
    const COOKIE_KEY = 'myarea_auth_token';
    Cookies.remove(COOKIE_KEY);
    Cookies.remove('refresh_myarea_auth_token');
    Cookies.remove('remember')
  };

  export const containsBadWords = (text) => {
    const lowerCaseText = text.toLowerCase();
    return badWords.some((word) => lowerCaseText.includes(word));
  };

  export const getFileType = (url) => {
    // Extract the file extension from the URL
    const extension = url.split('.').pop().toLowerCase();
    return extension;
  };
const determineTransactionType = (amount) => {
  return amount < 0 ? 'DEBIT' : 'CREDIT';
}

export const getTransactionType = (transaction) => {
  const transactionTypeUpper = transaction.transaction_type?.toUpperCase();
  return (transactionTypeUpper === 'CREDIT' || transactionTypeUpper === 'DEBIT') 
    ? transactionTypeUpper 
    : determineTransactionType(transaction.transaction_amount);
};

export const formatCurrency = (amount) => {
  return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

export const handleError = (e) => {
  if (e.response) {
    toast.error(e.response.data["code"]);
    toast.error(e.response.data["msg"]);
    toast.error(e.response.data["errors"]);
  } else {
    toast.error('Network Error');
  }
};

export const getDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'Unknown';
};

export const getAuthMode = (auth_mode) => {
  return auth_mode === (null || 'EMAIL') ? 'EMAIL' : 'AUTHENTICATOR';
}
