import { AddPhotoAlternate, CalendarToday, Person, Place } from '@mui/icons-material';
import { Avatar, Box, Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import './profile.css'
import RenderCountryList from '../renderCountryList/RenderCountryList';
import { useApiService } from '../../services/api-service';
import toast from 'react-hot-toast';

const EditProfile = ({userInfo, handleCloseEdit, fetchProfile}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const apiService = useApiService();
    const [formData, setFormData] = useState({
        country: userInfo.country,
        state: userInfo.city,
        customState: '',
      });
    
    const [profileData, setProfileData] = useState({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        gender: userInfo.gender,
        birthDate: userInfo.birthDate,
        country: userInfo.country,
        state: userInfo.city,
        bio: userInfo.bio
    })

      const currentDate = new Date();
      const thirteenYearsAgo = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate()
      );
    
    const formatDate = (dateString) => {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const handleProfileChange = (fieldName, value) => {
        setProfileData(prevData => ({
          ...prevData,
          [fieldName]: value
        }));
      };      

    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setSelectedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
        setMainImage(file);
      };

      const handleImageUpload = async (e) => {
        try {
          if (selectedImage) {
        const formData = new FormData();
        formData.append('file', mainImage);
        const response = await apiService.profile.profileUpload(formData);
          if (response.status === 200 || response.status === 201) {
            toast.success("Profile Photo Uploaded");
          }
          }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
              } else {
                toast.error('Network Error');
              }
        }
      };

    //   const handleUpdate = async (e) => {
    //     e.preventDefault();
    //     try {
    //       setIsUpdating(true)
    //       const requestBody = {
    //         first_name: profileData.firstName,
    //         last_name: profileData.lastName,
    //         city: formData.state,
    //         country: formData.country, // Consider using a default value for the country
    //         gender: profileData.gender,
    //         birth_date: profileData.birthDate,
    //         bio: profileData.bio
    //       };
    //       console.log('profile ',requestBody);
    //       const response = await apiService.auth.updateProfile(requestBody);
    //       if (response.status === 200) {
    //         await handleImageUpload();
    //         fetchProfile();
    //         toast.success('Profile successfully updated!');
    //         handleCloseEdit();
    //       }
    //     } catch (e) {
    //       if(e.response){
    //         toast.error(e.response.data["code "]);
    //       } else {
    //         toast.error('Network Error');
    //       }
    //     } finally{
    //       setIsUpdating(false);
    //     }
    //   };

      const handleImageUploadAndUpdate = async () => {
        try {
          setIsUpdating(true);
      
          // Upload image
          if (selectedImage) {
            await handleImageUpload();
          }
      
          // Update profile
          const requestBody = {
            first_name: profileData.firstName,
            last_name: profileData.lastName,
            bio: profileData.bio,
            city: formData.state,
            country: formData.country, // Consider using a default value for the country
            gender: profileData.gender,
            birth_date: profileData.birthDate
          };
          console.log('profile ', requestBody);
          const response = await apiService.auth.updateProfile(requestBody);
      
          if (response.status === 200) {
            fetchProfile();
            toast.success('Profile successfully updated!');
            handleCloseEdit();
          }
        } catch (e) {
          if (e.response) {
            toast.error(e.response.data["code "]);
          } else {
            toast.error('Network Error');
          }
        } finally {
          setIsUpdating(false);
        }
      };
      

      
  return (
    <Box className="raiseme-position-modal">
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'column', maxHeight: '500px', overflowY: 'auto'}}>
            <h2 style={{color: 'black', textAlign: 'center'}}>Edit Profile</h2>
            <Stack>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            mt: '1rem',
            mb: '1rem',
            display: 'flex',
            alignItems: 'center', // Center items vertically
            justifyContent: 'center' // Center items horizontally
          }}
        >
          {selectedImage || userInfo.photoUrl ? (
            // Display the community banner if it exists
            <>
            <Avatar
              src={selectedImage || userInfo.photoUrl}
              alt="Profile Image"
              sx={{ width: '150px', height: '150px', objectFit: 'cover' }}
            />
            <div className='add-commbanner-icon'>
            <AddPhotoAlternate
                sx={{
                    color: 'white'
                }}
                onClick={() => document.getElementById('uploadInput').click()}
              />
              </div>
              <input
                type="file"
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleImageSelect}
                accept="image/*"
              />
              </>
          ) : (
            // If no banner exists, display an icon and allow upload
            <>
              <div className='add-commbanner-icon'>
            <AddPhotoAlternate
                sx={{
                    color: 'white'
                }}
                onClick={() => document.getElementById('uploadInput').click()}
              />
              </div>
              <input
                type="file"
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleImageSelect}
                accept="image/*"
              />
            </>
          )}
        </Box>
            </Stack>
            <Box sx={{display: 'flex', gap: '10px', width: '100%'}}>
            <Box className='edit-profile-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Person />
                                <label htmlFor='lounge-target-amount'>First Name:</label>
                            </Stack>
                            <input
                                value={profileData.firstName}
                                onChange={e => handleProfileChange('firstName', e.target.value)}
                                className='edit-profile-input'
                                type="text"
                                name="target_amt"
                                id='lounge-target-amount'
                                required
                            />
            </Box>
            <Box className='edit-profile-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Person />
                                <label htmlFor='lounge-target-amount'>Last Name:</label>
                            </Stack>
                            <input
                                value={profileData.lastName}
                                onChange={e => handleProfileChange('lastName', e.target.value)}
                                className='edit-profile-input'
                                type="text"
                                name="target_amt"
                                id='lounge-target-amount'
                                required
                            />
            </Box>
            </Box>
            <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Person />
                                <label htmlFor='lounge-target-amount'>Bio:</label>
                            </Stack>
                            <textarea
                                value={profileData.bio}
                                onChange={e => handleProfileChange('bio', e.target.value)}
                                className='create-aid-input'
                                type="text"
                                name="target_amt"
                                id='lounge-target-amount'
                                required
                            />
            </Box>
            <Box className='create-aid-input-box'>
            <Stack direction={'row'} alignItems={'center'}>
                                <Place />
                                <label htmlFor='lounge-target-amount'>Country:</label>
                            </Stack>
            <RenderCountryList formData={formData} setFormData={setFormData} />
            </Box>
            <Box className='create-aid-input-box'>
                            <Stack direction={'row'} alignItems={'center'}>
                                <CalendarToday />
                                <label htmlFor='lounge-target-amount'>Date of Birth:</label>
                            </Stack>
                            <input
                            type="date"
                            name="date"
                            placeholder="Enter your Date of Birth"
                            value={profileData.birthDate}
                            onChange={e => handleProfileChange('birthDate', e.target.value)}
                            required
                            className='create-aid-input'
                            max={formatDate(thirteenYearsAgo)}
                        />
            </Box>
            </Box>
            <Box sx={{width: '100%'}}>
                <Button onClick={handleImageUploadAndUpdate} sx={{color: 'white', width: '100%'}} className='button' variant='contained'>{isUpdating ? 'Updating...':'Update'}</Button>
            </Box>
        </Box>
    </Box>
  )
}

export default EditProfile