import React, { useState } from 'react';
import "./recentContributions.css";
import { Contributions } from '../../Data/Contribution';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPaginate from 'react-paginate';

import { MoreHoriz, Replay } from '@mui/icons-material';
import { NO_PROFILE } from '../../utils/routes';
import { Avatar, Backdrop, Box, Button, Fade, LinearProgress, Modal, Tooltip } from '@mui/material';
import RetryPayment from './RetryPayment';
import { useProfileService } from '../../services/profile-service';

const RecentContributions = ({transactionData, loadTransactions, selectedLounge}) => {

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // adjust this to your desired number of items per page


  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [open, setOpen] = React.useState(false);
  const profile = useProfileService();
  const handleOpen = (data) => {
    setCurrentTransaction(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const filteredContributions = transactionData.filter((data) => {
    const transactionDate = new Date(data.transaction_date);
    const filterStartDate = startDate ? new Date(startDate) : null;
    const filterEndDate = endDate ? new Date(endDate) : null;
  
    // Check if the transaction date is within the selected date range
    const isDateWithinRange =
      (!filterStartDate || transactionDate >= filterStartDate) &&
      (!filterEndDate || transactionDate <= filterEndDate);
  
      const isTransactionTypeMatch =
      statusFilter === 'ALL' ||
      (statusFilter === 'ERROR' && (data.status === 'REJECTED' || data.status === 'CANCELLED')) ||
      data.status === statusFilter;    
  
    // Check if the search query matches the first name or last name
    const isSearchMatch =
      !searchQuery ||
      data.userDetails.firstName.toLowerCase().includes(searchQuery) ||
      data.userDetails.lastName.toLowerCase().includes(searchQuery);
  
    // Return true if date, type, and search query match the selected criteria
    return isDateWithinRange && isTransactionTypeMatch && isSearchMatch;
  });
  

  const renderContributions = () => {
    const offset = currentPage * itemsPerPage;
    const paginatedContributions = filteredContributions.slice(offset, offset + itemsPerPage);
  
    if (paginatedContributions.length === 0) {
      return <tr><td colSpan="7">No results found</td></tr>;
    }

    const mapStatus = (status) => {
      if(status === 'ACCEPTED' || status === 'OK' || status === 'SUBMITTED') return 'In Progress'
      else if(status === 'APPROVED' || status === 'SETTLED') return 'Completed'
      else return 'Error'
    };

  return paginatedContributions.map((data) => {
    const shouldRenderRetryButton = () => {
      return (
        (data.status === 'REJECTED' || data.status === 'CANCELLED' || data.status === 'ERROR') &&
        data.user_id === profile.profile.userId &&
        data.debit_ref_id !== null
      );
    };
      return (
        <tr className="table-row" key={data.id}>
          {/* <td>
            <h2>
              <a href="/">
                <img className="profilePic" src={NO_PROFILE} alt="" />{' '}
                
              </a>
            </h2>
          </td> */}
          <td>
          <h2>
                {/* <img className="profilePic" src={data.userDetails.photoUrl || NO_PROFILE} alt="" />{' '} */}
                <Avatar alt={`${data.userDetails.firstName} ${data.userDetails.lastName}`} src={data.userDetails.photoUrl} />
                <p>{data.userDetails.firstName} {data.userDetails.lastName}</p>
            </h2>
          </td>
          <td style={{textAlign: 'center'}}>{new Date(data.transaction_date).toLocaleString('en-US', {
                                  month: 'short',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                  day: 'numeric',
                                  ordinal: 'numeric'
                                })}</td>
          <td style={{textAlign: 'center'}}>${data.transaction_amount}</td>
          <td style={{textAlign: 'center'}}>{data.transaction_type}</td>
          <td>
          {shouldRenderRetryButton() ? (
            <>
            <Tooltip title={`Transaction ${data.status.toLowerCase()}`} arrow>
              <Button onClick={() => handleOpen(data)} startIcon={<Replay/>} variant='contained' sx={{width: "100%", backgroundColor: 'light.warning', color: 'white'}}>Retry</Button>
              </Tooltip>
              <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}>
              <Fade>
                <Box className="retry-popup-modal">
                  <RetryPayment loadTransactions={loadTransactions} data={currentTransaction} selectedLounge={selectedLounge} handleClose={handleClose}/>
                </Box>
                      </Fade>
                  </Modal>
                  </>
            ) : (
              <p
                style={{
                  backgroundColor:
                    data.status === 'SETTLED'
                      ? '#A6F8BD' // green
                      : data.status === 'OK' || data.status === 'ACCEPTED' || data.status === 'SUBMITTED'
                      ? '#FCD0A1' // peach
                      : '#FCA1A1', // red
                  width: '100%',
                }}
              >
                {mapStatus(data.status)}
              </p>
            )}
          </td>
        </tr>
      );
    });
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };
  

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  
  

  return (
    <div className="contributions-container">
      <div className="sort-list-container">
        <div className="sort-search-box">
        <input
          type="text"
          placeholder="Search by name"
          value={searchQuery}
          onChange={handleSearch}
        />
        </div>
         <div className="date-range-picker">
    <DatePicker
    className='date-range-picker-input'
      selected={startDate}
      onChange={handleStartDateChange}
      selectsStart
      startDate={startDate}
      endDate={endDate}
      placeholderText="Start Date"
    />
    <DatePicker
    className='date-range-picker-input'
      selected={endDate}
      onChange={handleEndDateChange}
      selectsEnd
      startDate={startDate}
      endDate={endDate}
      placeholderText="End Date"
    />
  </div>
  <div className="filter-container">
          <label htmlFor="status-filter">Filter by status:</label>
          <select id="status-filter" onChange={handleStatusFilterChange} value={statusFilter}>
            <option value="ALL">All</option>
            <option value="OK">In Progress</option>
            <option value="SETTLED">Completed</option>
            <option value="ERROR">Error</option>
          </select>
        </div>
      </div>
      <div className="contribution-tabs">
        
        <table className="table">
          <thead className="table-head">
            <tr >
              {/* <th style={{textAlign:'center'}}>Date</th> */}
              <th>Member</th>
              <th style={{textAlign: 'center'}}>Transaction Date</th>
              <th style={{textAlign: 'center'}}>Amount</th>
              
              <th style={{textAlign: 'center'}}>Transaction Type</th>
              <th style={{textAlign: 'center'}}>Status</th>
            </tr>
          </thead>
          <tbody>
            {loadTransactions ? (
              <>
              <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
              </>
            ) : (
              <>
              {renderContributions()}
              </>
            )}
            </tbody>
        </table>
      </div>
      <ReactPaginate
  pageCount={Math.ceil(filteredContributions.length / itemsPerPage)}
  pageRangeDisplayed={2}
  marginPagesDisplayed={1}
  onPageChange={handlePageChange}
  containerClassName="pagination"
  activeClassName="active"
/>
  </div>
  );
};

export default RecentContributions;