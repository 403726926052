import { Cancel } from '@mui/icons-material'
import { Box, Button, Divider, Stack } from '@mui/material'
import React from 'react'
import toast from 'react-hot-toast'

const LinkSettings = ({onCancel, handleMaxUseChange, handleDaysChange, daysExpired, maxUse, generateLink, handleBackClick}) => {
    const generateNewLink = async () => {
        try {
            generateLink();
            handleBackClick();
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "])
            }
        }
    }
  return (
    <>
    <Box sx={{padding: '15px'}}>
    <Cancel onClick={onCancel} sx={{cursor: 'pointer', float: 'right'}} />
        <h2 style={{color: 'black', textAlign: 'left', fontSize: '16px'}}>Community link invite settings</h2>
        
    </Box>
    <Divider />
    <Box sx={{padding: '15px', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
        <Stack spacing={1}>
            <h2 className='sub-header-popup'>Expiration in days</h2>
            <input type="text" 
            className='create-aid-input'
            onChange={handleDaysChange}
            value={daysExpired}
            placeholder='Enter the max days for link to expire' />
        </Stack>
        <Stack spacing={1}>
            <h2 className='sub-header-popup'>Max number of users</h2>
            <input type="text" 
            className='create-aid-input'
            onChange={handleMaxUseChange}
            value={maxUse}
            placeholder='Enter the maximum users that can use link' />
        </Stack>
    </Box>
    <Box sx={{display: 'flex', justifyContent: 'flex-end',
        alignItems: 'center', backgroundColor: '#f2f3f5',
        padding: '10px 15px', }}>
            <Button sx={{color: 'white'}} className='button'
            disabled={!daysExpired ||!maxUse}
            onClick={generateNewLink}
            >Generate a new link</Button>
    </Box>
    </>
  )
}

export default LinkSettings