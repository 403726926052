import React, { createContext, useEffect, useState } from 'react';
import { useAuthService } from '../services/auth-service';
import { useApiService } from '../services/api-service';
import toast from 'react-hot-toast';

export const CommunityContext = createContext({
  communityInfo: [],
  setCommunityInfo: () => {},
  loading: false,
  setLoading: () => {}, // Add the setLoading function with a default empty function
  loadCommunity: () => {}, // Make sure loadCommunity is also exported
});

export const CommunityProvider = ({ children }) => {
  const { token, clearToken } = useAuthService();
  const [communityInfo, setCommunityInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiService = useApiService();

  // const getUuid = async () => {
  //   if(token !== null) {
  //   try {
  //     const response = await apiService.community.membership();

  //     if (response.status === 404) return [];
  //     // if(response.status === 401){
  //     //   toast.error("Session expired. Login!");
  //     //   history.push(LOGIN_ROUTE);
  //     // }
  //     else {
  //       const data = await response.data;
  //       const uuids = Object.values(data).map(item => item.comm_uuid);

  //     if (uuids.length === 0) {
  //       setLoading(false);
  //     } else {
  //       await getCommunityInfo(uuids);
  //     }
  //     }
  //   } catch (e) {
  //     if(e.response){
  //       if(e.response.status !== 404){
  //         toast.error(e.response.data["code "])
  //       }else if(e.response.status === 401){
  //         clearToken();
  //       } 
  //     } else {
  //       toast.error('Network Error');
  //     }
  //   } finally{
  //     setLoading(false)
  //   }
  // }else{
  // }
  // };

  // const getCommunityInfo = async uuids => {
  //   try {
  //     const communityDetailsPromises = uuids.map(async uuid => {
  //       const requestBody = {
  //         comm_uuid: uuid
  //       };

  //       const response = await apiService.community.info(requestBody);
  //       const { data } = response; // Extract the data property from the response

  //       const community = {
  //         commName: data.commName,
  //         commDescr: data.commDescr,
  //         commUuid: data.commUuid,
  //         banner: data.banner,
  //         location: data.city,
  //         createdAt: data.createdAt,
  //         updatedAt: data.updatedAt,
  //         communityType: data.communityType,
  //         walletStatus: data.walletStatus
  //       };

  //       return community;
  //     });

  //     const communityDetails = await Promise.all(communityDetailsPromises);
  //     setCommunityInfo(communityDetails); // Update the state with community information
  //   } catch (error) {
  //     console.error(error);
  //   } finally{
  //     setLoading(false)
  //   }
  // };

  const loadCommunity = async () => {
    const MAX_RETRIES = 5;
    const RETRY_DELAY = 1000; // 1 second
    try {
      setLoading(true);
      const response = await apiService.community.membership();

      if (response.status === 200 || response.status === 201) {
        const data = Object.values(response.data || {});

        if (Object.keys(data).length === 0) {
          console.log('data is an empty object');
        } else {
          const fetchWithRetry = async (requestFn, maxRetries) => {
            for (let attempt = 0; attempt < maxRetries; attempt++) {
              try {
                const response = await requestFn();
                if (response.status === 200 || response.status === 201) {
                  const data = response.data;
                  if (Object.keys(data).length > 0) {
                    return data;
                  }
                }
              } catch (error) {
                console.error(`Attempt ${attempt + 1} failed:`, error);
                return null; // Exit the loop on error
              }
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            }
            throw new Error('Max retries reached or error encountered');
          };
          const handleRequest = async (community, requestFn, key) => {
            try {
              const data = await fetchWithRetry(requestFn, MAX_RETRIES);
              if (data) {
                community[key] = data;
              } else {
                community.failed = true;
              }
            } catch (error) {
              console.error(`Error fetching ${key} for community ID ${community.id}`, error);
              community.failed = true;
            }
          };

          const createRequests = (communities, requestFn, key) =>
            communities.map(async (community) =>
              handleRequest(community, () => requestFn(community), key)
            );

          const commDetailsRequests = createRequests(
            data,
            (n) => apiService.community.info({ comm_uuid: n.comm_uuid }),
            'commDetails'
          );

          await Promise.allSettled([
            ...commDetailsRequests
          ]);

          const successfulRequests = data.filter((community) => !community.failed);
          localStorage.setItem('communityInfo', JSON.stringify(successfulRequests));
          setCommunityInfo([...successfulRequests]);
          console.log('Community det', successfulRequests)
        }
      } else {
        console.error('Error fetching community:', response.statusText);
      }
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommunityContext.Provider value={{ communityInfo, setCommunityInfo, loading, setLoading, loadCommunity }}>
  {children}
  </CommunityContext.Provider>  
  );
};