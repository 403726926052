import React, { useState } from 'react';
import './loungeList.css';
import { NO_PROFILE } from '../../utils/routes';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Avatar, Box, Button, Divider, Stack, Tooltip } from '@mui/material';
import RaiseMe from '../../RaiseMe';
import { GroupAdd } from '@mui/icons-material';

const LoungeList = ({ lounges, uuid }) => {
  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <>
    {/*this is for list of raiseme*/}
    <div className='lounge-list'>
      <div className="lounge-list-container">
      {lounges.length === 0 ? (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} gap={0.5}>
          <p>No records of Collective Cash</p>
          <button onClick={handleOpenModal} className='button create-lounge-cta'>Create My Collective Cash</button>
          {/* <Button style={{width: 'fit-content', color: '#a06ccb', backgroundColor: '#ede7f6'}}>Create RaiseMe</Button> */}
        </Stack>
          
        ) : (
          <>
          <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <h2 style={{color: 'black'}}>My Collective Cash</h2>
          <Tooltip title='Create Collective Cash'>
          <button onClick={handleOpenModal} className="button" style={{padding: '7px'}}><GroupAdd/></button>
          </Tooltip>
          </Stack>
          <Divider />
          <Box className='lounge-list-box'>
          {lounges.map((lounge) => (
            <React.Fragment key={lounge.id}>
              <div className="lounge-list-item">
                {/* <img className='profile-img' src={NO_PROFILE} alt={lounge.name} /> */}
                <Avatar alt={`${lounge.name}`} src={lounge.photoUrl} />
                <div className='lounge-list-info'>
                  <h3>{lounge.name}</h3>
                  <p>{lounge.description}</p>
                </div>
                <Link to={`/community/${uuid ?? ''}/${lounge.id}`}>
                  <button className='button view-lounge-cta'>View</button>
                </Link>
                {/* Add any other lounge details you want to display */}
              </div>
              <Divider key={`divider-${lounge.id}`} />
            </React.Fragment>
          ))}
          </Box>
          </Box>
        </>
        )}
      </div>
    </div>
    {openModal && (
      <div className='modal-overlay'>
        <RaiseMe onCancel={handleCloseModal} />
      </div>
    )}
    </>
  );
};

export default LoungeList;
