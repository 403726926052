import * as Yup from 'yup'

export const EMAIL = 'email'

export const PASSWORD = 'password'

export default Yup.object({
  [EMAIL]: Yup.string().required().email(),
  [PASSWORD]: Yup.string().required().min(8)
})
