import React, { useEffect } from 'react';
import { useApiService } from '../../services/api-service';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import toast from "react-hot-toast";
import { useState } from 'react';
import { LOGIN_ROUTE } from '../../utils/routes';
import { useRef } from 'react';
import { useProfileService } from '../../services/profile-service';
import useEmailService from '../../services/email-service';
import './inviteVerificationRaiseMe.css'
import RaiseMeKYC from '../raiseMeKYC/RaiseMeKYC';
import RaiseMeAccount from '../RaiseMeAccount/RaiseMeAccount';
import RaiseMeAdditionalService from '../RaiseMeAdditionalService/RaiseMeAdditionalService';

const InviteVerificationRaiseMe = ({onCancel}) => {
  const modalRef = useRef(null);
  const apiService = useApiService();
  const { token } = useParams();
  const profile = useProfileService();
  const history = useHistory();
  const [raiseMeId, setRaiseMeId] = useState(null);
  const [communityId, setCommunityId] = useState(null);
  const [serviceLocation, setServiceLocation] = useState('Canada');
  const [raiseMePrice, setRaiseMePrice] = useState(0);
  const [selectedTier, setSelectedTier] = useState('1');
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [additionalService, setAdditionalService] = useState('1');
  const [bankName, setBankName] = useState(null);
  const [bankBranch, setBankBranch] = useState(null);
  const [interacEmail, setInteracEmail] = useState(null);
  const [interacPhone, setInteracPhone] = useState(null);
  const [accountType, setAccountType] = useState('');
  const [currency, setCurrency] = useState('');
  const [bvn, setBvn] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [email, setEmail] = useState('');
  const [Date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [gender, setGender] = useState('');
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [province, setProvince] = useState(null);
  const [city, setCity] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [tierAmount, setTierAmount] = useState(0);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLGA, setSelectedLGA] = useState(null);
  const [nextOfKinFirstName, setNextOfKinFirstName] = useState(null);
  const [nextOfKinLastName, setNextOfKinLastName] = useState(null);
  const [nextOfKinGender, setNextOfKinGender] = useState(null);
  const [nextOfKinEmail, setNextOfKinEmail] = useState(null);
  const [nextOfKinPhone, setNextOfKinPhone] = useState(null);
  const [nextOfKinRelationship, setNextOfKinRelationship] = useState(null);
  const [nextOfKinAddress, setNextOfKinAddress] = useState(null);
  const { getUserEmail } = useEmailService();
  const [step, setStep] = useState(1)
  const [isLoadingKYC, setIsLoadingKYC] = useState(true);
  const tiers = [
    {
      id: '1',
      name: 'Tier 1',
      description: 'This is the free package. You can create a maximum of 1 raise me lounge with the maximum of $200 as a contribution amount.',
      price: '0',
    },
    {
      id: '2',
      name: 'Tier 2',
      description: 'This is the $150 package. You can create a maximum of 10 raise me lounges with the maximum of $1000 as a contribution amount.',
      price: '150',
    },
    {
      id: '3',
      name: 'Tier 3',
      description: 'This is the $500 package. You can create unlimited raise me lounges and unlimited contribution amounts.',
      price: '500',
    },
  ];

  const handleTierChange = (tierId) => {
    setSelectedTier(tierId);
    setRaiseMePrice(tiers.find((tier) => tier.id === tierId).price);
  };  


  useEffect(() => {
    if(step === 2){
      const getKYC = async (e) => {
        try {
          const response = await apiService.raiseme.getKYC();
          setStep(step+1)
        } catch (error) {
          
        }finally {
          setIsLoadingKYC(false); // Set loading to false once the request is complete (whether it succeeds or fails)
        }
      }

      getKYC();
    }
  }, [step])

//   const AddMemberToCommunity = async (createdCommunityId) =>{
//     try {
//         const requestBody = {
//           comm_default: false,
//           comm_uuid: createdCommunityId,
//         };
//         await apiService.community.createMember(requestBody);
//       } catch (error) {
//         console.log('Error while adding owner as member');
//       }
//   }

//   useEffect(() => {
//     const verifyToken = async () => {
//       try {
//         const requestBody = { token };
//         const response = await apiService.notification.verify(requestBody);
//         console.log("RESSER", response)
//         // Handle the verification response as needed
//         if (response.status === 200) {
//           // Token verified successfully
//           const createdRaiseMeId = response.data.raiseme_id;
//           const createdCommunityId = response.data.comm_id;
//           setRaiseMeId(createdRaiseMeId);
//           setCommunityId(createdCommunityId);
//           console.log("raise me id", createdRaiseMeId)
//           console.log("comm me id", createdCommunityId)
//           await addMemberToRaiseMe(createdRaiseMeId);
//           toast('Token verified Successfully', {
//             type: 'success',
//             data: {
//               msg: 'Token verified Successfully',
//               type: 'success',
//             },
//           });
//         } else {
//           // Token verification failed
//           console.log('Token verification failed');
//         }
//       } catch (e) {
//         if(e.response){
//             switch(e.response.status){
//                case 401:
//                   toast("Session expired. Login!", {
//                     type: "error",
//                     data: {
//                     msg: "Session expired. Login!",
//                     type: "error",
//                     },
//                   });
//                   history.push(LOGIN_ROUTE)
//                   break
                
//                   case 400:
//                     toast("Token is invalid", {
//                       type: "error",
//                       data: {
//                       msg: "Token is invalid",
//                       type: "error",
//                       },
//                     });
//                     break
    
//                 default:
//                   toast("Server Error", {
//                     type: "error",
//                     data: {
//                       msg: "An error occurred on the server.",
//                       type: "error",
//                     },
//                   });
//                   // history.push('/raise-me')
//             }
//           } else{
//             toast("Network Error", {
//               type: "error",
//               data: {
//                 msg: "Network error, please check your internet connection.",
//                 type: "error",
//               }
//             });
//           }
//       }
//     };

//     verifyToken();
//   }, [apiService.notification.push.verify, token]);

const getRaiseMeInfo = async (raiseMeId) => {
  try {
    const requestBody = {
      raiseme_id: raiseMeId
    }
    const response = await apiService.raiseme.getInfo(requestBody)
    const resLocation = response.data.location;
    setLocation(resLocation);
  } catch (error) {
    
  }
}
  const verifyToken = async () => {
    try {
      const requestBody = { token };
      const response = await apiService.notification.verify(requestBody);
      // Handle the verification response as needed
      if (response.status === 200) {
        // Token verified successfully
        const createdRaiseMeId = response.data.raiseme_id;
        const createdCommunityId = response.data.comm_id;
        setRaiseMeId(createdRaiseMeId);
        setCommunityId(createdCommunityId);
        toast('Token verified Successfully', {
          type: 'success',
          data: {
            msg: 'Token verified Successfully',
            type: 'success',
          },
        });
        await getRaiseMeInfo(createdRaiseMeId);
        setStep(2)
      }
    } catch (e) {
      if(e.response){
        toast(e.response.data["code "], {
          type: "error",
          data: {
            msg: e.response.data["code "],
            type: "error",
          }
        });
      } else {
        toast.error('Network Error');
      }
    }
  };

  const handleCreateKYC = async (e) => {
    e.preventDefault();
    try {
      const requestBody = {
        address_city: city,
        address_line1: address,
        address_line2: address2,
        address_local_government: selectedLGA,
        address_street: address,
        birth_date: Date,
        bvn: bvn,
        country: location,
        email: email,
        first_name: firstName,
        last_name: lastName,
        gender: gender,
        interac_email: interacEmail,
        location: location,
        nationality: serviceLocation,
        next_of_kin_address: nextOfKinAddress,
        next_of_kin_email: nextOfKinEmail,
        next_of_kin_gender: nextOfKinGender,
        next_of_kin_name: `${nextOfKinFirstName} ${nextOfKinLastName}`,
        next_of_kin_phone: nextOfKinPhone,
        next_of_kin_relationship: nextOfKinRelationship,
        other_name: middleName,
        phone_number: phoneNumber,
        postal_code: postalCode
      }
      const response = await apiService.raiseme.createKYC(requestBody);
      if(response.status === 200 || response.status === 201){
        toast.success("KYC Created Successfully");
        setStep(3)
      }
    } catch (error) {
      toast.error("Error creating KYC");
    }
  };

  

  const handleCreateAccount = async (e) => {
    try {
      const requestBody = {
        financialInstitutionName: bankName,
        financialInstitutionBranch: bankBranch,
        accountNumber: accountNumber,
        accountType: accountType,
        bvn: bvn,
        currency: currency,
        interacEmail: interacEmail,
        interacPhoneNumber: interacPhone,
        location: location,
        routingTransitNumber: routingNumber,
        account_name: accountName
      }
      const response = await apiService.raiseme.createAccount(requestBody);
    if (response.status === 200 || response.status === 201) {
      toast.success("Account Created Successfully");
      return true; // Indicate success
    }
  } catch (error) {
    toast.error("Error creating Account");
    return false; // Indicate failure
  }
  }

  const createRaiseMeSubscription = async (e, createdRaiseMeId) => {
    try {
      const requestBody = {
        raiseme_product_id: additionalService,
        amount: tierAmount,
        raiseme_id: raiseMeId
      }
      const response = await apiService.raiseme.createSubscriptions(requestBody);
      if(response.status === 200 || response.status === 201){
        toast("Additional Service Setup Successfully",{
          type: "success",
          data:{
            msg: "Additional Service Setup Successfully",
            type: "success",
          },
        });
      }
      
    } catch (e) {
      if(e.response){
        toast(e.response.data["code "], {
          type: "error",
          data: {
            msg: e.response.data["code "],
            type: "error",
          }
        });
      } else {
        toast.error('Network Error');
      }
    }
  }

  const addMemberToRaiseMe = async (e, createdRaiseMeId, createdCommunityId) => {
    e.preventDefault();
    await createRaiseMeSubscription();
    const userEmail = await getUserEmail();
    
    try {
      await handleCreateAccount();
      const requestBody = {
        account_name: accountName,
        account_number: accountNumber,
        additional_service: "additionalService",
        bank_name: bankName,
        email: userEmail,
        raiseme_id: raiseMeId,
        routing_number: routingNumber,
        user_id: profile.profile.userId,
        user_type: profile.profile.userType
      }
      const response = await apiService.raiseme.addMember(requestBody);
      if(response.status === 200 || response.status === 201){
        toast("Account Setup Successfully",{
          type: "success",
          data:{
            msg: "RaiseMe Created Successfully",
            type: "success",
          },
        });
        history.push(`/community/${createdCommunityId ?? ''}/${createdRaiseMeId}`)
      }
    } catch (e) {
      if(e.response){
        toast(e.response.data["code "], {
          type: "error",
          data: {
            msg: e.response.data["code "],
            type: "error",
          }
        });
      } else {
        toast.error('Network Error');
      }
    }
  }

  return (
    
        <>
      {/* You can show a loading spinner or any message here while the verification is in progress */}
      {/* <p>Verifying token...</p> */}
      <div className="create-community">
      <div className="create-community-modal" ref={modalRef}>
      {step === 1 && (
        <>
        <h2 className='onboarding-text'>Do you want to verify invite to this raiseMe Lounge</h2>
        <div className='dual-buttons'>
            <button className='button verification-btn' onClick={verifyToken}>Yes</button>
            <button className='button verification-btn'>No</button>
        </div>
        </>
        )}
        {step === 2 && !isLoadingKYC && (
        <>
        <RaiseMeKYC onCancel={onCancel} handleCreateKYC={handleCreateKYC} step={step} setServiceLocation={setServiceLocation}
         firstName={firstName} setFirstName={setFirstName}
         lastName={lastName} setLastName={setLastName}
         middleName={middleName} setMiddleName={setMiddleName} email={email} setEmail={setEmail}
         Date={Date} setDate={setDate} location={location} setLocation={setLocation}
         gender={gender} setGender={setGender} countryCode={countryCode} setCountryCode={setCountryCode}
         phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} address={address} setAddress={setAddress}
         address2={address2} setAddress2={setAddress2} province={province} setProvince={setProvince}
         city={city} setCity={setCity} postalCode={postalCode} setPostalCode={setPostalCode}
         selectedCity={selectedCity} setSelectedCity={setSelectedCity} setSelectedLGA={setSelectedLGA} selectedLGA={selectedLGA}
         nextOfKinFirstName={nextOfKinFirstName} setNextOfKinFirstName={setNextOfKinFirstName}
         nextOfKinLastName={nextOfKinLastName} setNextOfKinLastName={setNextOfKinLastName}
         nextOfKinGender={nextOfKinGender} setNextOfKinGender={setNextOfKinGender}
         nextOfKinEmail={nextOfKinEmail} setNextOfKinEmail={setNextOfKinEmail}
         nextOfKinPhone={nextOfKinPhone} setNextOfKinPhone={setNextOfKinPhone}
         nextOfKinAddress={nextOfKinAddress} setNextOfKinAddress={setNextOfKinAddress}
         nextOfKinRelationship={nextOfKinRelationship} setNextOfKinRelationship={setNextOfKinRelationship}
         />
        </>
      )}
        {step === 3 && (
        <>
        <RaiseMeAccount onCancel={onCancel} setServiceLocation={setServiceLocation} serviceLocation={serviceLocation}
        accountName={accountName}
        setAccountName={setAccountName}
        accountNumber={accountNumber}
        setAccountNumber={setAccountNumber}
        routingNumber={routingNumber}
        setRoutingNumber={setRoutingNumber}
        bankName={bankName}
        setBankName={setBankName}
        addMemberToRaiseMe={addMemberToRaiseMe}
        bankBranch={bankBranch}
        setBankBranch={setBankBranch}
        interacEmail={interacEmail}
        setInteracEmail={setInteracEmail}
        accountType={accountType}
        setAccountType={setAccountType}
        currency={currency}
        setCurrency={setCurrency}
        bvn={bvn}
        setBvn={setBvn}
        location={location}
        setLocation={setLocation}
        setInteracPhone={setInteracPhone}
        interacPhone={interacPhone}
        raiseMeId={raiseMeId}
        setRaiseMeId={setRaiseMeId}
        />
        </>
      )}
      {step === 4 && (
        <>
        <RaiseMeAdditionalService
        selectedTier={selectedTier}
        onTierChange={handleTierChange}
        createRaiseMeSubscription={createRaiseMeSubscription}
        setRaiseMePrice={setRaiseMePrice}
        raiseMePrice={raiseMePrice}
        tiers={tiers}
        currency={currency}
      />
        </>
      )}
      </div>
    </div>
    </>
  );
};

export default InviteVerificationRaiseMe;
