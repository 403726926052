import React from 'react'
import Header from '../../components/header/Header'
import UserProfile from '../../components/userProfile/UserProfile'
import { Stack } from '@mui/material'
import { SupportOutlined } from '@mui/icons-material'

const ThriftersCorner = () => {
  return (
    <>
    <Header />
      <div className="communityWrapper">
        <div className='community-sidebar'>
          <UserProfile />
        </div>
        <div className="myPalsContainer">
            <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} height={'100%'}>
                <SupportOutlined style={{fontSize: '5rem', color: '#a06ccbc6'}}/>
                <p className='pals-list'>COMING SOON</p>
            </Stack>
        </div>
        </div>
    </>
  )
}

export default ThriftersCorner