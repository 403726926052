import { useState, createContext } from "react";
import { useApiService } from "../services/api-service";
import toast from "react-hot-toast";
import { useProfileService } from "../services/profile-service";

export const NotificationContext = createContext({
    notificationDetails: [],
    setNotificationDetails: () => {},
    loading: false,
    setLoading: () => {}, // Add the setLoading function with a default empty function
    loadNotification: () => {}, // Make sure loadNotifications is also exported
});

export const NotificationProvider = ({ children }) => {
    const [notificationDetails, setNotificationDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const apiService = useApiService();
    const profile = useProfileService();

    const loadNotification = async () => {
        const MAX_RETRIES = 5;
        const RETRY_DELAY = 1000; // 1 second
        const profileCache = new Map(); // Cache for storing profile data
        try {
          setLoading(true);
          const requestBody = {
            userId: profile.profile.userId,
          };
          const response = await apiService.notification.received(requestBody);
          const notificationData = Object.values(response.data || {});
          // Filter notifications by type
          const filterNotificationsByType = (type) =>
            notificationData.filter((notification) => notification.notification_type === type);
      
          const communityInviteNotifications = filterNotificationsByType('communityInvite');
          const loungeInviteNotifications = filterNotificationsByType('loungeInvite');
          const raiseMeInviteNotifications = filterNotificationsByType('raiseMeInvite');
          const chitInviteNotifications = filterNotificationsByType('chitfundInvite');
          const swapRequestNotifications = filterNotificationsByType('swapRequest');
          const broadcastNotifications = filterNotificationsByType('communityBroadcast');
          const withdrawRequestNotifications = filterNotificationsByType('aidloungeWithdrawal');
          const adminWithdrawalNotifications = filterNotificationsByType('adminManageCommWithdrawal');
          const democratizedWithdrawalNotifications = filterNotificationsByType('democratizedCommWithdrawal');
          const adminCommNotifications = filterNotificationsByType('adminManageCommInvite');
          const upgradeToAdminCommInviteNotifications = filterNotificationsByType('upgradeToAdminManagedCommInvite');
          const adminManageCommNameUpdateNotifications = filterNotificationsByType('adminManageCommNameUpdate');
          const democratizedCommNameUpdateNotifications = filterNotificationsByType('democratizedCommNameUpdate');
    
          const fetchWithRetry = async (requestFn, maxRetries) => {
            for (let attempt = 0; attempt < maxRetries; attempt++) {
              try {
                const response = await requestFn();
                if (response.status === 200 || response.status === 201) {
                  const data = response.data;
                  if (Object.keys(data).length > 0) {
                    return data;
                  }
                }
              } catch (error) {
                console.error(`Attempt ${attempt + 1} failed:`, error);
                return null; // Exit the loop on error
              }
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            }
            throw new Error('Max retries reached or error encountered');
          };
    
          const handleRequest = async (notification, requestFn, key) => {
            try {
              const data = await fetchWithRetry(requestFn, MAX_RETRIES);
              if (data) {
                notification[key] = data;
              } else {
                notification.failed = true;
              }
            } catch (error) {
              console.error(`Error fetching ${key} for notification ID ${notification.id}`, error);
              notification.failed = true;
            }
          };
      
          // Create requests for each notification type
          const createRequests = (notifications, requestFn, key) =>
            notifications.map(async (notification) =>
              handleRequest(notification, () => requestFn(notification), key)
            );

          const raisemeDetailsRequests = createRequests(
            [...raiseMeInviteNotifications, ...chitInviteNotifications],
            (n) => apiService.raiseme.getRaiseMeInfo({ raiseme_id: n.raiseme_id }),
            'raisemeInfo'
          )
    
          const loungeInfoRequests = createRequests(
            loungeInviteNotifications,
            (n) => apiService.lounge.getInfo({ lounge_uuid: `${n.lounge_id}` }),
            'loungeInfo'
          );
      
          // const userDetailsRequests = createRequests(
          //   [...swapRequestNotifications, ...broadcastNotifications, ...withdrawRequestNotifications, ...adminCommNotifications, ...adminWithdrawalNotifications, ...loungeInviteNotifications, ...upgradeToAdminCommInviteNotifications, ...democratizedWithdrawalNotifications, ...adminManageCommNameUpdateNotifications],
          //   (n) => apiService.profile.profileDetails({ user_id: n.sender_id }),
          //   'senderDetails'
          // );
          // Collect unique user IDs from all notifications
        const uniqueUserIds = new Set([
          ...swapRequestNotifications,
          ...broadcastNotifications,
          ...withdrawRequestNotifications,
          ...adminCommNotifications,
          ...adminWithdrawalNotifications,
          ...loungeInviteNotifications,
          ...upgradeToAdminCommInviteNotifications,
          ...democratizedWithdrawalNotifications,
          ...adminManageCommNameUpdateNotifications,
          ...democratizedCommNameUpdateNotifications,
      ].map(notification => notification.sender_id));

      // Fetch profile details for each unique user ID
      const userDetailsRequests = Array.from(uniqueUserIds).map(async userId => {
          if (!profileCache.has(userId)) {
              const profileData = await fetchWithRetry(() => apiService.profile.profileDetails({ user_id: userId }), MAX_RETRIES);
              if (profileData) {
                  profileCache.set(userId, profileData);
              }
          }
      });

      await Promise.all(userDetailsRequests);

      // Update notifications with fetched profile details
      notificationData.forEach(notification => {
          const userId = notification.sender_id;
          if (profileCache.has(userId)) {
              notification.senderDetails = profileCache.get(userId);
          } else {
              notification.failed = true;
          }
      });
      
          const aidLoungeInfoRequests = createRequests(
            withdrawRequestNotifications,
            (n) => apiService.aidlounge.info({ aid_lounge_id: `${n.aidlounge_id}` }),
            'aidLoungeInfoResponse'
          );

          const commDetailsRequests = createRequests(
            [...broadcastNotifications, ...adminCommNotifications, ...adminWithdrawalNotifications, ...democratizedWithdrawalNotifications, ...upgradeToAdminCommInviteNotifications, ...communityInviteNotifications, ...adminManageCommNameUpdateNotifications, ...democratizedCommNameUpdateNotifications],
            (n) => apiService.community.info({ comm_uuid: n.comm_uuid }),
            'commName'
          )
      
          await Promise.allSettled([
            ...raisemeDetailsRequests,
            ...userDetailsRequests,
            ...aidLoungeInfoRequests,
            ...commDetailsRequests,
            ...loungeInfoRequests
          ]);
      
          // Filter out notifications with fetch errors
          const successfulNotifications = notificationData.filter((notification) => !notification.failed);
      
          // Sort the notificationData by created_at in descending order
          notificationData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          localStorage.setItem('notificationDetails', JSON.stringify(notificationData));
          setNotificationDetails([...notificationData]);
        } catch (e) {
          if (e.response) {
            toast.error(e.response.data.code);
          } else {
            console.log('error', e);
            toast.error('Network Error');
          }
        } finally {
          setLoading(false);
        }
    };

    return (
        <NotificationContext.Provider
            value={{
                notificationDetails,
                setNotificationDetails,
                loading,
                setLoading,
                loadNotification,
            }}>
            {children}
        </NotificationContext.Provider>
    )
}
