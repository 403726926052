import React, { useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { Fade, Modal, Backdrop, Box, LinearProgress, Avatar } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import UserProfileDetail from '../UserProfileDetail/UserProfileDetail';
import './raiseMeMembers.css'

const RaiseMeMembers = ({ members, memberEmails, setSelectedLounge, selectedLounge, loadMembers }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // adjust this to your desired number of items per page
  const [dropdownVisibility, setDropdownVisibility] = useState({});
  const dropdownRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const toggleDropdown = (userId) => {
    setDropdownVisibility((prevVisibility) => ({
      ...prevVisibility,
      [userId]: !prevVisibility[userId], // Toggle visibility for the specific member
    }));
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if any of the dropdowns are open and if the click occurs outside any of them
      const isClickOutsideAnyDropdown = Object.keys(dropdownVisibility).some((userId) => {
        const dropdownNode = dropdownRef.current;
        return dropdownVisibility[userId] && dropdownNode && !dropdownNode.contains(event.target);
      });

      if (isClickOutsideAnyDropdown) {
        const updatedDropdownVisibility = {};
        Object.keys(dropdownVisibility).forEach((userId) => {
          updatedDropdownVisibility[userId] = false;
        });
        setDropdownVisibility(updatedDropdownVisibility);
      }
    };

    if (Object.values(dropdownVisibility).some((visibility) => visibility)) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [dropdownVisibility]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const filteredMembers = members.filter((member) => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const lowerCaseFirstName = member.userDetails.firstName.toLowerCase();
    const lowerCaseLastName = member.userDetails.lastName.toLowerCase();

    const matchFirstName = lowerCaseFirstName.includes(lowerCaseSearchQuery);
    const matchLastName = lowerCaseLastName.includes(lowerCaseSearchQuery);

    return matchFirstName || matchLastName;
  });

  const renderMembers = () => {
    if (filteredMembers.length === 0) {
      return (
        <tr>
          <td colSpan="4">No records found</td>
        </tr>
      );
    }
    const offset = currentPage * itemsPerPage;
    const paginatedMembers = filteredMembers.slice(offset, offset + itemsPerPage);

    if (paginatedMembers.length === 0) {
      return <tr><td colSpan="7">No results found</td></tr>;
    }

    return paginatedMembers.map((member) => {
      return (
        <tr className="table-row" key={member.userDetails.userId}>
          <td>
            <h2>
            <Avatar alt={`${member.userDetails.firstName} ${member.userDetails.lastName}`} src={member.userDetails.photoUrl} />
                <p>{member.userDetails.firstName} {member.userDetails.lastName}</p>
            </h2>
          </td>
          <td style={{ textAlign: 'center' }}>{member.email.email || 'N/A'}</td>
          <td style={{ textAlign: 'center' }}>
            <div className="more-icon" onClick={() => toggleDropdown(member.userDetails.userId)}>
              <MoreHoriz />
            </div>
            {dropdownVisibility[member.userDetails.userId] && (
              <div className="dropdown" ref={dropdownRef}>
                {/* <button className="dropdown-option">Swap</button> */}
                <button onClick={() => handleOpen(member)} className="dropdown-option">View Account Info</button>
              </div>
            )}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}>
              <Fade>
                <Box className="member-popup-modal">
                  <UserProfileDetail member={selectedMember} />
                </Box>
              </Fade>
            </Modal>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className='raiseme-members-container'>
      <div className="sort-list-container">
        <div className="raiseme-sort-search-box">
          <input
            className='member-search-input-box'
            type="text"
            placeholder="Search by name"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="contribution-tabs">
        <table className="table">
          <thead className="table-head">
            <tr >
              <th>Name</th>
              <th style={{ textAlign: 'center' }}>Contact</th>
              <th style={{ textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadMembers ? (
              <>
                <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
              </>
            ) : (
              <>
                {renderMembers()}
              </>
            )}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        pageCount={Math.ceil(filteredMembers.length / itemsPerPage)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
}

export default RaiseMeMembers;
