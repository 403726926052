import React, { useEffect, useState } from 'react'
import '../RaiseMe/raiseMe.css'
import '../OnBoarding/onboarding.css';
import '../../components/createCommunity/createCommunity.css';
import './communityPost.css'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Header from '../../components/header/Header';
import UserProfile from '../../components/userProfile/UserProfile';
import ShareComment from '../../components/ShareComment/ShareComment';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import { useCommunityPostsContext } from '../../contexts/CommunityPostsContext';
import { LinearLoad, NO_PROFILE, getFileType } from '../../utils/routes';
import { ArrowBack, Block, Comment, Download, Favorite, FavoriteBorder, Flag, MoreVert, Share } from '@mui/icons-material';
import { Avatar, Backdrop, Box, CircularProgress, Fade, Menu, MenuItem, Modal, Skeleton } from '@mui/material';
import ReportPost from '../../components/post/ReportPost';
import toast from 'react-hot-toast';
import ConfirmRequest from '../../utils/ConfirmRequest';
import FileViewer from '@codesmith-99/react-file-preview'

const CommunityPost = () => {
    const {uuid, postId} = useParams();
    const [communityPosts, setCommunityPosts] = useState([]);
    const [communityComments, setCommunityComments] = useState([]);
    const [isSharing, setIsSharing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingComments, setLoadingComments] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false);
    const apiService = useApiService();
    const { profile } = useProfileService();
    const [showOptionsMenu, setShowOptionsMenu] = useState(false); // Add state for options menu
    const [showComments, setShowComments] = useState(true);
    const [commentText, setCommentText] = useState('');
    const { refreshCommunityPosts } = useCommunityPostsContext();
    const [isLiked, setIsLiked] = useState(null);
    const [likesCount, setLikesCount] = useState(null);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isBlocking, setIsBlocking] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [postComments, setPostComments] = useState([]);

    //open confirm request
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);


    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const loadComments = async (postId) => {
      try {
        console.log('Loading comments for post:', postId);
        setLoadingComments(true);
    
        const response = await apiService.community.allComments({
          parent_id: postId,
        });
    
        if (response.status === 200 || response.status === 201) {
          const commPosts = response.data;
    
          // Use a set to store unique user IDs
          const userIdsSet = new Set();
    
          // Identify user IDs to fetch details for
          commPosts.forEach(post => userIdsSet.add(post.user_id));
    
          // Convert set to an array for easier manipulation
          const uniqueUserIds = Array.from(userIdsSet);
    
          // Throttle the number of concurrent requests to avoid overwhelming the server
          const concurrencyLimit = 5; // Adjust as needed
          const chunks = Array.from({ length: Math.ceil(uniqueUserIds.length / concurrencyLimit) }, (_, index) =>
            uniqueUserIds.slice(index * concurrencyLimit, (index + 1) * concurrencyLimit)
          );
    
          // Fetch user details in chunks
          const userDetailsChunks = await Promise.all(
            chunks.map(async (userIdsChunk) => {
              const userDetailsPromises = userIdsChunk.map(async (userId) => {
                try {
                  const userResponse = await apiService.profile.profileDetails({ user_id: userId });
                  return (userResponse.status === 200 || userResponse.status === 201) ? userResponse.data : null;
                } catch (error) {
                  return null; // Handle errors if needed
                }
              });
              return Promise.all(userDetailsPromises);
            })
          );
    
          // Flatten the array of chunks
          const userDetails = userDetailsChunks.flat();
    
          // Update posts with fetched user details
          const postsWithUserDetails = await Promise.all(
            commPosts.map(async (post) => {
              // Find user details for the current post
              const userDetailsForPost = userDetails.find(userDetail => userDetail && userDetail.userId === post.user_id);
    
              let liked = false;
              let reactionId = null;
    
              try {
                // Check if the post has been liked
                const reactionStatusResponse = await apiService.community.reactionStatus({ post_id: post.post_id });
                liked = reactionStatusResponse.status === 200;
                reactionId = liked ? reactionStatusResponse.data.id : null;
              } catch (error) {
                // Handle 404 response (Post not liked)
                if (error.response && error.response.status === 404) {
                  liked = false;
                  reactionId = null;
                } else {
                  throw error; // Rethrow other errors
                }
              }
    
              return { ...post, userDetails: userDetailsForPost, liked, reaction_id: reactionId };
            })
          );
    
          // Sort posts by createdAt in descending order
          postsWithUserDetails.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setPostComments(postsWithUserDetails);
          console.log('Posts sorted', postsWithUserDetails);
        } else {
          console.error('Failed to load comments, response status:', response.status);
        }
      } catch (e) {
        console.error('Error loading comments:', e);
        if (e.response) {
          if (e.response.status !== 404) {
            toast.error(e.response.data["code"]);
          }
        } else {
          toast.error("Network Error");
        }
      } finally {
        setLoadingComments(false);
      }
    };
    
    const fetchPosts = async (postId) => {
      try {
        setIsLoading(true);
        const requestBody = { post_id: postId };
        console.log('Fetching post with postId:', postId);
        const response = await apiService.community.getPost(requestBody);
    
        if (response.status === 200 || response.status === 201) {
          const commPost = response.data[0];
          console.log('Post data:', commPost);
    
          // Fetch user details for the post author
          const userResponse = await apiService.profile.profileDetails({
            user_id: commPost.user_id,
          });
    
          if (userResponse.status === 200 || userResponse.status === 201) {
            const userDetails = userResponse.data;
            let liked = false;
            let reactionId = null;
    
            try {
              // Check if the post has been liked
              const reactionStatusResponse = await apiService.community.reactionStatus({ post_id: postId });
              liked = reactionStatusResponse.status === 200;
              reactionId = liked ? reactionStatusResponse.data.id : null;
            } catch (error) {
              // Handle 404 response (Post not liked)
              if (error.response && error.response.status === 404) {
                liked = false;
                reactionId = null;
              } else {
                throw error; // Rethrow other errors
              }
            }
    
            const postWithDetails = {
              ...commPost,
              userDetails: userDetails,
              liked,
              reaction_id: reactionId
            };
    
            setLikesCount(postWithDetails.likes_count);
            setIsLiked(postWithDetails.liked);
            setCommunityPosts([postWithDetails]);
            
            // Load comments for the post
            await loadComments(postId);
          } else {
            console.error('Failed to fetch user details, response status:', userResponse.status);
            // Handle error fetching user details
            setCommunityPosts([commPost]);
          }
        } else {
          console.error('Failed to fetch post, response status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching post:', error);
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    

      const toggleComments = () => {
        setShowComments((prevShowComments) => !prevShowComments);
      };
    
      const toggleOptionsMenu = () => {
        setShowOptionsMenu((prevShowOptionsMenu) => !prevShowOptionsMenu);
      };
    
      const handleCommentChange = (event) => {
        setCommentText(event.target.value);
      };

      const handleCommentSubmit = async () => {
        try {
          setIsSharing(true)
            const requestBody = {
                community_id: uuid,
                message: commentText,
                user_id: profile.userId,
                parent_id: postId
            }
            await toast.promise(
              apiService.community.createPost(requestBody),
              {
                loading: 'Posting comment...',
                success: 'Comment posted successfully',
                error: 'Error posting comment'
              }
            )
              const newComment = {
                ...requestBody, // Assuming your API response contains necessary data for the new comment
                userDetails: profile,
                like_count: 0,
                comment_count: 0,
              };

              // Update the local state with the new comment
                communityPosts.comments.push(newComment);
              fetchPosts();
              loadComments(postId)
              setCommentText('');
        } catch (e) {
          toast.error()
        } finally{
          setIsSharing(false)
        }
      };
    
      const handleDeletePosts = async () => {
        try {
          setIsDeleting(true)
          const response = await toast.promise(
            apiService.community.deletePosts(postId),
            {
              loading: 'Deleting post...',
              success: 'Post deleted successfully',
              error: 'Error deleting post'
            }
          )
          if(response.status === 200 || response.status === 201){
            refreshCommunityPosts();
            history.goBack()
        }
        } catch (e) {
          
        } finally{
          setIsDeleting(false)
        }
      }

      const handleBlockUser = async (data) => {
        try {
          setIsBlocking(true)
          await toast.promise(
            apiService.user.blockUser({
              blocker_id: profile.userId,
              blocked_id: data.user_id
            }),
            {
              loading: 'Blocking user...',
              success: 'User blocked successfully',
              error: 'Error blocking user'
            }
          )
          refreshCommunityPosts();
          history.goBack()
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }
        } finally{
          setIsBlocking(false)
        }
      }
    
      const getTimeDifference = (createdAt) => {
        const currentDate = new Date();
        const pastDate = new Date(createdAt);
    
        const timeDifference = currentDate.getTime() - pastDate.getTime();
        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
    
        if (daysDifference > 0) {
          return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
        } else if (hoursDifference > 0) {
          return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
          return `${minutesDifference} min${minutesDifference > 1 ? 's' : ''} ago`;
        } else {
          return 'Just now';
        }
      };

      const likePost = async () => {
        try {
          setIsLiked(true)
          setLikesCount((prevLikesCount) => prevLikesCount + 1);
          const requestBody = {
            post_id: postId,
            rxn_type: 'like'
          }
          const response = await apiService.community.createReaction(requestBody);
          if(response.status === 200 || response.status === 201){
            refreshCommunityPosts();
        }
        } catch (e) {
          
        }
      }
    
      const unLikePost = async () => {
        try {
            setIsLiked(false)
            setLikesCount((prevLikesCount) => prevLikesCount - 1);
          const response = await apiService.community.deleteReaction(postId);
          if(response.status === 200 || response.status === 201){
            refreshCommunityPosts();
        }
        } catch (e) {
          
        }
      }

      const handleMenuItemClick = async (action) => {
        // Implement actions based on the selected menu item (e.g., delete or make admin)
        if (action === 'delete') {
          await handleDeletePosts();
        }
        if(action === 'block'){
          handleMenuClose();
          handleOpenConfirm();
          // await handleBlockUser(data);
        }
        if(action === 'report'){
          handleMenuClose();
          handleOpen();
        }
      };
    
      useEffect(() => {
        fetchPosts(postId);
        refreshCommunityPosts();
      }, [postId, uuid])
  return (
    <>
    <Header uuid={uuid} />
    <div className="communityWrapper">
        <div>
          <UserProfile />
          {/* <MembersCard />
          <EventCard /> */}
        </div>
        <div className='community-post-container'>
        {isLoading ? (
          // Display skeleton while loading
          <>
        <Box gap={'0.5rem'} height={'100vh'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress style={{ color: '#a06ccb'}}/>
        <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
        </Box>
        </>
        ) : (
          <>
            {communityPosts.map((data, index) => (
                <div className="post" key={index}>
                <div className="post-back">
                <ArrowBack onClick={() => history.goBack()}/>
                <p>Post</p>
                </div>
                <div className="postHeader">
                <div className="postHeader-left">
                    <Avatar alt={`${data.userDetails.firstName} ${data.userDetails.lastName}`} src={data.userDetails.photoUrl} />
                    {/* <img src={data.userDetails.photoUrl || NO_PROFILE} alt={data.userDetails.photoUrl} /> */}
                    <div className='postHeader-userInfo'>
                    <Link to={`/profile/${data.userDetails.userId}`}>
                    <p className='userInfo-name'>{data.userDetails.firstName} {data.userDetails.lastName}</p>
                    </Link>
                    <p className='userInfo-location'>{data.userDetails.city}, {data.userDetails.country}</p>
                    </div>
                    </div>
                    <div className="postHeader-right">
                    <div className="postime">
                    {getTimeDifference(data.created_at)}
                    <span onClick={(e) => handleMenuOpen(e)}><MoreVert /></span>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                        {data.user_id === profile.userId && (
                        <MenuItem onClick={() => handleMenuItemClick('delete')}>Delete</MenuItem>
                      )}
                      {data.user_id !== profile.userId && (
                          <>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('report')}>
                        <Flag />
                        Report
                        </MenuItem>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('block')}>
                        <Block />
                        Block
                        </MenuItem>
                        </>
                        )}
                    </Menu>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <Box className="comm-popup-modal">
                          <ReportPost refreshPosts={refreshCommunityPosts} handleClose={handleClose} selectedData={data}/>
                        </Box>
                      </Fade>
                    </Modal>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openConfirm}
                      onClose={handleCloseConfirm}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <ConfirmRequest
                        sendingAction={isBlocking}
                        title={'Block User?'}
                        text={`Are you sure you want to block ${data.userDetails.firstName} ${data.userDetails.lastName}?`}
                        onAction={() => handleBlockUser(data)} // Pass a function reference
                        onClose={handleCloseConfirm}
                        actionButton={'Block'}
                        sendingActionButton={'Blocking...'}/>
                      </Fade>
                    </Modal>
                    </div>
                    </div>
                </div>
                <div className='detail'>
                    <span>{data.message}</span>
                </div>
                {data.media_url && (
          <div style={{overflowY: 'auto', maxHeight: '100%'}}>
            {['jpg', 'jpeg', 'png', 'gif'].includes(getFileType(data.media_url)) ? (
              <>
              <img style={{cursor: 'pointer'}} className='media-img-attached' src={data.media_url} onClick={handleOpenImage}/>
              <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openImage}
                      onClose={handleCloseImage}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                          className: 'custom-backdrop'
                        },
                    }}>
                      <Fade>
                      <Box className="image-popup-modal">
                        <img className='media-img-attached-lightbox' src={data.media_url} />
                        </Box>
                      </Fade>
                    </Modal>
              </>
            ) : (
              <FileViewer
              src={data.media_url}
              fileName={getFileType(data.media_url)}/>
            )}
        </div>
        )}
                {/* <img src={data.img} alt='' className='postImg' /> */}
                <div className="postReaction">
          <div className="postReaction-likes">
          {isLiked? 
                <Favorite style={{color: 'red'}} onClick={unLikePost}/> :
                <FavoriteBorder onClick={likePost}/>
            }
            <p>{likesCount} Likes</p>
          </div>
          <div className="postReaction-comments" onClick={toggleComments}>
          <Comment className='comments-icon'  />
          <p>{data?.comments_count <= 1 ? `${data?.comments_count} Comment` : `${data?.comments_count} Comments`}</p>
          </div> 
          {data.media_url && (
            <a href={data.media_url} target='_blank'>
            <div className='postReaction-comments'>
              <Download />
              Download attachment
            </div>
            </a>
          )}
            {/* <Share/> */}
        </div>

                {showComments && (
                  <>
                  {loadingComments ? (
                    <>
                    {LinearLoad()}
                    </>
                  ) : (
                    <>
                    <Box >
                    <ShareComment commentText={commentText}
                      handleCommentChange={handleCommentChange}
                      handleCommentSubmit={handleCommentSubmit}
                      data={data}
                      isSharing={isSharing}
                      comment={communityComments}
                      postComments={postComments}
                      profile={profile}
                      setIsSharing={setIsSharing}
                      setCommunityPosts={setCommunityPosts}
                      loadComments={loadComments}
                      postId={postId}
                      />
                      </Box>
                      </>
                  )}
              </>
            )}
                </div>
            ))}
            </>
            )}
        </div>
      </div>
    </>
  )
}

export default CommunityPost