import React, { useEffect, useState } from 'react';

const useCountryCityList = () => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Fetch list of countries
    fetch('https://api.geonames.org/countryInfoJSON?username=MEETONOME')
      .then(response => response.json())
      .then(data => {
        const countryOptions = data.geonames.map((country, index) => ({
          id: index,
          name: country.countryName,
          code: country.countryCode,
        }));
        setCountries(countryOptions);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, []);

  const fetchCitiesByCountry = (countryCode) => {
    // Fetch list of cities for the selected country
    fetch(`https://api.geonames.org/searchJSON?username=MEETONOME&country=${countryCode}&maxRows=1000&style=SHORT`)
      .then(response => response.json())
      .then(data => {
        const cityOptions = data.geonames.map((city, index) => ({
          id: index,
          name: city.name,
        }));
        setCities(cityOptions);
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  };

  return { countries, cities, fetchCitiesByCountry };
};

export default useCountryCityList;