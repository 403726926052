import React, { useEffect } from 'react'
import Header from '../../components/header/Header'
import UserProfile from '../../components/userProfile/UserProfile'
import './raisemewallet.css'
import RaiseMeWalletOverview from '../../components/RaiseMeWalletOverview/RaiseMeWalletOverview'
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { useState } from 'react'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useApiService } from '../../services/api-service'
import { useProfileService } from '../../services/profile-service'
import toast from "react-hot-toast";
import { formatCurrency, getTransactionType } from '../../utils/routes'

const RaiseMeWallet = () => {
    const [tableData, setTableData] = useState([]);
      const [loading, setLoading] = useState();
      const apiService = useApiService();
      const profile = useProfileService();
      const [selectedTransactionType, setSelectedTransactionType] = useState('');
      const [startDate, setStartDate] = useState('');
      const [endDate, setEndDate] = useState('');

      useEffect(() => {
        const loadUserTransactions = async (e) => {
          try {
            setLoading(true)
            const requestBody = {
              user_id: profile.profile.userId
            }
            const response = await apiService.raiseme.getTransactionByID(requestBody);
            const table = response.data;
            setTableData(table);

          } catch (error) {
            if (error.response) {
              if(error.response.status !== 404){
                toast.error(error.response.data["code "]);
              }
            } else{
              toast.error('Network Error!')
            }
          } finally{
            setLoading(false)
          }
        }

        loadUserTransactions();
      }, [])
      const [page, setPage] = React.useState(0);
      const [rowsPerPage, setRowsPerPage] = React.useState(5);
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

      const filteredData = tableData.filter((data) => {
        const transactionDate = new Date(data.transaction_date);
        const filterStartDate = startDate ? new Date(startDate) : null;
        const filterEndDate = endDate ? new Date(endDate) : null;
      
        // Check if the transaction date is within the selected date range
        const isDateWithinRange =
          (!filterStartDate || transactionDate >= filterStartDate) &&
          (!filterEndDate || transactionDate <= filterEndDate);
        
        const isTransactionTypeMatch = !selectedTransactionType || getTransactionType(data) === selectedTransactionType;
      
        // Return true if both date and transaction type match the selected criteria
        return isDateWithinRange && isTransactionTypeMatch;
      });

      const calculateCreditAndPercentage = (transactions) => {
        // Filter transactions with status "APPROVED" or "SETTLED"
        const filteredTransactions = transactions.filter(
          (transaction) => transaction.status === 'APPROVED' || transaction.status === 'SETTLED'
        );
      
        // Separate filtered transactions into credit and debit
        const creditTransactions = filteredTransactions.filter((transaction) => getTransactionType(transaction) === 'CREDIT');
        const debitTransactions = filteredTransactions.filter((transaction) => getTransactionType(transaction) === 'DEBIT')
      
        // Calculate total credited amount for filtered transactions
        const totalCreditedAmount = creditTransactions.reduce((total, transaction) => total + transaction.transaction_amount, 0);
      
        // Calculate total debited amount for filtered transactions
        const totalDebitedAmount = debitTransactions.reduce((total, transaction) => total + transaction.transaction_amount, 0);
      
        return {
          totalCreditedAmount,
          totalDebitedAmount,
        };
      };
      
      

      const { totalCreditedAmount, totalDebitedAmount } = calculateCreditAndPercentage(tableData);
      
    
      const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
      const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
        const mapStatus = (status) => {
          if(status === 'ACCEPTED' || status === 'OK' || status === 'SUBMITTED' || status === 'PENDING') return 'In Progress'
          else if(status === 'APPROVED' || status === 'SETTLED') return 'Completed'
          else return 'Error'
        };
  return (
    <>
    <Header/>
    <div className="community-details-wrapper">
        <div className="community-details-sidebar">
            <UserProfile/>
        </div>
    
        <div className="community-details-container">
        <h2 className='transaction-title'>Transaction Dashboard</h2>
        <RaiseMeWalletOverview totalCreditedAmount={totalCreditedAmount} totalDebitedAmount={totalDebitedAmount}/>
        <div className="transactionsContainer">
            <div className="transaction-top">
                <div className="transaction-top-left">
                <h3>Transaction History</h3>
                </div>
                <div className="transaction-top-right">
                <input
    placeholder='Start Date'
    type="date"
    value={startDate}
    onChange={(e) => setStartDate(e.target.value)}
  />
  <input
    placeholder='End Date'
    type="date"
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
  />
                <div>
            <FormControl sx={{ m: 1, minWidth: 190 }}>
                <InputLabel sx={{color: 'light.main'}} id="demo-simple-select-autowidth-label">Transaction Type</InputLabel>
                <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedTransactionType}
    onChange={(event) => setSelectedTransactionType(event.target.value)}
                autoWidth
                label="Select Period"
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value="DEBIT">Debit</MenuItem>
                <MenuItem value="CREDIT">Credit</MenuItem>
                </Select>
            </FormControl>
            </div>
                </div>
            </div>
            <div className="transaction-bottom">
                <TableContainer sx={{maxHeight: '450px', marginTop: '10px'}}>
                    <Table aria-label='transaction-history' stickyHeader>
                        <TableHead>
                            <TableRow sx={{backgroundColor: 'light.main'}}>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black'}}>Date</TableCell>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black', textAlign: 'center'}}>Amount</TableCell>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black', textAlign: 'center'}}>Channel</TableCell>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black', textAlign: 'center'}}>Transaction Type</TableCell>
                                <TableCell sx={{backgroundColor: 'light.palemain', color: 'black', textAlign: 'center'}}>Status</TableCell>
                                {/* <TableCell sx={{backgroundColor: 'light.palemain', color: 'black', textAlign: 'center'}} align='right'>Action</TableCell> */}
                            </TableRow>
                        </TableHead>
                        {loading ? (
                        <LinearProgress style={{ marginBottom: '1rem', backgroundColor: '#a06ccb' }} />
                      ) : (
                        <>
                        {filteredData.length === 0 ? (
                          <p>No transaction history found</p>
                      ) : (
                        <TableBody>
                        {filteredData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((data, index) => (
                                <TableRow key={index}>
                                <TableCell>{new Date(data.transaction_date).toLocaleString('en-US', {
                                  month: 'short',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                  day: 'numeric',
                                  ordinal: 'numeric'
                                })}</TableCell>
                                <TableCell style={{textAlign: 'center'}} className='transaction-amount'>{formatCurrency(data.transaction_amount)}</TableCell>
                                <TableCell style={{textAlign: 'center'}}>{data.transaction_type}</TableCell>
                                <TableCell className='center-table-cell'>
                                    <span style={{ 
                                  backgroundColor:
                                  getTransactionType(data) === 'CREDIT'
                                    ? '#A6F8BD' // green
                                    : '#FCA1A1', textAlign: 'center', padding: '0.5rem', borderRadius: '5px' }}>{getTransactionType(data)}</span>
                                </TableCell>
                                <TableCell style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                                    <p style={{ 
                                  backgroundColor:
                                  data.status === 'SETTLED'
                                    ? '#A6F8BD' // green
                                    : data.status === 'OK' || data.status === 'ACCEPTED' || data.status === 'SUBMITTED' || data.status === 'PENDING'
                                    ? '#FCD0A1' // peach
                                    : '#FCA1A1', textAlign: 'center', padding: '0.5rem', borderRadius: '5px' }}>{mapStatus(data.status)}</p>
                                </TableCell>
                                {/* <TableCell style={{textAlign: 'center'}} align='right'><MoreHoriz/></TableCell> */}
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        )}
                         </>
                        )}
                    </Table>
                    <TablePagination
                    sx={{color: 'black'}}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            on
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </TableContainer>
            </div>
        </div>
        </div>
    </div>
    </>
  )
}

export default RaiseMeWallet