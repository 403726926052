import { useEffect } from 'react';
import { useApiService } from '../services/api-service';
import { useAuthService } from './auth-service';
import toast from "react-hot-toast";

const useEmailService = () => {
  const apiService = useApiService();
  const {clearToken} = useAuthService();

  const getUserEmail = async () => {
    try {
      const response = await apiService.auth.userProfile();
      return response.data.email;
    } catch (error) {
      if(error.response.status === 401){
        // clearToken();
        // toast.error('Session Expired! Login')
    }
      throw error;
    }
  };

  useEffect(() => {
    // Additional setup or initialization logic if needed
    // For example, handle authentication and token management
  }, []);

  return { getUserEmail };
};

export default useEmailService;
