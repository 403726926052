import { Box, Button } from '@mui/material'
import React from 'react'

const AlertMessage = ({message, title, handleCloseAlert}) => {
  return (
    <Box className="raiseme-position-modal">
        <h2 style={{textAlign: 'center'}} className='blackText'>{title}</h2>
        {message}
        <Button onClick={handleCloseAlert} sx={{color: 'white', width: '100%', marginTop: '10px'}} className='button'>Cancel</Button>
    </Box>
  )
}

export default AlertMessage