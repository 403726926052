import React, { useState } from 'react'
import '../Lounges/lounges.css'
import SuggestedCommunities from '../../components/SuggestedCommunities/SuggestedCommunities'
import Header from '../../components/header/Header';
import './joinCommunityPage.css'
import { Chair } from '@mui/icons-material';

const JoinCommunityPage = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
      };
  return (
    <div>
    <Header/>
    <div className="join-community-wrapper">
    <div className="section-banner">
            <Chair />
            <div>
              <h2>Suggested Communities</h2>
              <p>The communities we recommend for you!</p>
            </div>

          </div>
    <div className="join-community-container">
                <input
                className='search-communities'
                type="text"
                placeholder='Search Communities'
                value={searchQuery}
                onChange={handleSearch}
              />
              <div className="grid">
              <SuggestedCommunities searchQuery={searchQuery}/>
              </div>
    </div>
    </div>
    </div>
  )
}

export default JoinCommunityPage