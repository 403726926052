import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { LOGIN_ROUTE } from '../utils/routes'
import { useAuthService } from '../services/auth-service'

/**
 * Render a Private Route.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.component
 */
function PrivateRoute ({ component, ...rest }) {
  const { token } = useAuthService()

  return (
    <Route
      {...rest}
      component={
        token !== null
          ? component
          : () => (
              <Redirect
                to={{
                  pathname: LOGIN_ROUTE,
                  state: {
                    next: rest.location.pathname
                  }
                }}
              />
            )
      }
    />
  )
}

export default PrivateRoute
