import { Avatar, Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import InputFieldError from '../../components/forms/inputFieldError/input-field-error';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { capitalizeFirstLetter } from '../../utils/routes';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import loginFormValidator from '../../utils/validators/login-form-validator';

const LoginScreen = (
    {
        handleLogin, initialValues, handleRegisterClick,
        PASSWORD, EMAIL, showPassword, setShowPassword, rememberMe,
        setRememberMe, isUnverified, isResending, handleResendEmail, community, user
    }
) => {
  return (
    <>
                  <Avatar src={user.photoUrl} sx={{width: '75px', height: '75px'}}/>
                  <h2 className='text-md'>{user.firstName} {user.lastName} has invited you to join</h2>
                <Stack direction={'row'} spacing={0.5}>
                  <Avatar sx={{width: '30px', height: '30px'}} variant='rounded'>
                    {capitalizeFirstLetter(community.commName)}
                  </Avatar>
                  <h2 className='title-md'>{community.commName} Community</h2>
                </Stack>
                  <Box sx={{padding: '15px', display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%'}}>
                  <Formik
                            onSubmit={handleLogin}
                            initialValues={initialValues}
                            validationSchema={loginFormValidator}
                          >
                            {({ handleChange, handleSubmit, values, errors, touched, isSubmitting, handleBlur, isValid }) => (
                            <Form style={{display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%'}}>
                              <Stack spacing={1}>
                                <h2 className='sub-header-popup'>E-mail</h2>
                                <input
                                type='email'
                                placeholder='Email'
                                name={EMAIL}
                                value={values[EMAIL]}
                                className='create-aid-input'
                                onChange={handleChange}
                              />
                              {errors[EMAIL] && (
                                <InputFieldError errorText={errors[EMAIL]} />
                              )}
                              </Stack>
                              <Stack className='login-password-container' spacing={1}>
                                <h2 className='sub-header-popup'>Password</h2>
                                <input
                                    className='create-aid-input'
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Password'
                                    name={PASSWORD}
                                    value={values[PASSWORD]}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  />
                                  {values[PASSWORD] && (
                                    <button
                                      type="button"
                                      className="password-toggle-button"
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </button>
                                  )}
                                  {errors[PASSWORD] && (
                                    <InputFieldError errorText={errors[PASSWORD]} />
                                  )}
                            </Stack>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <FormControlLabel
                                style={{justifyContent: 'center'}}
                                control={
                                    <Checkbox
                                    checked={rememberMe}
                                    onChange={() => setRememberMe(!rememberMe)}
                                    />
                                }
                                label='Remember Me'
                                />
                                <Link to='/forgot-password'>
                                    <p className='register-link'>Forgot Password?</p>
                                </Link>
                            </Box>
                            {isUnverified && (
                            <Button
                                disabled={isResending}
                                className='sign-in-btn'
                                onClick={handleResendEmail}
                                style={{color: 'white', backgroundColor: '#a06ccb'}}
                            >
                                {isResending ? 'Resending...' : 'Resend Email Confirmation'}
                            </Button>
                            )}
                            <Button type='submit' className='sign-in-btn'
                            disabled={isSubmitting || !isValid} style={{color: 'white', backgroundColor: '#a06ccb'}}
                            >
                              {isSubmitting ? 'Signing In...' : 'Login'}
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    <p>
                    Don't have an account?
                        <span style={{color: '#a06ccb', textDecoration: 'underline', cursor: 'pointer'}} onClick={handleRegisterClick}>Sign Up</span>
                    </p>
                  </Box>
                  </>
  )
}

export default LoginScreen