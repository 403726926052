import React, { useEffect } from 'react';
import { useApiService } from '../../services/api-service';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import toast from "react-hot-toast";
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useAuthService } from '../../services/auth-service';
import useEmailService from '../../services/email-service';
import { useProfileService } from '../../services/profile-service';
import { LinearProgress } from '@mui/material';

const InviteVerification = () => {
  const apiService = useApiService();
  const { token } = useParams();
  const history = useHistory();
  const [communityId, setCommunityId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const eToken = Cookies.get('encryptedToken');
  const [raisemeId, setRaiseMeId] = useState();
  const [communityDetails, setCommunityDetails] = useState([]);
  const { getUserEmail } = useEmailService();
  const profile = useProfileService();

  const getInvitationDetails = async () => {
    try {
      setLoading(true);
      const response = await apiService.notification.verify({ token: eToken || token });
      if (response.status === 200 || response.status === 201) {
        const commid = response.data.comm_id;
        const raiseme_id = response.data.raiseme_id;
        setRaiseMeId(raiseme_id);
        setCommunityId(commid);
        const commInfoResponse = await apiService.community.info({comm_uuid: commid});
        if(commInfoResponse.status === 200 || commInfoResponse.status === 201){
          const commDetails = commInfoResponse.data;
          setCommunityDetails(commDetails);
        }
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error('Network Error');
      }
    } finally{
      setLoading(false);
    }
  }

  useEffect(() => {
    getInvitationDetails();
  }, []);

  useEffect(() => {
    if (token) {
      // Store encrypted values in cookies
      Cookies.set('encryptedToken', token);
      const Stoken = Cookies.get('myarea_auth_token');
      if (!Stoken) {
        toast.error("Login or register an account")
        history.push('/login'); // Change '/login' to the actual login page URL
      }
    }
  }, [token]);

  const AddMemberToCommunity = async (createdCommunityId) =>{
    try {
        const requestBody = {
          comm_default: false,
          comm_uuid: createdCommunityId,
          user_id: profile.profile.userId
        };
        const response = await apiService.community.createMember(requestBody);
        if(response.status === 200 || response.status === 201){
          toast.success("Added to Community")
          Cookies.remove('encryptedToken');
          history.push('/communities')
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
          if(e.response.status === 409){
            history.push('/communities');
            Cookies.remove('encryptedToken');
          }
        } else {
          toast.error('Network Error');
        }
      }
  }

  const getMemberEmail = async (userId) => {
    try {
      const requestBody = {
        user_id: userId
      };
      const response = await apiService.auth.getUserInfo(requestBody);
      if (response.status === 200) {
        return response.data.email; // Return the user's email from the response
      } else {
        return null; // Return null if the email retrieval fails
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else {
        toast.error("Network Error");
      }
    }
  };
  
  const verifyToken = async () => {
    let isRaiseMe;
    let email;
    let verifyResponse;

    try {
      setSubmitting(true);
      const requestBody = { token: eToken || token };
      const response = await apiService.notification.verify(requestBody);
      verifyResponse = response.data;
      // Handle the verification response as needed
      if (response.status === 200) {
        const raiseme_id = response.data.raiseme_id;
        const createdCommunityId = response.data.comm_id;
        setCommunityId(createdCommunityId);
        if(raiseme_id){
            isRaiseMe = true;
            const commResponse = await apiService.community.createMember({comm_default: false, comm_uuid: createdCommunityId, user_id: profile.profile.userId});
            if(commResponse.status === 200 || commResponse.status === 201){
              const userEmail = await getMemberEmail(profile.profile.userId);
              email = userEmail;
              if (!userEmail) {
                // Handle the case where user email retrieval failed
                toast('Error getting user email', {
                  type: 'error',
                  data: {
                    msg: 'Error getting user email',
                    type: 'error',
                  },
                });
              }
              const raisemeRequestBody = {
                raisemeId: raiseme_id,
                comm_uuid: response.data.comm_id,
                descr: 'ADD USER TO RAISEME',
                email: userEmail,
                sender_id: response.data.sender_id,
                reciever_id: profile.profile.userId,
                notification_type: 'raiseMeInvite',
                delivery_type: 2
              };
              const pushResponse = await apiService.notification.push(raisemeRequestBody);
              if (pushResponse.status === 201 || pushResponse.status === 200) {
              Cookies.remove('encryptedToken');
              history.push('/communities')
              toast.success("Added to Community! Join RaiseMe in notification bar")
              }
          }
        } else {
        await AddMemberToCommunity(createdCommunityId);
        }
      }
    } catch (e) {
      if(e.response){
        if(isRaiseMe){
          const requestBody = {
            raisemeId: verifyResponse.raiseme_id,
            comm_uuid: verifyResponse.comm_id,
            descr: 'ADD USER TO RAISEME',
            email: email,
            sender_id: verifyResponse.sender_id,
            reciever_id: profile.profile.userId,
            notification_type: 'raiseMeInvite',
            delivery_type: 2
          };
          const pushResponse = await apiService.notification.push(requestBody);
          if (pushResponse.status === 201 || pushResponse.status === 200) {
          Cookies.remove('encryptedToken');
          history.push('/communities')
          toast.success("Added to Community! Join RaiseMe in notification bar")
          }
        }
        toast.error(e.response.data["code "]);
        if(e.response.status === 409){
          Cookies.remove('encryptedToken');
          history.push('/communities');
        }
      } else {
        toast.error('Network Error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
    {loading ? (
      <LinearProgress/>
    ) : (
      <>
      <div className="create-community">
      <div className="create-community-modal">
      <h2 className='onboarding-text'>Do you want to verify invite to this {communityDetails.commName} Community</h2>
      {raisemeId && (
        <p style={{marginBottom: '10px'}}>This is a RaiseMe Invite, click 'yes' to join the origin community of the RaiseMe first then join RaiseMe in notification bell</p>
      )}
        <div className='dual-buttons'>
            <button className='button verification-btn' onClick={verifyToken}>{submitting ? 'Joining...' : 'Yes'}</button>
            <button className='button verification-btn'>No</button>
        </div>
      </div>
      </div>
      </>
    )}
    </>
  );
};

export default InviteVerification;
