import React, { useEffect, useState } from 'react'
import '../RaiseMe/raiseMe.css'
import '../OnBoarding/onboarding.css';
import '../../components/createCommunity/createCommunity.css';
import './loungePost.css'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Header from '../../components/header/Header';
import UserProfile from '../../components/userProfile/UserProfile';
import ShareComment from '../../components/ShareComment/ShareComment';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import { useCommunityPostsContext } from '../../contexts/CommunityPostsContext';
import { NO_PROFILE } from '../../utils/routes';
import { ArrowBack, Block, Comment, Favorite, FavoriteBorder, Flag, MoreVert, Share } from '@mui/icons-material';
import { Avatar, Backdrop, Box, Fade, Menu, MenuItem, Modal, Skeleton } from '@mui/material';
import { useLoungePostsContext } from '../../contexts/LoungePostsContext';
import ShareLoungeComment from '../../components/ShareComment/ShareLoungeComment';
import ReportPost from '../../components/post/ReportPost';
import toast from 'react-hot-toast';
import ConfirmRequest from '../../utils/ConfirmRequest';

const LoungePost = () => {
    const {uuid, postId} = useParams();
    const [communityPosts, setCommunityPosts] = useState([]);
    const [communityComments, setCommunityComments] = useState([]);
    const [isSharing, setIsSharing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const apiService = useApiService();
    const { profile } = useProfileService();
    const [showOptionsMenu, setShowOptionsMenu] = useState(false); // Add state for options menu
    const [showComments, setShowComments] = useState(true);
    const [commentText, setCommentText] = useState('');
    const { refreshLoungePosts } = useLoungePostsContext();
    const history = useHistory();
    const [isLiked, setIsLiked] = useState(null);
    const [likesCount, setLikesCount] = useState(null);
    const [loungeId, setIsLoungeId] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [refreshPost, setRefreshPost] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //open confirm request
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);


    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };


    const fetchPosts = async () => {
        try {
          setIsLoading(true)
          const requestBody = {
            post_id: postId,
          };
          const response = await apiService.lounge.getPost(requestBody);
      
          if (response.status === 200 || response.status === 201) {
            const commPost = response.data;
      
            // Fetch user details for the post author
            const userResponse = await apiService.profile.profileDetails({
              user_id: commPost[0].user_id,
            });
      
            if (userResponse.status === 200 || userResponse.status === 201) {
              const userDetails = userResponse.data;
      
              // Fetch comments for the specific post
              const commentsResponse = await apiService.lounge.allComments({ parent_id: postId });
      
              let comments = [];
      
              if (commentsResponse.status === 200 || commentsResponse.status === 201) {
                comments = commentsResponse.data;
      
                // Fetch user details for each comment
                const commentsWithUserDetails = await Promise.all(
                  comments.map(async (comment) => {
                    const commentUserResponse = await apiService.profile.profileDetails({
                      user_id: comment.user_id,
                    });
      
                    if (commentUserResponse.status === 200 || commentUserResponse.status === 201) {
                      const commentUserDetails = commentUserResponse.data;
                      return { ...comment, userDetails: commentUserDetails };
                    } else {
                      // Handle error fetching user details for comment
                      return comment;
                    }
                  })
                );

                let liked = false;
                let reactionId = null;
    
                  try {
                    // Check if the post has been liked
                    const reactionStatusResponse = await apiService.lounge.reactionStatus({ post_id: postId });
                    liked = reactionStatusResponse.status === 200;
                    reactionId = liked ? reactionStatusResponse.data.id : null;
                  } catch (error) {
                    // Handle 404 response (Post not liked)
                    if (error.response && error.response.status === 404) {
                      liked = false;
                      reactionId = null;
                    } else {
                      throw error; // Rethrow other errors
                    }
                  }
      
                const postWithDetails = {
                  ...commPost[0],
                  userDetails: userDetails,
                  comments: commentsWithUserDetails,
                  liked,
                  reaction_id: reactionId
                };

                console.log('postWithDetails', postWithDetails);

                setLikesCount(postWithDetails.likes_count);
                setIsLiked(postWithDetails.liked);
                setIsLoungeId(postWithDetails.lounge_id);
                setCommunityPosts([postWithDetails]); 
              } else {
                // Handle error fetching comments
                setCommunityPosts([commPost[0]]); // Wrap it in an array
              }
            } else {
              // Handle error fetching user details
              setCommunityPosts([commPost[0]]); // Wrap it in an array
            }
          }
        } catch (error) {
          // Handle error
        } finally {
          setIsLoading(false);
        }
      };

      const toggleComments = () => {
        setShowComments((prevShowComments) => !prevShowComments);
      };
    
      const toggleOptionsMenu = () => {
        setShowOptionsMenu((prevShowOptionsMenu) => !prevShowOptionsMenu);
      };
    
      const handleCommentChange = (event) => {
        setCommentText(event.target.value);
      };

      const handleCommentSubmit = async () => {
        // Implement the logic to submit the comment
        try {
          setIsSharing(true)
            const requestBody = {
                community_id: uuid,
                lounge_id: loungeId,
                message: commentText,
                user_id: profile.userId,
                parent_id: postId
            }
            const response = await toast.promise(
              apiService.lounge.createPost(requestBody),
              {
                loading: 'Posting Comment...',
                success: 'Comment Posted Successfully',
                error: 'Error Posting Comment',
              }
            )
            if(response.status === 200 || response.status === 201){
              setCommentText('');
              refreshLoungePosts();
          }
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }else {
            toast.error('Network Error');
          }
        } finally{
          setIsSharing(false)
        }
      };
    
      const handleDeletePosts = async () => {
        try {
          setIsDeleting(true)
          const response = await toast.promise(
            apiService.lounge.deletePosts(postId),
            {
              loading: 'Deleting Post...',
              success: 'Post Deleted Successfully',
              error: 'Error Deleting Post',
            }
          )
          if(response.status === 200 || response.status === 201){
            refreshLoungePosts();
            history.goBack()
        }
        } catch (e) {
          
        } finally{
          setIsDeleting(false)
        }
      }
    
    
      const getTimeDifference = (createdAt) => {
        const currentDate = new Date();
        const pastDate = new Date(createdAt);
    
        const timeDifference = currentDate.getTime() - pastDate.getTime();
        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
    
        if (daysDifference > 0) {
          return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
        } else if (hoursDifference > 0) {
          return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
          return `${minutesDifference} min${minutesDifference > 1 ? 's' : ''} ago`;
        } else {
          return 'Just now';
        }
      };

      const likePost = async () => {
        try {
          setIsLiked(true)
          setLikesCount((prevLikesCount) => prevLikesCount + 1);
          const requestBody = {
            post_id: postId,
            rxn_type: 'like'
          }
          const response = await apiService.lounge.createReaction(requestBody);
          if(response.status === 200 || response.status === 201){
            refreshLoungePosts();
        }
        } catch (e) {
          
        }
      }
    
      const unLikePost = async () => {
        try {
            setIsLiked(false)
            setLikesCount((prevLikesCount) => prevLikesCount - 1);
          const response = await apiService.lounge.deleteReaction(postId);
          if(response.status === 200 || response.status === 201){
            refreshLoungePosts();
        }
        } catch (e) {
          
        }
      }

      const handleBlockUser = async (data) => {
        try {
          setIsBlocking(true)
          await toast.promise(
            apiService.user.blockUser({
              blocker_id: profile.userId,
              blocked_id: data.user_id
            }),
            {
              loading: 'Blocking user...',
              success: 'User blocked successfully',
              error: 'Error blocking user'
            }
          )
          refreshLoungePosts();
          history.goBack()
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }
        } finally{
          setIsBlocking(false)
        }
      }

      const handleMenuItemClick = async (action) => {
        // Implement actions based on the selected menu item (e.g., delete or make admin)
        if (action === 'delete') {
          await handleDeletePosts();
        }
        if(action === 'block'){
          handleMenuClose();
          handleOpenConfirm();
        }
        if(action === 'report'){
          handleMenuClose();
          handleOpen();
        }
      };
    
      useEffect(() => {
        fetchPosts();
        refreshLoungePosts();
      }, [postId, uuid, refreshPost])
  return (
    <>
    <Header uuid={uuid} />
    <div className="communityWrapper">
        <div>
          <UserProfile />
          {/* <MembersCard />
          <EventCard /> */}
        </div>
        <div className="container">
        {isLoading ? (
          // Display skeleton while loading
          <>
        <Box sx={{ width: 300 }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        </Box>
        </>
        ) : (
          <>
            {communityPosts.map((data, index) => (
                <div className="post" key={index}>
                <div className="post-back">
                <ArrowBack onClick={() => history.goBack()}/>
                <p>Post</p>
                </div>
                <div className="postHeader">
                  <div className="postHeader-left">
                    <Avatar alt={`${data.userDetails.firstName} ${data.userDetails.lastName}`} src={data.userDetails.photoUrl} />
                    {/* <img src={data.userDetails.photoUrl || NO_PROFILE} alt={data.userDetails.photoUrl} /> */}
                    <div className='postHeader-userInfo'>
                    <Link to={`/profile/${data.userDetails.userId}`}>
                    <p className='userInfo-name'>{data.userDetails.firstName} {data.userDetails.lastName}</p>
                    </Link>
                    <p className='userInfo-location'>{data.userDetails.city}, {data.userDetails.country}</p>
                    </div>
                    </div>
                    <div className="postHeader-right">
                    <div className="postime">
                    {getTimeDifference(data.created_at)}
                    <span onClick={(e) => handleMenuOpen(e)}><MoreVert /></span>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                        {data.user_id === profile.userId && (
                        <MenuItem onClick={() => handleMenuItemClick('delete')}>Delete</MenuItem>
                      )}
                      {data.user_id !== profile.userId && (
                          <>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('report')}>
                        <Flag />
                        Report
                        </MenuItem>
                        <MenuItem sx={{padding: '5px 12px'}} onClick={() => handleMenuItemClick('block')}>
                        <Block />
                        Block
                        </MenuItem>
                        </>
                        )}
                    </Menu>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <Box className="comm-popup-modal">
                          <ReportPost refreshPosts={refreshLoungePosts} handleClose={handleClose} selectedData={data}/>
                        </Box>
                      </Fade>
                    </Modal>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openConfirm}
                      onClose={handleCloseConfirm}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <ConfirmRequest
                        sendingAction={isBlocking}
                        title={'Block User?'}
                        text={`Are you sure you want to block ${data.userDetails.firstName} ${data.userDetails.lastName}?`}
                        onAction={() => handleBlockUser(data)} // Pass a function reference
                        onClose={handleCloseConfirm}
                        actionButton={'Block'}
                        sendingActionButton={'Blocking...'}/>
                      </Fade>
                    </Modal>
                    </div>
                    </div>
                </div>
                <div className='detail'>
                    <span>{data.message}</span>
                </div>
                {/* <img src={data.img} alt='' className='postImg' /> */}
                <div className="postReaction">
          <div className="postReaction-likes">
          {isLiked? 
                <Favorite style={{color: 'red'}} onClick={unLikePost}/> :
                <FavoriteBorder onClick={likePost}/>
            }
            <p>{likesCount} Likes</p>
          </div>
          <div className="postReaction-comments" onClick={toggleComments}>
          <Comment className='comments-icon'  />
          <p>{data?.comments.length <= 1 ? `${data?.comments.length} Comment` : `${data?.comments.length} Comments`}</p>
          </div> 
            {/* <Share/> */}
        </div>
                {/* <span>{data.likes} likes</span> */}
                {showComments && (
              <ShareLoungeComment commentText={commentText}
              handleCommentChange={handleCommentChange}
              handleCommentSubmit={handleCommentSubmit}
              data={data}
              isSharing={isSharing}
              comment={communityComments}
              profile={profile}
              setIsSharing={setIsSharing}
              loungeId={loungeId}
              />
            )}
                </div>
            ))}
            </>
            )}
        </div>
      </div>
    </>
  )
}

export default LoungePost