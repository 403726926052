import { Avatar, Card, CardContent, CardMedia, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { NO_COVER_IMG } from '../../utils/routes'
import './userprofiledetail.css'
import { Place, WcRounded } from '@mui/icons-material'

const UserProfileDetail = ({member}) => {
  return (
    <Card className='profile-card'>
            <CardMedia image={NO_COVER_IMG} sx={{ height: '140px', position: 'relative', width: '100%' }}/>
            <Avatar
                alt={member.userDetails.firstName}
                src={member.userDetails.photoUrl}
                sx={{
                position: 'relative',
                zIndex: '1',
                margin: '-32px 0 0 0',
                width: '80px',
                height: '80px'
                }}
            />
            <CardContent>
                <h2 className='profile-card-name'>{member.userDetails.firstName} {member.userDetails.lastName}</h2>
                <Stack spacing={1}>
                <Stack direction={'row'} alignItems={'center'} gap={'0.5rem'}>
                    <div className="profile-card-icon">
                        <Place/>
                    </div>
                    <p className='profile-card-text'>{member.userDetails.city}, {member.userDetails.country}</p>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={'0.5rem'}>
                    <div className="profile-card-icon">
                        <WcRounded/>
                    </div>
                    <p className='profile-card-text'>{member.userDetails.gender}</p>
                </Stack>
                </Stack>
            </CardContent>
    </Card>
  )
}

export default UserProfileDetail