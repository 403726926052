import { LockOpen, Login, Notifications } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import DropDownProfile from '../dropDownProfile/DropDownProfile';
import './header.css';
import CommunityDropDown from '../CommunityDropDown/CommunityDropDown';
import { useProfileService } from '../../services/profile-service';
import { useEffect } from 'react';
import { useApiService } from '../../services/api-service';
import { useRef } from 'react';
import { NO_PROFILE } from '../../utils/routes';
import { CommunityContext } from '../../contexts/CommunityContext';
import { Avatar, Badge, Button, Drawer, LinearProgress, Stack } from '@mui/material';
import toast from "react-hot-toast";
import RaiseMeAccount from '../RaiseMeAccount/RaiseMeAccount';
import AccountVerification from '../AccountVerification/AccountVerification';
import useEmailService from '../../services/email-service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuthService } from '../../services/auth-service';
import PendingVerification from '../PendingVerification/PendingVerification';
import NotificationList from './NotificationList';
import images from '../../Data/imageHelper';

const Header = ({uuid}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  const [notificationCount, setNotificationCount] = useState(0);
  const profile = useProfileService();
  const apiService = useApiService();
  const [notificationDetails, setNotificationDetails] = useState([]);
  const { loadCommunity } = useContext(CommunityContext);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [useExistingAccount, setUseExistingAccount] = useState(false);
  const [location, setLocation] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankBranch, setBankBranch] = useState(null);
  const [interacEmail, setInteracEmail] = useState(null);
  const [interacPhone, setInteracPhone] = useState(null);
  const [accountType, setAccountType] = useState('');
  const [currency, setCurrency] = useState('');
  const [bvn, setBvn] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [raiseMeId, setRaiseMeId] = useState(null);
  const [verifyUrl, setVerifyUrl] = useState(null);
  const [step, setStep] = useState(1);
  const modalRef = useRef(null);
  const [notifsDetail, setNotifsDetail] = useState(null);
  const { getUserEmail } = useEmailService();
  const history = useHistory();
  const { token, clearToken } = useAuthService();
  const [accountStatus, setAccountStatus] = useState(null);
  const [partnerStatus, setPartnerStatus] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [acceptingNotification, setAcceptingNotification] = useState(null);
  const [decliningNotification, setDecliningNotification] = useState(null);
  const [openNotification, setOpenNotification] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenNotification(newOpen);
  };

  const nextStep = async (e) => {
    if(!useExistingAccount) e.preventDefault();
    setStep(3)
    if(partnerStatus === 'AML check in progress' || accountStatus === null || partnerStatus === 'error'){
      setStep(3)
    } else {
      await joinRaiseMe(accountId);
      handleCloseModal();
    }
}

  const handleCloseModal = () => {
    setOpenModal(false)
    setUseExistingAccount(false);
    setStep(1);
  }

  // const handleOutsideClick = (e) => {
  //   if (modalRef.current && !modalRef.current.contains(e.target)) {
  //     handleCloseModal();
  //   }
  // };

  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };

  // const handleNotificationsClick = () => {
  //   setShowNotifications((prevShowNotifications) => !prevShowNotifications);
  // };

  const checkVerification = async () => {
    try {
      setIsVerifying(true);
      const kycdata = await apiService.raiseme.getKYCByLocation(location);
      const partnerStatus = kycdata.data.partnerStatus;
      setPartnerStatus(partnerStatus);
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      } else{
        toast.error('Network Error!')
      }
    } finally {
      setIsVerifying(false);
    }
  }

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    try {
      if(useExistingAccount){
        // await joinRaiseMe();
        setStep(step + 2)
        nextStep();
      } else {
      const requestBody = {
        financial_institution_name: bankName,
        financial_institution_branch: bankBranch,
        account_number: accountNumber,
        account_type: accountType,
        bvn: bvn,
        currency: currency,
        interac_email: interacEmail,
        interac_phone_number: interacPhone,
        location: location,
        routing_transit_number: routingNumber,
        account_name: accountName
      }
      const response = await apiService.raiseme.createAccount(requestBody);
    if (response.status === 200 || response.status === 201) {
      toast.success("Account Created Successfully");
      const getAccountID = response.data.id;
      const verificationURL = response.data.verifyUrl;
      const getStatus = response.data.partnerStatus;
      await setAccountId(getAccountID)
      setVerifyUrl(verificationURL);
      setAccountStatus(getStatus);
      setStep(step+1)
      // await joinRaiseMe(getAccountID);
    }
  }
  } catch(e){
    if(useExistingAccount){
      toast.error('Error while joining Raiseme');
    }
    else {
      if(e.response){
        toast(e.response.data["code "], {
          type: "error",
          data: {
            msg: e.response.data["code "],
            type: "error",
          }
        });
      } else{
        toast("Network Error", {
          type: "error",
          data: {
            msg: "Network error, please check your internet connection.",
            type: "error",
          }
        });
    }
    }
  }
  }
  const joinRaiseMe = async (getAccountID) => {
    let communityConflict = false;
    const userEmail = await getUserEmail();
    try {
      setIsJoining(true);
      // const isChitFund = notifsDetail.raisemeInfo.raisemeType === "CHIT_FUND";
      const requestBody = {
        comm_default: false,
        comm_uuid: notifsDetail.comm_uuid,
        user_id: profile.profile.userId
        // Add other necessary data for the request
      };
      // Make the API call to join the community
      const response = await apiService.community.createMember(requestBody);
      
      if(response.status === 200 || response.status === 201){
        const createRaiseMeBody = {
        additional_service: "additionalService",
        email: userEmail,
        raiseme_id: notifsDetail.raisemeInfo.id,
        user_id: profile.profile.userId,
        user_type: profile.profile.userType,
        account_id: useExistingAccount ? accountId : getAccountID
        }
        const res = await apiService.raiseme.addMember(createRaiseMeBody);
        if(res.status === 200 || res.status === 201){
          const requestBody = {
            id: notifsDetail.id
          }
          const updateResponse = await apiService.notification.updateStatus(requestBody)
          if(updateResponse.status === 200 || updateResponse.status === 201){
            toast.success('Collective Cash joined');
            history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
            // if(isChitFund){
            //   toast.success('Chit Fund joined');
            //   history.push(`/community/chit/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
            // } else {
            //   toast.success('Collective Cash joined');
            // history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
            // }
          }
        }
      }
      const updatedNotifications = notificationDetails.filter(
        (notification) => notification.id !== notifsDetail.id
      );
      setNotificationDetails(updatedNotifications);
      
      // Update notification count
      setNotificationCount(updatedNotifications.length);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        communityConflict = true;
      } else {
        if (e.response) {
          toast(e.response.data["code "], {
            type: "error",
            data: {
              msg: e.response.data["code "],
              type: "error",
            }
          });
        } else {
          toast("Network Error", {
            type: "error",
            data: {
              msg: "Network error, please check your internet connection.",
              type: "error",
            }
          });
          console.error("Error while joining community:", e);
        }
      }
    } finally{
      if(!communityConflict){
        setIsJoining(false);
      }
    }
  
    // If there was a conflict while adding to the community, proceed to add to Raiseme
    if (communityConflict) {
      // const isChitFund = notifsDetail.raisemeInfo.raisemeType === "CHIT_FUND";
      const createRaiseMeBody = {
        additional_service: "additionalService",
        email: userEmail,
        raiseme_id: notifsDetail.raisemeInfo.id,
        user_id: profile.profile.userId,
        user_type: profile.profile.userType,
        account_id: useExistingAccount ? accountId : getAccountID
      };
      
      try {
        const res = await apiService.raiseme.addMember(createRaiseMeBody);
        if (res.status === 200 || res.status === 201) {
          const requestBody = {
            id: notifsDetail.id
          };
          await apiService.notification.updateStatus(requestBody);
          toast.success('Collective Cash joined');
          history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
          // if(isChitFund){
          //   toast.success('Chit Fund joined');
          //   history.push(`/community/chit/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
          // } else {
          //   toast.success('Contribution joined');
          // history.push(`/community/${notifsDetail.comm_uuid ?? ''}/${notifsDetail.raiseme_id}`);
          // }
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
        } else{
          toast.error('Network Error')
        }
      } finally{
        setIsJoining(false);
      }
    }
  
    const updatedNotifications = notificationDetails.filter(
      (notification) => notification.id !== notifsDetail.id
    );
    setNotificationDetails(updatedNotifications);
  
    // Update notification count
    setNotificationCount(updatedNotifications.length);
  };

  useEffect(() => {
    // loadCommunity();
    if(profile && token){
      const loadNotification = async () => {
        try {
          setLoadingNotifications(true);
          const requestBody = {
            commUuid: uuid,
            userId: profile.profile.userId,
          };
          const response = await apiService.notification.received(requestBody);
          const notificationData = Object.values(response.data || {});
          setNotificationDetails(notificationData);
          setNotificationCount(notificationData.length);
        } catch (e) {
          if(e.response){
            if(e.response.status !== 404)
              {
                toast.error(e.response.data["code "]);
              } else if(e.response.status === 401){
              // clearToken();
              // toast.error('Session expired! Login')
            }
        } else{
          console.log('error', e)
          toast.error('Network Error')
        }
        } finally{
          setLoadingNotifications(false);
        }
          
      };
    loadNotification();
  }
    
  }, [open]);

  useEffect(() => {
    if(profile && token && location){
      checkVerification();
    }
  }, [step]);
  

  useEffect(() =>{
    if(token){
      let handler = (e) =>{
        if(!menuRef.current.contains(e.target)){
            setOpen(false);
        }
        
    };

    document.addEventListener("mousedown", handler);

    return() =>{
        document.removeEventListener("mousedown", handler);
    }
    }
});

  return (
    <>
    <div className="header">
  <div className="leftHeader">
    <Link to="/communities">
      <img src={images.logo} className='logo' alt='MyAreaa' />
    </Link>
  </div>
  {profile && token ? (
  <div className="rightHeader">
  <div className={`navIcon ${isOpen ? "is-open" : ""}`} onClick={toggleMenu}>
  <CommunityDropDown/>
  {/* <span> <Chat className='icons'/> </span> */}
  <div className='notification-div'>
    <span className="notification-icon" ref={menuRef}>
      {/* <Notifications className="icons" onClick={()=>{setOpen(!open)}} /> */}
      <Badge badgeContent={notificationCount} color='secondary' max={100}>
        <Notifications onClick={toggleDrawer(true)} />
        <Drawer anchor='right' open={openNotification} onClose={toggleDrawer(false)}>
          <NotificationList
          setLocation={setLocation} loadCommunity={loadCommunity}
          partnerStatus={partnerStatus}
          setNotificationCount={setNotificationCount} profile={profile} notificationData={notificationDetails} toggleDrawer={toggleDrawer} />
        </Drawer>
      </Badge>
    </span>
    </div>
  <DropDownProfile/>
</div>
</div>
) : (
  <>
  <div className='loggedOutRightHeader'>
  <Button startIcon={<Login/>} sx={{color: 'white'}} href='/login' className='button'>Login</Button>
  </div>
  </>
)} 
</div>
          {openModal && (
            <div className="modal-overlay">
            <div className="create-community">
              <div className='create-community-modal' ref={modalRef}>
                {step === 1 && (
                  <RaiseMeAccount onCancel={handleCloseModal}
                  accountName={accountName}
                  setAccountName={setAccountName}
                  accountNumber={accountNumber}
                  setAccountNumber={setAccountNumber}
                  routingNumber={routingNumber}
                  setRoutingNumber={setRoutingNumber}
                  bankName={bankName}
                  setBankName={setBankName}
                  handleCreateAccount={handleCreateAccount}
                  bankBranch={bankBranch}
                  setBankBranch={setBankBranch}
                  interacEmail={interacEmail}
                  setInteracEmail={setInteracEmail}
                  accountType={accountType}
                  setAccountType={setAccountType}
                  currency={currency}
                  setCurrency={setCurrency}
                  bvn={bvn}
                  setBvn={setBvn}
                  location={location}
                  setLocation={setLocation}
                  setInteracPhone={setInteracPhone}
                  interacPhone={interacPhone}
                  raiseMeId={raiseMeId}
                  setRaiseMeId={setRaiseMeId}
                  setAccountId={setAccountId}
                  accountId={accountId}
                  useExistingAccount={useExistingAccount}
                  setUseExistingAccount={setUseExistingAccount}
                  setAccountStatus={setAccountStatus}
                  setVerifyUrl={setVerifyUrl}
            />
                )}
                {step === 2 && (
            <>
            <AccountVerification verifyUrl={verifyUrl} nextStep={nextStep}/>
            </>
          )}
          {step === 3 && (
            <>
            {isVerifying ? (
              <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
            ) : (
              <>
              {isJoining ? (
                <LinearProgress style={{ margin: '1rem 0', backgroundColor: '#a06ccb' }} />
              ) : (
                <>
                <PendingVerification partnerStatus={partnerStatus} accountStatus={accountStatus} verifyUrl={verifyUrl} onCancel={handleCloseModal}/>
                </>
              )}
              </>
            )}
            </>
            )}
              </div>
              </div>
              </div>
          )}
</>
  )
}

export default Header;