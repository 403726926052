import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header';
import { MonetizationOn } from '@mui/icons-material';
import CreateAidLounge from '../../components/CreateAidLounge/CreateAidLounge';
import UserProfile from '../../components/userProfile/UserProfile';
import { useApiService } from '../../services/api-service';
import AidLoungeCard from '../../components/AidLoungeCard/AidLoungeCard';
import { Backdrop, Box, Fade, Modal } from '@mui/material';
import MyAidLoungeCard from '../../components/AidLoungeCard/MyAidLoungeCard';

const AidLounges = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [showAllAidLounges, setShowAllAidLounges] = useState(true);
    const uuid = null;
    const handleOpenModal = () => {
        setOpenModal(true);
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
      };
      const handleSearch = (event) => {
        setSearchQuery(event.target.value);
      };

      // const handleTabChange = () => {
      //   setShowAllAidLounges(!showAllAidLounges);
      // };

    
  return (
    <>
        <Header />
        <div className="communityWrapper">
        <div className='community-sidebar'>
          <UserProfile />
        </div>
        <div style={{width: '100%'}}>
          <div className="section-banner">
            <MonetizationOn />
            <div>
              <h2>My Aid Lounges</h2>
              <p className='alignLeft'>All the Aid Lounges you belong to!</p>
            </div>

          </div>

          <div className="lounge-navigation">
            <div className="section-navigation-left">
              <input
                className='search-box'
                type="text"
                placeholder='Search Aid Lounges'
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div className="section-navigation-right">
              {/* <div
               className={`section-navigation-tab ${showAllAidLounges ? 'active' : ''}`}
               onClick={handleTabChange}
            >
              <p>All Aid Lounges</p>
            </div> */}
            <div
              className={`section-navigation-tab ${showAllAidLounges ? 'active' : ''}`}
            >
              <p>My Aid Lounges</p>
            </div>
              <div className="section-navigation-tab" onClick={handleOpenModal}>
                <p>Create Aid Lounge</p>
              </div>
              <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                      <Box className="comm-popup-modal">
                      <CreateAidLounge uuid={uuid} onCancel={handleCloseModal} />
                      </Box>
                            </Fade>
                 </Modal>
            </div>
          </div>
          <div className="grid">
          <MyAidLoungeCard searchQuery={searchQuery} />
        {/* {showAllAidLounges ? <AidLoungeCard searchQuery={searchQuery}/> : <MyAidLoungeCard searchQuery={searchQuery} />} */}
        </div>
        </div>
      </div>
    </>
  )
}

export default AidLounges