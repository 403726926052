import React, { useEffect, useState } from 'react';
import './raiseMeTab.css';
import RecentContributions from '../RecentContributions/RecentContributions';
import RaiseMeMembers from '../RaiseMeMembers/RaiseMeMembers';
import { Button, Fade, Modal, Backdrop } from '@mui/material';
import SubmitRaiseMePosition from '../SubmitRaiseMePosition/SubmitRaiseMePosition';
import { Visibility } from '@mui/icons-material';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import { LinearLoad } from '../../utils/routes';
import toast from 'react-hot-toast';

const RaiseMeTab = ({ profile, members, memberEmails, transactionData,
                  selectedLounge, setSelectedLounge, loungeId, loadTransactions, loadMembers, isMemberValid }) => {
  const [activeTab, setActiveTab] = useState('members');
  const apiService = useApiService();
  const [RaisemePositions, setRaisemePositions] = useState([]);
  const [canViewPosition, setCanViewPosition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let isStarted;
  let endpoint;
  if (selectedLounge && selectedLounge.startDate) {
  const startDate = new Date(selectedLounge.startDate);

  // Get the current date
  const currentDate = new Date();
  isStarted = currentDate >= startDate;

  
  if (isStarted) {
      endpoint = "getPositionsKickOff"; // Endpoint for after start
  } else {
      endpoint = "getPositions"; // Endpoint for before start
  }
}

  const fetchPositions = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.raiseme[endpoint]({raiseme_id: loungeId})
      if(response.status === 200 || response.status === 201){
        const positions = response.data;
        setRaisemePositions(positions)
        setCanViewPosition(true);
    }
    } catch (e) {
      if(e.response){
        if(e.response.status === 404){
          setCanViewPosition(false)
          // toast.info(e.response.data["code "]);
        }
      } else{
        toast.error("Network Error");
    }
    } finally{
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedLounge && selectedLounge.startDate) {
    fetchPositions();
    }
  }, [])
  

  return (
    <div className='raiseMeTab-container'>
        <div className="tabs-info">
          <div className="member-transaction">
          
          <h2
            className={activeTab === 'members' ? 'active-tab' : ''}
            onClick={() => handleTabClick('members')}
          >
            Members
          </h2>
          <h2
            className={activeTab === 'transactions' ? 'active-tab' : ''}
            onClick={() => handleTabClick('transactions')}
          >
            Transactions
          </h2>
          </div>
          <div className='raiseme-position'>
          {isMemberValid && (
          <Button onClick={handleOpen} style={{ color: "#fff", backgroundColor: "#a06ccb" }} variant='filled' startIcon={<Visibility/>}>
            View Position
          </Button>
        )}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                      <SubmitRaiseMePosition canViewPosition={canViewPosition} isStarted={isStarted} isLoading={isLoading} loungeId={loungeId} RaisemePositions={RaisemePositions} handleClose={handleClose} fetchPositions={fetchPositions} selectedLounge={selectedLounge}/>
                            </Fade>
                        </Modal>
          </div>
        </div>
        <>
        {loadMembers ? (
          <>
          {LinearLoad()}
          </>
        ) : (
        <div>
        {isMemberValid ? (
          <>
            {activeTab === 'members' ? (
              <RaiseMeMembers
                loadMembers={loadMembers}
                setSelectedLounge={setSelectedLounge}
                selectedLounge={selectedLounge}
                members={members}
                memberEmails={memberEmails}
              />
            ) : null}
            {activeTab === 'transactions' ? (
              <RecentContributions
                selectedLounge={selectedLounge}
                loadTransactions={loadTransactions}
                transactionData={transactionData}
                memberEmails={memberEmails}
              />
            ) : null}
          </>
        ) : (
          <p style={{textAlign: 'left'}}>User not in RaiseMe</p>
        )}
        </div>
        )}
        </>
    </div>
  );
}

export default RaiseMeTab;
