import React, { useCallback, useEffect, useState } from 'react';
import './communityInfo.css';
import '../CommunityMembers/communityMembers.css'
import { CalendarMonthRounded, InfoRounded, LocationCityRounded, MoreVert, Person, Place, Replay } from '@mui/icons-material';
import { Avatar, Box, Chip, CircularProgress, Divider, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { NO_PROFILE } from '../../utils/routes';
import { useProfileService } from '../../services/profile-service';
import { useApiService } from '../../services/api-service';
import toast from 'react-hot-toast';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CommunityInfo = ({ members, community, handleCloseCommunityInfo, fetchData, isUserAdmin, isAdminComm }) => {
  const [value, setValue] = React.useState(0);
  const profile = useProfileService();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMember, setSelectedMember] = React.useState(null);
  const apiService = useApiService();
  const [loading, setLoading] = useState(false);
  const [loadingAdminAction, setLoadingAdminAction] = useState(false);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const formattedDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
      });
    };

      const handleMenuOpen = (event, member) => {
        setAnchorEl(event.currentTarget);
        setSelectedMember(member);
        console.log('view member selected', member)
      };
      
      const handleMenuClose = () => {
        setAnchorEl(null);
      };

      const removeMember = async (member) => {
        try {
          setLoading(true);
          const requestBody = {
            comm_uuid: community.commUuid,
            user_id: member.user_id,
          }
          const response = await apiService.community.removeMember(requestBody);
          if(response.status === 201 || response.status === 200){
            toast.success("Removed from Community")
          }
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }else {
            toast.error("Network Error");
          }
        } finally {
          setLoading(false);
        }
      }

      const makeAdmin = async (member) => {
        try {
          setLoadingAdminAction(true);
          const requestBody = {
            comm_uuid: community.commUuid,
            user_id: member.user_id,
            role_name: 'admin'
          };
          const response = await apiService.community.assignRole(requestBody);
          if (response.status === 201 || response.status === 200) {
            toast.success("Assigned member successfully");
          }
        } catch (e) {
          if (e.response) {
            toast.error(e.response.data["code "]);
          } else {
            toast.error("Network Error");
          }
        } finally {
          setLoadingAdminAction(false);
        }
      };
      
      const handleMenuItemClick = async (action, member) => {
        if (action === 'delete') {
          await removeMember(member);
          handleMenuClose(); // Close the menu after performing the action
          handleCloseCommunityInfo(); // Close the community info dialog
          fetchData(); // Refresh the community info data
        } else if (action === 'makeAdmin') {
          await makeAdmin(member); // Pass the member parameter to makeAdmin function
          handleMenuClose(); // Close the menu after performing the action
          handleCloseCommunityInfo(); // Close the community info dialog
          fetchData(); // Refresh the community info data
        }
      };

      let loggedInUserRole = null;

      if (members && profile?.profile?.userId) {
        loggedInUserRole = members.find(member => member.userDetails?.userId === profile.profile.userId)?.role ?? null;
      }

      
  return (
    <>
        <div className='community-info'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary" indicatorColor="secondary">
                <Tab label="Community Members" {...a11yProps(0)} />
                <Tab label="Community Info" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            <div className="community-members-container">
              <>
              {members.length === 0 ? (
                <>
                <p>No records of members</p>
                </>
              ) : (
                <>
                {members
                .sort((a, b) => (a.user_id === profile.profile.userId ? -1 : b.user_id === profile.profile.userId ? 1 : 0))
                .map((member, index) => (
                <>
                  <div className="community-info-items" key={index}>
                  {/* <img className='profile-img' src={member.photoUrl || NO_PROFILE} alt={`${member.firstName} ${member.lastName}`} /> */}
                  <Avatar alt={`${member.userDetails.firstName} ${member.userDetails.lastName}`} src={member.userDetails.photoUrl} />
                  <div className="member-info">
                    <div>
                    <p>{`${member.userDetails.firstName} ${member.userDetails.lastName}`}</p>
                    <span className='comm-member-location'><Place sx={{color: 'light.main'}}/>{member.userDetails.city}, {member.userDetails.country}</span>
                    </div>
                    <Stack direction={'column'} spacing={1}>
                    {member.userDetails.userId === profile.profile.userId && (<Chip sx={{backgroundColor: "light.main"
                      }} icon={<Person/>} label='You' size='small'/> )}
                      <>
                              {member.role === 'admin' && isAdminComm ? (
                    // Admin user, show admin badge
                    <Chip sx={{ backgroundColor: "#25D366" }} label='Admin' size='small'/>
                  ) : (
                    // Non-admin user, show MoreVert icon and menu
                    <>
                    {community.communityType === 'ADMIN_MANAGED_COMM' && loggedInUserRole === 'admin' &&  (
                      <>
                      <MoreVert sx={{ cursor: 'pointer' }} onClick={(e) => handleMenuOpen(e, member)} />
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => handleMenuItemClick('delete', selectedMember)}>{loading ? 'Removing...' : 'Remove User'}</MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('makeAdmin', selectedMember)}>{loadingAdminAction ? 'Making admin...' : 'Make Admin'}</MenuItem>
                      </Menu>
                      </>
                    ) }
                    </>
                  )}
                      </>
                    </Stack>
                  </div>
                  </div>
                  <Divider/>
                  </>
              ))}
                </>
              )}
              </>
            </div>
            
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            <div className="community-info-container">
              <div className="community-info-list">
                <InfoRounded/>
                <p>{community.commDescr}</p>
              </div>
              <div className="community-info-list">
                <CalendarMonthRounded/>
                <p>Created {formattedDate(community.createdAt)}</p>
              </div>
              <div className="community-info-list">
                <Place/>
                <p>{community.city}, {community.country}</p>
              </div>
            </div>
            </CustomTabPanel>
          </Box>
        </div>
      </>
  );
};

export default CommunityInfo;
