import React, { useContext, useEffect, useState } from 'react'
import { useProfileService } from '../../services/profile-service'
import { useApiService } from '../../services/api-service';
import CardSkeleton from '../CardSkeleton/CardSkeleton';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { NO_COVER_IMG, NO_PROFILE } from '../../utils/routes';
import Skeleton from 'react-loading-skeleton';
import '../LoungeCard/loungeCard.css';
import '../../pages/Lounges/lounges.css'
import { Button } from '@mui/material';
import { CommunityContext } from '../../contexts/CommunityContext';
import toast from 'react-hot-toast';

const SuggestedCommunities = ({searchQuery}) => {
    const {profile} = useProfileService();
    const apiService = useApiService();
    const [loading, setLoading] = useState(true);
    const [isJoining, setIsJoining] = useState({});
    const [joinedCommunity, setJoinedCommunity] = useState(false);
    const [pendingRequests, setPendingRequests] = useState({});
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [userid, setUserid] = useState('');
    const [isLoaded, setIsLoaded] = useState(false)
    const [communities, setCommunities] = useState([]);
    const [pendingCommunities, setPendingCommunities] = useState([]);
    const [communityInfo, setCommunityInfo] = useState([]);

    const getSuggestedCommunities = async () => {
      try {
        setLoading(true);
        const requestBody = {
          city: profile.city,
          country: profile.country
        };
        const response = await apiService.community.retrieveLocation(requestBody);
        if (response.status === 200) {
          const data = await response.data;
          const communitiesArray = Object.values(data);
          setCommunities(communitiesArray);
          // Remove the getPendingCommunities() call from here
        }
      } catch (error) {
        console.error('Error fetching communities by location:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCommunities = async () => {
      try {
        const response = await apiService.community.membership();
        if (response.status === 200) {
          const data = Object.values(response.data || {});
          setCommunityInfo(data);
        }
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
        }
      }
    }

    const fetchPendingCommunities = async () => {
      try {
        const response = await apiService.community.getPendingEndorsements();
        if (response.status === 200) {
          const data = await response.data;
          const pendingCommunitiesArray = data.map((item) => item.comm_uuid);
          setPendingCommunities(pendingCommunitiesArray);
        }
      } catch (error) {
        console.error('Error fetching pending communities:', error);
      }
    };

    useEffect(() => {
      fetchCommunities();
      fetchPendingCommunities();
    }, [apiService.community.getPendingEndorsements]);

    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await apiService.auth.profile();
          setCity(response.data.city);
          setCountry(response.data.country);
          setUserid(response.data.userId);
          setIsLoaded(true);
        } catch (error) {
          console.error('Error fetching communities by location:', error);
        }
      };
    
      if (isLoaded) {
        getSuggestedCommunities();
      }
    
      fetchProfile();
    }, [apiService.community.retrieveLocation, profile, isLoaded]);

      const handleJoinCommunity = async (community) => {
        try {
      
              // Loop through communityMembers and invite each user with ranking 1 to the community
              let joined = false;
              let requestBody;
              console.log('community members', community)
              setIsJoining((prevLoadingStates) => ({
                ...prevLoadingStates,
                [community.comm_uuid]: true,
              }));
                // Use the retrieved userEmail in the requestBody
                if(community.community_type === 'ADMIN_MANAGED_COMM'){
                  requestBody = {
                    comm_uuid: community.comm_uuid,
                    sender_id: userid,
                    notification_type: 'adminManageCommInvite'
                  };
                } else {
                  requestBody = {
                    comm_uuid: community.comm_uuid,
                    sender_id: userid,
                    notification_type: 'communityBroadcast'
                  };
                }
                
      
                // Log the requestBody to check if it contains the necessary data
      
                const response = await toast.promise(
                  apiService.notification.push(requestBody),
                  {
                    loading: 'Requesting to join Community...',
                    success: 'Request to join Community Successfully',
                    error: 'Error requesting to joining community',
                  }
                )
                if (response.status === 201 || response.status === 200) {
                  // setPendingRequests((prevPendingRequests) => ({
                  //   ...prevPendingRequests,
                  //   [commUuid]: 'Pending',
                  // }));
                  fetchPendingCommunities();
                  joined = true;
                }
                setJoinedCommunity(joined);
      
          // Continue with other actions after inviting users with ranking 1 (if needed)
          // ...
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }
        } finally{
          setIsJoining((prevLoadingStates) => ({
            ...prevLoadingStates,
            [community.comm_uuid]: false,
          }));
        }
      };

      const filteredCommunityData = communities.filter((community) => {
        const { comm_name, comm_descr, comm_uuid } = community;
        if (comm_name && comm_descr) {
          return (
            comm_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            comm_descr.toLowerCase().includes(searchQuery.toLowerCase())
          ) && !communityInfo.some(info => info.comm_uuid === comm_uuid);
        }
        return false;
      });

      if (loading) {
        return <CardSkeleton cards={8} />;
      }

      if (filteredCommunityData.length === 0) {
        return (
          <>
          <p>No Records Found</p>
          </>
        );
      }

  return (
    <>
      {filteredCommunityData.map((community, index) => (
        <div className="lounge-card" key={index}>
          <div className="lounge-cover">
          <img
          src={
            community.banner
              ? `${community.banner}`
              : NO_COVER_IMG
          }
          alt="Lounge Cover"
        />

          </div>
          <div className="lounge-profile">
            <img src={community.photoUrl || NO_PROFILE} alt="Lounge Profile" />
          </div>
          <div className="lounge-info">
            <h2 className="lounge-name">{community.comm_name || <Skeleton />}</h2>
            <p className="lounge-description">{community.comm_descr || <Skeleton />}</p>
            {/* <div className="lounge-stats">
              <span className="lounge-members">999 members</span>
              <span className="lounge-posts">20 posts</span>
            </div> */}
          </div>
          <div className="lounge-cta">
          <Button
          className="button join-button"
          sx={{color: 'white'}}
                          onClick={() => handleJoinCommunity(community)}
                          disabled={pendingCommunities.includes(community.comm_uuid) || isJoining[community.comm_uuid]}
                        >
                          {isJoining[community.comm_uuid] ? 'Joining...' : pendingCommunities.includes(community.comm_uuid) ? 'Pending' : 'Join'}
                        </Button>
          </div>
        </div>
      ))}
    </>
  )
}

export default SuggestedCommunities