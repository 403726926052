import React, { useState } from 'react'
import '../Lounges/lounges.css'
import UserProfile from '../../components/userProfile/UserProfile';
import { Chair } from '@mui/icons-material';
import Header from '../../components/header/Header';
import CommunityCard from '../../components/CommunityCard/CommunityCard';
import CreateCommunity from '../../components/createCommunity/CreateCommunity';
import SuggestedCommunities from '../../components/SuggestedCommunities/SuggestedCommunities';

const Communities = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showMyCommunities, setShowMyCommunities] = useState(true);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTabChange = () => {
    setShowMyCommunities(!showMyCommunities);
  };

  return (
    <>
      <Header />
      <div className="community-details-wrapper">
        <div className='community-details-sidebar'>
          <UserProfile />
        </div>
        <div className='community-details-container'>
          <div className="section-banner">
            <Chair />
            <div>
              <h2>Communities</h2>
              <p>All the communities you belong!</p>
            </div>

          </div>

          <div className="lounge-navigation">
            <div className="section-navigation-left">
              <input
                className='search-box'
                type="text"
                placeholder='Search Communities'
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div className="section-navigation-right">
              <div
              className={`section-navigation-tab ${showMyCommunities ? 'active' : ''}`}
              onClick={handleTabChange}
            >
              <p>My Communities</p>
            </div>
            <div
              className={`section-navigation-tab ${!showMyCommunities ? 'active' : ''}`}
              onClick={handleTabChange}
            >
              <p>Suggested Communities</p>
            </div>
              <div className="section-navigation-tab" onClick={handleOpenModal}>
                <p>Create Community</p>
              </div>
            </div>
          </div>


          <div className="grid">
        {showMyCommunities ? <CommunityCard searchQuery={searchQuery} /> : <SuggestedCommunities searchQuery={searchQuery} />}
      </div>
        </div>
      </div>
      {openModal &&
        <div className="modal-overlay">
          <CreateCommunity openModal={openModal} onCancel={handleCloseModal} />
        </div>
      }
    </>
  )
}

export default Communities