import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useApiService } from '../../services/api-service';
import { useAuthService } from '../../services/auth-service';
import RaiseMeOverview from '../../components/raiseMeOverview/RaiseMeOverview';
import RaiseMeTab from '../../components/RaiseMeTab/RaiseMeTab';
import RaiseMeInviteModal from '../../components/raiseMeInviteModal/RaiseMeInviteModal';
import { PersonAddAlt } from '@mui/icons-material';
import { NO_COVER_IMG, NO_PROFILE } from '../../utils/routes';
import UserProfile from '../../components/userProfile/UserProfile';
import Header from '../../components/header/Header';
import { Box, Skeleton } from '@mui/material';
import toast from 'react-hot-toast';

const ChitFund = () => {
    const { uuid, id } = useParams();
    const apiService = useApiService();
    const { token } = useAuthService();
    const [loading, setLoading] = useState(false);
    const [loadMembers, setLoadMembers] = useState(false);
    const [members, setMembers] = useState([]);
    const [memberEmails, setMemberEmails] = useState([]);
    const [period, setPeriod] = useState();
    const [cycleCalculation, setCycleCalculation] = useState();
    const [selectedLounge, setSelectedLounge] = useState(null);
    const [loadTransactions, setLoadTransactions] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
      };
    
      useEffect(() => {
        const loadUserTransactions = async (e) => {
          try {
            setLoadTransactions(true);
            const response = await apiService.raiseme.getTransactionsByRaiseMe({ raisemeId: id });
            const transactions = response.data;
        
            // Use a set to store unique user IDs
            const userIdsToFetch = new Set();
        
            // Identify user IDs to fetch details for
            transactions.forEach((transaction) => {
              if (!transaction.userDetails && !userIdsToFetch.has(transaction.user_id)) {
                userIdsToFetch.add(transaction.user_id);
              }
            });
        
            // Convert set to an array for easier manipulation
            const uniqueUserIds = Array.from(userIdsToFetch);
        
            // Throttle the number of concurrent requests to avoid overwhelming the server
            const concurrencyLimit = 5; // Adjust as needed
            const chunks = Array.from({ length: Math.ceil(uniqueUserIds.length / concurrencyLimit) }, (_, index) =>
              uniqueUserIds.slice(index * concurrencyLimit, (index + 1) * concurrencyLimit)
            );
        
            // Fetch user details in chunks
            const userDetailsChunks = await Promise.all(
              chunks.map(async (userIdsChunk) => {
                const userDetailsPromises = userIdsChunk.map(async (userId) => {
                  try {
                    const userDetailsResponse = await apiService.profile.profileDetails({ user_id: userId });
                    return userDetailsResponse.data;
                  } catch (error) {
                    return null; // Handle errors if needed
                  }
                });
        
                return Promise.all(userDetailsPromises);
              })
            );
        
            // Flatten the array of chunks
            const userDetails = userDetailsChunks.flat();
        
            // Update transactions with fetched user details
            const updatedTransactions = transactions.map((transaction) => ({
              ...transaction,
              userDetails: userDetails.find((userDetail) => userDetail?.userId === transaction.user_id) || {},
            }));
        
            setTransactionData(updatedTransactions);
          } catch (error) {
            if (error.response) {
              if(!error.response.status === 404){
                toast.error(error.response.data["code "]);
              }
            } else {
              toast.error("Network Error");
            }
          } finally {
            setLoadTransactions(false);
          }
        };
        
        loadUserTransactions();    
      }, [id]);

    useEffect(() => {
        const getRaiseMeInfo = async () => {
          try {
            setLoading(true);
            const response = await apiService.raiseme.getRaiseMeInfo({ raiseme_id: id });
            if (response.status === 200) {
              const data = await response.data;
              setSelectedLounge(data);
              const cycle = data.contributionAmount * data.groupSize;
              setCycleCalculation(cycle);
              setPeriod(data.period);
           }
          } catch (error) {
            console.error('Error fetching lounges:', error);
          } finally{
            setLoading(false);
          }
        };
    
    
        const fetchMembers = async () => {
          try {
            setLoadMembers(true);
            const response = await apiService.raiseme.getMembers({raisemeId: id});
    
            if (response.status === 200 || response.status === 201) {
              const data = response.data;
              const userIds = Object.values(data).map(member => member.user_id);
              const memberDetailsPromises = userIds.map((userId) => apiService.profile.profileDetails({user_id: userId}));
              const emailDetailsPromises = userIds.map((userId) => apiService.auth.userInfo({user_id: userId}));
    
              const [memberDetailsResponses, emailDetailsResponses] = await Promise.all([
                Promise.all(memberDetailsPromises),
                Promise.all(emailDetailsPromises)
              ]);
    
              const memberDetails = await Promise.all(memberDetailsResponses.map(response => response.data));
              const emailDetails = await Promise.all(emailDetailsResponses.map(response => response.data));
    
              setMembers(memberDetails);
              setMemberEmails(emailDetails);
              
            } else {
              console.error('Error fetching community members:', response.statusText);
            }
          } catch (e) {
            if(e.response){
              toast.error(e.response.data["code "]);
            }else{
              toast.error('Network Error');
            }
          }  finally{
            setLoadMembers(false);
          }
        };
        getRaiseMeInfo();
        fetchMembers();
      }, [id]);
  return (
    <>
    {loading ? (
        <>
        <Box sx={{ width: 300 }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        </Box>
        </>
    ) : (
    <>
      <Header uuid={uuid} />
      <div className="communityWrapper">
        <div className='community-sidebar'>
          <UserProfile />
          {/* <MembersCard />
          <EventCard /> */}
        </div>
        <div style={{width: '100%'}} className="raiseme-container">
          {/* Profile header section */}
          <div className="profile-header-cover">
          <div className="profile-header-cover">
                    <div className='bannerContanier'><img src={NO_COVER_IMG} alt='cover-img' /></div>
                  <div className="profile-header-info">
                    <div className="user-short-description">
                      {/* <img className='profile-img' src="img/calgary.png" alt="" /> */}
                      <img className='profile-img' src={NO_PROFILE} alt='profile-img' />
                      <div className="username">
                        <h2>{selectedLounge?.name ?? ''}</h2>
                        <p>{selectedLounge?.description ?? ''}</p>
                      </div>
                    </div>
                    <div className="user-actions">
                      <button className="button" onClick={handleOpenModal}><PersonAddAlt/></button>
                    </div>

                  </div>
                  </div>
          </div>

          {/* Overview and tabs */}
          <RaiseMeOverview selectedLounge={selectedLounge} period={period} members={members} loadMembers={loadMembers} cycleCalculation={cycleCalculation}/>
          <RaiseMeTab loungeId={id} setSelectedLounge={setSelectedLounge} selectedLounge={selectedLounge} transactionData={transactionData} members={members} memberEmails={memberEmails} loadTransactions={loadTransactions} loadMembers={loadMembers}/>
        </div>
      </div>

      {openModal && (
        <div className="modal-overlay">
          <RaiseMeInviteModal uuid={uuid} selectedLounge={selectedLounge} onCancel={handleCloseModal} />
        </div>
      )}
    </>
    )}
    </>
  )
}

export default ChitFund