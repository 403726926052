import { Avatar, Box, Button, Divider, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useApiService } from '../../services/api-service';
import toast from 'react-hot-toast';
import { capitalizeFirstLetter, LinearLoad } from '../../utils/routes';
import { Group, Person } from '@mui/icons-material';

const EditCommDetails = ({isAdminComm, profile, community, communityName, handleCommunityNameChange}) => {
    const apiService = useApiService();
    const [isRequesting, setIsRequesting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [votes, setVotes] = useState();
    const [isVotesRequested, setIsVotesRequested] = useState();
    const [senderDetails, setSenderDetails] = useState();

    const fetchVotes = async () => {
        try {
            setIsLoading(true);
            if(isAdminComm){
                const response = await apiService.community.getCommNameVotesAdmin({
                    user_id: profile.profile.userId,
                    comm_uuid: community.commUuid
                })
                setVotes(response.data);
                const senderResponses = await apiService.profile.profileDetails({
                    user_id: response.data.senderId
                  })
                setSenderDetails(senderResponses.data);
            } else{
                const response = await apiService.community.getCommNameVotesDemocratized({
                    user_id: profile.profile.userId,
                    comm_uuid: community.commUuid
                })
                setVotes(response.data);
                const senderResponses = await apiService.profile.profileDetails({
                    user_id: response.data.senderId
                  })
                  setSenderDetails(senderResponses.data);
            }
              
            setIsVotesRequested(true);
        } catch (e) {
            if(e.response.status === 404){
                setIsVotesRequested(false);
              }else{
                toast.error(e.response.data["code "]);
              }
        }finally{
            setIsLoading(false);
        }
    }

    const requestToEditComm = async () => {
        try {
            setIsRequesting(true);
            const requestBody = {
                sender_id: profile.profile.userId,
                comm_uuid: community.commUuid,
                notification_type: isAdminComm ? 'adminManageCommNameUpdate' : 'democratizedCommNameUpdate',
                delivery_type: 2
            }
            await toast.promise(
                apiService.notification.push(requestBody),{
                    loading: 'Sending Request...',
                    success: 'Request Sent Successfully',
                    error: 'Error Sending Request',
                }
            )
            fetchVotes();
        } catch (e) {
            toast.error(e.response.data["code "]);
            toast.error(e.response.data["error"]);
        } finally{
            setIsRequesting(false);
        }
    }

    useEffect(() => {
        fetchVotes();
    }, [])

    let validVote = null;
    if(votes){
        validVote = votes.endorsementCount>=votes.requiredEndorsement;
      }
  return (
    <>
    {isLoading ? (
        <>
            {LinearLoad()}
        </>
    ) : (
        <>
            {!isVotesRequested ? (
                <>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    <p className='alignLeft' style={{textAlign: 'left', fontSize: '1rem', fontWeight: 'bold'}}>Request To Edit Community</p>
                    <p style={{textAlign: 'left'}}>
                        {isAdminComm ? (
                            <>
                            The Admin-Managed Community is overseen and 
                        controlled exclusively by the admin, who holds 
                        all authority. Community edit access irreversible and requires 
                        voting process by the community admins for approval.
                            </>
                        ) : (
                            <>
                                The Democratized Community is overseen by the 
                                entire community. 
                                Community edit access irreversible and requires 
                                voting process by the simple majority of the community
                                for approval.
                            </>
                        )}
                        
                        </p>
                    <Button sx={{color: 'white', width: 'fit-content'}}
                    onClick={requestToEditComm} className='button'
                    disabled={isRequesting}>{isRequesting ? 'Requesting...' : 'Request To Edit Community'}</Button>
                </Box>
                </>
            ) : (
                <>
                <Box sx={{width: '100%'}}>
                            <Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p className='alignLeft' style={{textAlign: 'left', fontSize: '1rem', fontWeight: 'bold', width: '100%'}}>Edit Community</p>
                                    {votes && !validVote ? (
                                        <>
                                        <Stack direction={'column'} spacing={1} width={'100%'} marginBottom={'10px'}>
                                        <p style={{textAlign: 'left'}}>
                                            {isAdminComm ? (
                                                <>
                                                The Admin-Managed Community is overseen and 
                                            controlled exclusively by the admin, who holds 
                                            all authority. Community edit access irreversible and requires 
                                            voting process by the community admins for approval.
                                                </>
                                            ) : (
                                                <>
                                                    The Democratized Community is overseen by the 
                                                    entire community. 
                                                    Community edit access irreversible and requires 
                                                    voting process by the simple majority of the community
                                                    for approval.
                                                </>
                                            )}
                                            </p>
                                            <Box sx={{display: 'flex', gap: '10px'}}>
                                                <Avatar src={senderDetails.photoUrl} alt={senderDetails.firstName}>
                                                    {capitalizeFirstLetter(senderDetails.firstName)}
                                                </Avatar>
                                                <Stack direction={'column'}>
                                                    <p style={{textAlign: 'left'}}>Proposed By</p>
                                                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>{senderDetails.firstName} {senderDetails.lastName}</p>
                                                </Stack>
                                            </Box>
                                            <Box>
                                            <Divider />
                                            <Box sx={{display: 'flex', width: '100%', gap: '10px', marginTop: '10px'}}>
                                                <Group />
                                                <Stack direction={'column'} width={'100%'} spacing={0.5}>
                                                <p style={{textAlign: 'left'}}>{votes.requiredEndorsement - votes.endorsementCount} vote(s) left</p>
                                                <progress className='aid-lounge-progress' value={votes.endorsementCount} max={votes.requiredEndorsement} />
                                                </Stack>
                                            </Box>
                                        </Box>
                                        </Stack>
                                        </>
                                    ) : (
                                        <>
                                        <Stack sx={{marginTop: '10px'}} direction={'column'} spacing={1} width={'100%'} marginBottom={'10px'}>
                                        <Box className='create-aid-input-box'>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <label htmlFor='lounge-name'>Community Name:</label>
                                            </Stack>
                                            <input value={communityName}
                                            onChange={handleCommunityNameChange}
                                            className='create-aid-input'
                                            type="text" name="name" id='lounge-name'
                                            />
                                        </Box>
                                        </Stack>
                                        </>
                                    )}
                                </Box>
                            </Box>
                            <Box>
                            </Box>
                </Box>
                </>
            )}
        </>
    )}
    
    </>
  )
}

export default EditCommDetails