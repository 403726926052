// Import images
import logo from '../assets/img/myareaa-logo.svg';
import error from '../assets/img/error-vector.svg';
import appStore from '../assets/img/app-store.svg';
import googlePlay from '../assets/img/google-play.svg';
import favicon from '../assets/img/favicon.svg';
import logoPNG from '../assets/img/myareaa-logo.png';

// Export images as an object
const images = {
    logo,
    error,
    appStore,
    googlePlay,
    favicon,
    logoPNG
};

export default images;