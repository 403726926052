import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from '../privateRoute/privateRoute'
import { useProfileService } from '../services/profile-service'
import CommunityDetails from '../pages/CommunityDetails/CommunityDetails'
import CreateCommunityPage from '../pages/CreateCommunityPage/CreateCommunityPage'
import Communities from '../pages/Communities/Communities'
import InviteVerification from '../components/InviteVerification/InviteVerification'
import InviteVerificationRaiseMe from '../components/InviteVerificationRaiseMe/InviteVerificationRaiseMe'
import JoinCommunityPage from '../pages/JoinCommunityPage/JoinCommunityPage'
import RaiseMeWallet from '../pages/RaiseMeWallet/RaiseMeWallet';
import NeedHelp from '../pages/NeedHelp/NeedHelp';
import RaiseMeContributions from '../pages/RaiseMeContributions/RaiseMeContributions';
import LoungeDetails from '../pages/LoungeDetails/LoungeDetails';
import CommunityPost from '../pages/CommunityPost/CommunityPost';
import LoungePost from '../pages/LoungePost/LoungePost';
import Error404 from '../pages/404/Error404';
import MyPals from '../pages/MyPals/MyPals';
import ThriftersCorner from '../pages/ThriftersCorner/ThriftersCorner';
import AidLounge from '../pages/AidLounge/AidLounge'
import AidLounges from '../pages/AidLounges/AidLounges'
import ChitFund from '../pages/ChitFunds/ChitFund'
import MyChitFunds from '../pages/ChitFunds/MyChitFunds'
import Profile from '../pages/Profile/Profile'
import VirtualCard from '../pages/VirtualCard/VirtualCard'
import CommunityInvite from '../pages/CommunityInvite/CommunityInvite'
import MobileVersionDrawer from '../components/MobileVersionDrawer/MobileVersionDrawer'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useMediaQuery } from '@mui/material'
import { getDeviceType } from '../utils/routes'

// const Home = React.lazy(() => import('../pages/Home/Home'))
const MyBusiness = React.lazy(() => import('../pages/myBusiness/MyBusiness'))
const MyNeighbours = React.lazy(() =>
  import('../pages/myNeighbours/MyNeighbours')
)
const Events = React.lazy(() => import('../pages/Events/Events'))
const Settings = React.lazy(() => import('../pages/Settings/Settings'))
const Community = React.lazy(() => import('../pages/Community/Community'))
const RaiseMe = React.lazy(() => import('../pages/RaiseMe/RaiseMe'))
const Lounges = React.lazy(() => import('../pages/Lounges/Lounges'))
const CLounge = React.lazy(() => import('../pages/Community-lounge/CLounge'))
const Register = React.lazy(() => import('../pages/Register/Register'))
const Login = React.lazy(() => import('../pages/Login/Login'))
const OnBoarding = React.lazy(() => import('../pages/OnBoarding/OnBoarding'))
const EmailConfirmation = React.lazy(() =>
  import('../components/confirmEmail/ConfirmEmail')
)
const ForgotPassword = React.lazy(() =>
  import('../pages/ForgotPassword/ForgotPassword')
)
const ResetPassword = React.lazy(() =>
  import('../components/ResetPassword/ResetPassword')
)

const AppRoutes = () => {
  const { profileLoaded } = useProfileService();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const deviceType = getDeviceType();
  

  useEffect(() => {
    if (isMobile) {
      const hasShownDrawer = sessionStorage.getItem('hasShownDrawer');
      const nonShowPaths = [
        /^\/$/,
        /^\/login$/,
        /^\/register$/,
        /^\/onboarding$/,
        /^\/community\/invite\/?.*/,
        /^\/notification\/?.*/,
        /^\/reset-password\/?.*/,
        /^\/forgot-password$/,
        /^\/auth\/confirm-email\/?.*/
      ];

      const isNonShowPath = nonShowPaths.some(pathRegex => pathRegex.test(location.pathname));

      if (deviceType !== 'Unknown' && !hasShownDrawer && !isNonShowPath) {
        setIsDrawerOpen(true);
        sessionStorage.setItem('hasShownDrawer', 'true');
      }
    }
  }, [isMobile, location.pathname]);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return profileLoaded ? (
    <>
    <MobileVersionDrawer deviceType={deviceType} isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
    <Switch>
      {/* <PrivateRoute exact path='/' component={Home} /> */}
      <Route exact path='/community/invite/:id' component={CommunityInvite} />
      <Route
        exact
        path='/auth/confirm-email/:token'
        component={EmailConfirmation}
      />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path='/reset-password/:token' component={ResetPassword} />
      <Route exact path='/aid-lounge/:id' component={AidLounge} />
      <Route exact path='/register' component={Register} />
      <Route exact path='/login' component={Login} />
      <Route exact path="/notification/:token/" component={InviteVerification} />
      <PrivateRoute exact path='/my-business' component={MyBusiness} />
      <PrivateRoute exact path='/my-neighbours' component={MyNeighbours} />
      <PrivateRoute exact path='/events' component={Events} />
      <PrivateRoute exact path='/settings' component={Settings} />
      <PrivateRoute exact path='/settings/:tab' component={Settings} />
      <PrivateRoute exact path='/community' component={Community} />
      <PrivateRoute exact path="/community/:uuid" component={CommunityDetails} />
      <PrivateRoute exact path="/community/:uuid/:loungeId" component={RaiseMe} />
      <PrivateRoute exact path="/community/chit/:uuid/:id" component={ChitFund} />
      <PrivateRoute exact path="/lounge/:uuid/:loungeId" component={LoungeDetails} />
      <PrivateRoute exact path="/community/post/:uuid/:postId" component={CommunityPost}/>
      <PrivateRoute exact path="/lounge/post/:uuid/:postId" component={LoungePost}/>
      <PrivateRoute exact path='/raise-me' component={RaiseMe} />
      
      <PrivateRoute exact path='/lounges' component={Lounges} />
      <PrivateRoute exact path='/clounge' component={CLounge} />
      <PrivateRoute exact path='/create-community' component={CreateCommunityPage} />
      <PrivateRoute exact path='/join-community' component={JoinCommunityPage} />
      <PrivateRoute exact path='/communities' component={Communities}/>
      <PrivateRoute exact path='/my-aid-lounges' component={AidLounges}/>
      {/* <PrivateRoute exact path='/my-chit-funds' component={MyChitFunds}/> */}
      <PrivateRoute exact path='/' component={Communities}/>
      <PrivateRoute exact path='/transactions' component={RaiseMeWallet}/>
      <PrivateRoute exact path='/need-help' component={NeedHelp}/>
      <PrivateRoute exact path='/my-pals' component={MyPals}/>
      <PrivateRoute exact path='/thrifters-corner' component={ThriftersCorner}/>
      <PrivateRoute exact path='/virtual-card' component={VirtualCard}/>
      <PrivateRoute exact path='/raiseme-contributions' component={RaiseMeContributions}/>
      
      <PrivateRoute exact path="/raise-me/notification/:token" component={InviteVerificationRaiseMe}/>
      
      <PrivateRoute path='/onboarding' component={OnBoarding} />
      <PrivateRoute path='/profile/:id' component={Profile} />
      
      <PrivateRoute exact path='*' component={Error404} />
    </Switch>
    </>
  ) : null
}

export default AppRoutes
