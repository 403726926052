import React, { useState } from 'react'
import { useApiService } from '../../services/api-service';
import toast from 'react-hot-toast';
import RenderCountryList from '../../components/renderCountryList/RenderCountryList';
import { Button } from '@mui/material';
import { useProfileService } from '../../services/profile-service';

const OnboardingScreen = ({fetchProfile}) => {
    const [formData, setFormData] = useState({
        importContact: '',
        createCommunity: '',
        inviteFriends: '',
        email: '',
        completeProfile: '',
        date: '',
        firstName: '',
        lastName:'',
        city: '',
        gender: '',
        bio: '',
        otherGender: ''
      });
    const [error, setError] = useState('');
    const apiService = useApiService();
    const [firstNameError, setFirstNameError] = useState("");
    const [bioError, setBioError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const formattedDate = formatDate(selectedDate);
        setSelectedDate(formattedDate);
      };
    
      const currentDate = new Date();
        const thirteenYearsAgo = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData((prevFormData) => ({
              ...prevFormData,
              [name]: value,
            }));
          
            // Validate the length of first name and last name
            if (name === "firstName") {
              if (value.length < 2) {
                setFirstNameError("First name must be at least 2 characters.");
              } else {
                setFirstNameError("");
              }
            }
          
            if (name === "lastName") {
              if (value.length < 2) {
                setLastNameError("Last name must be at least 2 characters.");
              } else {
                setLastNameError("");
              }
            }
          
            if (name === "bio") {
              if (value.length > 150) {
                setBioError("Bio must be 150 characters or less.");
              } else {
                setBioError("");
              }
            }
          
            // Enable the button only if both first name and last name are valid and bio is within limit
            setIsButtonDisabled(
              formData.firstName.length < 2 || 
              formData.lastName.length < 2 || 
              (formData.bio && formData.bio.length > 150)
            );
          };
      
      const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        
          try {
            setSubmitting(true);
            const formattedDate = selectedDate ? formatDate(selectedDate) : '';
            // Prepare the data for the request body
            const requestBody = {
              first_name: formData.firstName,
              last_name: formData.lastName,
              city: formData.state,
              country: formData.country,
              gender: formData.gender,
              birth_date: formattedDate,
              bio: formData.bio,
            };
    
            if (formData.state === 'other') {
              requestBody.city = formData.customState;
            }
    
            if(formData.gender === 'other'){
              requestBody.gender = formData.otherGender;
            }
            // Make the POST request to create user profile
            const response = await apiService.auth.createProfile(requestBody);
            if (response.status === 200 || response.status === 201) {
              toast.success('Profile created successfully')
              fetchProfile();
            } 
          } catch (e) {
            if(e.response){
              toast.error(e.response.data["code "]);
            } else {
              toast.error("Network Error");
            }
          } finally{
            setSubmitting(false);
          }
      };
  return (
    <>
    <div className="step-container">
                
                <div className="step-middle">
                  <h2 className="onboarding-text">Complete Your Profile</h2>
                  <p>Complete your profile to join </p>
                  <input
                  type="text"
                  name="firstName"
                  placeholder="Enter your First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
    
                {firstNameError && <span className="error-message">{firstNameError}</span>}
    
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Enter your Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      minLength={2}
                    />
    
                  {lastNameError && <span className="error-message">{lastNameError}</span>}
                  <textarea
                                    value={formData.bio}
                                    style={{marginBottom: '0'}}
                                    className='create-aid-input'
                                    placeholder='Enter Bio'
                                    required
                                    type="text"
                                    name="bio"
                                    onChange={handleChange}
                                    id='lounge-target-amount'
                                />
                                {bioError && <span className="error-message">{bioError}</span>}
    
            <RenderCountryList formData={formData} setFormData={setFormData} />
            <div>
            <select
              required
              className='onboarding-field'
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <option value="">Select your gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="transgender">Transgender</option>
              <option value="cisgender">Cisgender</option>
              <option value="nonbinary">Non-binary</option>
              <option value="other">Other</option>
            </select>
          </div>
          {formData.gender === 'other' && (
            <div>
              <input
                required
                className='onboarding-field'
                type="text"
                name="otherGender"
                placeholder="Enter your gender"
                value={formData.otherGender}
                onChange={handleChange}
              />
            </div>
          )}
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <label className='dob-label'>Select your Date of Birth</label>
            <input
              type="date"
              name="date"
              placeholder="Enter your Date of Birth"
              value={selectedDate}
              onChange={handleDateChange}
              required
              className="date-input onboarding-field"
              max={formatDate(thirteenYearsAgo)}
            />
            </div>
          </div>
          {error && <p className="error">{error}</p>}
          <div className="step-bottom">
          <Button
          sx={{color: 'white'}}
            className="button full-width-btn onboarding-btn"
            onClick={handleSubmit}
            disabled={isButtonDisabled || formData.gender === '' || !formData.state || !formData.country || !selectedDate} // Disable the button based on the state
          >
            {submitting ? 'Creating' : 'Continue'}
          </Button>
          </div>
        </div>
    </>
  )
}

export default OnboardingScreen