import { Error } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import './errors.css'

const ErrorComponent = ({message}) => {
    const reloadPage = () => {
        window.location.reload();
    }
  return (
    <>
              <Box className='error-component-wrapper'>
                <Error className='big-icon' />
                <p>{message}</p>
                <Stack direction={'column'} spacing={1}>
                <Button onClick={reloadPage} style={{backgroundColor: '#a06ccb', color: 'white'}} variant='contained'>Reload Page</Button>
                <Button href='/' style={{backgroundColor: '#a06ccb', color: 'white'}} variant='contained'>HomePage</Button>
                </Stack>
              </Box>
              </>
  )
}

export default ErrorComponent