import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import LoungeList from '../LoungeList/LoungeList';
import ChitFundsList from '../ChitFunds/ChitFundsList';
import PropTypes from 'prop-types';
import { useApiService } from '../../services/api-service';
import toast from "react-hot-toast";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const RaiseMes = ({uuid}) => {
    const [loading, setLoading] = useState(false);
    const [chitFunds, setChitFunds] = useState([]);
    const [lounges, setLounges] = useState([]);
    const apiService = useApiService();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      // const fetchData = async () => {
      //   try {
      //     setLoading(true);
      //     const [chitFundsResponse, ajoResponse] = await Promise.all([
      //       apiService.raiseme.fetchbyRaisemeType({ comm_uuid: uuid, raiseme_type: 'CHIT_FUND' }),
      //       apiService.raiseme.fetchbyRaisemeType({ comm_uuid: uuid, raiseme_type: 'RAISEME_CONTRIBUTION' })
      //     ]);
    
      //     // Handle chit funds response
      //     if (chitFundsResponse.status === 200 || chitFundsResponse.status === 201) {
      //       setChitFunds(chitFundsResponse.data);
      //     }

      //         // Handle raiseme response
      //     if (ajoResponse.status === 200) {
      //       setLounges(ajoResponse.data);
      //     }
      //   } catch (e) {
      //     if(e.response){
      //       toast.error(e.response.data[" code"]);
      //     }else{
      //       toast.error("Network Error")
      //     }
      //   } finally {
      //     setLoading(false);
      //   }
      // };
      const fetchData = async () => {
        try {
          setLoading(true);
          const [ajoResponse] = await Promise.all([
            apiService.raiseme.allRaiseMe({ comm_uuid: uuid })
          ]);

              // Handle raiseme response
          if (ajoResponse.status === 200) {
            setLounges(ajoResponse.data);
          }
        } catch (e) {
          if(e.response){
            toast.error(e.response.data[" code"]);
          }else{
            toast.error("Network Error")
          }
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchData();
      }, [uuid]);

  return (
    <>
    {loading ? (
      <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <CircularProgress style={{ color: '#a06ccb'}}/>
      <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
      </Box>
    ) : (
      <>
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary" indicatorColor="secondary">
            <Tab label="Collective Cash" {...a11yProps(0)} />
            {/* <Tab label="My Chit Funds" {...a11yProps(1)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <LoungeList uuid={uuid} lounges={lounges} />
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={1}>
        <ChitFundsList uuid={uuid} chitFunds={chitFunds}/>
        </CustomTabPanel> */}
      </Box>
      </>
    )}
    </>
  )
}

export default RaiseMes