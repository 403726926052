// Import the functions you need from the SDKs you need
// import firebase from 'firebase/app';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDRn5kN9jiE6yVtLLeVHcjEEvyFhRgrocE",
  authDomain: "myareaa-f2250.firebaseapp.com",
  projectId: "myareaa-f2250",
  storageBucket: "myareaa-f2250.appspot.com",
  messagingSenderId: "306086580420",
  appId: "1:306086580420:web:da1c4cd8d0ea0e586c7991",
  measurementId: "G-R8S7858JH0"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const requestFCMToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);
    if(permission === "granted"){
        const token = await getToken(messaging, {
            vapidKey:
                "BH8bJw7-MLfVqYgPigTWWBGhLxsxWg0DvUP29UgzEl4029bJqnk-p8OuUv2p8JKNQM377fFFCVCIbjk97vU1Qu0"
        });
        return token;
    } else {
        return 'no token'
    }
}