import { Box, Button, Container, Stack } from '@mui/material'
import React from 'react'

const ErrorBoundaryComponent = ({error}) => {
    const reloadPage = () => {
        window.location.reload();
    }
  return (
    <>
    <Container style={{height: '98vh'}}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
            <Stack alignItems={'center'} spacing={2}>
            <div className="oops-header">
                <h1>Error!</h1>
            </div>
            <div className="oops-description">
                <p>{error.message}</p>
            </div>
            <Button onClick={reloadPage} style={{backgroundColor: '#a06ccb', color: 'white'}} variant='contained'>Reload Page</Button>
            <Button href='/' style={{backgroundColor: '#a06ccb', color: 'white'}} variant='contained'>HomePage</Button>
            </Stack>
        </Box>
    </Container>
    </>
  )
}

export default ErrorBoundaryComponent