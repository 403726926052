import { Cancel, CopyAll, CopyAllOutlined, Settings } from '@mui/icons-material'
import { Backdrop, Box, Button, Divider, Fade, Modal, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useApiService } from '../../services/api-service'
import toast from 'react-hot-toast'
import { LinearLoad } from '../../utils/routes'
import LinkSettings from './LinkSettings'
import SharePost from '../Share/SharePost'

const GenerateLinkInvite = ({community, onCancel, uuid}) => {
  const apiService = useApiService();
  const [daysExpired, setDaysExpired] = useState();
  const [showMainScreen, setShowMainScreen] = useState(false);
  const [showSettingScreen, setShowSettingScreen] = useState(true);
  const [maxUse, setMaxUse] = useState();
  const [inviteLink, setInviteLink] = useState();
  const [fetchingLink, setFetchingLink] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const handleOpenShare = () => {
    setShowMainScreen(false);
    setOpenShare(true);
  }
  const handleCloseShare = () => {
    setShowMainScreen(true)
    setOpenShare(false);
  }

  const handleBackClick = () => {
    setShowMainScreen(true);
    setShowSettingScreen(false);
  }

  const handleSettingsClick = () => {
    setShowMainScreen(false);
    setShowSettingScreen(true);
  }

  const generateLink = async () => {
    try {
        setFetchingLink(true);
        const response = await apiService.community.generateLink({
            comm_uuid: uuid,
            expires_after: daysExpired,
            max_number_of_uses: maxUse
        });
        const link = response.data.link;
        setInviteLink(link);
        setIsCopied(false)
    } catch (e) {
        if(e.response){
            toast.error(e.response.data["code "]);
        } else{
            toast.error('Network Error');
        }
    } finally{
        setFetchingLink(false);
    }
  }

  const handleDaysChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && (value === '' || value.charAt(0) !== '0')) {
        setDaysExpired(value);
    }
}

const handleMaxUseChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && (value === '' || value.charAt(0) !== '0')) {
        setMaxUse(value);
    }
}
  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink)
        .then(() => {
            setIsCopied(true);
            toast.success("Link Copied to Clipboard");
            setTimeout(() => {
                setIsCopied(false);
            }, 5000);
        })
        .catch(err => {
            toast.error("Failed to copy the link");
            console.error('Error copying text: ', err);
        });
};

const handleShareLink = () => {
  navigator.clipboard.writeText(`You have been invited to join ${community.commName} Community in MyAreaa.\n${inviteLink}`)
      .then(() => {
          setIsCopied(true);
          toast.success("Link Copied to Clipboard");
          setTimeout(() => {
              setIsCopied(false);
          }, 5000);
      })
      .catch(err => {
          toast.error("Failed to copy the link");
          console.error('Error copying text: ', err);
      });
};
  return (
    <>
    <Box className="generate-link-modal">
        {showMainScreen && (
            <>
            <Box sx={{padding: '15px'}}>
    <Cancel onClick={onCancel} sx={{cursor: 'pointer', float: 'right'}} />
    <h2 style={{color: 'black', textAlign: 'left', fontSize: '16px'}}>Invite friends to {community.commName}</h2>
    <p>Share this link with others to grant access to this community!</p>
    </Box>
    <Divider />
    {fetchingLink ? (
        <>
            {LinearLoad()}
        </>
    ) : (
        <>
        <Box sx={{padding: '15px'}}>
        <Box sx={{display: 'flex', backgroundColor: '#ebebeb',
        padding: '5px', marginBottom: '10px'}}>
        <input
        className='link-input'
        type='text'
        value={inviteLink}
        readOnly
        />
        <Button onClick={handleCopyLink} sx={{color: 'white'}} className='button'>
            {isCopied ? <CopyAll/> : <CopyAllOutlined />}
        </Button>
        
    </Box>
    <p style={{textAlign: 'left', fontSize: '.775rem'}}>{daysExpired === '1' ? 'Your link expires in 1 day' : `Your link expires in ${daysExpired} days`}. Your link has {maxUse} max use case</p>
    </Box>
        </>
    )}
    
    <Box sx={{display: 'flex', justifyContent: 'flex-end',
        alignItems: 'center', backgroundColor: '#f2f3f5',
        padding: '10px 15px', gap: '10px'}}>
          
          <Tooltip title={'Link invite settings'}>
        <Settings sx={{cursor: 'pointer'}} onClick={handleSettingsClick}/>
        </Tooltip>
        <Button sx={{color: 'white'}} className='button'
          onClick={handleOpenShare}
          >Share</Button>
    </Box>
            </>
        )}
        {showSettingScreen && (
            <>
            <LinkSettings handleDaysChange={handleDaysChange}
            handleMaxUseChange={handleMaxUseChange}
            daysExpired={daysExpired}
            maxUse={maxUse} generateLink={generateLink}
            handleBackClick={handleBackClick}
            onCancel={onCancel}/>
            </>
        )}
        <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openShare}
                    onClose={handleCloseShare}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                    <SharePost onCancel={handleCloseShare} url={`You have been invited to join ${community.commName} Community in MyAreaa.\n${inviteLink}`} handleCopyLink={handleShareLink} isCopied={isCopied}/>
                            </Fade>
                        </Modal>
    </Box>
    </>
  )
}

export default GenerateLinkInvite