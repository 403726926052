import React, { useEffect, useRef, useState } from 'react';
import './dropDownProfile.css';
import { Avatar, Badge, Divider, Rating } from '@mui/material';
import Accordions from '../accordion/Accordions';
import { ArrowDropDown, Place } from '@mui/icons-material';
import { useProfileService } from '../../services/profile-service';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    padding: '0 4px',
  },
}));

const DropDownProfile = () => {
  const [open, setOpen] = useState(false);
  const { profile, profileLoaded } = useProfileService();
  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <div className='dropDownProfile'>
      <div className='menu-container' ref={menuRef}>
        {profile && (
          <div className='menu-trigger' onClick={() => setOpen(!open)}>
          <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<ArrowDropDown/>}
        >
          <Avatar alt={`${profile.firstName} ${profile.lastName}`} src={profile?.photoUrl} />
        </StyledBadge>
            {/* <img src={profile?.photoUrl || NO_PROFILE} alt='' />
            <ArrowDropDown /> */}
          </div>
        )}
        <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`}>
        {profile && (
          <>
          <div className='userInfo'>
          <Avatar alt={`${profile.firstName} ${profile.lastName}`} src={profile?.photoUrl} />
            <div className='Uinfo'>
            <Link to={`/profile/${profile.userId}`}>
                <b>{profile.firstName} {profile.lastName}</b>
                </Link>
                <span className="profile-location">
              <Place/> {profile?.city}, {profile?.country}
              </span>
            </div>
          </div>
          <Divider />
          </>
        )}
          <ul>
            <li className='accordionsMenu'>
              <Accordions />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropDownProfile;
