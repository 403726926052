import { Link } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useApiService } from '../../services/api-service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useProfileService } from '../../services/profile-service';
import useCountryCityList from '../../components/useCountryCityList/useCountryCityList';
import './createCommunityPage.css';
import '../Login/login.css'
import Header from '../../components/header/Header';
import { LOGIN_ROUTE } from '../../utils/routes';
import { CommunityContext } from '../../contexts/CommunityContext';
import { useContext } from 'react';
import { useAuthService } from '../../services/auth-service';
import toast from 'react-hot-toast';

const CreateCommunityPage = () => {
    const [communityName, setCommunityName] = useState('');
    const [communityType, setCommunityType] = useState('exclusive');
    const [communityDescription, setCommunityDescription] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [communityDefault, setCommunityDefault] = useState();
    const { countries, cities, fetchCitiesByCountry } = useCountryCityList();
    const [uuid] = useState('');
    const history = useHistory();
    const apiService = useApiService();
    const [error, setError] = useState('');
    const { profile, loadProfile } = useProfileService(); // Access the loadProfile function from the profile service
    const { loadCommunity } = useContext(CommunityContext);
    const [bannerFile, setBannerFile] = useState(null);
    const [communityId, setCommunityId] = useState(null);
    const [step, setStep] = useState(1)
    const { token } = useAuthService();

    const handleCommunityNameChange = (e) => {
        setCommunityName(e.target.value);
      };
    
      const handleCommunityTypeChange = (e) => {
        setCommunityType(e.target.value);
      };
    
      const handleCountryChange = (event) => {
        const selectedCountryCode = event.target.value;
        setSelectedCountry(selectedCountryCode);
        fetchCitiesByCountry(selectedCountryCode);
      };
    
      const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
      };

      const handleBannerFileChange = (e) => {
        const file = e.target.files[0];
        setBannerFile(file);
      };
    
      useEffect(() => {
        const checkCommunityDefault = async () => {
          try {
            const membersResponse = await apiService.community.membership();
            if (Object.keys(membersResponse.data).length > 0) {
              setCommunityDefault(false);
            } else
            setCommunityDefault(true);
          } catch (e) {
            if(e.response){
              if(e.response.status !== 404){
                toast.error(e.response.data["code "])
              }
            } else {
              toast.error("Network Error");
            }
          }
        };
    
        checkCommunityDefault();
      }, [apiService.community]);
    
      const CreateMemberForOwner = async (createdCommunityId) => {
        try {
          const requestBody = {
            comm_default: true,
            comm_uuid: createdCommunityId,
            user_id: profile.userId
          };
          await apiService.community.createMember(requestBody);
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          } else {
            toast.error("Network Error");
          }
        }
      };


      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const selectedCountryObj = countries.find(country => country.code === selectedCountry);
          const requestBody = {
            comm_name: communityName,
            comm_descr: communityDescription,
            city: profile.city,
            country: profile.country,
            comm_default: communityDefault
          };
      
          const response = await apiService.community.createCommunity(requestBody);
          if (response.status === 201) {
            const createdCommunityId = response.data.id;
            setCommunityId(createdCommunityId);
            // Close the modal
            loadProfile();
            await CreateMemberForOwner(createdCommunityId);
            toast("Community Created Successfully",{
                type: "success",
                data:{
                  msg: "Community Created Successfully",
                  type: "success",
                },
              });
             setStep(2)
          }
        } catch (e) {
            if(e.response){
              toast(e.response.data["code "], {
                type: "error",
                data: {
                  msg: e.response.data["code "],
                  type: "error",
                }
              });
              } else{
                toast("Network Error", {
                  type: "error",
                  data: {
                    msg: "Network error, please check your internet connection.",
                    type: "error",
                  }
                });
              }
        }
      };

      const handleUploadBanner = async (e) => {
        e.preventDefault();
        
        try {
          const formData = new FormData();
          formData.append('file', bannerFile);
          // Append community ID to the form data
          formData.append('comm_uuid', communityId);
          const response = await apiService.community.uploadBanner(formData);
          if (response.status === 200 || response.status === 201) {
            toast('Banner Photo Uploaded', {
              type: 'success',
              data: {
                msg: 'Profile Photo Uploaded',
                type: 'success'
              }
            });
            loadCommunity();
            history.push('/communities');
          }
        } catch (error) {
          // Handle error if the request fails
          toast('Error uploading banner picture', {
            type: 'error',
            data: {
              msg: 'Error uploading banner picture',
              type: 'error'
            }
          });
        }
      };


  return (
    <>
      <Header/>
      <div className="register-wrapper">
        <div className="ccp-container">
          <div className="register-card">
            <div className="register-left">
            {step === 1 && (
              <>
              <h2>Create a community</h2>
              <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="community-name">Community Name:</label>
            <input
              type="text"
              id="community-name"
              value={communityName}
              onChange={handleCommunityNameChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="community-description">Community Description:</label>
            <textarea
              id="community-description"
              value={communityDescription}
              onChange={(e) => setCommunityDescription(e.target.value)}
              required
            ></textarea>
          </div>
        {error && <p className="error">{error}</p>}
          <div className="form-actions">
            <button className='button full-width-btn' type="submit">Create</button>
          </div>
        </form>
        </>
        )}
        {step === 2 && (
        <>
          <h2>Upload your community banner</h2>
          <div className="form-group">
                  <label htmlFor="banner">Community Banner:</label>
                  <input
                    type="file"
                    id="banner"
                    accept="image/*"
                    onChange={handleBannerFileChange}
                    required
                  />
                </div>
            <div className="form-actions">
            <button className='button full-width-btn' type="submit" onClick={handleUploadBanner}>Upload Banner</button>
          </div>
        </>
         )}
            </div>
            <div className="create-comm-page-right"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateCommunityPage