import React, { useEffect, useState } from 'react'
import { useApiService } from '../../services/api-service';
import CardSkeleton from '../CardSkeleton/CardSkeleton';
import { Box, Button, Stack } from '@mui/material';
import { NO_COVER_IMG, formatAmountWithCommas, handleError } from '../../utils/routes';
import './aidloungecard.css'

const MyAidLoungeCard = ({searchQuery}) => {
    const [loading, setLoading] = useState(false)
    const [loungeList, setLoungeList] = useState([]);
    const apiService = useApiService();
    const getLounges = async () => {
      try {
        setLoading(true)
        const response = await apiService.aidlounge.allLoungesByCreator();
        const lounges = response.data;
  
        // Fetch balances for each lounge
        const loungesWithBalances = await Promise.all(lounges.map(async (lounge) => {
          try {
            const balanceResponse = await apiService.aidlounge.getBalance({ aidlounge_id: lounge.id });
            return { ...lounge, balance: balanceResponse.data.availableBalance };
          } catch (e) {
            console.error(`Failed to fetch balance for lounge ${lounge.id}`, e);
            return { ...lounge, balance: 0 };
          }
        }));
  
        setLoungeList(loungesWithBalances);
      } catch (e) {
        handleError(e)
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
        getLounges();
    },[])
    

    const filteredAidLoungeData = loungeList.filter((lounge) => {
        const { name } = lounge;
        if (name) {
          return (
            name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return false;
      });

    if (loading) {
        return <CardSkeleton cards={8} />;
      }

      if (loungeList.length === 0) {
        return (
          <>
          <div className='no-community'>
            <p style={{textAlign: 'center'}}>You have not created any aid lounge.</p>
          </div>
          </>
        );
      }
  return (
    <>
        {filteredAidLoungeData.map((lounges, index) => (
            <Box className='aid-lounge-card-wrapper' key={index}>
                <Box className='aid-lounge-card-top'>
                    <img className='aid-lounge-card-banner' src={lounges.banner || NO_COVER_IMG}/>
                </Box>
                <Box className='aid-lounge-card-bottom'>
                    <Stack direction={'column'}>
                    <h2 style={{color: '#a06ccb'}}>{lounges.name}</h2>
                    <p className={`alignLeft${lounges.descr.length > 200 ? ' truncate' : ''}`}>{lounges.descr}</p>
                    </Stack>
                    <progress style={{marginTop: 'auto'}} className='aid-lounge-progress' value={lounges.balance} max={lounges.target_amt}/>
                    <Stack direction={'row'} spacing={1}>
                      <h2 style={{ color: '#a06ccb' }}>${formatAmountWithCommas(lounges.balance)}</h2>
                      <p>CAD raised of ${formatAmountWithCommas(lounges.target_amt)} goal</p>
                    </Stack>
                    <Button href={`/aid-lounge/${lounges.id}`} sx={{ width: '100%', color: 'white' }} className='button'>
                        View Aid Lounge
                    </Button>
                </Box>
            </Box>
      ))}
    </>
  )
}

export default MyAidLoungeCard