import React, {useEffect, useRef, useState} from 'react'
import './communityDropDown.css'
import { Divider, Drawer, Rating, Tooltip } from '@mui/material';
import Accordions from '../accordion/Accordions';
import { ArrowDropDown, Diversity1 } from '@mui/icons-material';
import { useProfileService } from '../../services/profile-service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SwitchCommunityList from '../SwitchCommunityList/SwitchCommunityList';

const CommunityDropDown = () => {
    const [open, setOpen] = useState(false);
    const { profile, profileLoaded } = useProfileService();
    let menuRef = useRef();
    const [openCommunities, setOpenCommunities] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenCommunities(newOpen);
    };
    const history = useHistory();

    useEffect(() =>{
        let handler = (e) =>{
            if(!menuRef.current.contains(e.target)){
                setOpen(false);
            }
            
        };

        document.addEventListener("mousedown", handler);

        return() =>{
            document.removeEventListener("mousedown", handler);
        }
    });


  return (
    <div className='dropDownProfile'>
        <div className="menu-container" ref={menuRef}>
            <Tooltip title='My Communities'>
            <div className="menu-trigger" onClick={toggleDrawer(true)}>
            <Diversity1/>
            <ArrowDropDown/>
            </div>
            </Tooltip>
            <Drawer anchor='right' open={openCommunities} onClose={toggleDrawer(false)}>
            <SwitchCommunityList toggleDrawer={toggleDrawer} />
            </Drawer>
            {/* <div className={`community-dropdown-menu ${open? 'active' : 'inactive'}`}>
                <ul className='community-dropdown-list'>
                    <li className="accordionsMenu">
                    <SwitchCommunityList/>
                    </li>
                </ul>
            </div> */}
        </div>
    </div>
  )
}


export default CommunityDropDown