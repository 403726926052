import React, { useEffect, useState } from 'react'
import { formatAmountWithCommas } from '../../utils/routes'
import { Box, Button, Chip, CircularProgress, Stack } from '@mui/material'
import { ArrowBack, MonetizationOn } from '@mui/icons-material'
import AccountsDropdown from '../RaiseMeAccount/AccountsDropdown'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";
import Lottie from 'lottie-react'
import Successful from "../../lottiefiles/Successful.json"

const WithdrawWallet = ({ filteredAccounts, selectedAccount, handleSelectAccount, setSelectedAccount,
    isWithdrawing, amount, intAmount, handleAmount,
    handleBackButtonClick, availableBalance, setIsWithdrawing,
    community, profile, isAdminComm }) => {
        const apiService = useApiService();
        const [kycData, setKycData] = useState();
        const [isLoading, setIsLoading] = useState(true);
        const [step, setStep] = useState(1);
        const [requestStep, setRequestStep] = useState(1);
        const [votes, setVotes] = useState();
        const [isVotesRequested, setIsVotesRequested] = useState();
        const [isRequesting, setIsRequesting] = useState(false);

    const getKYC = async () => {
        try {
            setIsLoading(true);
            let votesResponse;
            const response = await apiService.raiseme.getKYCByLocation('Canada');
            if(response.status === 200 || response.status === 201){
                setKycData(response.data);
            }

          if(isAdminComm){
            votesResponse = await apiService.community.getVotes({
                user_id: profile.profile.userId,
                comm_uuid: community.commUuid
            })
          }else {
            votesResponse = await apiService.community.getDemocratizedVotes({
                user_id: profile.profile.userId,
                comm_uuid: community.commUuid
            })
          }
        setIsVotesRequested(true);
        setVotes(votesResponse.data);
        } catch (e) {
          if(e.response.status === 404){
            setIsVotesRequested(false);
          }else{
            toast.error(e.response.data["code "]);
          }
        } finally{
            setIsLoading(false);
        }
      }
    const handleWithdrawal = async () => {
        try {
            setIsWithdrawing(true);
            const customerNumber = parseInt(kycData.customerNumber, 10);
            const requestBody = {
                customer_number: customerNumber,
                amount: intAmount,
                transit_number: selectedAccount.routing_transit_number,
                account_number: `${selectedAccount.account_number}`,
                bank_number: selectedAccount.financial_institution_branch,
                comm_uuid: community.commUuid
            }
            const response = await apiService.community.walletWithdrawal(requestBody);
            if(response.status === 200 || response.status === 201){
                toast.success('Withdrawal Initiated Successfully');
                setStep(step + 1);
            }
        } catch (e) {
            
        } finally{
            setIsWithdrawing(false)
        }
    }

    const requestWithdrawal = async () => {
        try {
            setIsRequesting(true);
            const response = await apiService.notification.push({
                comm_uuid: community.commUuid,
                sender_id: profile.profile.userId,
                notification_type: isAdminComm ? 'adminManageCommWithdrawal' : 'democratizedCommWithdrawal',
                delivery_type: 2
            })
            if(response.status === 201 || response.status === 200){
                toast.success('Request Sent Successfully');
                setRequestStep(requestStep + 1);
            }
        } catch (e) {
            
        } finally{
            setIsRequesting(false)
        }
    }

    useEffect(() => {
        getKYC();
    },[])

    let validVote = null;
    if(votes){
        validVote = votes.endorsementCount>=votes.requiredEndorsement;
      }
  return (
    <>
    {isLoading ? (
        <Box gap={'0.5rem'} height={'300px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress style={{ color: '#a06ccb'}}/>
        <p style={{color: 'black', textAlign: 'center'}}>Loading...</p>
        </Box>
    ) : (
        <>
        {!isVotesRequested ? (
            <>
                {requestStep === 1 && (
                    <Box sx={{width: '100%'}}>
                        <Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <h3>Request Withdrawal</h3>
                                <p style={{marginBottom: '5px'}}>{isAdminComm ? 'When you request a withdrawal, a request gets sent to all the admins of the community and when 2/3 of the admins accept your request, you can withdraw' : 'When you request a withdrawal, a request gets sent to all the members of the community and when a simple majority of the members accept your request, you can withdraw'}</p>
                            </Box>
                            <Box>
                                <Button className='button'
                                onClick={requestWithdrawal}
                                disabled={isRequesting}
                                sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}
                                type='submit'
                                >{isRequesting ? 'Requesting' : 'Request Withdraw'}</Button>
                            </Box>
                        </Box>
                    </Box>
                )}
                {requestStep === 2 && (
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        <h2 style={{textAlign: 'center', display: 'inline'}} className='blackText'>You have sucessfully requested to make withdrawal in <b>{community.commName}</b> community</h2>
                        <p>{isAdminComm ? 'You need 2/3 of the community admins to endorse your withdrawal' : 'You need the simple majority of the community to endorse your withdrawal'}</p>
                        <Lottie animationData={Successful}/>
                    </Box>
                )}
            </>
        ) : (
            <>
            {step === 1 && (
                <Box sx={{width: '100%'}}>
                            <Box>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                    <Chip sx={{backgroundColor: 'light.main',
                                                "&:hover": {
                                                backgroundColor: "light.pending"
                                            },
                                    }} icon={<ArrowBack />} label="Back" onClick={handleBackButtonClick}/>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p>Wallet Balance</p>
                                    <h2 className='purple-text withdrawal-balance'>
                                    {community.commBalanceVisibility ? `$${availableBalance}` : '$****'}
                                    </h2>
                                    {votes && !validVote && (
                                        <>
                                        <Stack direction={'column'} spacing={1} width={'100%'} marginBottom={'10px'}>
                                        <progress className='aid-lounge-progress' value={votes.endorsementCount} max={votes.requiredEndorsement} />
                                        <p>{votes.requiredEndorsement - votes.endorsementCount} vote(s) left</p>
                                        </Stack>
                                        </>
                                    )}
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                                    <AccountsDropdown filteredAccounts={filteredAccounts} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} handleSelectAccount={handleSelectAccount}/>
                                    <p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1rem'}}>Enter your withdrawal amount</p>
                                    <Box sx={{marginBottom: '10px'}} className='donate-input-container'>
                                            <Stack sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <MonetizationOn/>
                                                <h3>CAD</h3>
                                            </Stack>
                                            <Stack direction={'row'} style={{width: '100%'}}>
                                                <input style={{width: '100%'}} className='donate-input'
                                                type="text" inputMode='numeric' autoComplete='off'
                                                maxLength={6} autoFocus onChange={handleAmount}
                                                value={formatAmountWithCommas(amount)}
                                                required
                                                />
                                                <h3 className='donate-h3'>.00</h3>
                                            </Stack>
                                        </Box>
                                </Box>
                            </Box>
                            <Box>
                            <Button className='button'
                            onClick={handleWithdrawal}
                            disabled={!selectedAccount || amount === '' || amount === '0' || intAmount === 0 || intAmount > availableBalance || !kycData || !validVote}
                            sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}
                            type='submit'
                            >{isWithdrawing ? 'Withdrawing' : 'Withdraw'}</Button>
                            </Box>
                </Box>
            )}
            {step === 2 && (
                    <>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '10px'}}>
                            <h2 style={{textAlign: 'center', display: 'inline'}} className='blackText'>You have sucessfully requested to make withdrawal from <b>{community.commName}</b> community</h2>
                            <p>Check your email to process your request</p>
                            <Lottie animationData={Successful}/>
                        </Box>
                    </>
                )}
            </>
        )}
        <>
        </>
        </>
    )}  
    </>
  )
}

export default WithdrawWallet