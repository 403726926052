import { Box, Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import toast from "react-hot-toast";
import { useApiService } from '../../services/api-service';
import { Cancel } from '@mui/icons-material';

const ReportPost = ({refreshPosts, selectedData, handleClose}) => {
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const apiService = useApiService();

  const handleReasonChange = (event) => {
    setReason(event.target.value);
    console.log('data', selectedData)
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        setSubmitting(true);
        if(selectedData.lounge_id){
            await toast.promise(
                apiService.lounge.reportPost({
                    post_id: selectedData.post_id,
                    report_reason: description,
                    report_type: reason
                }),
                {
                    loading: 'Reporting & Hiding Post...',
                    success: 'Post reported successfully',
                    error: 'Error reporting post'
                }
            )
        } else {
            await toast.promise(
                apiService.community.reportPost({
                    post_id: selectedData.post_id,
                    report_reason: description,
                    report_type: reason
                }),
                {
                    loading: 'Reporting & Hiding Post...',
                    success: 'Post reported successfully',
                    error: 'Error reporting post'
                }
            )
        }
        handleClose();
        refreshPosts();
    } catch (e) {
        if(e.response){
            toast.error(e.response.data["code "]);
        }
    } finally{
        setSubmitting(false);
    }
  };
  return (
    <Box padding={'0 1rem'}>
        <Box sx={{width: '100%', display: 'flex', alignItems: 'flex-end', justifyItems: 'flex-end'}}>
        <Cancel onClick={handleClose} sx={{cursor: 'pointer', marginLeft: 'auto'}}/>
        </Box>
        <h2 style={{color: 'black', textAlign: 'center'}}>Submit a report</h2>
        <p>Thanks for looking out for yourself and your fellow myareaa community by reporting things that break the rules. Let us know what's happening, and we'll look into it. </p>
        <Stack direction={'column'} spacing={2} marginTop={'10px'}>
        <select value={reason} onChange={handleReasonChange}>
            <option value={''}>Select a reason</option>
            <option value={'Spamming'}>Spamming</option>
            <option value={'Abuse'}>Abuse/Harassment</option>
            <option value={'Inappropraite Content'}>Inappropraite Content</option>
            <option value={'Copyright Violation'}>Copyright Violation</option>
            <option value={'Fake News'}>Fake News</option>
            <option value={'Impersonation'}>Impersonation</option>
            <option value={'Scam/Fraud'}>Scam/Fraud</option>
            <option value={'Terrorism/Violent Extremism'}>Terrorism/Violent Extremism</option>
            <option value={'Child Exploitation'}>Child Exploitation</option>
            <option value={'Hate Speech'}>Hate Speech</option>
            <option value={'Privacy Violation'}>Privacy Violation</option>
            <option value={'Quality/Accuracy'}>Quality/Accuracy</option>
            <option value={'Sensitive Information'}>Sensitive Information</option>
            <option value={'Malware/Phishing'}>Malware/Phishing</option>
            <option value={'Suicide/Self-Harm Incitement'}>Suicide/Self-Harm Incitement</option>
            <option value={'Pornography'}>Pornography</option>
        </select>
        <textarea
        className='create-aid-input'
        placeholder='Enter report Reason'
        rows={4}
        value={description}
        onChange={handleDescriptionChange}
        />
        <Button onClick={handleSubmit} disabled={reason === '' || description === '' || submitting}
        sx={{color: 'white', width: '100%'}} className='button'>
            {submitting ? 'Reporting...' : 'Report'}
            </Button>
        </Stack>
    </Box>
  )
}

export default ReportPost