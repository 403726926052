import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './appRoutes'
import { AuthServiceProvider } from '../services/auth-service'
import { ApiServiceProvider } from '../services/api-service'
import { ProfileServiceProvider } from '../services/profile-service'
import './App.css' /* import css file*/
import { SkeletonTheme } from 'react-loading-skeleton'
import { CommunityProvider } from '../contexts/CommunityContext'
import { CommunityPostsProvider } from '../contexts/CommunityPostsContext'
import { LoungePostsProvider } from '../contexts/LoungePostsContext'
import { ThemeProvider, createTheme } from '@mui/material'
import { deepPurple, green, grey, orange, red } from '@mui/material/colors'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryComponent from '../components/errors/ErrorBoundaryComponent'
import { LoadingFullScreen, LoadingPopup } from '../utils/routes'
import { Toaster } from 'react-hot-toast'
import { NotificationProvider } from '../contexts/NotificationContext'
import { messaging, requestFCMToken } from '../utils/firebaseUtils'
import { onMessage } from 'firebase/messaging'

function App () {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      light: {
        main: '#a06ccb',
        palemain: '#e9def2',
        pending: grey[500],
        success: green[500],
        failed: red[500],
        warning: orange[800]
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputLabel-root": {
              color: '#a06ccb',
              fontSize: '13px',
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: '#a06ccb',
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: '#a06ccb',
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: '#a06ccb',
            },
            "&.MuiInputBase-root": {
              color: '#333',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& .MuiInputLabel-root": {
              fontSize: '13px',
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: '#a06ccb',
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: '#a06ccb',
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: '#a06ccb',
            },
            "& .MuiInputBase-root": {
              color: '#a06ccb',
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: 'lightgrey',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#a06ccb',
            '&.Mui-selected': {
              color: '#a06ccb',
            },
          },
        },        
      },
      MuiTable:{
        styleOverrides: {
          root: {
            fontFamily: 'Nunito Sans'
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: '#a06ccb',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            '&.MuiTableRow-head': {
              color: 'black',
              fontFamily: 'Nunito Sans'
            }
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: 'white',
            '&.MuiTableCell-body': {
              color: 'black',
              fontFamily: 'Nunito Sans'
            }
          },
        },
      },
      MuiInputBase:{
        styleOverrides: {
          root: {
            '&.MuiSelect-select': {
              color: 'black'
            }
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'Nunito Sans',
            color: '#a06ccb',
            '&.Mui-selected': {
              color: '#a06ccb'
            }
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            fontFamily: 'Nunito Sans',
            '&.MuiTabs-indicator': {
              backgroundColor: '#a06ccb'
            }
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: '#c6c3bd',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: 'white'
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Nunito Sans'
          },
        },
      },
      MuiIconButton:{
        styleOverrides: {
          root: {
            backgroundColor: '#a06ccb'
          },
        },
      },
      MuiCheckbox:{
        styleOverrides: {
          root: {
            fontFamily: 'Nunito Sans',
            color: '#a06ccb',
            '&.Mui-checked': {
              color: '#a06ccb'
            }
          },
        },
      },
      MuiRadio:{
        styleOverrides: {
          root: {
            fontFamily: 'Nunito Sans',
            color: '#a06ccb',
            '&.Mui-checked': {
              color: '#a06ccb'
            },
            '&.Mui-disabled':{
              color: '#595c60'
            }
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root: {
            fontFamily: 'Nunito Sans'
          },
        },
      },
      MuiBackdrop:{
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(0 0 0 / 10%)',
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "mintcream"
          }
        }
      }
    },
  });

  useEffect(() => {
    requestFCMToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.image,
      };
  
      if (Notification.permission === "granted") {
        const notification = new Notification(notificationTitle, notificationOptions);
        notification.onclick = () => {
          window.open("https://webapp.myareaa.com/", "_blank");
        };
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const notification = new Notification(notificationTitle, notificationOptions);
            notification.onclick = () => {
              window.open("https://webapp.myareaa.com/", "_blank");
            };
          }
        });
      }
    });
  }, []);
  
  
  return (
    <ThemeProvider theme={theme}>
    <div className='app'>
      {/* <div className='bg_blur' style={{ top: '-18%' }}></div>
      <div className='bg_blur' style={{ top: '36%', left: '-8rem' }}></div> */}
      <ErrorBoundary
      FallbackComponent={ErrorBoundaryComponent}
      onError={() => console.log('Error Occured')}
      >
        <BrowserRouter>
      <AuthServiceProvider>
        <ApiServiceProvider>
          <ProfileServiceProvider>
            <NotificationProvider>
            <CommunityProvider>
            <SkeletonTheme baseColor='#f1f2f3' highlightColor='#e9ecef'>
            
              <Toaster
                position="center"
                reverseOrder={false}
              />
              <Suspense fallback={LoadingFullScreen()}>
              <CommunityPostsProvider>
                <LoungePostsProvider>
                <AppRoutes />
                </LoungePostsProvider>
                </CommunityPostsProvider>
              </Suspense>
            
            </SkeletonTheme>
            </CommunityProvider>
            </NotificationProvider>
          </ProfileServiceProvider>
        </ApiServiceProvider>
      </AuthServiceProvider>
      </BrowserRouter>
      </ErrorBoundary>
    </div>
    </ThemeProvider>
  )
}

export default App