// AccountsDropdown.jsx
import React, { useState } from 'react';
import './raiseMeAccount.css';
import { ArrowDropDown, NewReleases, Verified } from '@mui/icons-material';
import { Button, Chip } from '@mui/material';

const AccountsDropdown = ({ filteredAccounts, selectedAccount, handleSelectAccount, setSelectedAccount }) => {
  const [isActive, setIsActive] = useState(false);

  const resetSelection = () => {
    handleSelectAccount(null); // Reset the selected account in the parent component
    setSelectedAccount(''); // Reset the selected account name
    setIsActive(false); // Close the dropdown
  };

  return (
    <div className={`account-dropdown ${isActive ? 'active' : ''}`}>
      <div className="account-dropdown-btn" onClick={() => setIsActive(!isActive)}>
      {selectedAccount ? `${selectedAccount.financial_institution_name} - ${selectedAccount.account_number}` : 'Select Bank Account'}
        <ArrowDropDown />
      </div>
      {isActive && (
        <div className="account-dropdown-content">
            <div
            className="account-dropdown-item"
            onClick={resetSelection}
          >
            Select Bank Account
          </div>
          {filteredAccounts.map((account) => (
            <div
              key={account.id}
              className="account-dropdown-item"
              onClick={() => {
                handleSelectAccount(account);
                setSelectedAccount(account)
                setIsActive(false);
              }}
            >
              {account.financial_institution_name} - {account.account_number}
              <Chip icon={account.partner_status === null ? <NewReleases/> : <Verified/>}
              label={account.partner_status === null ? 'Unverified' : 'Verified'}
              size='small' sx={{ backgroundColor: account.partner_status === null ? "light.failed" : "light.success", width: '30%' }}/>
            </div>
          ))}
        </div>
      )}
      {selectedAccount !== "" && selectedAccount.partner_status !== 'ACCOUNT_VERIFICATION_DONE' ?
          <Button sx={{backgroundColor: 'light.main', color: 'white', marginTop: '20px', width: '100%'}}
           variant='contained' target='_blank' href={selectedAccount.verify_url}>Verify Account</Button> : null}
    </div>
  );
};

export default AccountsDropdown;
